import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import * as Yup from "yup";
import React from "react";

// Customizable Area Start
import { isEqual, isEmpty, property } from "lodash";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  step: number;
  stateList: any;
  homeTypeList: any;
  statusList: any;
  snackBar: {
    show: boolean;
    message?: string;
    type?: "success" | "info" | "warning" | "error" | undefined;
  };
  startDate: any;
  endDate: any;
  addressDetailSchema: any;
  basicDetailsSchema: any;
  roomDetailList: any;

  file: any;
  uploadMediaSchema: any;
  pricingSchema: any;
  checkListSchema: any;
  amanitiesList: any;
  reviewList: any;
  propertiesDoc: {
    appraisal: boolean;
    inspection: boolean;
    title: boolean;
  };
  docFile: any;
  counter: number;
  termsAndConditionShow: boolean;
  sucessPropertyShow: boolean;
  working_items: number;
  not_working_items: number;
  address_details: any;
  basic_details: any;
  upload_media: any;
  pricing: any;
  checklist: any;
  property_id: any;
  showFile: any;
  publishSchema: any;
  HeaderDropdownFlag: any;
  detailFlag: any;
  paymentFlag: any;
  accDetail: any;
  accountDetailSchema: any;
  profileImage: any;
  upcomingPayment: any;
  PaymentHistory: any;
  // Customizable Area End
  DoughnutValue: any;
  DoughnutValueIncome: any;
  DoughnutValuerents: any;
  BarChartValue: any;
  BarChartOption: any;
  propertyTermsServicesContent: any;
  sortFlag: any,
  agreementSchema: any;
  agreementState: {
    reviewAgreement: boolean;
    billingDetail: boolean;
    paymentMethod: boolean;
  };
  reviewAgreementState: {
    agreement: boolean;
    ownerDetails: boolean;
    buyerDetails: boolean;
  };
  completeAgreementState: {
    review: boolean;
    billing: boolean;
    payment: boolean;
  };
  sucessAgreementShow: boolean;
  ownerDetails: any;
  buyerDetails: any;

}
interface SS {
  id: any;
}



export default class DashboardController extends BlockComponent<Props, S, SS> {
  [x: string]: any;
  apiAddressDetailCallId: string = "";
  apiBasicDetailCallId: string = "";
  getHomeTypeListId: string = "";
  getStateListId: string = "";
  getPropertyListId: string = "";
  getReviewListId: string = "";
  getPropertyTermsOfServicesContentId: string = "";

  apiUploadMediaCallId: string = "";
  getAmanitiesListId: string = "";
  apiPricingCallId: string = "";
  apiCheckListCallId: string = "";
  getReviewId: string = "";
  accDetailId: string = "";
  accSettingCallId: string = "";
  apiLinkTokenCallId: string = "";
  apiBankAccountCallId: string = "";
  apiDeleteBankAccCallId: string = "";
  getOwnerDetailId: string = "";
  getBuyerDetailId: string = "";
  agreementSignCallId: string = "";


  fileUpload: React.RefObject<HTMLInputElement>;
  files: any = [];
  urlReg: RegExp;
  phoneRegExp: RegExp;
  publishPropertyId: string;
  deleteImageId: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.fileUpload = React.createRef();
    this.showFileUpload = this.showFileUpload.bind(this);
    this.urlReg = new RegExp(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
    );
    this.phoneRegExp = new RegExp(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/);

    this.handleImageChange = this.handleImageChange.bind(this);
    // this.handleWorkingCountIncrement=this.handleWorkingCountIncrement.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    let addressDetailSchema = {
      address: Yup.string().required(configJSON.errorAddressNotValid),
      city: Yup.string().required(configJSON.errorCityNotValid),
      state: Yup.string().required(configJSON.errorStateNotValid),
      zip: Yup.string().required(configJSON.errorZipNotValid),
      homeType: Yup.string().required(configJSON.errorHomeTypeNotValid),
      status: Yup.string().required(configJSON.errorStatusNotValid),
      latitude: Yup.string(),
      longitude: Yup.string(),
    };

    let basicDetailsSchema = {
      totalRoom: Yup.number()
        .required(configJSON.errortotalRoomNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      bedrooms: Yup.number()
        .required(configJSON.errorbedroomsNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      floorNo: Yup.number()
        .required(configJSON.errorfloorNoNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      unit: Yup.number()
        .required(configJSON.errorunitNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      stories: Yup.number()
        .required(configJSON.errorstoriesNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      fullBaths: Yup.number()
        .required(configJSON.errorfullBathsNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      baths: Yup.number()
        .required(configJSON.errorbathsNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      style: Yup.number()
        .required(configJSON.errorstyleNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      squareFeet: Yup.number()
        .required(configJSON.errorsquareFeetNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      basement: Yup.number()
        .required(configJSON.errorbasementNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      lotSize: Yup.number()
        .required(configJSON.errorlotSizeNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      garage: Yup.number()
        .required(configJSON.errorgarageNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      yearBuilt: Yup.number()
        .required(configJSON.erroryearBuiltNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive)
      ,
      remodelYear: Yup.number()
        .required(configJSON.errorremodelYearNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      describeYourPlace: Yup.string()
        .required(
          configJSON.errordescribeYourPlaceNotValid
        ),
      loveAbout: Yup.string()
        .required(configJSON.errorloveAboutNotValid),
    };

    let uploadMediaSchema = {
      media: Yup.string(),
      videoUrlWalkthrough: Yup.string()
        .matches(this.urlReg, configJSON.errorEnterCorrectURL)
        .required(configJSON.errorVideoUrlWalkthroughNotValid),
      videoUrlVirtualTour: Yup.string()
        .matches(this.urlReg, configJSON.errorEnterCorrectURL)
        .required(configJSON.errorVideoUrlVirtualTourNotValid),
    };

    let pricingSchema = {
      price: Yup.number().
        required(configJSON.errorPriceNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      currency: Yup.string().required(configJSON.errorCurrencyNotValid),
      minDownpay: Yup.number()
        .required(configJSON.errorMinDownpayNotValid)
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      termPeriod: Yup.string().required(configJSON.errorTermNotValid),
      contactNumber: Yup.string()
        // .required()
        .matches(/^[0-9]+$/, configJSON.errorInteger)
        .min(4, configJSON.errorContactMinValue)
        .max(12, configJSON.errorContactMaxValue)
        // contactNumber: Yup.number()
        //   .integer(configJSON.errorInteger)
        //   .positive(configJSON.errorPositive)
        //   .test('len', 'Must be exactly 4 characters', val => val.length < 4)
        //   .test('len', 'Must be exactly 12 characters', val => val.length <= 12)
        // .min(4, configJSON.errorContactMinValue)
        // .max(12, configJSON.errorContactMaxValue)
        // .test(
        //   'is-decimal',
        //   configJSON.errorContactNumberNotValid,
        //   value => (value + "").match(this.phoneRegExp),
        // ),
        // .matches(this.phoneRegExp, configJSON.errorContactNumberNotValid)
        .required(
          configJSON.errorContactNumberNotValid
        ),
      termsConditions: Yup.bool().oneOf([true], 'Please Accept Terms & Conditions'),
      taxes: Yup.number()
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      hoaFees: Yup.number()
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      homeOwnerInsurance: Yup.number()
        .integer(configJSON.errorInteger)
        .positive(configJSON.errorPositive),
      additional_details: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("Name required"),
          value: Yup.number()
            .integer(configJSON.errorInteger)
            .positive(configJSON.errorPositive),
        })
      )
    };

    let checkListSchema = {
    };

    let publishSchema = {
      termsAndConditionAccepted: Yup.bool().oneOf([true], 'Please Accept Terms & Conditions'),

    };

    let accountDetailSchema = {
      email: Yup.string().required(configJSON.errorEmailNotValid),
      first_name: Yup.string().required(configJSON.errorFirstNameNotValid),
      middle_name: Yup.string(),
      last_name: Yup.string().required(configJSON.errorLastNameNotValid),
      address_line: Yup.string().required(configJSON.errorAddressLineNotValid),
      city: Yup.string().required(configJSON.errorCityNotValid),
      state: Yup.string().required(configJSON.errorStateNotValid),
      zip_code: Yup.string().required(configJSON.errorZipCodeNotValid),
      address_line_2: Yup.string(),
    }

    let agreementSchema = {
      owner_sign: Yup.string().required(configJSON.errorOwnerSignNotValid),
      buyer_sign: Yup.string().required(configJSON.errorBuyerSignNotValid),
      agreementCheck: Yup.bool().oneOf([true], 'Please Accept Terms & Conditions'),
    };


    this.state = {
      DoughnutValue: {
        datasets: [
          {
            data: [200, 100],
            backgroundColor: ["#ff8800", '#e9eaee'],
            cutout: 37,
            spacing: 0,
            borderWidth: 0,
            responsive: true,
            maintainAspectRatio: true,
          }
        ]
      },
      DoughnutValueIncome: {
        datasets: [
          {
            data: [200, 100],
            backgroundColor: ["#22456e", '#e9eaee'],
            cutout: 37,
            spacing: 0,
            borderWidth: 0,
            responsive: true,
            maintainAspectRatio: true,
          }
        ]
      },
      DoughnutValuerents: {
        datasets: [
          {
            data: [200, 100],
            backgroundColor: ["#aadc91", '#e9eaee'],
            cutout: 37,
            spacing: 0,
            borderWidth: 0,
            responsive: true,
            maintainAspectRatio: true,
          }
        ]
      },
      BarChartValue: {
        labels: ["Mar", "Apr", "May", "June", "July", "Aug", "June", "July", "Aug", "June", "July", "Aug"],
        datasets: [
          {
            barThickness: 10,
            maxBarThickness: 4,
            label: "Option 1",
            backgroundColor: "#40acc2",
            data: [5, 3, 2, 6, 3, 6, 8, 0, 2, 5, 7, 2],
            responsive: true,
          },
          {
            barThickness: 10,
            maxBarThickness: 4,
            label: "Option 2",
            backgroundColor: "#827fd2",
            data: [3, 7, 6, 3, 6, 8, 0, 2, 5, 7, 9, 2],
            responsive: true,
          },
          {
            barThickness: 10,
            maxBarThickness: 4,
            label: "Option 3",
            backgroundColor: "#f69c36",
            data: [7, 6, 4, 5, 3, 2, 6, 3, 6, 8, 2, 0],
            responsive: true,
          }
        ]
      },
      BarChartOption: {
        maintainAspectRatio: false
      },
      dashboardData: [],
      accDetail: {},
      errorMsg: "",
      token: "",
      profileImage: "",
      loading: false,
      HeaderDropdownFlag: false,
      step: 1,
      startDate: new Date(),
      endDate: "",
      stateList: [
        {
          label: "Alexandria",
          value: 1,
        },
        {
          label: "Aurora",
          value: 2,
        },
        {
          label: "Boston",
          value: 3,
        },
        {
          label: "Charlotte",
          value: 4,
        },
      ],
      homeTypeList: [],
      statusList: [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "In-Active",
          value: "inactive",
        },
      ],
      snackBar: {
        show: false,
      },
      addressDetailSchema: addressDetailSchema,
      basicDetailsSchema: basicDetailsSchema,
      pricingSchema: pricingSchema,
      checkListSchema: checkListSchema,
      detailFlag: true,
      paymentFlag: false,
      upcomingPayment: true,
      PaymentHistory: false,
      roomDetailList: [],
      file: [],
      counter: 0,
      uploadMediaSchema: uploadMediaSchema,
      amanitiesList: [],
      reviewList: [],
      propertiesDoc: {
        appraisal: false,
        inspection: false,
        title: false,
      },
      docFile: "",
      termsAndConditionShow: false,
      sucessPropertyShow: false,
      working_items: 0,
      not_working_items: 0,
      address_details: {},
      basic_details: {},
      upload_media: {},
      pricing: {},
      checklist: {},
      property_id: "",
      showFile: [],
      publishSchema: publishSchema,
      accountDetailSchema: accountDetailSchema,
      propertyTermsServicesContent: {},
      sortFlag: false,
      agreementSchema: agreementSchema,
      agreementState: {
        reviewAgreement: true,
        billingDetail: false,
        paymentMethod: false,
      },
      reviewAgreementState: {
        agreement: true,
        ownerDetails: false,
        buyerDetails: false,
      },
      completeAgreementState: {
        review: false,
        billing: false,
        payment: false,
      },
      sucessAgreementShow: false,
      ownerDetails: {},
      buyerDetails: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    if (localStorage.getItem("role") == "buyer") {
      console.log("it did work");

    }
    super.componentDidMount();
    this.getHomeType();
    this.getStateList();
    this.getPropertyLists();
    this.getAmanitiesLists();
    this.getReview();
    //@ts-ignore
    if (this.props?.history?.location?.pathname === "/account-setting") this.getAccountDetail();
    this.fetchLinkToken();
  }

  async componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
  }


  fetchLinkToken() {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      // @ts-ignore
      token: localStorage.getItem("auth")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiLinkTokenCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTokenApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  activeDetailtoggle = () => {
    this.setState({
      ...this.state, detailFlag: !this.state.detailFlag, paymentFlag: !this.state.paymentFlag,
    })
  };

  paymentToggle = () => {
    this.setState({
      ...this.state, upcomingPayment: !this.state.upcomingPayment, PaymentHistory: !this.state.PaymentHistory,
    })
  };

  getAddressMountAPI = () => {
    this.getHomeType();
  };

  doToggleHeaderDropdown = () => {
    this.setState({
      ...this.state,
      HeaderDropdownFlag: !this.state.HeaderDropdownFlag
    })
  }

  setProfileImg = (value: any, setFieldValue: any) => {
    this.setState({
      ...this.state,
      profileImage: value
    }, () => {
      setFieldValue("profile_picture", this.state.profileImage)
    })
  }

  doDeleteBankAcc = (id: any) => {
    const header = {
      "token": localStorage.getItem("auth"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiDeleteBankAccCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteBankAccountAPiEndPoint + id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  onSuccess = (publicToken: string, metadata: object) => {
    const header = {
      "Content-Type": "application/json",
      "token": localStorage.getItem("auth"),
    };

    const attrs = {
      public_token: publicToken,
      metadata: metadata
    };;

    const httpBody = {
      data: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBankAccountCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bankAccountApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getHomeType = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHomeTypeListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllHomeTypeListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getStateList = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStateListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStateApiEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getAccountDetail = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accDetailId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAccountDetailApiEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getPropertyLists = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPropertyListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllPropertyListApiEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //Get Amanities
  getAmanitiesLists = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAmanitiesListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAmanitiesListApiEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //Get Review
  // Review API
  getReview = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReviewListId = requestMessage.messageId;
    let endpoint = `${configJSON.getAllReviewApiEndPoints}${localStorage.getItem("property_id") ? localStorage.getItem("property_id") : ""}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  doStepSet = (values: any): boolean => {
    this.setState({
      ...this.state,
      step: values,
    });
    return true;
  };

  doAddressDetails = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const attrs = {
      latitude: 12.3456,
      longitude: 34.5678,
      address_line: values.address,
      city: values.city,
      state: values.state,
      zip_code: values.zip,
    };

    const data = {
      address_attributes: attrs,
      home_type_id: values.homeType,
      status: values.status,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAddressDetailCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllAddressDetailsApiEndPoints}/${localStorage.getItem("property_id") ? localStorage.getItem("property_id") : ""}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      localStorage.getItem("property_id") ? configJSON.putApiMethodType : configJSON.postApiMethodType
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  doBasicDetails = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const attrs = {
      total_rooms: values.totalRoom,
      bedrooms: values.bedrooms,
      floor_number: values.floorNo,
      number_of_unit: values.unit,
      number_of_stories: values.stories,
      full_baths: values.fullBaths,
      half_baths: values.baths,
      style: values.style,
      finished_sq_ft: values.squareFeet,
      lot_size: values.lotSize,
      lot_unit: values.lotSqft,
      basement_sq_ft: values.basement,
      garage_space: values.garage,
      year_built: values.yearBuilt,
      structural_remodel_year: values.remodelYear,
      description: values.describeYourPlace,
      about: values.loveAbout,
      parking_space: values.parkingSpaces,
      details: values.details,
      property_open_houses_attributes: values.openHouse,
    };

    const data = attrs;

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBasicDetailCallId = requestMessage.messageId;
    const endpoint =
      configJSON.getAllBasicDetailsApiEndPoints +
      localStorage.getItem("property_id");

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Upload Media API
  doUploadMedia = (values: any): boolean => {
    const formData = new FormData();
    formData.append("data[video_walkthrough_url]", values.videoUrlWalkthrough);
    formData.append("data[virtual_tour_url]", values.videoUrlVirtualTour);
    // formData.append("data[images]", Object.entries(values.media||[]));
    // let media: [] = values.media;

    values.media.map((img: any, index: any) => {
      return formData.append(`data[images][]`, img);
    });

    const header = {
      // "Content-Type": configJSON.fileContentType,
      token: localStorage.getItem("auth"),
    };

    // const attrs = {
    //   video_walkthrough_url: values.videoUrlWalkthrough,
    //   virtual_tour_url: values.videoUrlVirtualTour,
    //   images: values.media,
    // };
    const attrs = formData;

    const httpBody = {
      data: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUploadMediaCallId = requestMessage.messageId;

    const endpoint =
      configJSON.getAllUploadMediaApiEndPoints +
      localStorage.getItem("property_id");

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Pricing API
  doPricing = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const attrs = {
      price: values.price,
      currency: values.currency,
      min_down_payment: values.minDownpay,
      term_period: values.termPeriod,
      property_additional_details_attributes: values.additional_details,
      contact_number: values.contactNumber,
      taxes: values.taxes,
      hoa_fees: values.hoaFees,
      homeowner_insurance: values.homeOwnerInsurance,
      agreed_terms: values.termsConditions,
      step_number: values.stepNumber,
    };

    const data = attrs;

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPricingCallId = requestMessage.messageId;

    const endpoint =
      configJSON.getAllPricingApiEndPoints +
      localStorage.getItem("property_id");

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // CheckList API
  doCheckList = (values: any): boolean => {
    const formData = new FormData();
    !values.remove_appraisal_document && typeof values.appraisalDoc !== "string" && formData.append("data[appraisal_document]", values.appraisalDoc);
    !values.remove_inspection_document && typeof values.inspectionDoc !== "string" && formData.append("data[inspection_document]", values.inspectionDoc);
    !values.remove_title_report && typeof values.titleDoc !== "string" && formData.append("data[title_report]", values.titleDoc);
    values.remove_title_report && formData.append("data[remove_title_report]", values.remove_title_report);
    values.remove_inspection_document && formData.append("data[remove_inspection_document]", values.remove_inspection_document);
    values.remove_appraisal_document && formData.append("data[remove_appraisal_document]", values.remove_appraisal_document);
    values.propertyAmenities.map((item: any, index: any) => {
      return formData.append(`data[property_amenities_attributes][${index}][id]`, item.id);
    });
    values.propertyAmenities.map((item: any, index: any) => {
      return formData.append(`data[property_amenities_attributes][${index}][amenity_id]`, item.amenity_id);
    });
    values.propertyAmenities.map((item: any, index: any) => {
      return formData.append(`data[property_amenities_attributes][${index}][status]`, item.status);
    });
    values.propertyAmenities.map((item: any, index: any) => {
      return formData.append(`data[property_amenities_attributes][${index}][note]`, item.note);
    });


    const header = {
      // "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const attrs = formData;

    const httpBody = {
      data: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCheckListCallId = requestMessage.messageId;

    const endpoint =
      configJSON.getAllCheckListApiEndPoints +
      localStorage.getItem("property_id");

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //Publish API
  doPublish = (values: any) => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const attr = {
      terms_and_condition_accepted: values.termsAndConditionAccepted,
      step_number: values.stepNumber,
    }
    const httpBody = {
      data: attr,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.publishPropertyId = requestMessage.messageId;
    const endpoint =
      configJSON.publishPropertyApiEndPoints +
      localStorage.getItem("property_id") +
      "/publish";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //Delete Image
  doDeleteImage = (id: number) => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteImageId = requestMessage.messageId;
    const endpoint =
      configJSON.publishPropertyApiEndPoints +
      localStorage.getItem("property_id") +
      "/property_images/" +
      id;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //Privacy Policy
  getPropertyTermsOfServicesContent = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      token: localStorage.getItem("auth"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPropertyTermsOfServicesContentId = requestMessage.messageId;
    // let endpoint = `${configJSON.getAllPropertyTermsOfServicesContentApiEndPoints}}`
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllPropertyTermsOfServicesContentApiEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  deleteFile = (e: any, setFieldValue: any) => {
    const s = this.state.file.filter((item: any, index: any) => index !== e);
    this.setState({ file: s }, () => setFieldValue("media", this.state.file));
  };

  showFileUpload = (e: any) => {
    e.preventDefault();
    if (this.fileUpload.current != null) {
      this.fileUpload.current.click();
    }
  };

  //New
  handleImageChange = (e: any, setFieldValue: any) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map(
        (file: any, index: number) => {
          return file;
        }
      );
      this.setState({ file: this.state.file.concat(filesArray) }, () => {
        setFieldValue("media", this.state.file);
      });
    }
  };

  handleUploadDocChange = (e: any, setFieldValue: any, docType: string) => {
    if (e.target.files) {
      let reader = new FileReader();
      let file = e.target.files[0];
      reader.onloadend = () => {
        this.setState({
          docFile: reader.result,
        });
      };
      reader.readAsDataURL(file);
      setFieldValue(docType, URL.createObjectURL(e.target.files[0]));
    }
  };
  // Customizable Area Start

  handleShowTermsAndCondition = () => {
    this.setState({ termsAndConditionShow: true });
  };

  handleHideTermsAndCondition = () => {
    this.setState({ termsAndConditionShow: false });
  };

  handleShowSuccessModal = () => {
    this.setState({ sucessPropertyShow: true });
  };

  handleHideSuccessModal = () => {
    this.setState({ sucessPropertyShow: false });
  };

  handleWorkingCountIncrement = () => {
    this.setState((prev) => {
      working_items: prev.working_items + 1;
    });
  };

  doDetail = (detail: any) => {
    let newData = [];
    if (!isEmpty(detail)) {
      if (!isEmpty(detail.basement)) {
        newData.push({ type: "Basement", id: detail.basement.id });
      }
      if (!isEmpty(detail.floors)) {
        let floor = Object.assign([], detail.floors);
        newData.push(...floor.map((r: any) => ({ type: "Floor", id: +r.id })));
      }
      // -------------------------
      if (!isEmpty(detail.cooling_types)) {
        let ct = Object.assign([], detail.cooling_types);
        newData.push(...ct.map((r: any) => ({ type: "CoolingType", id: +r.id })));
      }
      if (!isEmpty(detail.heating_fuels)) {
        let hf = Object.assign([], detail.heating_fuels);
        newData.push(...hf.map((r: any) => ({ type: "HeatingFuel", id: +r.id })));
      }
      if (!isEmpty(detail.heating_types)) {
        let ht = Object.assign([], detail.heating_types);
        newData.push(...ht.map((r: any) => ({ type: "HeatingType", id: +r.id })));
      }
      if (!isEmpty(detail.indoor_features)) {
        let inf = Object.assign([], detail.indoor_features);
        newData.push(...inf.map((r: any) => ({ type: "IndoorFeature", id: +r.id })));
      }
      if (!isEmpty(detail.outdoor_amenities)) {
        let oua = Object.assign([], detail.outdoor_amenities);
        newData.push(...oua.map((r: any) => ({ type: "OutdoorAmenity", id: +r.id })));
      }
      if (!isEmpty(detail.outside_views)) {
        let ouv = Object.assign([], detail.outside_views);
        newData.push(...ouv.map((r: any) => ({ type: "OutsideView", id: +r.id })));
      }
      if (!isEmpty(detail.appliances)) {
        let apl = Object.assign([], detail.appliances);
        newData.push(...apl.map((r: any) => ({ type: "Appliance", id: +r.id })));
      }
      if (!isEmpty(detail.parkings)) {
        let park = Object.assign([], detail.parkings);
        newData.push(...park.map((r: any) => ({ type: "Parking", id: +r.id })));
      }
      if (!isEmpty(detail.roofs)) {
        let roof = Object.assign([], detail.roofs);
        newData.push(...roof.map((r: any) => ({ type: "Roof", id: +r.id })));
      }
      if (!isEmpty(detail.rooms)) {
        let room = Object.assign([], detail.rooms);
        newData.push(...room.map((r: any) => ({ type: "Room", id: +r.id })));
      }
    }
    return newData;
  };

  // Account Setting API
  doAccountSetting = (values: any): boolean => {
    const headers = {
      // "Content-Type": configJSON.fileContentType,
      token: localStorage.getItem("auth"),
    };

    const formData = new FormData();
    for (let i = 0; i < Object.keys(values).length; i++) {
      if (values[Object.keys(values)?.[i]]) {
        if (Object.keys(values)[i] === "address_line" || Object.keys(values)[i] === "city" ||
          Object.keys(values)[i] === "state" || Object.keys(values)[i] === "zip_code" ||
          Object.keys(values)[i] === "address_line_2") {
          formData.append(`data[address_attributes][${Object.keys(values)[i]}]`, values[Object.keys(values)?.[i]])
        } else if (Object.keys(values)[i] === "profile_picture" && typeof values.profile_picture !== "object") {
          // console.log("values.profile_picture", values.profile_picture)
        }
        else {
          formData.append(`data[${Object.keys(values)[i]}]`, values[Object.keys(values)?.[i]])
        }
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accSettingCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAccountDetailApiEndPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    // for(var pair of formData.entries()) {
    //   console.log(pair[0]+ ', '+ pair[1]);
    // }

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  // Toogle Sort 
  doSortToggle = () => {
    this.setState({
      ...this.state,
      sortFlag: !this.state.sortFlag
    })
  }

  //Get Owner/Buyer Detail Details
  // getUserDetail = (id: any, type: string) => {
  //   const headers = {
  //     "Content-Type": configJSON.dashboarContentType,
  //     token: localStorage.getItem("auth"),
  //   };

  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   let endpoint;
  //   if (type == "owner") {
  //     this.getOwnerDetailId = requestMessage.messageId;
  //     endpoint = configJSON.getOwnerDetailApiEndPoints;
  //   }
  //   else if (type == "buyer") {
  //     this.getBuyerDetailId = requestMessage.messageId;
  //     endpoint = configJSON.getBuyerDetailApiEndPoints;

  //   }
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     endpoint + id
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(headers)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.getApiMethodType
  //   );

  //   runEngine.sendMessage(requestMessage.id, requestMessage);

  //   return true;
  // };

  // Signature agreement
  // doAgreementSign = (values: any, id: any): boolean => {
  //   const header = {
  //     "Content-Type": configJSON.dashboarContentType,
  //     // token: localStorage.getItem("auth"),
  //   };

  //   const attrs = {
  //     agreement_id: id,
  //     signature:
  //     {
  //       buyer_signature: values?.buyer_sign,
  //       seller_signature: values?.owner_sign
  //     }
  //   };


  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.agreementSignCallId = requestMessage.messageId;


  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.agreementSignApiEndPoints
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     JSON.stringify(attrs)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     configJSON.patchApiMethodType
  //   );

  //   runEngine.sendMessage(requestMessage.id, requestMessage);

  //   return true;
  // };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("runEngine.debugLog", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          // Home Type List API
          if (
            apiRequestCallId === this.getHomeTypeListId &&
            responseJson !== undefined
          ) {
            this.openSnackBarHandler("success", configJSON.successLogin);
            this.setState({
              ...this.state,
              homeTypeList: responseJson.data,
            });
          }
          // State List
          if (
            apiRequestCallId === this.getStateListId &&
            responseJson !== undefined
          ) {
            // console.log("---------responseJson--------->", responseJson.states)
            this.setState({
              ...this.state,
              stateList: responseJson.states,
            });
          }
          // Address API
          if (
            apiRequestCallId === this.apiAddressDetailCallId &&
            responseJson !== undefined
          ) {
            this.doStepSet(2);
            localStorage.setItem("property_id", responseJson.data.id);
            // @ts-ignore
            this.props?.history?.push({
              pathname: "/add-property/basic-detail",
              state: {
                steps: this.state.step,
                data: responseJson.data.attributes,
              },
            });

            this.openSnackBarHandler("success", configJSON.successLogin);
          }
          // Property List
          if (
            apiRequestCallId === this.getPropertyListId &&
            responseJson !== undefined
          ) {
            this.setState({
              ...this.state,
              roomDetailList: responseJson,
            });
            this.openSnackBarHandler("success", configJSON.successLogin);
          }
          // Basic API
          if (
            apiRequestCallId === this.apiBasicDetailCallId &&
            responseJson !== undefined
          ) {
            this.doStepSet(3);
            this.openSnackBarHandler("success", configJSON.successLogin);
            // @ts-ignore
            this.props?.history?.push({
              pathname: "/add-property/upload-media",
              state: {
                steps: this.state.step,
                data: responseJson.data?.attributes,
              },
            });
          }
          //Upload Media
          if (
            apiRequestCallId === this.apiUploadMediaCallId &&
            responseJson !== undefined
          ) {
            this.doStepSet(4);
            this.openSnackBarHandler("success", configJSON.successLogin);
            // @ts-ignore
            this.props?.history?.push({
              pathname: "/add-property/pricing",
              state: {
                steps: this.state.step,
                data: responseJson.data?.attributes,
              },
            });
          }
          //Amanities API Get
          if (
            apiRequestCallId === this.getAmanitiesListId &&
            responseJson !== undefined
          ) {
            this.setState({
              ...this.state,
              amanitiesList: responseJson.data,
            });
            this.openSnackBarHandler("success", configJSON.successLogin);
          }
          //Pricing API
          if (
            apiRequestCallId === this.apiPricingCallId &&
            responseJson !== undefined
          ) {
            this.doStepSet(5);
            this.openSnackBarHandler("success", configJSON.successLogin);
            // @ts-ignore
            this.props?.history?.push({
              pathname: "/add-property/checklist",
              state: {
                steps: this.state.step,
                data: responseJson.data?.attributes,
              },
            });
          }
          //CheckList API 
          if (
            apiRequestCallId === this.apiCheckListCallId &&
            responseJson !== undefined
          ) {
            this.doStepSet(6);
            this.openSnackBarHandler("success", configJSON.successLogin);
            // @ts-ignore
            this.props?.history?.push({
              pathname: "/add-property/review-upload",
              state: {
                steps: this.state.step,
                data: responseJson.data?.attributes,
              },
            });
          }
          //Review API Get
          if (
            apiRequestCallId === this.getReviewListId &&
            responseJson !== undefined
          ) {
            this.setState({
              ...this.state,
              reviewList: responseJson.data,
            });
            this.openSnackBarHandler("success", configJSON.successLogin);
          }
          //Property Terms of Services API Get
          if (
            apiRequestCallId === this.getPropertyTermsOfServicesContentId &&
            responseJson !== undefined
          ) {
            this.setState({
              ...this.state,
              propertyTermsServicesContent: responseJson.data.attributes,

            });
          }
          //Publish property
          if (
            apiRequestCallId === this.publishPropertyId &&
            responseJson !== undefined
          ) {
            this.handleShowSuccessModal();
            this.handleHideTermsAndCondition();
            localStorage.removeItem("property_id");
            this.openSnackBarHandler("success", configJSON.successLogin);
          }
          //Delete Image
          if (
            apiRequestCallId === this.deleteImageId &&
            responseJson !== undefined
          ) {
            this.openSnackBarHandler("success", responseJson.message);
          }
          // Get User Account Detail 
          if (
            apiRequestCallId === this.accDetailId &&
            responseJson !== undefined
          ) {
            localStorage.setItem("email", responseJson.data.attributes.email)
            localStorage.setItem("last_name", responseJson.data.attributes.last_name)
            localStorage.setItem("role", responseJson.data.attributes.role)
            localStorage.setItem("first_name", responseJson.data.attributes.first_name)
            localStorage.setItem("profile_image", responseJson.data.attributes.profile_picture)
            this.setState({
              ...this.state,
              accDetail: responseJson.data.attributes,
            });
          }
          // PUT User Account Detail  
          if (
            apiRequestCallId === this.accSettingCallId &&
            responseJson !== undefined
          ) {
            this.getAccountDetail();
            this.openSnackBarHandler("success", configJSON.successProfileUpdate);
          }
          // Create link
          if (
            apiRequestCallId === this.apiLinkTokenCallId &&
            responseJson !== undefined
          ) {
            // this.openSnackBarHandler("success", configJSON.successLogin);
            this.setState({
              ...this.state,
              token: responseJson?.plaid_response?.link_token,
            });
          }
          //  token-exchange
          if (
            apiRequestCallId === this.apiBankAccountCallId &&
            responseJson !== undefined
          ) {
            this.getAccountDetail();
            this.openSnackBarHandler("success", configJSON.successProfileAdd);

          }
          // Delete Bank Acc apiDeleteBankAccCallId
          if (
            apiRequestCallId === this.apiDeleteBankAccCallId &&
            responseJson !== undefined
          ) {
            this.getAccountDetail();
            this.openSnackBarHandler("success", configJSON.successProfileDelete);

          }
          // Owner Detail
          if (
            apiRequestCallId === this.getOwnerDetailId &&
            responseJson !== undefined
          ) {
            this.setState({
              ...this.state,
              ownerDetails: responseJson?.data?.attributes,
            });
          }
          // // Buyer Detail
          // if (
          //   apiRequestCallId === this.getBuyerDetailId &&
          //   responseJson !== undefined
          // ) {
          //   this.setState({
          //     ...this.state,
          //     buyerDetails: responseJson?.data?.attributes,
          //   });
          // }
          // // Agreement Signature
          // if (
          //   apiRequestCallId === this.agreementSignCallId &&
          //   responseJson !== undefined
          // ) {
          //   console.log("Signed", responseJson)
          //   this.getAccountDetail();
          // }
        }
      } else {
        this.handleHideSuccessModal();
        // console.log("ERRROR",responseJson)
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        if (errors?.property !== undefined) {
          this.openSnackBarHandler("error", errors?.property);
        } else if (errors && errors[0]?.message) {
          this.openSnackBarHandler("error", errors[0]?.message);
        }
      }
    }
  }

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };
  // Customizable Area End
}
