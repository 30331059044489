// @ts-nocheck

import React from "react";
// Customizable Area Start
import Sidebar from "../../../../../components/src/Sidebar";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as Yup from "yup";
export const configJSON = require("../../config");
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// Customizable Area End
import AddPropertyFlowController, { Props } from "./AddPropertyFlowController.web";

import { isEqual } from "lodash";
import moment from "moment";

class StepIIBasicDetails extends AddPropertyFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    setTimeout(() => {
      if (sessionStorage.getItem("type") == 'addP') {
        sessionStorage.setItem("propertyId", this.props?.fieldData?.id);
      }
    }, 4000)

    this.getPropertyLists();

  }
  // Customizable Area End

  render() {
    console.log("Basic COMP", this.props.fieldData);
    const field = this.props?.fieldData?.attributes?.property_open_houses;
    return (
      <>
        <h3 className="page-title">Basic Detail</h3>
        <div className="basic-detail-wrapper">
          <Formik
            innerRef={this.props.formRef}
            initialValues={{
              totalRoom: this.props?.fieldData?.attributes?.total_rooms || "",
              bedrooms: this.props?.fieldData?.attributes?.bedrooms || "",
              floorNo: this.props?.fieldData?.attributes?.floor_number || "",
              unit: this.props?.fieldData?.attributes?.number_of_unit || "",
              stories: this.props?.fieldData?.attributes?.number_of_stories || "",
              fullBaths: this.props?.fieldData?.attributes?.full_baths || "",
              baths: this.props?.fieldData?.attributes?.half_baths || "",
              style: this.props?.fieldData?.attributes?.style || "",
              squareFeet: this.props?.fieldData?.attributes?.finished_sq_ft || "",
              basement: this.props?.fieldData?.attributes?.basement_sq_ft || "",
              lotSize: this.props?.fieldData?.attributes?.lot_size || "",
              lotSqft: "sq_ft",
              garage: this.props?.fieldData?.attributes?.garage_space || "",
              parkingSpaces: this.props?.fieldData?.attributes?.parking_space || '',
              yearBuilt: this.props?.fieldData?.attributes?.year_built || "",
              remodelYear: this.props?.fieldData?.attributes?.structural_remodel_year || "",
              describeYourPlace: this.props?.fieldData?.attributes?.description || "",
              loveAbout: this.props?.fieldData?.attributes?.about || "",
              openHouse: field && field.length != 0 ? field.map((key, index) => (
                {
                  id: key.id,
                  date: new Date(key.start_time),
                  start_time: new Date(key.start_time),
                  end_time: new Date(key.end_time)
                }
              ))
                : [{
                  id: "",
                  date: new Date(),
                  start_time: new Date(),
                  end_time: new Date(),
                }],
              openHouseView: field && field.length != 0 ? field.map((key, index) => (
                {
                  id: key.id,
                  date: new Date(key.start_time),
                  start_time: new Date(key.start_time),
                  end_time: new Date(key.end_time)
                }
              ))
                : [{
                  id: "",
                  date: new Date(),
                  start_time: new Date(),
                  end_time: new Date(),
                }],
              details: this.doDetail(this.props?.fieldData?.attributes),
              // checkField:this.props?.fieldData?.checkField,
              // details: this.doDetail(this.props?.location?.state?.data) 

            }}
            validationSchema={Yup.object().shape(this.state.basicDetailsSchema)}
            onSubmit={(values) => { console.log("this.props.propertyId", this.props?.fieldData?.id); this.doBasicDetails(values, this.props?.fieldData?.id) }}
            enableReinitialize
            validateOnMount
          >
            {({ values, setFieldValue, errors }) => (
              //@ts-ignore

              <Form>
                <h2 className="green-title">Basic Details</h2>

                {/* Total Rooms / Bedrooms * / Floor No. / Units */}
                <div className="field-outer-flex">
                  <div className="field-wrapper width-25">
                    <label className="mb-1">Total Rooms</label>
                    <Field
                      type="number"
                      name="totalRoom"
                      placeholder={configJSON.totalRoomHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="totalRoom" />
                    </span>
                  </div>

                  <div className="field-wrapper width-25">
                    <label className="mb-1">Bedrooms *</label>
                    <Field
                      type="number"
                      name="bedrooms"
                      placeholder={configJSON.bedroomsHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="bedrooms" />
                    </span>
                  </div>

                  <div className="field-wrapper width-25">
                    <label className="mb-1">Floor No.</label>
                    <Field
                      type="number"
                      name="floorNo"
                      placeholder={configJSON.floorNoHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="floorNo" />
                    </span>
                  </div>

                  <div className="field-wrapper width-25">
                    <label className="mb-1"># of Unit</label>
                    <Field
                      type="number"
                      name="unit"
                      placeholder={configJSON.unitHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="unit" />
                    </span>
                  </div>
                </div>

                {/* Stories / Full baths * / 1/2 baths */}
                <div className="field-outer-flex">
                  <div className="field-wrapper width-33">
                    <label className="mb-1"># of Stories</label>
                    <Field
                      type="number"
                      name="stories"
                      placeholder={configJSON.storiesHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="stories" />
                    </span>
                  </div>

                  <div className="field-wrapper width-33">
                    <label className="mb-1">Full baths</label>
                    <Field
                      type="number"
                      name="fullBaths"
                      placeholder={configJSON.fullBathsHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="fullBaths" />
                    </span>
                  </div>

                  <div className="field-wrapper width-33">
                    <label className="mb-1">1/2 baths</label>
                    <Field
                      type="number"
                      name="baths"
                      placeholder={configJSON.bathsHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="baths" />
                    </span>
                  </div>
                </div>

                {/* Style / Finished square feet */}
                <div className="field-outer-flex">
                  <div className="field-wrapper width-50">
                    <label className="mb-1">Style</label>
                    <Field
                      type="text"
                      name="style"
                      placeholder={configJSON.styleHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="style" />
                    </span>
                  </div>

                  <div className="field-wrapper width-50">
                    <label className="mb-1">Finished square feet</label>
                    <Field
                      type="number"
                      name="squareFeet"
                      placeholder={configJSON.squareFeetHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="squareFeet" />
                    </span>
                  </div>
                </div>

                {/* Basement sq. ft. / Lot size */}
                <div className="field-outer-flex">
                  <div className="field-wrapper width-50">
                    <label className="mb-1">Basement sq. ft.</label>
                    <Field
                      type="number"
                      name="basement"
                      placeholder={configJSON.basementHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="basement" />
                    </span>
                  </div>
                  <div className="field-wrapper width-25">
                    <label className="mb-1">Lot size</label>
                    <div className="flex">
                      <Field
                        type="number"
                        name="lotSize"
                        placeholder={configJSON.lotSizeHolder}
                        className="custom-input secondary-input"
                      />
                    </div>
                    <span className="error">
                      <ErrorMessage name="lotSize" />
                    </span>
                  </div>
                  <div className="field-wrapper width-25">
                    <label className="mb-1" />
                    <Field
                      type="text"
                      as="select"
                      name="lotSqft"
                      autoComplete="off"
                      defaultValue="sq_ft"
                      className="custom-input secondary-input "
                    >
                      <option value="sq_ft" >
                        Sq ft
                      </option>

                      <option value="acres" >
                        Acres
                      </option>
                    </Field>
                    <span className="error">
                      <ErrorMessage name="lotSqft" />
                    </span>
                  </div>
                </div>

                {/* Garage / Year Built / Structural remodel year */}
                <div className="field-outer-flex">
                  <div className="field-wrapper width-33">
                    <label className="mb-1">Garage (# cars)</label>
                    <Field
                      type="number"
                      name="garage"
                      placeholder={configJSON.garageHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="garage" />
                    </span>
                  </div>

                  <div className="field-wrapper width-33">
                    <label className="mb-1">Year Built</label>
                    <Field
                      type="number"
                      name="yearBuilt"
                      placeholder={configJSON.yearBuiltHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="yearBuilt" />
                    </span>
                  </div>

                  <div className="field-wrapper width-33">
                    <label className="mb-1">Structural remodel year</label>
                    <Field
                      type="number"
                      name="remodelYear"
                      placeholder={configJSON.remodelYearHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="remodelYear" />
                    </span>
                  </div>
                </div>

                {/* Describe Your Place  */}
                <div className="field-wrapper">
                  <label className="mb-1">Describe Your Place</label>
                  <Field
                    type="text"
                    name="describeYourPlace"
                    placeholder={configJSON.describeYourPlaceHolder}
                    className="custom-input secondary-input"
                  />
                  <span className="error">
                    <ErrorMessage name="describeYourPlace" />
                  </span>
                </div>

                {/* What I love about this home  */}
                <div className="field-wrapper">
                  <label className="mb-1">What I love about this home</label>
                  <Field
                    type="text"
                    name="loveAbout"
                    placeholder={configJSON.loveAboutHolder}
                    className="custom-input secondary-input"
                  />
                  <span className="count-digit">
                    ( 500 characters remaining )
                  </span>
                  <span className="error">
                    <ErrorMessage name="loveAbout" />
                  </span>
                </div>

                <h2 className="green-title">Room Details</h2>

                {/* APPLIANCES */}
                <h2 className="check-title">APPLIANCES</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.appliances?.data.map((app) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`Appliance${app.id}`}>
                          <input
                            type="checkbox"
                            id={`Appliance${app.id}`}
                            name="Appliance"
                            className="input-checkbox"
                            defaultChecked={values.details?.map((key) => key.id === +app.id && key.type === "Appliance" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "Appliance",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              if (idx === -1) {
                                array = array.concat(data)
                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>

                {/* BASEMENT */}
                <h2 className="check-title">BASEMENT</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.basements?.data.map((app, index) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`Basements${app.id}`}>
                          <input
                            type="checkbox"
                            id={`Basements${app.id}`}
                            name="rememberMe"
                            className="input-checkbox"
                            defaultChecked={values.details?.map((key) => key.id === +app.id && key.type === "Basement" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "Basement",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              const idxd = array.findIndex((el) => isEqual(el.type, data.type));
                              if (idx === -1) {
                                array.splice(idxd, 1);

                                array = array.concat(data)

                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>

                {/* Floor */}
                <h2 className="check-title">Floor</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.floors?.data.map((app, index) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`Floor${app.id}`}>
                          <input
                            type="checkbox"
                            id={`Floor${app.id}`}
                            name="rememberMe"
                            className="input-checkbox"
                            defaultChecked={values.details?.map((key) => +key.id === +app.id && key.type === "Floor" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "Floor",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              if (idx === -1) {
                                array = array.concat(data)
                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>

                {/* Rooms */}
                <h2 className="check-title">Rooms</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.rooms?.data.map((app, index) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`Rooms${app.id}`}>
                          <input
                            type="checkbox"
                            id={`Rooms${app.id}`}
                            name="rememberMe"
                            className="input-checkbox"
                            // value={values.details?.rooms?.id === app.id}
                            defaultChecked={values.details?.map((key) => key.id === +app.id && key.type === "Room" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "Room",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              if (idx === -1) {
                                array = array.concat(data)
                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>

                {/* Indoor Features */}
                <h2 className="check-title">Indoor Features</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.indoor_features?.data.map((app, index) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`Indoor_Features${app.id}`}>
                          <input
                            type="checkbox"
                            id={`Indoor_Features${app.id}`}
                            name="rememberMe"
                            className="input-checkbox"
                            defaultChecked={values.details?.map((key) => key.id === +app.id && key.type === "IndoorFeature" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "IndoorFeature",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              if (idx === -1) {
                                array = array.concat(data)
                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>

                <h2 className="green-title mt-5">Utility Details</h2>

                {/* Cooling Type */}
                <h2 className="check-title">Cooling Type</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.cooling_types?.data.map((app, index) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`Cooling_Type${app.id}`}>
                          <input
                            type="checkbox"
                            id={`Cooling_Type${app.id}`}
                            name="rememberMe"
                            className="input-checkbox"
                            defaultChecked={values.details?.map((key) => key.id === +app.id && key.type === "CoolingType" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "CoolingType",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              if (idx === -1) {
                                array = array.concat(data)
                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>

                {/* Heating Type */}
                <h2 className="check-title">Heating Type</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.heating_types?.data.map((app, index) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`Heating_Type${app.id}`}>
                          <input
                            type="checkbox"
                            id={`Heating_Type${app.id}`}
                            name="rememberMe"
                            className="input-checkbox"
                            defaultChecked={values.details?.map((key) => key.id === +app.id && key.type === "HeatingType" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "HeatingType",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              if (idx === -1) {
                                array = array.concat(data)
                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>

                {/* Heating Fuel */}
                <h2 className="check-title">Heating Fuel</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.heating_fuels?.data.map((app, index) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`Heating_Fuel${app.id}`}>
                          <input
                            type="checkbox"
                            id={`Heating_Fuel${app.id}`}
                            name="rememberMe"
                            className="input-checkbox"
                            defaultChecked={values.details?.map((key) => key.id === +app.id && key.type === "HeatingFuel" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "HeatingFuel",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              if (idx === -1) {
                                array = array.concat(data)
                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>

                {/* Outdoor Amenities */}
                <h2 className="check-title">Outdoor Amenities</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.outdoor_amenities?.data.map((app, index) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`Outdoor_Amenities${app.id}`}>
                          <input
                            type="checkbox"
                            id={`Outdoor_Amenities${app.id}`}
                            name="rememberMe"
                            className="input-checkbox"
                            defaultChecked={values.details?.map((key) => key.id === +app.id && key.type === "OutdoorAmenity" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "OutdoorAmenity",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              if (idx === -1) {
                                array = array.concat(data)
                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>

                {/* Parking */}
                <h2 className="check-title">Parking</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.parkings?.data.map((app, index) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`Parking${app.id}`}>
                          <input
                            type="checkbox"
                            id={`Parking${app.id}`}
                            name="rememberMe"
                            className="input-checkbox"
                            defaultChecked={values.details?.map((key) => key.id === +app.id && key.type === "Parking" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "Parking",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              if (idx === -1) {
                                array = array.concat(data)
                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>
                <div className="field-wrapper width-25">
                  <label className="mb-1 mt-4">#Parking Spaces</label>
                  <Field
                    type="number"
                    name="parkingSpaces"
                    placeholder={""}
                    className="custom-input secondary-input"
                  />
                  <span className="error">
                    <ErrorMessage name="parkingSpaces" />
                  </span>
                </div>


                {/* Roof */}
                <h2 className="check-title">Roof</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.roofs?.data.map((app, index) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`Roof${app.id}`}>
                          <input
                            type="checkbox"
                            id={`Roof${app.id}`}
                            name="rememberMe"
                            className="input-checkbox"
                            defaultChecked={values.details?.map((key) => key.id === +app.id && key.type === "Roof" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "Roof",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              if (idx === -1) {
                                array = array.concat(data)
                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                              let a = {
                                type: "Roof",
                                id: values.checkField?.roofs?.includes(+app.id) ? app.id : ""
                              }
                              setFieldValue("checkField",)
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>

                {/* View */}
                <h2 className="check-title">View</h2>
                <div className="checkbox-flex-wrapper">
                  {this.state.roomDetailList &&
                    this.state.roomDetailList.outside_views?.data.map((app, index) => {
                      return <div className="checkbox-wrapper check-list" key={app.id}>
                        <label htmlFor={`View${app.id}`}>
                          <input
                            type="checkbox"
                            id={`View${app.id}`}
                            name="rememberMe"
                            className="input-checkbox"
                            defaultChecked={values.details?.map((key) => key.id === +app.id && key.type === "OutsideView" ? true : false).includes(true)}
                            onClick={() => {
                              let array = Object.assign([], values.details);
                              let data = {
                                type: "OutsideView",
                                id: +app.id
                              }
                              const idx = array.findIndex((el) => isEqual(el, data));
                              if (idx === -1) {
                                array = array.concat(data)
                              } else {
                                array.splice(idx, 1);
                              }
                              setFieldValue("details", array);
                            }}
                          />
                          {app.attributes.name}
                          <span className="checkmark" />
                        </label>
                      </div>;
                    })}
                </div>

                <h2 className="green-title mt-5">Open house</h2>
                <p className="more-info">
                  Open houses may not currently allow for social distancing or
                  comply with public health orders. Please consider alternatives,
                  such as Sparen 3D Home tours, or scheduling a real-time video
                  tour.
                </p>
                {values.openHouseView.map((x, index) => {
                  return (
                    <div className="field-outer-flex flex-end-box">
                      <div className="field-wrapper width-25">
                        <label className="mb-1">Select Date</label>
                        <DatePicker
                          selected={x.date}
                          onChange={(date) => {
                            let array = [...values.openHouse];
                            let obj = Object.assign([], array, {
                              [index]: {
                                ...array[index],
                                id: x.id,
                                date: date,
                              },
                            })
                            setFieldValue(`openHouse`, obj);
                            setFieldValue(`openHouseView`, obj)

                          }}
                          minDate={new Date()}
                        />
                        <span className="error">
                          <ErrorMessage name={x.date} />
                        </span>
                      </div>

                      <div className="field-wrapper width-25">
                        <label className="mb-1">Start Time</label>
                        <DatePicker
                          selected={x.start_time}
                          onChange={(date) => {
                            let array = [...values.openHouse];
                            let obj = Object.assign([], array, {
                              [index]: {
                                ...array[index],
                                id: x.id,
                                start_time: date,
                              },
                            })
                            setFieldValue(`openHouse`, obj);
                            setFieldValue(`openHouseView`, obj)

                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                        <span className="error">
                          <ErrorMessage name={x.start_time} />
                        </span>
                      </div>

                      <div className="field-wrapper width-25">
                        <label className="mb-1">End Time</label>
                        <DatePicker
                          selected={x.end_time}
                          onChange={(date) => {
                            let array = [...values.openHouse];
                            let obj = Object.assign([], array, {
                              [index]: {
                                ...array[index],
                                id: x.id,
                                end_time: date,
                              },
                            })
                            setFieldValue(`openHouse`, obj);
                            setFieldValue(`openHouseView`, obj);

                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          minTime={x.start_time}
                          maxTime={moment().endOf('day').toDate()}
                        />
                        <span className="error">
                          <ErrorMessage name={x.end_time} />
                        </span>
                      </div>
                      <div className="field-wrapper width-25">
                        <button
                          type="button"
                          className="btn gray-fill-btn"
                          onClick={() => {
                            let obj = Object.assign([], values.openHouseView);
                            let array = Object.assign([], values.openHouse);

                            const idx = obj.findIndex((el) => isEqual(el.id, x.id));
                            const idxtwo = array.findIndex((el) => isEqual(el.id, x.id));

                            if (idx !== -1) { obj.splice(idx, 1); }
                            if (idxtwo !== -1 && x.id !== "") {
                              array.splice(idx, 1);
                              array.push({
                                "id": x.id,
                                "_destroy": true
                              })
                            }
                            else {
                              array.splice(idx, 1);
                            }
                            setFieldValue("openHouseView", obj);
                            setFieldValue("openHouse", array);
                          }}>
                          Delete
                        </button>

                      </div>

                    </div>
                  )
                })}
                <button
                  type="button"
                  className="btn orange-fill-btn"
                  onClick={() => {
                    let obj = Object.assign([], values.openHouse)
                    let data = {
                      id: "",
                      date: new Date(),
                      start_time: new Date(),
                      end_time: new Date(),
                    }
                    obj.push(data)
                    setFieldValue("openHouse", obj);
                    setFieldValue("openHouseView", obj);

                  }}>
                  Add Date
                </button>

                {/* {JSON.stringify(values.details, null, 2)} */}
                {/* Next  */}
                <div className="btn-wrapper flex justify-end">
                  <button type="button" className="btn gray-fill-btn"
                    onClick={() => {
                      // this.doStepSet(1);
                      console.log('this.props?.fieldData,1', this.props?.fieldData)
                      this.props.setStep(1, this.props?.fieldData);

                      // @ts-ignore
                      // this.props?.history?.push({
                      //   pathname: "/add-property/address-detail",
                      //   state: { steps: this.state.step, data: this.props?.fieldData },
                      // });
                    }}
                  >
                    Back
                  </button>
                  <button type="submit" className="rounded-fill-btn ml-3">
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* Snackbar for display success and failed messages. */}
        {/* <Snackbar
            open={this.state.snackBar.show}
            autoHideDuration={3000}
            onClose={this.closeSnackBarHandler}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.closeSnackBarHandler}
              severity={this.state.snackBar.type}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar> */}
      </>
    );
  }
}

// Customizable Area Start
export default withRouter(StepIIBasicDetails);

// Customizable Area End
