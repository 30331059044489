// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import ImagePicker from '../../blocks/ImagePicker/src/ImagePicker';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Reviews from '../../blocks/Reviews/src/Reviews';
import Sorting from '../../blocks/sorting/src/Sorting';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import ShoppingCart from '../../blocks/ShoppingCart/src/ShoppingCart';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import StoreLocator from '../../blocks/StoreLocator/src/StoreLocator';
import PlaidApiIntegrations from '../../blocks/PlaidApiIntegrations/src/PlaidApiIntegrations';
import CustomForm from '../../blocks/CustomForm/src/CustomForm';
import Maps from '../../blocks/Maps/src/Maps';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import StripeIntegration from '../../blocks/StripeIntegration/src/StripeIntegration';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import ZipcodesSearch from '../../blocks/ZipcodesSearch/src/ZipcodesSearch';
import SummaryCard from '../../blocks/SummaryCard/src/SummaryCard';
import Referrals from '../../blocks/Referrals/src/Referrals';
import _150PointInspectionWhatIsThisFor from '../../blocks/_150PointInspectionWhatIsThisFor/src/_150PointInspectionWhatIsThisFor';
import CustomCalculatorCarvanaMakeItYours from '../../blocks/CustomCalculatorCarvanaMakeItYours/src/CustomCalculatorCarvanaMakeItYours';
import FormApprovalWorkflow from '../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow';
import LiveChat from '../../blocks/LiveChat/src/LiveChat';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import CollectTransactionFees from '../../blocks/CollectTransactionFees/src/CollectTransactionFees';
import GuestLogin from '../../blocks/GuestLogin/src/GuestLogin';
import DocusignIntegration from '../../blocks/DocusignIntegration/src/DocusignIntegration';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import CarouselDisplay0 from '../../blocks/CarouselDisplay0/src/CarouselDisplay0';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import LandingPage from '../../blocks/LandingPage/src/LandingPage';
import ServicespecificSettingsadmin from '../../blocks/ServicespecificSettingsadmin/src/ServicespecificSettingsadmin';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import EmailNotifications2 from '../../blocks/EmailNotifications2/src/EmailNotifications2';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import NavigationMenu from '../../blocks/NavigationMenu/src/NavigationMenu';
import ItemGrouper from '../../blocks/ItemGrouper/src/ItemGrouper';
import ApiIntegration from '../../blocks/ApiIntegration/src/ApiIntegration';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';

// web
import { isEmpty } from 'lodash';
import { Redirect, Route } from 'react-router-dom';

import Homepagemain from '../../blocks/LandingPage/src/Homepagemain.web';
import Homepageseller from '../../blocks/LandingPage/src/Homepageseller.web';
import Homepagebuyer from '../../blocks/LandingPage/src/Homepagebuyer.web';

import SearchResults from '../../blocks/LandingPage/src/SearchResults.web';
import FavoriteHomes from '../../blocks/dashboard/src/Buyer/FavoriteHomes.web';
import CompareHomes from '../../blocks/dashboard/src/Buyer/CompareHomes.web';
import Contact from '../../blocks/dashboard/src/Seller/AddPropertyStepper/Contact.web';
import AccountSetting from '../../blocks/dashboard/src/Seller/AddPropertyStepper/AccountSetting.web';

import ChatPage from '../../blocks/LiveChat/src/ChatPage.web';
import chatSchedule from '../../blocks/LiveChat/src/ChatSchedule.web';
import BuyerPropertyList from '../../blocks/LiveChat/src/BuyerPropertyList.web';

import DashboardSeller from '../../blocks/dashboard/src/Seller/DashboardSeller.web';
import PropertyList from '../../blocks/dashboard/src/HandOverFlow/PropertyListFlow/PropertyList.web';
import DashboardBuyer from '../../blocks/dashboard/src/Buyer/DashboardBuyer.web';
import PropertyDetail from '../../blocks/dashboard/src/Buyer/PropertyDetail.web';
import MainDashboard from '../../blocks/dashboard/src/Buyer/MainDashboard.web';
import BuyerApplicationDetail from '../../blocks/dashboard/src/Buyer/BuyerApplication/BuyerApplicationDetail.web';
import SchedulePropertyTour from '../../blocks/dashboard/src/Buyer/BuyerApplication/SchedulePropertyTour.web';
import Financials from '../../blocks/dashboard/src/Seller/AddPropertyStepper/Financials.web';
import Agreement from '../../blocks/dashboard/src/HandOverFlow/AggrementFlow/Agreement.web';

import Signup from '../../blocks/email-account-registration/src/Signup.web';
import Signin from '../../blocks/email-account-login/src/Signin.web';
import ForgotPasswordWeb from '../../blocks/forgot-password/src/ForgotPassword.web';
import SocialMediaAccountRegistrationScreenWeb from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen.web';
import InstantVerification from '../../blocks/PlaidApiIntegrations/src/InstantVerification.web';
import InstantVerificationSuccess from '../../blocks/PlaidApiIntegrations/src/InstantVerificationSuccess.web';
import StepperMain from '../../blocks/dashboard/src/Seller/AddPropertyStepper/StepperMain.web';
import AddressDetail from '../../blocks/dashboard/src/Seller/AddPropertyStepper/AddressDetail.web';
import BasicDetail from '../../blocks/dashboard/src/Seller/AddPropertyStepper/BasicDetails.web';
import UploadMedia from '../../blocks/dashboard/src/Seller/AddPropertyStepper/UploadMedia.web';
import Pricing from '../../blocks/dashboard/src/Seller/AddPropertyStepper/Pricing.web';
import Checklist from '../../blocks/dashboard/src/Seller/AddPropertyStepper/Checklist.web';
import ReviewUpload from '../../blocks/dashboard/src/Seller/AddPropertyStepper/ReviewUpload.web';
import BuyerDashboard from '../../blocks/dashboard/src/Buyer/BuyerDashboard.web';
import OldPropertyList from '../../blocks/dashboard/src/HandOverFlow/PropertyListFlow/OldPropertyList.web';
import AddPropertyParent from '../../blocks/dashboard/src/Seller/AddPropertyFlow/AddPropertyParent.web.tsx';
import EditPropertyParent from '../../blocks/dashboard/src/Seller/AddPropertyFlow/EditPropertyParent.web.tsx';
import HomePropertyList from '../../blocks/dashboard/src/Buyer/FilterHomesFlow/HomePropertyList.web.tsx';
import ChatParent from '../../blocks/LiveChat/src/ChatFlow/ChatParent.web';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/index.css';
import '../styles/styles.scss';

// const routeMap = {
//   ImagePicker: {
//     component: ImagePicker,
//     path: '/ImagePicker'
//   },
//   Categoriessubcategories: {
//     component: Categoriessubcategories,
//     path: '/Categoriessubcategories'
//   },
//   SocialMediaAccountLoginScreen: {
//     component: SocialMediaAccountLoginScreen,
//     path: '/SocialMediaAccountLoginScreen'
//   },
//   Reviews: {
//     component: Reviews,
//     path: '/Reviews'
//   },
//   Sorting: {
//     component: Sorting,
//     path: '/Sorting'
//   },
//   Dashboard: {
//     component: Dashboard,
//     path: '/Dashboard'
//   },
//   Catalogue: {
//     component: Catalogue,
//     path: '/Catalogue'
//   },
//   PostCreation: {
//     component: PostCreation,
//     path: '/PostCreation'
//   },
//   Posts: {
//     component: Posts,
//     path: '/Posts'
//   },
//   PostDetails: {
//     component: PostDetails,
//     path: '/PostDetails'
//   },
//   ShoppingCart: {
//     component: ShoppingCart,
//     path: '/ShoppingCart'
//   },
//   SocialMediaAccountRegistrationScreen: {
//     component: SocialMediaAccountRegistrationScreen,
//     path: '/SocialMediaAccountRegistrationScreen'
//   },
//   StoreLocator: {
//     component: StoreLocator,
//     path: '/StoreLocator'
//   },
//   PlaidApiIntegrations: {
//     component: PlaidApiIntegrations,
//     path: '/PlaidApiIntegrations'
//   },
//   CustomForm: {
//     component: CustomForm,
//     path: '/CustomForm'
//   },
//   Maps: {
//     component: Maps,
//     path: '/Maps'
//   },
//   TermsAndConditions: {
//     component: TermsAndConditions,
//     path: '/TermsAndConditions'
//   },
//   StripeIntegration: {
//     component: StripeIntegration,
//     path: '/StripeIntegration'
//   },
//   OTPInputAuth: {
//     component: OTPInputAuth,
//     path: '/OTPInputAuth'
//   },
//   ZipcodesSearch: {
//     component: ZipcodesSearch,
//     path: '/ZipcodesSearch'
//   },
//   SummaryCard: {
//     component: SummaryCard,
//     path: '/SummaryCard'
//   },
//   Referrals: {
//     component: Referrals,
//     path: '/Referrals'
//   },
//   _150PointInspectionWhatIsThisFor: {
//     component: _150PointInspectionWhatIsThisFor,
//     path: '/_150PointInspectionWhatIsThisFor'
//   },
//   CustomCalculatorCarvanaMakeItYours: {
//     component: CustomCalculatorCarvanaMakeItYours,
//     path: '/CustomCalculatorCarvanaMakeItYours'
//   },
//   FormApprovalWorkflow: {
//     component: FormApprovalWorkflow,
//     path: '/FormApprovalWorkflow'
//   },
//   LiveChat: {
//     component: LiveChat,
//     path: '/LiveChat'
//   },
//   UserProfileBasicBlock: {
//     component: UserProfileBasicBlock,
//     path: '/UserProfileBasicBlock'
//   },
//   CollectTransactionFees: {
//     component: CollectTransactionFees,
//     path: '/CollectTransactionFees'
//   },
//   GuestLogin: {
//     component: GuestLogin,
//     path: '/GuestLogin'
//   },
//   DocusignIntegration: {
//     component: DocusignIntegration,
//     path: '/DocusignIntegration'
//   },
//   Filteritems: {
//     component: Filteritems,
//     path: '/Filteritems'
//   },
//   Filteroptions: {
//     component: Filteroptions,
//     path: '/Filteroptions'
//   },
//   CarouselDisplay0: {
//     component: CarouselDisplay0,
//     path: '/CarouselDisplay0'
//   },
//   ForgotPassword: {
//     component: ForgotPassword,
//     path: '/ForgotPassword'
//   },
//   ForgotPasswordOTP: {
//     component: ForgotPasswordOTP,
//     path: '/ForgotPasswordOTP'
//   },
//   NewPassword: {
//     component: NewPassword,
//     path: '/NewPassword'
//   },
//   LandingPage: {
//     component: LandingPage,
//     path: '/LandingPage'
//   },
//   ServicespecificSettingsadmin: {
//     component: ServicespecificSettingsadmin,
//     path: '/ServicespecificSettingsadmin'
//   },
//   AdminConsole: {
//     component: AdminConsole,
//     path: '/AdminConsole'
//   },
//   EmailAccountRegistration: {
//     component: EmailAccountRegistration,
//     path: '/EmailAccountRegistration'
//   },
//   EmailNotifications2: {
//     component: EmailNotifications2,
//     path: '/EmailNotifications2'
//   },
//   CountryCodeSelector: {
//     component: CountryCodeSelector,
//     path: '/CountryCodeSelector'
//   },
//   EmailAccountLoginBlock: {
//     component: EmailAccountLoginBlock,
//     path: '/EmailAccountLoginBlock'
//   },
//   NavigationMenu: {
//     component: NavigationMenu,
//     path: '/NavigationMenu'
//   },
//   ItemGrouper: {
//     component: ItemGrouper,
//     path: '/ItemGrouper'
//   },
//   ApiIntegration: {
//     component: ApiIntegration,
//     path: '/ApiIntegration'
//   },
//   RolesPermissions: {
//     component: RolesPermissions,
//     path: '/RolesPermissions'
//   },

//   Home: {
//     component: HomeScreen,
//     path: '/HomeScreen',
//     exact: true
//   },
//   InfoPage: {
//     component: InfoPage,
//     path: '/InfoPage'
//   },
//   AlertWeb: {
//     component: AlertBlock,
//     path: '*/AlertWeb',
//     modal: true
//   },
//   // web Screen
//   Homepagemain: {
//     component: Homepagemain,
//     path: '/',
//     exact: true
//   },
//   Homepageseller: {
//     component: Homepageseller,
//     path: '/home-page-seller',
//     exact: true
//   },
//   Homepagebuyer: {
//     component: Homepagebuyer,
//     path: '/home-page-buyer',
//     exact: true
//   },
//   Signup: {
//     component: Signup,
//     path: '/signup',
//     exact: true
//   },
//   Signin: {
//     component: Signin,
//     path: '/signin',
//     exact: true
//   },
//   ForgotPasswordWeb: {
//     component: ForgotPasswordWeb,
//     path: '/forgot-web',
//     exact: true
//   },
//   SocialMediaAccountRegistrationScreenWeb: {
//     component: SocialMediaAccountRegistrationScreenWeb,
//     path: '/social-web',
//     exact: true
//   },
//   // InstantVerification: {
//   //   component: InstantVerification,
//   //   path: '/instant-verification',
//   //   exact: true
//   // },
//   // InstantVerificationSuccess: {
//   //   component: InstantVerificationSuccess,
//   //   path: '/instant-verification-success',
//   //   exact: true
//   // },
//   DashboardSeller: {
//     component: DashboardSeller,
//     path: '/dashboard-seller',
//     exact: true
//   },
//   // StepperMain: {
//   //   component: StepperMain,
//   //   path: '/add-property'
//   // },
//   SearchResults: {
//     component: SearchResults,
//     path: '/search-result',
//     exact: true
//   },
//   FavoriteHomes: {
//     component: FavoriteHomes,
//     path: '/favorite-homes',
//     exact: true
//   },
//   DashboardBuyer: {
//     component: DashboardBuyer,
//     path: '/filter-homes',
//     search: '?search=:id'
//   },
//   PropertyDetail: {
//     component: PropertyDetail,
//     path: '/property-detail',
//     search: '?id=:id'
//   },
//   CompareHomes: {
//     component: CompareHomes,
//     path: '/compare-homes',
//     exact: true
//   },
//   ChatPage: {
//     component: ChatPage,
//     path: '/chat-page',
//     exact: true
//   },
//   BuyerPropertyList: {
//     component: BuyerPropertyList,
//     path: '/buyer-list',
//     exact: true
//   },
//   BuyerApplicationDetail: {
//     component: BuyerApplicationDetail,
//     path: '/buyer-application',
//     exact: true
//   },
//   AddressDetail: {
//     component: AddressDetail,
//     path: '/add-property/address-detail',
//     exact: true
//   },
//   BasicDetail: {
//     component: BasicDetail,
//     path: '/add-property/basic-detail',
//     exact: true
//   },
//   UploadMedia: {
//     component: UploadMedia,
//     path: '/add-property/upload-media',
//     exact: true
//   },
//   Pricing: {
//     component: Pricing,
//     path: '/add-property/pricing',
//     exact: true
//   },
//   Checklist: {
//     component: Checklist,
//     path: '/add-property/checklist',
//     exact: true
//   },
//   ReviewUpload: {
//     component: ReviewUpload,
//     path: '/add-property/review-upload',
//     exact: true
//   },
//   SchedulePropertyTour: {
//     component: SchedulePropertyTour,
//     path: '/property-tour',
//     exact: true
//   },
//   Contact: {
//     component: Contact,
//     path: '/contact',
//     exact: true
//   },
//   AccountSetting: {
//     component: AccountSetting,
//     path: '/account-setting',
//     exact: true
//   },
//   MainDashboard: {
//     component: MainDashboard,
//     path: '/dashboard-buyer',
//     exact: true
//   },
//   PropertyList: {
//     component: PropertyList,
//     path: '/property-list',
//     exact: true
//   },
//   BuyerDashboard: {
//     component: BuyerDashboard,
//     path: '/buyer-dashboardTest',
//     exact: true
//   },
//   Financials: {
//     component: Financials,
//     path: '/financials',
//     exact: true
//   },
//   Agreement: {
//     component: Agreement,
//     path: '/agreement',
//     exact: true
//   },
//   chatSchedule: {
//     component: chatSchedule,
//     path: '/chat-schedule',
//     exact: true
//   }
// };
const routeMapLogin = {
  ImagePicker: {
    component: ImagePicker,
    path: '/ImagePicker'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Reviews: {
    component: Reviews,
    path: '/Reviews'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  ShoppingCart: {
    component: ShoppingCart,
    path: '/ShoppingCart'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  StoreLocator: {
    component: StoreLocator,
    path: '/StoreLocator'
  },
  PlaidApiIntegrations: {
    component: PlaidApiIntegrations,
    path: '/PlaidApiIntegrations'
  },
  CustomForm: {
    component: CustomForm,
    path: '/CustomForm'
  },
  Maps: {
    component: Maps,
    path: '/Maps'
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions'
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  ZipcodesSearch: {
    component: ZipcodesSearch,
    path: '/ZipcodesSearch'
  },
  SummaryCard: {
    component: SummaryCard,
    path: '/SummaryCard'
  },
  Referrals: {
    component: Referrals,
    path: '/Referrals'
  },
  _150PointInspectionWhatIsThisFor: {
    component: _150PointInspectionWhatIsThisFor,
    path: '/_150PointInspectionWhatIsThisFor'
  },
  CustomCalculatorCarvanaMakeItYours: {
    component: CustomCalculatorCarvanaMakeItYours,
    path: '/CustomCalculatorCarvanaMakeItYours'
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  LiveChat: {
    component: LiveChat,
    path: '/LiveChat'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  CollectTransactionFees: {
    component: CollectTransactionFees,
    path: '/CollectTransactionFees'
  },
  GuestLogin: {
    component: GuestLogin,
    path: '/GuestLogin'
  },
  DocusignIntegration: {
    component: DocusignIntegration,
    path: '/DocusignIntegration'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  CarouselDisplay0: {
    component: CarouselDisplay0,
    path: '/CarouselDisplay0'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  ServicespecificSettingsadmin: {
    component: ServicespecificSettingsadmin,
    path: '/ServicespecificSettingsadmin'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: '/EmailNotifications2'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  ItemGrouper: {
    component: ItemGrouper,
    path: '/ItemGrouper'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },

  Home: {
    component: HomeScreen,
    path: '/HomeScreen',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  // web Screen
  Homepagemain: {
    component: Homepagemain,
    path: '/',
    exact: true
  },

  Signup: {
    component: Signup,
    path: '/signup',
    exact: true
  },
  Signin: {
    component: Signin,
    path: '/signin',
    exact: true
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: '/forgot-web',
    exact: true
  },
  SocialMediaAccountRegistrationScreenWeb: {
    component: SocialMediaAccountRegistrationScreenWeb,
    path: '/social-web',
    exact: true
  },
  // InstantVerification: {
  //   component: InstantVerification,
  //   path: '/instant-verification',
  //   exact: true
  // },
  // InstantVerificationSuccess: {
  //   component: InstantVerificationSuccess,
  //   path: '/instant-verification-success',
  //   exact: true
  // },

  // StepperMain: {
  //   component: StepperMain,
  //   path: '/add-property'
  // },
  SearchResults: {
    component: SearchResults,
    path: '/search-result',
    exact: true
  },
  DashboardBuyer: {
    component: DashboardBuyer,
    path: '/filter-homes',
    search: '?search=:id'
  },
  Homepageseller: {
    component: Homepageseller,
    path: '/home-page-seller',
    exact: true
  },
  Homepagebuyer: {
    component: Homepagebuyer,
    path: '/home-page-buyer',
    exact: true
  }
};
const routeMap = {
  DashboardSeller: {
    component: DashboardSeller,
    path: '/dashboard-seller',
    exact: true
  },
  FavoriteHomes: {
    component: FavoriteHomes,
    path: '/favorite-homes',
    exact: true
  },

  PropertyDetail: {
    component: PropertyDetail,
    path: '/property-detail',
    search: '?id=:id'
  },
  CompareHomes: {
    component: CompareHomes,
    path: '/compare-homes',
    exact: true
  },
  ChatPage: {
    component: ChatPage,
    path: '/chat-page',
    exact: true
  },
  BuyerPropertyList: {
    component: BuyerPropertyList,
    path: '/buyer-list',
    exact: true
  },
  BuyerApplicationDetail: {
    component: BuyerApplicationDetail,
    path: '/buyer-application',
    exact: true
  },
  AddressDetail: {
    component: AddressDetail,
    path: '/add-property/address-detail',
    exact: true
  },
  BasicDetail: {
    component: BasicDetail,
    path: '/add-property/basic-detail',
    exact: true
  },
  UploadMedia: {
    component: UploadMedia,
    path: '/add-property/upload-media',
    exact: true
  },
  Pricing: {
    component: Pricing,
    path: '/add-property/pricing',
    exact: true
  },
  Checklist: {
    component: Checklist,
    path: '/add-property/checklist',
    exact: true
  },
  ReviewUpload: {
    component: ReviewUpload,
    path: '/add-property/review-upload',
    exact: true
  },
  SchedulePropertyTour: {
    component: SchedulePropertyTour,
    path: '/property-tour',
    exact: true
  },
  Contact: {
    component: Contact,
    path: '/contact',
    exact: true
  },
  AccountSetting: {
    component: AccountSetting,
    path: '/account-setting',
    exact: true
  },
  MainDashboard: {
    component: MainDashboard,
    path: '/dashboard-buyer',
    exact: true
  },
  PropertyList: {
    component: PropertyList,
    path: '/property-list',
    exact: true
  },
  BuyerDashboard: {
    component: BuyerDashboard,
    path: '/buyer-dashboardTest',
    exact: true
  },
  Financials: {
    component: Financials,
    path: '/financials',
    exact: true
  },
  Agreement: {
    component: Agreement,
    path: '/agreement',
    exact: true
  },
  chatSchedule: {
    component: chatSchedule,
    path: '/chat-schedule',
    exact: true
  },
  ChatParent: {
    component: ChatParent,
    path: '/messages',
    exact: true
  },
  OldPropertyList: {
    component: OldPropertyList,
    path: '/seller-property-list',
    exact: true
  },
  AddPropertyParent: {
    component: AddPropertyParent,
    path: '/add-property',
    exact: true
  },
  EditPropertyParent: {
    component: EditPropertyParent,
    path: '/edit-property',
    exact: true
  },
  HomePropertyList: {
    component: HomePropertyList,
    path: '/properties',
    exact: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    const auth = localStorage.getItem('auth');
    let islogged = !isEmpty(auth);

    return (
      <View>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap, islogged })}

        {WebRoutesGenerator({ routeMapLogin })}
        {/* {isEmpty(auth) ? (
          <Redirect from="*" to="/signin" />
        ) : (
          <Redirect from="*" to="/dashboard-seller" />
        )} */}
        {/* <ModalContainer /> */}
      </View>
    );
  }
}

export default App;
