// @ts-nocheck
import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "./DashboardBuyerController.web";
import {
  imageLike,
  imageLikeRed,
  imageView,
  viewMap,
  buyerLogo,
  imageHeart,
  imageArrow,
  siginImage,
  sliderProperty1,
  sliderProperty2,
  mainPropertySlider,
  buyerSkew,
  footerLogo,
  instaImage,
  fbImage,
  twitterImage,
  paymentWithSparen,
  imageCash,
  insurance,
  homecare,
  imageDashboard,
  imageSetting,
  imageLikeFill,
  imageLogout,
  sliderThumbnil,
  profieImg,
  noImgAvailable
} from ".././assets";
import OwlCarousel from "react-owl-carousel2";
import "react-owl-carousel2/lib/styles.css";
import Slider from "@material-ui/core/Slider";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { videoImg } from ".././assets";
import { isEmpty } from "lodash";
import ScheduleTour from "./ScheduleTour.web";
export const configJSON = require(".././config.js");
import Switch from "@material-ui/core/Switch";
import TravelTime from "../Popups/TravelTime.web";
export interface Props { }
interface S {
  SliderModal: any;
}

const events = {
  // onDragged: function(event) {...},
  // onChanged: function(event) {...}
};

class PropertyDetail extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
    // this.propertyid=new URLSearchParams(this.props.location.search);
    // this.params = Object.fromEntries(this.propertyid.entries());
    // this.price5per=0;
    // this.price20per=0;
    this.tprice = 0;
    this.pricePerUnit = 0;
    this.offerDifference = 100;
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.propertyDetails !== this.state.propertyDetails) {
      this.setDpMp(this.state.propertyDetails);
    }
    if (prevProps.location.search?.split("=")?.[1] !== this.props.location.search?.split("=")?.[1]) {
      location.reload()
    }
    this.SliderFunction();
  }


  SliderFunction() {
    if (document.querySelector("#sync2 .owl-next")) {
      document.querySelector("#sync2 .owl-next").onclick = function () {
        document.querySelector("#sync1 .owl-next").click();
      };
      document.querySelector("#sync2 .owl-prev").onclick = function () {
        document.querySelector("#sync1 .owl-prev").click();
      };
    }
  }

  // toggleSliderModal=()=> {
  //   this.setState({
  //     ...this.state,
  //     SliderModal:!this.state.SliderModal,
  //   });
  // }

  // componentDidMount(){
  //   this.setState({propertyId:+this.params.id},()=>{this.getPropertyDetail(this.state.propertyId);
  //   this.getSimilarPropertyDetails(this.state.propertyId)});
  //   this.tourDates();
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    const currentProductId = new URLSearchParams(nextProps.location.search);
    const paramsC = Object.fromEntries(currentProductId.entries());
    if (+paramsC.id !== prevState.propertyId) {
      DashboardBuyerController.handlePropertydetailId(+paramsC.id)
    }
  }

  render() {
    this.tprice = +this.state.propertyDetails?.price;
    this.price5per = Math.round((+this.tprice * 0.95) / 5);
    this.price20per = Math.round((+this.tprice * 0.8) / 5);

    this.pricePerUnit = +(Math.round(this.tprice / 100 + "e+2") + "e-2");
    if (this.state.propertyClicked) {
      this.getPropertyDetail(this.state.propertyId);
      this.getSimilarPropertyDetails(this.state.propertyId);
      this.setState({ propertyClicked: false })
    }
    return (
      <>
        <header className="site-header fixed-header">
          <div className="container-main">
            <div className="left-header">
              <Link to={
                localStorage.getItem("role") === "buyer"
                  ? "/dashboard-buyer"
                  : "/dashboard-seller"
              } className="page-logo" title="Sparen Home">
                <img src={buyerLogo} alt="Logo" />
              </Link>
              <div className="link-wrapper">
                <ul>
                  <li>
                    <Link to="/home-page-buyer" title="Buy" className="active">
                      Buy
                    </Link>
                  </li>
                  <li>
                    <Link to="/home-page-seller" title="Sell">
                      Sell
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} title="How It Works">
                      How It Works
                    </Link>
                  </li>
                  <button
                    className="rounded-fill-btn blue-fill sm-visible"
                    onClick={() => this.props?.history?.push("signin")}
                  >
                    Log In
                  </button>
                  <button
                    className="rounded-fill-btn blue-fill sm-visible"
                    onClick={() => this.props?.history?.push("signup")}
                  >
                    Sign Up
                  </button>
                </ul>
              </div>
            </div>
            <div className="right-header">
              <div className="right-block">
                {localStorage.getItem("auth") ? (
                  <div className="profile-wrapper" onClick={this.doToggleHeaderDropdown}>
                    <div className="icon">
                      <span />
                    </div>
                    <div className="content">
                      <div className="name">{`${!isEmpty(localStorage.getItem("first_name")) &&
                        localStorage.getItem("first_name") !== "null"
                        ? localStorage.getItem("first_name")
                        : localStorage.getItem("email").split("@")[0]
                        } ${!isEmpty(localStorage.getItem("last_name")) &&
                          localStorage.getItem("last_name") !== "null"
                          ? localStorage.getItem("last_name")
                          : ""
                        }`}</div>
                      {/* <div
                  className="logout"
                  onClick={() => {
                    localStorage.clear();
                    this.props.history.push("/signin");
                  }}
                >
                  Logout
                </div> */}
                    </div>
                    <div className="profile-image">
                      <img
                        src={!isEmpty(localStorage.getItem("profile_image")) &&
                          localStorage.getItem("profile_image") !== "null"
                          ? localStorage.getItem("profile_image")
                          : profieImg}
                        alt="profile_image"
                      />
                    </div>
                    {/* DropDown  */}
                    {this.state.HeaderDropdownFlag &&
                      <ul className="dropdown-list">
                        <li>
                          <Link to={
                            !isEmpty(localStorage.getItem("role")) && localStorage.getItem("role") === "buyer"
                              ? "/dashboard-buyer"
                              : "/dashboard-seller"
                          } className="buyer-link">
                            Go to Buyer Account
                          </Link>
                        </li>
                        <li>
                          <Link to={
                            !isEmpty(localStorage.getItem("role")) && localStorage.getItem("role") === "buyer"
                              ? "/dashboard-buyer"
                              : "/dashboard-seller"
                          }>
                            <img src={imageDashboard} alt="" />
                            <span>Dashboard</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/account-setting">
                            <img src={imageSetting} alt="" />
                            <span>Account Settings</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/favorite-homes">
                            <img src={imageLikeFill} alt="" />
                            <span>Favorite Homes</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/" onClick={() => {
                            localStorage.clear();
                            this.props?.history?.push("/signin");
                          }}>
                            <img src={imageLogout} alt="" /> <span>Log out</span>
                          </Link>
                        </li>
                      </ul>
                    }
                  </div>
                ) : (
                  <div className="auth-btns-space">
                    <button
                      className="rounded-fill-btn green-fill btn-mr"
                      onClick={() => this.props?.history?.push("/signin")}
                    >
                      Log In
                    </button>
                    <button
                      className="rounded-fill-btn blue-fill"
                      onClick={() => this.props?.history?.push("/signup")}
                    >
                      Sign Up
                    </button>
                  </div>
                )}
              </div>

            </div>
            <div className="mobile-menu" onClick={this.toggleClass}>
              <ul className={this.toggleMenu ? "active" : ""}>
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>


          </div>
        </header>
        <section className="slider-section">
          <div className="container-main">
            <div className="slider-wrapper">
              {console.log("IMAGES", this.state.propertyDetails)}
              {this.state.propertyDetails?.images?.length > 0 ?
                <OwlCarousel options={this.options}>
                  {this.state.propertyDetails?.images?.map((img) => (
                    <img
                      src={img.url}
                      key={img.id}
                    />
                  ))}
                </OwlCarousel> : <img className="no-img" src={noImgAvailable} />
              }

            </div>
            <div className="right-block">
              <div
                className="top-image"
                onClick={() => this.state.propertyDetails?.images?.length > 0 ? this.setState({ SliderModal: true }) : this.setState({ SliderModal: false })}
              >
                <img src={`${this.state.propertyDetails?.images?.length > 0 && this.state.propertyDetails?.images ? this.state.propertyDetails?.images[0]?.url : noImgAvailable}`} alt="" />
              </div>
              <div
                className="bottom-image"
                onClick={() => this.state.propertyDetails?.images?.length > 0 ? this.setState({ SliderModal: true }) : this.setState({ SliderModal: false })}
              >
                <img src={`${this.state.propertyDetails?.images?.length > 0 && this.state.propertyDetails?.images[1] ? this.state.propertyDetails?.images[1]?.url : noImgAvailable}`} alt="" />

                {/* <img src={sliderProperty2} alt="" /> */}
              </div>
            </div>
          </div>
        </section>
        <section className="property-detail-wrapper">
          <div className="container-main">
            <div className="left-block">
              <div className="property-info">
                <div className="block-detail">

                  <p className="title">{this.state.propertyDetails?.address?.address_line}</p>
                  <p className="sub-title">
                    {
                      this.state.propertyDetails?.address?.state +
                      ", " + this.state.propertyDetails?.address?.city +
                      " " + this.state.propertyDetails?.address?.zip_code}</p>
                </div>
                <div className="block-detail">
                  <p className="title">
                    <span>3 Beds</span> <em>•</em> <span>3 Baths</span>
                  </p>
                  <p className="sub-title">2,567 sq ft</p>
                </div>
                <div className="block-detail">
                  <div className="view">
                    <i>
                      <img src={imageView} alt="" />
                    </i>
                    <span>2,105</span>
                  </div>
                  <div className="like">
                    <i>
                      <img src={imageLikeRed} alt="" />
                    </i>
                    <span>918</span>
                  </div>
                </div>
              </div>
              <div className="text-block">
                {this.state.propertyDetails?.about}
              </div>
              <div className="location-block">
                <p className="section-title">Location</p>
                <div>
                  <img src={viewMap} alt="" />
                </div>
                <div className="travel-time">
                  <span className="travel-text">TRAVEL TIME</span>
                  <Switch checked={this.state.traveltimeShow} onClick={() => this.handleTimeTravel()} />
                  <TravelTime timetravelShow={this.state.traveltimeShow} handleTimetravel={this.handleTimeTravel} />
                </div>
              </div>
            </div>
            <div className="right-block">
              <div className="top-block">
                <div className="left-block">
                  <p className="title">List Price</p>
                  <p className="price">{(+this.state.propertyDetails?.price)?.toLocaleString("en-IN", { minimumFractionDigits: 2 })}</p>
                </div>
                <div className="image-block">
                  <img src={imageHeart} alt="" />
                </div>
              </div>
              <div className="content-block">
                <div className="tab-header">
                  <div className="tab active">Payment Options</div>
                  <div className="tab">Build Your Price</div>
                </div>
                <div className="tab-content-wrapper">
                  <div className="tab-content">
                    <div className="payment-block">
                      <div className="block-detail">
                        <div className="monthly-payment">
                          <span>${this.price5per.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</span>
                          <sub>/ month</sub>
                        </div>
                        <div className="tag-wrapper">
                          <div className="tag">5% down</div>
                          <div className="tag">20 year payoff</div>
                        </div>
                        <span className="info-text">
                          Lowest monthly payment
                        </span>
                        <em className="arrow-icon">
                          <a href="#property-price-section">
                            <img src={imageArrow} alt="" />
                          </a>
                        </em>
                      </div>
                      <div className="block-detail">
                        <div className="monthly-payment">
                          <span>${this.price20per.toLocaleString('en-IN', { minimumFractionDigits: 2 })}</span>
                          <sub>/ month</sub>
                        </div>
                        <div className="tag-wrapper">
                          <div className="tag">20% down</div>
                          <div className="tag">10 year payoff</div>
                        </div>
                        <span className="info-text">
                          Fastest Payoff
                        </span>
                        <em className="arrow-icon">
                          <a href="#property-price-section">
                            <img src={imageArrow} alt="" />
                          </a>
                        </em>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="saving-block">
                <p className="tiny-text">Savings with Sparen</p>
                <h2 className="saving-price">$111,342.60</h2>
              </div>
              <div className="btn-wrapper">
                <button
                  className="rounded-fill-btn"
                  onClick={() =>

                    // this.props.history.push({
                    //   pathname: "/buyer-application",
                    //   state: { property_id: this.props?.location?.state?.property_id},
                    // })
                    this.state.propertyDetails?.price ?
                      // @ts-ignore
                      this.props?.history?.push({
                        pathname: "/buyer-application",
                        search: `id=${this.state.propertyId}`,
                        state: {
                          propertyID: this.state.propertyId,
                        }
                      }) : this.openSnackBarHandler("error", "No price Available")

                  }
                >
                  Get Started
                </button>
                <button
                  type="button"
                  className="btn gray-fill-btn"
                  onClick={() => {
                    this.handleShowTourModal(
                      this.state.propertyId
                    );
                  }}
                >
                  Schedule Tour
                </button>
              </div>
              <p className="note-block">
                Disclaimer: Savings are estimates and provided for informational
                purposes only. Actual savings may vary.
              </p>
            </div>
          </div>
        </section>
        <section className="price-section" id="property-price-section">
          <div className="container-main">
            <div className="left-block">
              <h2 className="title">What’s Your Price?</h2>
              <div className="tab-outer">
                <div className="tab-header">
                  <div
                    className={`tab ${this.state.activeTab == "tab1" ? "active" : ""}`}
                    onClick={() => this.changeTabs("tab1")}
                  >
                    <span className="image-block">
                      <img src={paymentWithSparen} alt="" />
                    </span>
                    <span>Pay With Sparen</span>
                  </div>
                  <div
                    className={`tab ${this.state.activeTab == "tab2" ? "active" : ""}`}
                    onClick={() => this.changeTabs("tab2")}
                  >
                    <span className="image-block">
                      <img src={imageCash} alt="" />
                    </span>
                    <span>Pay Cash</span>
                  </div>
                </div>
                <div className="tab-content">
                  {this.state.activeTab == "tab1" ? (
                    <div className="tab-item">
                      <div className="term-wrapper">
                        <div className="block-heading">
                          <p>CHOOSE YOUR TERM</p>
                        </div>
                        <div className="term-btn">
                          <span
                            onClick={() => this.setTerm(10)}
                            className={this.state.propertyDetailPrice?.term === 10 ? "active" : ""}
                          >
                            10 Years
                          </span>
                          <span
                            onClick={() => this.setTerm(15)}
                            className={this.state.propertyDetailPrice?.term === 15 ? "active" : ""}
                          >
                            15 Years
                          </span>
                          <span
                            onClick={() => this.setTerm(20)}
                            className={this.state.propertyDetailPrice?.term === 20 ? "active" : ""}
                          >
                            20 Years
                          </span>
                        </div>
                      </div>
                      <div className="block-heading">
                        <p>CHOOSE YOUR DOWN PAYMENT</p>
                        <span className="display-price">
                          {(this.state.propertyDetailPrice?.downpayment).toLocaleString("en-IN")}
                        </span>
                      </div>
                      {console.log(":::::::::::::this.state.propertyDetails::::::>", this.state.propertyDetails)}
                      <div className="slider-wrapper orange-slider">
                        <Slider
                          defaultValue={20}
                          // value={this.state.propertyDetailPrice?.downpayment}
                          aria-labelledby="discrete-slider-custom"
                          step={1}
                          min={1}
                          // onChange={(e, newValue) => this.setPaymentDetail("downpayment", newValue)}
                          valueLabelDisplay="auto"
                        // marks={[
                        //   {
                        //     value: 0,
                        //     label: "$50,000",
                        //   },
                        //   {
                        //     value: 100,
                        //     label: "$5,000,000",
                        //   },
                        // ]}
                        />
                      </div>.
                      <div className="block-heading">
                        <p>CHOOSE YOUR MONTHLY PAYMENT</p>
                        <span className="display-price">
                          {(this.state.propertyDetailPrice?.monthlypayment * 500).toLocaleString("en-IN")}
                        </span>
                      </div>
                      <div className="slider-wrapper orange-slider">
                        <Slider
                          defaultValue={20}
                          value={this.state.propertyDetailPrice?.monthlypayment}
                          aria-labelledby="discrete-slider-custom"
                          onChange={(e, newValue) => this.setPaymentDetail("monthlypayment", newValue)}
                          step={1}
                          valueLabelDisplay="auto"
                          marks={[
                            {
                              value: 0,
                              label: "$50,000",
                            },
                            {
                              value: 100,
                              label: "$5,000,000",
                            },
                          ]}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="tab-item">
                      <div className="pay-cash-wrap">
                        <div className="block-heading">
                          <p>Offer Amount</p>
                        </div>

                        <div className="slider-wrapper green-slider">
                          {/* <Slider
                          defaultValue={20}
                          value={this.state.propertyDetailPrice?.downpayment}
                          aria-labelledby="discrete-slider-custom"
                          step={1}
                          onChange={(e, newValue) => this.setPaymentDetail("downpayment", newValue)}
                          valueLabelDisplay="auto"
                          marks={[
                            {
                              value: 0,
                              label: "$50,000",
                            },
                            {
                              value: 100,
                              label: "$5,000,000",
                            },
                          ]}
                        /> */}

                          <Slider
                            className="priceSlider"
                            value={this.state.offerAmountLimit}
                            // defaultValue={values.downPayLimit}
                            min={1}
                            step={1}
                            max={100}
                            scale={(x) => x * this.pricePerUnit}
                            aria-labelledby="discrete-slider-always"
                            getAriaValueText={this.setLimitLabel}
                            valueLabelFormat={this.setLimitLabel}
                            onChange={(e, value) => {
                              this.setLimitHandler(
                                e,
                                value,
                                this.state.offerAmount,
                                this.pricePerUnit
                              );
                              this.setState({ offerAmountLimit: 100 * this.state.offerAmount / this.tprice },
                                () => {
                                  this.offerDifference = this.roundData(100 - this.state.offerAmountLimit)
                                }
                              )
                            }
                            }
                            valueLabelDisplay="auto"

                          />
                        </div>
                        <div className="content-wrapper pay-cash">
                          <div className="slider-wrapper">

                            <div className="value-wrapper">
                              <div className="left-block-cash">
                                {/* <div className="content">
                                  <span className="title-c">
                                    Est. back at close:
                                  </span>
                                  <span className="value v-highlight">$2000</span>
                                </div> */}
                                <div className="content">
                                  <span className="title-c">
                                    List price:
                                  </span>
                                  <span className="value">${this.tprice}</span>
                                </div>
                              </div>
                              <div className="right-block-cash">
                                <div className="content">
                                  <span className="value">
                                    ${this.state.offerAmount}
                                  </span>
                                </div>{" "}
                                <div className="content">
                                  <span className="title-c">
                                    Your offer is <span className="p-highlight">{this.offerDifference}%</span> below list
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="maintenance-wrapper">
                    <p className="block-title">Maintenance &amp; Insurance</p>
                    <div className="maintenance-block">
                      <div className="content-block"
                        onClick={() => { this.setState({ comingSoon: true, serviceType: "home_care" }) }}
                      >
                        <img src={homecare} alt="" />
                        <em>+</em>
                        <div className="content">
                          <span className="label">
                            KEEPER HOME CARE SERVICE
                          </span>
                          <span className="opation">2 Options</span>
                        </div>
                      </div>
                      <div className="content-block"
                        onClick={() => { this.setState({ comingSoon: true, serviceType: "home_insurance" }) }}

                      >
                        <img src={insurance} alt="" />
                        <em>+</em>
                        <div className="content">
                          <span className="label">HOME INSURANCE</span>
                          <span className="opation">2 Options</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-block">
              <div className="top-block">
                <p>Monthly Payment</p>
                <em>$1,686</em>
              </div>
              <div className="content-block">
                <div className="content">
                  <p className="title">Down Payment</p>
                  <p className="value">$ {(this.state.propertyDetailPrice?.downpayment * 500).toLocaleString("en-IN")}</p>
                </div>
                <div className="content">
                  <p className="title">Principle</p>
                  <p className="value">$ {(+this.state.propertyDetails?.price).toLocaleString("en-IN")}</p>
                </div>
                <div className="content">
                  <p className="title">Interest (APR)</p>
                  <p className="value">0%</p>
                </div>
                <hr />
                <div className="content">
                  <p className="title">Taxes</p>
                  <p className="value">$397/month</p>
                </div>
                <div className="content">
                  <p className="title">Home Insurance</p>
                  <p className="value">$89/month</p>
                </div>
                <div className="content">
                  <p className="title">Maintenance</p>
                  <p className="value">$140/month</p>
                </div>
                <div className="content">
                  <p className="title">Transaction Fee</p>
                  <p className="value">$50/month</p>
                </div>
                <hr />
                <div className="content">
                  <p className="title">Term</p>
                  <p className="value">{this.state.propertyDetailPrice?.term} Years</p>
                </div>
                <button className="btn orange-fill-btn">Get Started</button>
              </div>
            </div>
          </div>
        </section>
        <section className="similar-properties">
          <div className="title">Similar Properties</div>
          {this.state.similarPropertyDetails?.length > 0 ? (

            <OwlCarousel options={this.options1} loop={this.state.similarPropertyDetails.length > 3 ? true : false}>
              {this.state.similarPropertyDetails?.map((item) => {
                return (
                  <div className="property-block" key={item.id} >
                    <div className="top-block">
                      <div className="profile-block">
                        <i>
                          <img src={buyerSkew} alt="" />
                        </i>
                        <span>{`${item.attributes.owner.first_name ? item.attributes.owner.first_name : "Unknown"} ${item.attributes.owner.last_name ? item.attributes.owner.last_name : ""}`}</span>
                      </div>
                      {item.attributes.is_favorited ? (
                        <div className="like-block" onClick={() => this.doRemoveFavoriteHouse(item.id)}>
                          <img src={imageLikeRed} alt="Liked image" />
                        </div>) : (
                        <div className="like-block" onClick={() => this.doMakeFavoriteHouse(item.id)}>
                          <img src={imageLike} alt="Like image" />
                        </div>
                      )}
                      {/* <div className="like-block">
                  <img src={imageLike} alt="Like image" />
                </div> */}
                    </div>
                    <i className="property-image">
                      <img src={item.attributes.images[0] ? item.attributes.images[0]?.url : noImgAvailable} alt="Property Image" />
                    </i>
                    <div className="like-view-block">
                      <div className="left-block">
                        <p className="lable">List Price</p>
                        <p className="price">{item.attributes.formatted_price}</p>
                      </div>
                      <div className="right-block">
                        <div className="view">
                          <i>
                            <img src={imageView} alt="" />
                          </i>
                          <span>{item.attributes.view_count}</span>
                        </div>
                        <div className="like">
                          <i>
                            <img src={imageLikeRed} alt="" />
                          </i>
                          <span>{item.attributes.favorited_count}</span>
                        </div>
                      </div>
                    </div>
                    <div className="more-detail" onClick={() => this.setState({ propertyId: item.id })}>
                      <Link to={{
                        pathname: "/property-detail",
                        search: `id=${item.id}`,
                        state: { propertyID: item.id }
                      }}
                      >
                        <p className="property-name">{item.attributes.address.address_line}</p>
                      </Link>
                      <p className="property-address">{item.attributes.address.city + ", " + item.attributes.address.state + " " + item.attributes.address.zip_code}</p>
                      <ul>
                        <li>{item.attributes.bedrooms} Beds</li>
                        <li>{item.attributes.full_baths + item.attributes.half_baths} Baths</li>
                        <li>{item.attributes.finished_sq_ft} Sq ft</li>
                      </ul>
                    </div>
                  </div>
                )
              })
              }
            </OwlCarousel>
          ) : null}
        </section>
        <footer>
          <div className="container-main">
            <div className="left-footer">
              <div className="logo">
                <i>
                  <img
                    src={footerLogo}
                    alt="footerLogo"
                    className="footer-logo"
                  />
                </i>
                <span>
                  KEEP UP WITH THE <br /> NEIGHBORHOOD.
                </span>
              </div>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email(configJSON.errorEmailNotValid).required(configJSON.errorEmailReq),
                })}
                onSubmit={(values) => this.doSubscribe(values)}
                enableReinitialize
              >
                {({ values, setFieldValue, errors }) => (
                  <Form className="form-wrapper fill-detail">
                    <div className="field-wrapper">
                      <Field
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        className="custom-input type-mail mb-2"
                      />
                      <div className="error ml-3">
                        <ErrorMessage name="email" />
                      </div>
                      <button className="btn white-fill-btn mt-3" type="submit">
                        SUBSCRIBE
                      </button>
                    </div>
                  </Form>)}
              </Formik>
            </div>
            <div className="right-footer">
              <div className="footer-links">
                <h3>COMPANY</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="About us">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="How it Works">
                      How it Works
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Referrals">
                      Referrals
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Careers">
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Contact Us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links">
                <h3>SOLUTIONS</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Buy A Home">
                      Buy A Home
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Sell A Home">
                      Sell A Home
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Keeper">
                      Keeper
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links">
                <h3>RESOURCES</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Blog">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Support">
                      Support
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Partners">
                      Partners
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links larg-width">
                <h3>BUYERS</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Recently Listed">
                      Recently Listed
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="How It Works">
                      How It Works
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Savings Calculator">
                      Savings Calculator
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="SafeSpend Estimator">
                      SafeSpend Estimator
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Buyer FAQ">
                      Buyer FAQ
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links">
                <h3>SELLERS</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Recently Sold">
                      Recently Sold
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="How It Works">
                      How It Works
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Savings Calculator">
                      Savings Calculator
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Seller FAQ">
                      Seller FAQ
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bottom-footer">
              <div className="media-icon">
                <div className="icon-wrapper">
                  <img src={instaImage} alt="Instagram Logo" />
                </div>
                <div className="icon-wrapper facebook-icon">
                  <img src={fbImage} alt="Facebook Logo" />
                </div>
                <div className="icon-wrapper">
                  <img src={twitterImage} alt="Twitter Logo" />
                </div>
              </div>
              <p className="copy-right">&copy;THE SPAREN COMPANY 2021</p>
              <div className="link-wrapper">
                <Link to={this.props?.location?.pathname} title="TERMS OF SERVICE">
                  TERMS OF SERVICE
                </Link>
                <Link to={this.props?.location?.pathname} title="PRIVACY POLICY">
                  PRIVACY POLICY
                </Link>
              </div>
            </div>
          </div>
        </footer>
        <Modal
          show={this.state.SliderModal}
          onHide={this.toggleSliderModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="property-slider-modal"

          shouldcloseonoverlayclick={"true"}
        >
          <Modal.Header closeButton />

          <Modal.Body>
            <OwlCarousel id="sync1" options={this.Slideroption}>
              {this.state.propertyDetails.images?.map((img) => (
                <img
                  src={img.url}
                  key={img.id}
                />
              )) || <img src={mainPropertySlider} />}
            </OwlCarousel>
            <OwlCarousel id="sync2" options={this.Thumbniloption}>
              {this.state.propertyDetails.images?.map((img) => (
                <img
                  src={img.url}
                  key={img.id}
                />
              )) || <img src={mainPropertySlider} />}
            </OwlCarousel>
          </Modal.Body>
        </Modal>
        {/* Tour Schedule 1*/}
        {/* <ScheduleTour showTourModalProp={this.state.scheduleTourShow} onHideTourModal={this.handleShowScheduleTour} propertyId={this.state.propertyId} /> */}
        {/* Tour Schedule 2*/}
        <Modal
          show={this.state.scheduleTourShow}
          onHide={this.handleHideTourModal}
          dialogClassName="schedule-tour-modal"
          backdrop="static"
          aria-labelledby="buyer-schedule-tour-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Schedule Tour
            </Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{
              type: "",
              date: "",
              time: new Date(),
              note: "",
              showtime: "",
            }}
            validationSchema={Yup.object().shape(this.state.tourSchema)}
            onSubmit={(values) => {
              this.doBuyerTour(values, this.state.propertyId);
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <Modal.Body>
                  <div className="schedule-div">
                    {/* Schedule Type Buttons */}
                    <div className="schedule-type-btn">
                      <div className="sche-btn-wrap">
                        <button
                          className={`btn ${values.type === "in_person"
                            ? "orange-fill-btn"
                            : "gray-fill-btn"
                            }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue("type", "in_person");
                          }}
                        >
                          <span>In Person</span>
                        </button>
                        <button
                          className={`btn ${values.type === "live_video"
                            ? "orange-fill-btn"
                            : "gray-fill-btn"
                            }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue("type", "live_video");
                          }}
                        >
                          <img src={videoImg} />
                          <span>Live Video</span>
                        </button>
                      </div>
                      <span className="error">
                        <ErrorMessage name="type" />
                      </span>
                    </div>

                    {/* Select Date */}
                    <div className="select-date-div">
                      <h6 className="schedule-title">Select Date</h6>

                      <ul className="select-date-ul">
                        {this.state.nextSevenDays.map((d) => (
                          <li
                            key={d[0]}
                            className={`delect-date-li ${values.date == d[1] ? "green-bg-li" : ""
                              }`}
                            onClick={() => setFieldValue("date", d[1])}
                          >
                            <p className="day">{d[3]}</p>
                            <p className="date">{d[2]}</p>
                          </li>
                          //    <li
                          //   key={d.fullDate}
                          //   className={`delect-date-li ${
                          //     values.date == d.fullDate ? "green-bg-li" : ""
                          //   }`}
                          //   onClick={() => setFieldValue("date", d.fullDate)}
                          // >
                          //   <p className="day">{d.day}</p>
                          //   <p className="date">{d.date}</p>
                          // </li>
                        ))}
                      </ul>
                      <span className="error">
                        <ErrorMessage name="date" />
                      </span>
                    </div>

                    {/* Select Time */}
                    <div className="select-time-div">
                      <h6 className="schedule-title">Select Time</h6>

                      {/* <DatePicker
                      // selected={x.start_time}
                      onChange={(date) => setFieldValue(`time`, date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    /> */}
                      <DatePicker
                        selected={
                          values.showtime ? values.showtime : new Date()
                        }
                        onChange={(time) => {
                          let t = time.toLocaleTimeString("it-IT");
                          setFieldValue(`time`, t);
                          setFieldValue("showtime", time);
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="HH:mm aa"
                      />
                      <span className="error">
                        <ErrorMessage name="time" />
                      </span>
                    </div>

                    {/* Note */}
                    <div className="note-div">
                      <h6 className="schedule-title">Note</h6>

                      <div className="field-wrapper">
                        <Field
                          type="text"
                          name="note"
                          // placeholder={configJSON.styleHolder}
                          className="custom-input secondary-input"
                        />
                        <span className="error">
                          <ErrorMessage name="note" />
                        </span>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    variant="primary"
                    type="submit"
                    className="rounded-fill-btn"
                  // onClick={this.props.onHide}
                  >
                    Schedule Tour
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>



        {/* Coming Soon Modal */}

        <Modal
          show={this.state.comingSoon}
          onHide={this.handleHideComingSoon}
          dialogClassName="coming-soon"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <div>
              <div className="modal-wrapper">
                <div className="content-wrapper">
                  <h1 className="head-title">
                    Coming Soon
                  </h1>
                  <div className="image-wrap">
                    <img src={this.state.serviceType == "home_care" ? homecare : insurance} alt="homeImg" />
                  </div>
                  <p className="sub-title">
                    {this.state.serviceType == "home_care" ? "KEEPER HOME CARE SERVICE" : "HOME INSURANCE"}
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

export default withRouter(PropertyDetail);
