// @ts-nocheck

import React from "react";
// Customizable Area Start
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import DashboardController, { Props } from "./DashboardBuyerController.web";
import {
  imageDashboard,
  imageSetting,
  imageLikeFill,
  imageLogout,
  profieImg,
} from "../assets";
import { isEmpty } from "lodash";

class Header extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  toggleClass = () => {
    this.toggleMenu = !this.toggleMenu;
    document.body.classList.toggle("sidebar-open");
    this.forceUpdate();
  };
  render() {
    return (
      <>
        <div className="step-header-input">
          <div className="left-block">
            <div className="mobile-menu sidebar-toggle" onClick={this.toggleClass}>
              <ul className={this.toggleMenu ? "active" : ""}>
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
          <div className="right-block">
            {localStorage.getItem("auth") ? (
              <div className="profile-wrapper" onClick={this.props.toggle}>
                <div className="icon">
                  <span />
                </div>
                <div className="content">
                  <div className="name">
                    {`${!isEmpty(localStorage.getItem("first_name")) &&
                        localStorage.getItem("first_name") !== "null"
                        ? localStorage.getItem("first_name")
                        : localStorage.getItem("email").split("@")[0]
                      } ${!isEmpty(localStorage.getItem("last_name")) &&
                        localStorage.getItem("last_name") !== "null"
                        ? localStorage.getItem("last_name")
                        : ""
                      }`}
                  </div>
                  {/* <div
                    className="logout"
                    onClick={() => {
                      localStorage.clear();
                      this.props.history.push("/signin");
                    }}
                  >
                    Logout
                  </div> */}
                </div>
                <div className="profile-image">
                  <img
                    src={
                      !isEmpty(localStorage.getItem("profile_image")) &&
                        localStorage.getItem("profile_image") !== "null"
                        ? localStorage.getItem("profile_image")
                        : profieImg
                    }
                    alt="profile_image"
                  />
                </div>
                {this.props.flag && (
                  <ul className="dropdown-list">
                    <li>
                      <Link
                        to={
                          localStorage.getItem("role") === "buyer"
                            ? "/dashboard-buyer"
                            : "/dashboard-seller"
                        }
                        className="buyer-link"
                      >
                        Go to Buyer Account
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          localStorage.getItem("role") === "buyer"
                            ? "/dashboard-buyer"
                            : "/dashboard-seller"
                        }
                      >
                        <img src={imageDashboard} alt="" />
                        <span>Dashboard</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/account-setting">
                        <img src={imageSetting} alt="" />
                        <span>Account Settings</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/favorite-homes">
                        <img src={imageLikeFill} alt="" />
                        <span>Favorite Homes</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/"
                        onClick={() => {
                          localStorage.clear();
                          this.props?.history?.push("/signin");
                        }}
                      >
                        <img src={imageLogout} alt="" /> <span>Log out</span>
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            ) : (
              <div className="auth-btns-space">
                <button
                  className="rounded-fill-btn green-fill btn-mr"
                  onClick={() => this.props?.history?.push("/signin")}
                >
                  Log In
                </button>
                <button
                  className="rounded-fill-btn blue-fill"
                  onClick={() => this.props?.history?.push("/signup")}
                >
                  Sign Up
                </button>
              </div>
            )}
          </div>
        </div>
        {/* <h2 className="step-header-title">Add Property</h2> */}
      </>
    );
  }
}

// Customizable Area Start
export default withRouter(Header);

// Customizable Area End
