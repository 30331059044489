// @ts-nocheck

import React from "react";
// Customizable Area Start

import SidebarBuyer from "../../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../../components/src/Sidebar";
import { Link, withRouter, Route, Redirect } from "react-router-dom";

// Customizable Area End
import DashboardController, { Props } from "./DashboardSellerController.web";
import Header from "./AddPropertyStepper/Header.web";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

// Chart.register(ArcElement);
// Chart.register(CategoryScale)
import { MDBContainer } from "mdbreact";
import { Doughnut } from "react-chartjs-2";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { imageEdit, imageArrowBg, imageAttachment } from "../assets";
import { isEmpty } from "lodash";
class DashboardSeller extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    function createData(date, from, subject, catagory, amount, action, type) {
      return { date, from, subject, catagory, amount, action, type };
    }
    const rows = [
      createData(
        "03 July, 2020",
        "John Doe",
        "Maintenance in July Month",
        "Roof",
        "-$400",
        "",
        "debit"
      ),
      createData(
        "03 July, 2020",
        "John Doe",
        "Maintenance in July Month",
        "Roof",
        "+$400",
        "",
        "credit"
      ),
      createData(
        "03 July, 2020",
        "John Doe",
        "Maintenance in July Month",
        "Roof",
        "-$400",
        "",
        "debit"
      ),
      createData(
        "03 July, 2020",
        "John Doe",
        "Maintenance in July Month",
        "Roof",
        "+$400",
        "",
        "credit"
      ),
      createData(
        "03 July, 2020",
        "John Doe",
        "Maintenance in July Month",
        "Roof",
        "-$400",
        "",
        "debit"
      ),
    ];

    return localStorage.getItem("role") == "buyer" ? (
      <Route><Redirect to={{ pathname: '/dashboard-buyer', state: { from: this.props?.location } }} /></Route>) : (
      <>
        <div className="flex">
          <div>
            {/* Sidebar  */}
            {localStorage.getItem("role") === "buyer" ? (
              <SidebarBuyer menuCollapsed={true} />
            ) : (
              <SidebarSeller menuCollapsed={true} />
            )}
          </div>
          <div className="stepper-outer">
            <Header
              flag={this.state.HeaderDropdownFlag}
              toggle={this.doToggleHeaderDropdown}
            />
            <div className="seller-dashboard">
              <div className="page-title">
                Welcome {this.props.location?.state?.statusType === "login" ? "Back" : ""},{" "}
                {`${!isEmpty(localStorage.getItem("first_name")) &&
                  localStorage.getItem("first_name") !== "null"
                  ? localStorage.getItem("first_name")
                  : !isEmpty(localStorage.getItem("email"))
                    ? localStorage.getItem("email").split("@")[0]
                    : "User"
                  } ${!isEmpty(localStorage.getItem("last_name")) &&
                    localStorage.getItem("last_name") !== "null"
                    ? localStorage.getItem("last_name")
                    : ""
                  }`}
              </div>
              <div className="welcome-section">
                <h2>Welcome to Sparen!</h2>
                <div className="left-block">
                  <p>
                    Sparen manages your real estate for you. Now create your
                    first property to use Immocloud. Or maybe you don't have
                    time and you want to explore the functions first? Then fill
                    your account with test data, which you can later delete in
                    your profile.
                  </p>
                  <button
                    className="btn orange-fill-btn"
                    onClick={() => {
                      sessionStorage.removeItem('propertyId');
                      sessionStorage.removeItem('type');

                      this.props?.history?.push("/add-property");
                    }
                    }>
                    Add Property
                  </button>
                </div>
              </div>
              <div className="chart-block-outer">
                <div className="block-wrapper">
                  <div className="left-block">
                    <div className="label">Rent Income</div>
                    <div className="value">$50,000</div>
                    <Link to="/" className="link-wrapper">
                      <span>Financial Overview</span>
                      <img src={imageArrowBg} alt="" />
                    </Link>
                  </div>
                  <MDBContainer className="chart-wrapper">
                    <div>
                      <span className="content">January</span>
                      <Doughnut
                        data={this.state.DoughnutValueIncome}
                        height="100px"
                        width="100px"
                      />
                    </div>
                  </MDBContainer>
                </div>
                <div className="block-wrapper">
                  <div className="left-block">
                    <div className="label">Rent Income</div>
                    <div className="value">$50,000</div>
                    <Link to="/" className="link-wrapper">
                      <span>Financial Overview</span>
                      <img src={imageArrowBg} alt="" />
                    </Link>
                  </div>
                  <MDBContainer className="chart-wrapper">
                    <div>
                      <span className="content">January</span>
                      <Doughnut
                        data={this.state.DoughnutValuerents}
                        height="100px"
                        width="100px"
                      />
                    </div>
                  </MDBContainer>
                </div>
                <div className="block-wrapper">
                  <div className="left-block">
                    <div className="label">Rent Income</div>
                    <div className="value">$50,000</div>
                    <Link to="/" className="link-wrapper">
                      <span>Financial Overview</span>
                      <img src={imageArrowBg} alt="" />
                    </Link>
                  </div>
                  <MDBContainer className="chart-wrapper">
                    <div>
                      <span className="content">January</span>
                      <Doughnut
                        data={this.state.DoughnutValue}
                        height="100px"
                        width="100px"
                      />
                    </div>
                  </MDBContainer>
                </div>
              </div>
              <div className="bar-chart-wrapper">
                <div className="top-block">
                  <p className="title">Monthly Report Data</p>
                  <button className="btn orange-fill-btn">
                    Download Report
                  </button>
                </div>
                <MDBContainer>
                  <Bar data={this.state.BarChartValue} options={this.state.BarChartOption} />
                </MDBContainer>
              </div>
              <TableContainer className="custom-table-wrapper">
                <div className="top-block">
                  <p className="title">Transactions</p>
                  <button className="btn orange-fill-btn">View All</button>
                </div>
                <Table className="custom-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>From</TableCell>
                      <TableCell>Subject</TableCell>
                      <TableCell>Catagory</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {row.date}
                        </TableCell>
                        <TableCell>{row.from}</TableCell>
                        <TableCell>{row.subject}</TableCell>
                        <TableCell>
                          <span className="catagory-tag">{row.catagory}</span>
                        </TableCell>
                        <TableCell
                          className={`${row.type === "debit" ? "red-text" : "green-text"
                            }`}
                        >
                          {row.amount}
                        </TableCell>
                        <TableCell className="image-block">
                          <img src={imageEdit} alt="" />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="bottom-block">
                <div className="left-block">
                  <div className="head-block">
                    <h3 className="title">Messages</h3>
                    <button className="btn orange-fill-btn">View All</button>
                  </div>
                  <div className="content-wrapper">
                    <div className="content-block">
                      <div className="top-block">
                        <div className="block-detail">
                          <img src={imageAttachment} alt="" />
                          <div className="name-block">
                            <span>John Doe</span>
                            <p>Subject Name</p>
                          </div>
                        </div>
                        <span className="time-block">1:13pm</span>
                      </div>
                      <p className="info">
                        Lorem ipsu dolor sit amet, consecte udipiscing elit, sed
                        do eiusmod tempor incididunt ut
                      </p>
                    </div>
                    <div className="content-block">
                      <div className="top-block">
                        <div className="block-detail">
                          <img src={imageAttachment} alt="" />
                          <div className="name-block">
                            <span>John Doe</span>
                            <p>Subject Name</p>
                          </div>
                        </div>
                        <span className="time-block">1:13pm</span>
                      </div>
                      <p className="info">
                        Lorem ipsu dolor sit amet, consecte udipiscing elit, sed
                        do eiusmod tempor incididunt ut
                      </p>
                    </div>
                  </div>
                </div>
                <div className="right-block">
                  <div className="head-block">
                    <h3 className="title">Property</h3>
                    <button className="btn orange-fill-btn">View All</button>
                  </div>
                  <div className="content-wrapper">
                    <div className="content-block">
                      <div className="top-block">
                        <div className="block-detail">
                          <img src={imageAttachment} alt="" />
                          <div className="name-block">
                            <span>37577 Susan St.</span>
                            <p>Sterling Heights, MI 48310</p>
                          </div>
                        </div>
                        <span className="link-block">Active</span>
                      </div>
                    </div>
                    <div className="content-block">
                      <div className="top-block">
                        <div className="block-detail">
                          <img src={imageAttachment} alt="" />
                          <div className="name-block">
                            <span>37577 Susan St.</span>
                            <p>Sterling Heights, MI 48310</p>
                          </div>
                        </div>
                        <span className="link-block">Active</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// Customizable Area Start
export default withRouter(DashboardSeller);

// Customizable Area End
