export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const sparenBg = require("../assets/ReactNative_Homepagemain_assets_5a85994eb2e942ed89a088024492138101e18f9c.png");
export const sparenHomes = require("../assets/ReactNative_Homepagemain_assets_ab0e481490cf1e52e8d089961802ca7b743b253c.png");

export const boxesImage = require("../assets/boxesImage.png");
export const eyeImage = require("../assets/eye_image.png");
export const familyImage = require("../assets/family_image.png");
export const fbImage = require("../assets/fb_image.png");
export const heartEmptyImg = require("../assets/heart_Empty_img.png");
export const heartImage = require("../assets/heart_image.png");
export const homeBgImg = require("../assets/home_bg_img.png");
export const instaImage = require("../assets/insta_image.png");
export const logoImgBg = require("../assets/logo_img_bg.png");
export const logoWithNameImage = require("../assets/logo_with_name_image.png");
export const sparenLogo = require("../assets/sparen_logo.png");
export const twitterImage = require("../assets/twitter_image.png");
// export const houseDoller = require("../assets/ReactNative_Homepageseller_assets_c9d13dca6f9084e794bd92aece67fad5fa6d7e81.png");
export const glassHall = require("../assets/ReactNative_Homepageseller_assets_b7fc63f0c831ed22fd25d49770c994fb4d87a972.png");
export const ladyFace = require("../assets/5569f4ed1c6910886a00129e8cc1e87348b08627.png");

export const buyBgImg = require("../assets/26593c0fce3e200e5e793d251f0e44426644ed5b.png");
export const fenceBgImg = require("../assets/3540223ae4063782016bbbcb41ee9fad6618f538.png");
export const imageDollar = require("../../../web/images/image-dollar.png");
export const imageDollarSeller = require("../../../web/images/image-dollar-seller.png");
export const homePercentage = require("../../../web/images/home-percentage-img.png");
export const buyerLogo = require("../../../web/images/logo-green-text.png");
export const logoWhite = require("../../../web/images/logo-white.png");
export const imageView = require("../../../web/images/image-views.png");
export const imageLikeRed = require("../../../web/images/image-like-red.png");
export const imageLike = require("../../../web/images/image-like.png");
export const siginImage = require("../../../web/images/siginImage.png");
export const buyerSkew = require("../../../web/images/buyer-skew.png");
export const footerLogo = require("../../../web/images/footer-logo.png");
export const filterLogo = require("../../../web/images/filter.svg");
export const sortIcon = require("../../../web/images/sort-icon.svg");
export const tabClose = require("../../../web/images/tab-close.svg");
export const iconSearch = require("../../../web/images/iconSearch.svg");
export const arrowBottom = require("../../../web/images/arrow-bottom.svg");
export const imageLikeActive = require("../../../web/images/image-like-active.png");
export const bathroom = require("../../../web/images/bathroom.svg");
export const bedroom = require("../../../web/images/bedroom.svg");
export const areaSquare = require("../../../web/images/area-square-Ft.svg");
export const squareFt = require("../../../web/images/square-ft.svg");

export const profieImg = require("../../dashboard/assets/profile.jpeg");
export const noImgAvailable = require("../../../web/images/no-img-available.png");
