// @ts-nocheck

import React from "react";
// Customizable Area Start
import SidebarBuyer from "../../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../../components/src/Sidebar";
import { withRouter } from "react-router-dom";
// Customizable Area End
import ChatParentController, { Props } from "./ChatParentController.web";
import Header from "../../../dashboard/src/Seller/AddPropertyStepper/Header.web";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ScheduleTour from "../../../dashboard/src/Buyer/ScheduleTour.web";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { videoImg } from "../../../dashboard/src/assets";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CounterOffer from "./CounterOffer.web";
import ChatList from "./ChatList.web";
import ChatSearch from "./ChatSearch.web";
import ChatItem from "./ChatItem.web";
class ChatParent extends ChatParentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getAllConversation();
        // this.tourDates();
        // this.getMessages()
    }
    // Customizable Area End

    render() {
        return (
            <>
                <div className="flex">
                    <div>
                        {/* Slider  */}
                        {localStorage.getItem("role") === "buyer" ? (
                            <SidebarBuyer menuCollapsed={true} />
                        ) : (
                            <SidebarSeller menuCollapsed={true} />
                        )}
                    </div>
                    <div className="stepper-outer">
                        <Header
                            flag={this.state.HeaderDropdownFlag}
                            toggle={this.doToggleHeaderDropdown}
                        />
                        <div className="new-msg-block">
                            <div className="msg-notification">
                                <button type="button" className="btn orange-fill-btn">
                                    Messages
                                    <span>{this.state.displayChat?.length}</span>
                                </button>
                            </div>
                            <div className="new-msg">
                                <button type="button" className="rounded-fill-btn">
                                    New Message
                                </button>
                            </div>
                        </div>
                        <div className="chat-block-wrapper">
                            <div className="chat-list-wrapper">
                                <div className="search-wrapper">
                                    <Formik
                                        initialValues={{}}
                                        // validationSchema={Yup.object().shape(this.state.emailSchema)}
                                        onSubmit={(values) => console.log(values)}
                                        enableReinitialize
                                    >
                                        {({ values, setFieldValue, errors }) => (
                                            <Form>
                                                <Field
                                                    type="text"
                                                    name="Keywords"
                                                    placeholder="MLS, Yard, etc…"
                                                    className="custom-input secondary-input"
                                                    onChange={(e) => this.doSearch(e.target.value)}
                                                />
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                                <ChatList
                                    chatList={this.state.displayChat}
                                    coversationId={this.state.coversationId}
                                    setRecipientId={this.doRecipientId}
                                    setConversationId={this.doConversationId}
                                />
                            </div>
                            <div className="chat-content">
                                <div className="content-header">
                                    <span className="title">Seller Made Counter Offer</span>
                                    <div className="btn-wrapper">
                                        <button type="button" className="btn orange-fill-btn">
                                            Resolved
                                        </button>
                                        <button type="button" className="gray-border-btn" onClick={() => this.deleteConv(this.state.coversationId)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                                <div className="content-block">
                                    <ChatItem
                                        message={this.state.message}
                                        setCounterOfferDetail={this.doSetCounterOfferDetail}
                                    />

                                    <div className="send-msg-section">
                                        <ChatSearch
                                            coversationId={this.state.coversationId}
                                            recipientId={this.state.recipientId}
                                            sendMessageData={this.doMessaging}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Tour Schedule1 */}
                <ScheduleTour
                    showTourModalProp={this.state.scheduleTourShow}
                    onHideTourModal={this.handleShowScheduleTour}
                    propertyId={this.state.propertyId}
                    tourId={this.state.tourId}
                />

                {/* Tour Schedule2 */}
                <Modal
                    show={false}
                    onHide={this.handleHideTourModal}
                    dialogClassName="schedule-tour-modal"
                    backdrop="static"
                    aria-labelledby="buyer-schedule-tour-modal"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Schedule Tour
                        </Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            type: "",
                            date: "",
                            time: "",
                            note: "",
                            showtime: "",
                        }}
                        validationSchema={Yup.object().shape(this.state.tourSchema)}
                        onSubmit={(values) => {
                            this.doBuyerTour(values, this.state.propertyId);
                        }}
                        enableReinitialize
                    >
                        {({ values, setFieldValue, errors }) => (
                            <Form>
                                <Modal.Body>
                                    <div className="schedule-div">

                                        <div className="schedule-type-btn">
                                            <div className="sche-btn-wrap">
                                                <button
                                                    className={`btn ${values.type === "in_person"
                                                        ? "orange-fill-btn"
                                                        : "gray-fill-btn"
                                                        }`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setFieldValue("type", "in_person");
                                                    }}
                                                >
                                                    <span>In Person</span>
                                                </button>
                                                <button
                                                    className={`btn ${values.type === "live_video"
                                                        ? "orange-fill-btn"
                                                        : "gray-fill-btn"
                                                        }`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setFieldValue("type", "live_video");
                                                    }}
                                                >
                                                    <img src={videoImg} />
                                                    <span>Live Video</span>
                                                </button>
                                            </div>
                                            <span className="error">
                                                <ErrorMessage name="type" />
                                            </span>
                                        </div>


                                        <div className="select-date-div">
                                            <h6 className="schedule-title">Select Date</h6>
                                            <ul className="select-date-ul">
                                                {this.state.nextSevenDays.map((d) => (
                                                    <li
                                                        key={d[0]}
                                                        className={`delect-date-li ${values.date == d[1] ? "green-bg-li" : ""
                                                            }`}
                                                        onClick={() => setFieldValue("date", d[1])}
                                                    >
                                                        <p className="day">{d[3]}</p>
                                                        <p className="date">{d[2]}</p>
                                                    </li>
                                                    // <li
                                                    //   key={d.fullDate}
                                                    //   className={`delect-date-li ${values.date == d.fullDate ? "green-bg-li" : ""
                                                    //     }`}
                                                    //   onClick={() => setFieldValue("date", d.fullDate)}
                                                    // >
                                                    //   <p className="day">{d.day}</p>
                                                    //   <p className="date">{d.date}</p>
                                                    // </li>
                                                ))}
                                            </ul>
                                            <span className="error">
                                                <ErrorMessage name="date" />
                                            </span>
                                        </div>


                                        <div className="select-time-div">
                                            <h6 className="schedule-title">Select Time</h6>

                                            <DatePicker
                                                selected={values.showtime ? values.showtime : new Date()}
                                                onChange={(time) => {
                                                    let t = time.toLocaleTimeString("it-IT");
                                                    setFieldValue(`time`, t);
                                                    setFieldValue("showtime", time);
                                                }}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                dateFormat="HH:mm aa"
                                            />
                                            <span className="error">
                                                <ErrorMessage name="time" />
                                            </span>
                                        </div>

                                        <div className="note-div">
                                            <h6 className="schedule-title">Note</h6>

                                            <div className="field-wrapper">
                                                <Field
                                                    type="text"
                                                    name="note"
                                                    // placeholder={configJSON.styleHolder}
                                                    className="custom-input secondary-input"
                                                />
                                                <span className="error">
                                                    <ErrorMessage name="note" />
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <button
                                        variant="primary"
                                        type="submit"
                                        className="rounded-fill-btn"
                                    // onClick={this.props.onHide}
                                    >
                                        Schedule Tour
                                    </button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>


                {/* Loader  */}
                <Snackbar
                    open={this.state.snackBar.show}
                    autoHideDuration={3000}
                    onClose={this.closeSnackBarHandler}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.closeSnackBarHandler}
                        severity={this.state.snackBar.type}
                    >
                        {this.state.snackBar.message}
                    </MuiAlert>
                </Snackbar>


            </>
        );
    }
}

// Customizable Area Start
export default withRouter(ChatParent);

// Customizable Area End
