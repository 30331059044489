// @ts-nocheck

import React from "react";
// Customizable Area Start
import SidebarBuyer from "../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../components/src/Sidebar";
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import LiveChatController, { Props } from "../src/LiveChatController";
import Header from "../../dashboard/src/Seller/AddPropertyStepper/Header.web";
import {
  imageAttachment,
  checked,
  imageInfo,
  checkListHome,
  fixImg,
  arrowDown,
  workingImg,
  imagePriceTag,
  imageScrewdrive,
} from "./assets";
import { Formik, Form, Field } from "formik";
import Accordion from "react-bootstrap/Accordion";
import { Modal } from "react-bootstrap";
import Card from "react-bootstrap/Card";

interface S {
  CheckListFlag: any;
  CheckListSummary: any;
  CheckListFinal: any;
}

class BuyerPropertyList extends LiveChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  toggleCheckListFlag = () => {
    this.setState({
      ...this.state,
      CheckListFlag: !this.state.CheckListFlag,
    });
  };

  toggleCheckListSummary = () => {
    this.setState({
      ...this.state,
      CheckListFlag: false,
      CheckListSummary: !this.state.CheckListSummary,
    });
  };

  toggleCheckListFinal = () => {
    this.setState({
      ...this.state,
      CheckListFlag: false,
      CheckListSummary: false,
      CheckListFinal: !this.CheckListFinal,
    });
  };

  render() {
    return (
      <div className="flex">
        <div>
          {/* Slider  */}
          {localStorage.getItem("role") === "buyer" ? (
            <SidebarBuyer menuCollapsed={true} />
          ) : (
            <SidebarSeller menuCollapsed={true} />
          )}
        </div>
        <div className="stepper-outer">
          <Header
            flag={this.state.HeaderDropdownFlag}
            toggle={this.doToggleHeaderDropdown}
          />
          <div className="new-msg-block">
            <div className="msg-notification">
              <button type="button" className="btn orange-fill-btn">
                Properties
              </button>
            </div>
          </div>
          <div className="buyer-property-list">
            <div className="buyer-property">
              <div className="left-block">
                <div className="image-block">
                  <img src={imageAttachment} alt="" />
                </div>
                <div className="content">
                  <span className="title">37577 Susan St.</span>
                  <span className="info-text">Sterling Heights, MI 48310</span>
                  <span className="orange-text">Under Process</span>
                </div>
              </div>
              <div className="right-block">
                <span className="orange-text">
                  Offer is accepted. Please sign agreement and then proceed
                  further.
                </span>
                <div className="btn-wrapper">
                  <button type="button" className="btn orange-fill-btn">
                    Agreement
                  </button>
                  <button
                    type="button"
                    className="gray-border-btn"
                    onClick={() => this.toggleCheckListFlag()}
                  >
                    Check List
                  </button>
                  <button type="button" className="gray-border-btn">
                    Payment
                  </button>
                </div>
              </div>
            </div>
            <div className="buyer-property">
              <div className="left-block">
                <div className="image-block">
                  <img src={imageAttachment} alt="" />
                </div>
                <div className="content">
                  <span className="title">37577 Susan St.</span>
                  <span className="info-text">Sterling Heights, MI 48310</span>
                  <span className="orange-text">Under Process</span>
                </div>
              </div>
              <div className="right-block">
                <span className="orange-text">
                  Offer is accepted. Please sign agreement and then proceed
                  further.
                </span>
                <div className="btn-wrapper">
                  <button type="button" className="rounded-fill-btn">
                    <img src={checked} alt="" />
                    Agreement
                  </button>
                  <button type="button" className="btn orange-fill-btn">
                    Check List
                  </button>
                  <button type="button" className="gray-border-btn">
                    Payment
                  </button>
                </div>
              </div>
            </div>
            <div className="buyer-property">
              <div className="left-block">
                <div className="image-block">
                  <img src={imageAttachment} alt="" />
                </div>
                <div className="content">
                  <span className="title">37577 Susan St.</span>
                  <span className="info-text">Sterling Heights, MI 48310</span>
                  <span className="orange-text">Under Process</span>
                </div>
              </div>
              <div className="right-block">
                <span className="orange-text">
                  Offer is accepted. Please sign agreement and then proceed
                  further.
                </span>
                <div className="btn-wrapper">
                  <button type="button" className="rounded-fill-btn">
                    <img src={checked} alt="" />
                    Agreement
                  </button>
                  <button type="button" className="rounded-fill-btn">
                    <img src={checked} alt="" />
                    Check List
                  </button>
                  <button type="button" className="btn orange-fill-btn">
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
            <div className="buyer-property">
              <div className="left-block">
                <div className="image-block">
                  <img src={imageAttachment} alt="" />
                </div>
                <div className="content">
                  <span className="title">37577 Susan St.</span>
                  <span className="info-text">Sterling Heights, MI 48310</span>
                  <span className="orange-text">Under Process</span>
                </div>
              </div>
              <div className="right-block">
                <span className="orange-text">
                  Offer is accepted. Please sign agreement and then proceed
                  further.
                </span>
                <div className="btn-wrapper">
                  <button type="button" className="rounded-fill-btn">
                    <img src={checked} alt="" />
                    Agreement
                  </button>
                  <button type="button" className="btn red-fill-btn">
                    Check List Issue
                  </button>
                  <button type="button" className="gray-border-btn">
                    Payment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* checklist modal 1 */}
        <Modal
          show={this.state.CheckListFlag}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="checklist-modal"
          onHide={this.toggleCheckListFlag}
        >
          <Modal.Header closeButton>
            <Modal.Title>Buyer Check List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="info-block">
              <span className="img-block">
                <img src={imageInfo} />
              </span>
              <span>
                Please check all the below details before taking pusation. If
                required then ask owner for replacement.
              </span>
            </div>
            <div className="content-wrapper">
              <Formik>
                <Form>
                  <p className="title">Amenities</p>
                  <div className="que-ans-block">
                    <label>Water Supply</label>
                    <button className="select-ans">Agree</button>
                    <button className="select-ans active">Disagree</button>
                    <div className="field-wrapper">
                      <Field
                        type="text"
                        name="note"
                        className="custom-input secondary-input"
                        placeholder="Write note if any...."
                      />
                    </div>
                  </div>
                  <div className="que-ans-block">
                    <label>Lift</label>
                    <button className="select-ans active">Agree</button>
                    <button className="select-ans">Disagree</button>
                  </div>
                  <div className="que-ans-block">
                    <label>Security Service</label>
                    <button className="select-ans active">Agree</button>
                    <button className="select-ans">Disagree</button>
                  </div>
                  <div className="que-ans-block">
                    <label>Pool</label>
                    <button className="select-ans active">Agree</button>
                    <button className="select-ans">Disagree</button>
                  </div>
                </Form>
              </Formik>
            </div>
            <div className="btn-wrapper">
              <div className="left-block">
                <button type="button" className="btn gray-fill-btn">
                  Back
                </button>
                <button
                  type="button"
                  className="btn rounded-fill-btn"
                  onClick={() => this.toggleCheckListSummary()}
                >
                  Next
                </button>
              </div>
              <div className="right-block">
                <button type="button" className="btn gray-fill-btn">
                  Terminate Agreement
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* checklist modal 2 */}
        <Modal
          show={this.state.CheckListSummary}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="checklist-modal checklist-summary"
          onHide={this.toggleCheckListSummary}
        >
          <Modal.Header closeButton>
            <Modal.Title>Buyer Check List Summary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="info-block">
              <span className="img-block">
                <img src={imageInfo} />
              </span>
              <span>
                Please check all the below details before taking pusation. If
                required then ask owner for replacement.
              </span>
            </div>
            <div className="content-block">
              <img src={checkListHome} alt="" />
            </div>
            <span className="fixed-item">5 Items needs to fix</span>
            <div className="btn-wrapper">
              <button type="button" className="btn gray-fill-btn">
                Back
              </button>
              <button
                type="button"
                className="btn rounded-fill-btn"
                onClick={() => this.toggleCheckListFinal()}
              >
                Continue Payment
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* checklist modal 3 */}
        <Modal
          show={this.state.CheckListFinal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="checklist-modal checklist-final"
          onHide={this.toggleCheckListFinal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Buyer Check List Summary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="left-block">
              <Accordion>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <div className="left-title">
                      <div className="image-block">
                        <img src={fixImg} alt="" />
                      </div>
                      <span>Need To Fix</span>
                    </div>
                    <div className="right-title">
                      <div className="count-block">10</div>
                      <span>Items</span>
                      <div className="arrow-icon">
                        <img src={arrowDown} alt="" />
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <ul>
                        <li>Water Tank</li>
                        <li>Garage</li>
                        <li>Parking</li>
                        <li>Bathtub</li>
                        <li>Washroom</li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1">
                    <div className="left-title">
                      <div className="image-block">
                        <img src={workingImg} alt="" />
                      </div>
                      <span>Working</span>
                    </div>
                    <div className="right-title">
                      <div className="count-block">10</div>
                      <span>Items</span>
                      <div className="arrow-icon">
                        <img src={arrowDown} alt="" />
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <ul>
                        <li>Water Tank</li>
                        <li>Garage</li>
                        <li>Parking</li>
                        <li>Bathtub</li>
                        <li>Washroom</li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
            <div className="right-block">
              <div className="block-wrapper">
                <div className="image-block">
                  <img src={imagePriceTag} alt="" />
                </div>
                <div className="content-block">
                  <p>Ask to Adjust Price</p>
                  <span className="price">$100,000</span>
                </div>
              </div>
              <div className="block-wrapper">
                <div className="image-block">
                  <img src={imageScrewdrive} alt="" />
                </div>
                <div className="content-block">
                  <p>Ask for Repair Items</p>
                  <span className="price">10 Items</span>
                </div>
              </div>
              <span className="optional-block">Or</span>
              <button type="button" className="btn gray-fill-btn">
                Terminate Agreement
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

// Customizable Area Start
export default withRouter(BuyerPropertyList);

// Customizable Area End
