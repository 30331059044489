// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "./DashboardBuyerController.web";
import {
  imageLikeActive,
  imageView,
  siginImage,
  buyerSkew,
  buyerLogo,
  bathroom,
  bedroom,
  areaSquare,
  squareFt,
  imageDashboard,
  imageSetting,
  imageLikeFill,
  imageLogout,
  profieImg,
  videoImg,
  imageLikeRed,
  imageLike,
  noImgAvailable
} from ".././assets";
import { isEmpty } from "lodash";
import { Modal } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AppLoader from '../../../../components/src/AppLoader.web'
import ScheduleTour from "./ScheduleTour.web";

export interface Props { }
interface S {
  FilterFlag: any;
}
class CompareHomes extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <header className="site-header fixed-header">
          <div className="container-main">
            <div className="left-header">
              <Link to={
                localStorage.getItem("role") === "buyer"
                  ? "/dashboard-buyer"
                  : "/dashboard-seller"
              }
                className="page-logo" title="Sparen Home">
                <img src={buyerLogo} alt="Logo" />
              </Link>
              <div className="link-wrapper">
                <ul>
                  <li>
                    <Link
                      to="/home-page-buyer"
                      title="Buy"
                      className="active"
                    >
                      Buy
                    </Link>
                  </li>
                  <li>
                    <Link to="/home-page-seller" title="Sell">
                      Sell
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} title="How It Works">
                      How It Works
                    </Link>
                  </li>
                  <button
                    className="rounded-fill-btn blue-fill sm-visible"
                    onClick={() => this.props?.history?.push("signin")}
                  >
                    Log In
                  </button>
                  <button
                    className="rounded-fill-btn blue-fill sm-visible"
                    onClick={() => this.props?.history?.push("signup")}
                  >
                    Sign Up
                  </button>
                </ul>
              </div>
            </div>
            <div className="right-header">
              <div className="profile-wrapper" onClick={this.doToggleHeaderDropdown}>
                <div className="icon">
                  <span />
                </div>
                <div className="content">
                  <div className="name">Hello , {`${!isEmpty(localStorage.getItem("first_name")) &&
                    localStorage.getItem("first_name") !== "null"
                    ? localStorage.getItem("first_name")
                    : localStorage.getItem("email").split("@")[0]
                    } ${!isEmpty(localStorage.getItem("last_name")) &&
                      localStorage.getItem("last_name") !== "null"
                      ? localStorage.getItem("last_name")
                      : ""
                    }`}</div>
                </div>
                <div className="profile-image">
                  <img
                    src={!isEmpty(localStorage.getItem("profile_image")) &&
                      localStorage.getItem("profile_image") !== "null"
                      ? localStorage.getItem("profile_image")
                      : profieImg}
                    alt="profile_image"
                  />
                </div>
                {/* DropDown  */}
                {this.state.HeaderDropdownFlag &&
                  <ul className="dropdown-list">
                    <li>
                      <Link to={
                        !isEmpty(localStorage.getItem("role")) && localStorage.getItem("role") === "buyer"
                          ? "/dashboard-buyer"
                          : "/dashboard-seller"
                      } className="buyer-link">
                        Go to Buyer Account
                      </Link>
                    </li>
                    <li>
                      <Link to={
                        !isEmpty(localStorage.getItem("role")) && localStorage.getItem("role") === "buyer"
                          ? "/dashboard-buyer"
                          : "/dashboard-seller"
                      }>
                        <img src={imageDashboard} alt="" />
                        <span>Dashboard</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/account-setting">
                        <img src={imageSetting} alt="" />
                        <span>Account Settings</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/favorite-homes">
                        <img src={imageLikeFill} alt="" />
                        <span>Favorite Homes</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/" onClick={() => {
                        localStorage.clear();
                        this.props?.history?.push("/signin");
                      }}>
                        <img src={imageLogout} alt="" /> <span>Log out</span>
                      </Link>
                    </li>
                  </ul>
                }
              </div>
            </div>
            <div className="mobile-menu" onClick={this.toggleClass}>
              <ul className={this.toggleMenu ? "active" : ""}>
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
        </header>
        <section className="favrite-property-list compare-property">
          <div className="container-main">
            <h3 className="title">Compare Homes</h3>
            <div className="property-wrapper">
              {!isEmpty(this.state.compareHouseList) ? this.state.compareHouseList?.map(home => <div className="property-block" key={home.id}>
                <div className="top-block">
                  <div className="profile-block">
                    <i>
                      <img src={buyerSkew} alt="" />
                    </i>
                    <span>{`${home.attributes.owner.first_name ? home.attributes.owner.first_name : "Unknown"} ${home.attributes.owner.last_name ? home.attributes.owner.last_name : ""}`}</span>
                  </div>
                  {/* <div className="like-block">
                    <img src={imageLikeActive} alt="Like image" 
                    // onClick={()=>this.doRemoveCompareHouse(home.id)}
                    />
                  </div> */}
                  {home.attributes?.is_favorited ? (
                    <div className="like-block" onClick={() => this.doRemoveFavoriteHouse(home.id)}>
                      <img src={imageLikeRed} alt="Liked image" />
                    </div>) : (
                    <div className="like-block" onClick={() => this.doMakeFavoriteHouse(home.id)}>
                      <img src={imageLike} alt="Like image" />
                    </div>
                  )}
                </div>
                <i className="property-image">
                  <img src={home.attributes.images[0] ? home.attributes.images[0]?.url : noImgAvailable} alt="Property Image" />

                  {/* <img src={home.attributes.images?.[0]?.url} alt="Property Image" /> */}
                </i>
                <div className="like-view-block">
                  <div className="left-block">
                    <p className="lable">List Price</p>
                    <p className="price">{home.attributes.formatted_price}</p>
                  </div>
                  <div className="right-block">
                    <div className="view">
                      <i>
                        <img src={imageView} alt="" />
                      </i>
                      <span>{home.attributes.view_count}</span>
                    </div>
                    <div className="like">
                      <i>
                        <img src={imageLikeRed} alt="" />
                      </i>
                      <span>{home.attributes.favorited_count}</span>
                    </div>
                  </div>
                </div>
                <div className="more-detail">
                  <p className="property-name">{home.attributes.address.address_line}</p>
                  <p className="property-address">
                    {`${home.attributes.address.state} , ${home.attributes.address.city} ${home.attributes.address.zip_code}`}
                  </p>
                </div>
                <div className="margin-top">
                  <div className="more-detail">
                    <ul>
                      <li>{home.attributes.bedrooms} Beds</li>
                      <li>{home.attributes.full_baths} Baths</li>
                      <li>{home.attributes.finished_sq_ft} Sq ft</li>
                    </ul>
                  </div>
                  <div className="bottom-block">
                    <p>Lorem ipsum dolor sit amet, consectetur iscing</p>
                    <div className="detail-block">
                      <div className="left-block">
                        <div className="icon-block">
                          <img src={bedroom} alt="" />
                        </div>
                        <span>Bedrooms</span>
                      </div>
                      <div className="right-block">{home.attributes.bedrooms}</div>
                    </div>
                    <div className="detail-block">
                      <div className="left-block">
                        <div className="icon-block">
                          <img src={bathroom} alt="" />
                        </div>
                        <span>Bathrooms</span>
                      </div>
                      <div className="right-block">{home.attributes.full_baths}</div>
                    </div>
                    <div className="detail-block">
                      <div className="left-block">
                        <div className="icon-block">
                          <img src={areaSquare} alt="" />
                        </div>
                        <span>Area Square Ft.</span>
                      </div>
                      <div className="right-block">{home.attributes.finished_sq_ft} {home.attributes.lot_unit === "sq_ft" ? "Sq ft." : "ft."}</div>
                    </div>
                    <div className="detail-block">
                      <div className="left-block">
                        <div className="icon-block">
                          <img src={squareFt} alt="" />
                        </div>
                        <span>Square Ft.</span>
                      </div>
                      <div className="right-block">{home.attributes.finished_sq_ft} {home.attributes.lot_unit === "sq_ft" ? "Sq ft." : "ft."}</div>
                    </div>
                    <div className="btn-wrapper">
                      <button className="rounded-fill-btn" onClick={() => { this.handleShowTourModal(home.id) }}>
                        Schedule Tour
                      </button>
                      <button type="button" className="btn gray-fill-btn" onClick={() => this.doRemoveCompareHouse(home.id)}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>)
                : <h2>No Compare Houses Found</h2>}
            </div>
          </div>
        </section>

        {/* Tour Schedule1 */}
        {/* <ScheduleTour showTourModalProp={this.state.scheduleTourShow} onHideTourModal={this.handleShowScheduleTour} propertyId={this.state.propertyId} /> */}

        {/* Tour Schedule */}
        <Modal
          show={this.state.scheduleTourShow}
          onHide={this.handleHideTourModal}
          dialogClassName="schedule-tour-modal"
          backdrop="static"
          aria-labelledby="buyer-schedule-tour-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Schedule Tour
            </Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{
              type: "",
              date: "",
              time: new Date(),
              note: "",
              showtime: "",
            }}
            validationSchema={Yup.object().shape(this.state.tourSchema)}
            // onSubmit={(values) => {
            //   this.doBuyerTour(values, this.state.propertyId);
            // }}
            onSubmit={(
              values: FormValues,
              { setSubmitting }: FormikHelpers<FormValues>
            ) => {
              setTimeout(() => {
                this.doBuyerTour(values, this.state.propertyId);
                setSubmitting(false);
              }, 500);
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, isSubmitting }) => (
              <Form>
                <Modal.Body>
                  <div className="schedule-div">
                    {/* Schedule Type Buttons */}
                    <div className="schedule-type-btn">
                      <div className="sche-btn-wrap">
                        <button
                          className={`btn ${values.type === "in_person"
                            ? "orange-fill-btn"
                            : "gray-fill-btn"
                            }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue("type", "in_person");
                          }}
                        >
                          <span>In Person</span>
                        </button>
                        <button
                          className={`btn ${values.type === "live_video"
                            ? "orange-fill-btn"
                            : "gray-fill-btn"
                            }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue("type", "live_video");
                          }}
                        >
                          <img src={videoImg} />
                          <span>Live Video</span>
                        </button>
                      </div>
                      <span className="error">
                        <ErrorMessage name="type" />
                      </span>
                    </div>

                    {/* Select Date */}
                    <div className="select-date-div">
                      <h6 className="schedule-title">Select Date</h6>

                      <ul className="select-date-ul">
                        {this.state.nextSevenDays.map((d) => (
                          <li
                            key={d[0]}
                            className={`delect-date-li ${values.date == d[1] ? "green-bg-li" : ""
                              }`}
                            onClick={() => setFieldValue("date", d[1])}
                          >
                            <p className="day">{d[3]}</p>
                            <p className="date">{d[2]}</p>
                          </li>
                        ))}
                      </ul>
                      <span className="error">
                        <ErrorMessage name="date" />
                      </span>
                    </div>

                    {/* Select Time */}
                    <div className="select-time-div">
                      <h6 className="schedule-title">Select Time</h6>

                      <DatePicker
                        selected={
                          values.showtime ? values.showtime : new Date()
                        }
                        onChange={(time) => {
                          let t = time.toLocaleTimeString("it-IT");
                          setFieldValue(`time`, t);
                          setFieldValue("showtime", time);
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="HH:mm aa"
                      />
                      <span className="error">
                        <ErrorMessage name="time" />
                      </span>
                    </div>

                    {/* Note */}
                    <div className="note-div">
                      <h6 className="schedule-title">Note</h6>

                      <div className="field-wrapper">
                        <Field
                          type="text"
                          name="note"
                          // placeholder={configJSON.styleHolder}
                          className="custom-input secondary-input"
                        />
                        <span className="error">
                          <ErrorMessage name="note" />
                        </span>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {/* <button
                    variant="primary"
                    type="submit"
                    className="rounded-fill-btn"
                    // onClick={this.props.onHide}
                  >
                    Schedule Tour
                  </button> */}
                  <button
                    type="submit"
                    className="rounded-fill-btn"
                    disabled={isSubmitting}
                  >
                    Schedule Tour
                    {isSubmitting && (
                      <p>Load</p>
                      // <RefreshIcon
                      //   style={{ fontSize: 16, marginLeft: 10, animation: spin 2s linear infinite }}
                      //   // className="op-rotate"
                      //   />
                    )}
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>

        {this.state.loader && <AppLoader title="Loading" />}

      </>
    );
  }
}

export default withRouter(CompareHomes);
