//@ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { PlaidLink } from "react-plaid-link";
//Customizable Area Start

import InstantVerificationController, {
  Props,
} from "./InstantVerificationController.web";
import Slider from "@material-ui/core/Slider";

import { Modal, Button } from "react-bootstrap";
import { verifyImg, logoImg, homeImg } from "./assets";
export const configJSON = require("./config");
import Loader from "../../../components/src/Loader.web";

//Customizable Area End

class InstantVerification extends InstantVerificationController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
  render() {
    // console.log("in Render Verify", this.state.verifyAccountFlag);

    return (
      <>
        <Modal
          show={this.state.verifyAccountFlag}
          dialogClassName="verification-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div>
              {this.state.loader && <Loader loading={this.state.loader} />}
              <div className="modal-wrapper">
                <div className="image-wrapper">
                  <img src={verifyImg} alt="verifyIMg" className="" />
                </div>
                <div className="content-wrapper">
                  <h1 className="head-title">
                    {configJSON.titleForInstantVerification}
                  </h1>
                  <p className="content">
                    {configJSON.labelForInstantVerification1}
                  </p>
                  <p className="content">
                    {configJSON.labelForInstantVerification2}
                  </p>
                  {/* <Link
                    to="/instant-verification-success"
                    className="rounded-fill-btn link-btn"
                  >
                    Verify Account
                  </Link> */}
                  <PlaidLink
                    className="rounded-fill-btn link-btn"
                    style={{
                      width: "226px",
                      padding: "12px 16px",
                      borderRadius: "50px",
                    }}
                    token={this.state.token}
                    onSuccess={this.onSuccess}
                    onExit={this.onExit}
                    onEvent={this.onEvent}
                  >
                    Verify Account
                  </PlaidLink>
                  {/* <button onClick={() => this.toggleVerifyAccountFlag()}>
                    click
                  </button> */}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Successful Registered*/}

        <Modal
          show={this.state.sucessVerifyAccountFlag}
          dialogClassName="signup-success-modal change-label-success"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div>
              <div className="modal-wrapper">
                <div className="content-wrapper">
                  <h1 className="head-title">
                    {configJSON.titleForRegSuccess}
                  </h1>
                  <div className="image-wrap">
                    <img src={homeImg} alt="homeImg" />
                  </div>
                  {localStorage.getItem("role") === "buyer" ? (
                    <>
                      <p className="sub-title">
                        {configJSON.subTitleRegSuccess}
                      </p>
                      <p className="content">{configJSON.labelForRegSuccess}</p>

                      <Slider
                        value={this.state.limit}
                        step={1}
                        scale={(x) => x * 1000}
                        aria-labelledby="discrete-slider-always"
                        getAriaValueText={this.setLimitLabel}
                        valueLabelFormat={this.setLimitLabel}
                        onChange={this.setLimitHandler}
                        valueLabelDisplay="on"
                      />

                      <div className="btn-wrapper">
                        {/* <Link
                      to={
                        localStorage.getItem("role") === "buyer"
                          ? "/dashboard-buyer"
                          : "/dashboard-seller"
                      }
                      className="btn rounded-fill-btn with-icon"
                    > */}
                        <div
                          onClick={() =>
                            this.doSpendingPricing(this.state.limit * 1000)
                          }
                          className="btn rounded-fill-btn with-icon"
                        >
                          <img src={logoImg} alt="logoImg" className="" />
                          <span>Search Homes</span>
                        </div>
                        {/* </Link> */}
                      </div>
                    </>
                  ) : (
                    <div className="btn-wrapper">
                      <Link
                        to={"/dashboard-seller"}
                        className="btn rounded-fill-btn with-icon"
                      >
                        {/* <div
                      onClick={() =>
                        this.doSpendingPricing(this.state.limit * 1000)
                      }
                      className="btn rounded-fill-btn with-icon"
                    > */}
                        <img src={logoImg} alt="logoImg" className="" />
                        <span>Search Homes</span>
                        {/* </div> */}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Loader  */}
      </>
    );
  }
}
export default withRouter(InstantVerification);
