// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import HomepagemainController, { Props } from "./HomepagemainController.web";
import { familyImage, buyerLogo, imageDollar } from "./assets";
import Slider from "@material-ui/core/Slider";
import { Carousel } from "react-bootstrap";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
class Homepagebuyer extends HomepagemainController {
  // toggleMenu: boolean = false;
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  // toggleClass = () => {
  //   this.toggleMenu = !this.toggleMenu;
  //   document.body.classList.toggle("menu-open");
  //   this.forceUpdate();
  // };

  render() {
    return (
      <div class="hm-wrap">
        <div className="page-wrapper">
          <header className="site-header">
            <div className="container-main">
              <div className="left-header">
                <Link to="/" className="page-logo" title="Sparen Home">
                  <img src={buyerLogo} alt="Logo" />
                </Link>
                <div className="link-wrapper">
                  <ul>
                    <li className="active" onClick={() => {
                      //@ts-ignore
                      this.props.history.push("home-page-buyer");
                      this.toggleClass();
                    }}>
                      {/* <Link to="/home-page-buyer" title="Buy"> */}
                      <a> Buy</a>
                      {/* </Link> */}
                    </li>
                    <li onClick={() => {
                      //@ts-ignore
                      this.props.history.push("home-page-seller");
                      this.toggleClass();
                    }}>
                      {/* <Link
                        to="/home-page-seller"
                        title="Sell"
                        className="active"
                      > */}
                      <a>Sell</a>
                      {/* </Link> */}
                    </li>
                    <li onClick={() => {
                      //@ts-ignore
                      this.props.history.push(this.props?.location?.pathname);
                      this.toggleClass();
                    }}>
                      {/* <Link to={this.props?.location?.pathname} title="How It Works"> */}
                      <a>How It Works</a>
                      {/* </Link> */}
                    </li>

                    <button
                      className="rounded-fill-btn blue-fill sm-visible"
                      onClick={() => this.props?.history?.push("signin")}
                    >
                      Log In
                    </button>
                    <button
                      className="rounded-fill-btn blue-fill sm-visible"
                      onClick={() => this.props?.history?.push("signup")}
                    >
                      Sign Up
                    </button>
                  </ul>
                </div>
              </div>
              <div className="right-header">
                <button
                  className="text-btn blue-text-btn"
                  onClick={() => this.props?.history?.push("signin")}
                >
                  Log In
                </button>
                <button
                  className="rounded-fill-btn blue-fill"
                  onClick={() => this.props?.history?.push("signup")}
                >
                  Sign Up
                </button>
              </div>
              <div className="mobile-menu" onClick={this.toggleClass}>
                <ul className={this.toggleMenu ? "active" : ""}>
                  <li />
                  <li />
                  <li />
                  <li />
                </ul>
              </div>
            </div>
          </header>
          <section className="buyer-seller-banner">
            <div className="container-main">
              <div className="left-block">
                <h1>Welcome Home.</h1>
                <p>
                  The faster,simpler,more flexible way <br />
                  to buy a house.
                </p>
                <div className="search-box">
                  <input
                    type="text"
                    placeholder="Search by State,City,or Zip Code"
                    onChange={(e) => this.searchChange(e.target.value)}
                  />
                  <button type="button" onClick={() => this.doSearch()}>
                    Find Homes
                  </button>
                </div>
                <ul>
                  <li>No credit.</li>
                  <li>No mortgage.</li>
                  <li>No interest.</li>
                </ul>
              </div>
              <div className="right-block banner-image">
                <img src={familyImage} alt="familyImage" />
              </div>
            </div>
          </section>
        </div>
        <section className="buyer-seller-content">
          <div className="container-main">
            <div className="left-block">
              <p className="heading">Effortless Home Buying</p>
              <p className="content">
                Buying a house is hard. The underwriting process is invasive,
                financing options are limited, and an unexpected change in
                financial.
              </p>
              <p className="title">It’s Lightening fast</p>
              <p className="content">
                From near instant decisions to easy setup, we use digital data
                rather than manual processes so you can find your dream home and
                start making memories sooner.
              </p>
              <p className="title">It’s simple</p>
              <p className="content">
                Rather than waiting weeks, our 100% digital application gives a
                decision in a matter of minutes so you can find your dream home
                and start making memories sooner.
              </p>
              <p className="title">And it’s extra bendy flexible</p>
              <p className="content">
                Life isn’t fixed-rate so why should your payments be? If you
                have a financial emergency, let us know and we’ll work with you
                to reduce your monthly payments.
              </p>
            </div>
            <div className="right-block">
              <img src={imageDollar} alt="imageDollar" />
            </div>
          </div>
        </section>
        <section className="saving-section">
          <div className="container-main">
            <h2 className="text-center">How Much do you save ?</h2>
            <p className="content">
              Mortgage payments are loaded with interest and you could end up
              paying up to 100% more for your home over the course of your term.
              That’s a lot of extra money. But we don’t charge interest so all
              that extra money stays in your pocket.
            </p>
            <p className="content">
              Use our savings calculator to see just how much you’d save buying
              your home with us vs a traditional mortgage.
            </p>
            <div className="terms-price-block">
              <div className="left-block">
                <div className="title">
                  <span>Term</span>
                </div>
                <div className="term-btn">
                  <span
                    className={
                      this.state.buyerPurchasePrice.term === 10 ? "active" : ""
                    }
                    onClick={() => this.setTerm(10)}
                  >
                    10 Years
                  </span>
                  <span
                    className={
                      this.state.buyerPurchasePrice.term === 15 ? "active" : ""
                    }
                    onClick={() => this.setTerm(15)}
                  >
                    15 Years
                  </span>
                  <span
                    className={
                      this.state.buyerPurchasePrice.term === 20 ? "active" : ""
                    }
                    onClick={() => this.setTerm(20)}
                  >
                    20 Years
                  </span>
                </div>
              </div>
              <div className="right-block">
                <div className="title">
                  <span>Saving</span>
                </div>
                <div className="total-saving">$ {this.state.buyerPurchasePrice.savings}</div>
              </div>
              <div className="slider-wrapper">
                <Slider
                  key={`slider-${this.state.buyerPurchasePrice.price}`}
                  defaultValue={this.state.buyerPurchasePrice.price}
                  aria-valuetext={0}
                  aria-labelledby="discrete-slider-custom"
                  step={1}
                  valueLabelDisplay="auto"
                  scale={(x) => x * 5000}
                  valueLabelFormat={(limit: number) => `$${limit}`}
                  valueLabelDisplay="on"
                  onChange={(e, newValue) => this.purchasePriceSlider(newValue)}
                // marks={[
                //   {
                //     value: 0,
                //     label: "$50,000",
                //   },
                //   {
                //     value: 100,
                //     label: "$5,000,000",
                //   },
                // ]}
                />
                <div className="title">
                  <span>Your Purchase Price</span>
                </div>
                <div className="total-saving">$ {this.convertPrice(this.state.buyerPurchasePrice.price * 5000)}</div>
              </div>
            </div>
          </div>
        </section>
        <section className="skew-section">
          <div className="text-block">
            <p>Ready to buy?</p>
          </div>
          <div className="image-block">
            <div className="white-border-skew" />
            <button type="button" className="btn white-fill-btn">
              Get Started
            </button>
          </div>
        </section>
        <section className="calculator-section">
          <div className="container-main">
            <div className="form-outer">
              <div className="form-header">
                <p className="form-title">SAFESPEND ESTIMATE</p>
                <p className="amount">
                  $ {this.convertPrice(
                    this.state.safespendEstimate.safeSpend
                  )}
                  <span>
                    /mo<sup>*</sup>
                  </span>
                </p>
              </div>
              <div className="form-body">
                <div className="slider-wrapper">
                  <p>MONTHLY INCOME</p>
                  <h3>
                    <em>$</em>
                    {this.convertPrice(
                      this.state.safespendEstimate.monthlyIncome * 5000
                    )}
                  </h3>
                  <Slider
                    key={`slider-${this.state.safespendEstimate.monthlyIncome}`}
                    defaultValue={this.state.safespendEstimate.monthlyIncome}
                    aria-valuetext={0}
                    aria-labelledby="discrete-slider-custom"
                    step={1}
                    valueLabelDisplay="auto"
                    scale={(x) => x * 5000}
                    valueLabelFormat={(limit: number) => `$${limit}`}
                    // valueLabelDisplay="on"
                    onChange={(e, newValue) =>
                      this.safespendEstimateSlider("monthlyIncome", newValue)
                    }
                  />
                </div>
                <div className="slider-wrapper">
                  <p>MONTHLY EXPENSES</p>
                  <h3>
                    <em>$</em>
                    {this.convertPrice(
                      this.state.safespendEstimate.monthlyExpenses * 5000
                    )}
                  </h3>
                  <Slider
                    key={`slider-${this.state.safespendEstimate.monthlyExpenses
                      }`}
                    defaultValue={this.state.safespendEstimate.monthlyExpenses}
                    aria-valuetext={0}
                    aria-labelledby="discrete-slider-custom"
                    step={1}
                    valueLabelDisplay="auto"
                    scale={(x) => x * 5000}
                    valueLabelFormat={(limit: number) => `$${limit}`}
                    onChange={(e, newValue) =>
                      this.safespendEstimateSlider("monthlyExpenses", newValue)
                    }
                  />
                </div>
                <div className="slider-wrapper">
                  <p>MONTHLY HOUSING COSTS</p>
                  <h3>
                    <em>$</em>
                    {this.convertPrice(
                      this.state.safespendEstimate.monthlyHousingCost * 5000
                    )}
                  </h3>
                  <Slider
                    key={`slider-${this.state.safespendEstimate.monthlyHousingCost
                      }`}
                    defaultValue={
                      this.state.safespendEstimate.monthlyHousingCost
                    }
                    aria-valuetext={0}
                    aria-labelledby="discrete-slider-custom"
                    step={1}
                    valueLabelDisplay="auto"
                    scale={(x) => x * 5000}
                    valueLabelFormat={(limit: number) => `$${limit}`}
                    onChange={(e, newValue) =>
                      this.safespendEstimateSlider(
                        "monthlyHousingCost",
                        newValue
                      )
                    }
                  />
                </div>
              </div>
              <div className="form-footer">
                <p>Get My Personalized Terms</p>
              </div>
              <p className="note-text">
                *Taxes and fees are not included in your estimated amount.
              </p>
            </div>
            <div className="content-block">
              <h2 className="head-title">SafeSpend Calculator</h2>
              <p>
                When you're trying to figure out how much you can afford to
                spend on housing, there are a few things you’ll need to
                remember:
              </p>
              <ul>
                <li>
                  Spend no more than 30% of your gross income on a monthly
                  mortgage payment
                </li>
                <li>
                  You should have at least 30% of the home's value saved up in
                  cash (or semi-liquid assets)
                </li>
                <li>
                  Buy a home valued at no more than three times your annual
                  household gross income.
                </li>
              </ul>
              <p>
                But instead of wracking your brain, just tell us how much you
                make and spend every month, and how much you spend on housing
                and we’ll tell you how much you can afford.
              </p>
              <p>
                But for a more accurate picture, click the button below to
                personalize your monthly payment without impacting your credit
                score.
              </p>
            </div>
          </div>
        </section>
        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </div>
    );
  }
}

export default withRouter(Homepagebuyer);
