// @ts-nocheck

import React from "react";
// Customizable Area Start
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import DashboardController, { Props } from "../DashboardSellerController.web";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Header from "./Header.web";
import { PlaidLink } from "react-plaid-link";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import SidebarBuyer from "../../../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../../../components/src/Sidebar";
import { imageEdit, imageDelete, imageUser, imageUpload } from "../../assets";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isEmpty } from "lodash";
export const configJSON = require("../../config.js");
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBSimpleChart
} from "mdbreact";
import { Doughnut } from "react-chartjs-2";


class AccountSetting extends DashboardController {
  // detailFlag: boolean = false;
  // paymentFlag: boolean = true;
  constructor(props: Props) {
    super(props);
  }


  // state = {
  //   dataDoughnut: 
  // }

  render() {
    function createData(date, id, note, amount,type) {
      return { date, id, note, amount,type };
    }
    const rows = [
      createData("03 July, 2020",123245674, "For July Rent","-$400","debit"),
      createData("03 July, 2020",123245674, "For July Rent","+$400","credit"),
      createData("03 July, 2020",123245674, "For July Rent","-$400","debit"),
      createData("03 July, 2020",123245674, "For July Rent","+$400","credit"),
      createData("03 July, 2020",123245674, "For July Rent","-$400","debit"),
    ];
    return (
      <>
      <div className="flex">
        <div>
          {localStorage.getItem("role") === "buyer" ? (
            <SidebarBuyer />
          ) : (
            <SidebarSeller />
          )}
        </div>
        <div className="stepper-outer contact-detail-page">
          <Header
            flag={this.state.HeaderDropdownFlag}
            toggle={this.doToggleHeaderDropdown}
          />
          <div className="btn-wrapper">
            <button
             className={
              "btn " + (this.state.detailFlag ? "orange-fill-btn" : "gray-border-btn")
            }  onClick={this.activeDetailtoggle}>Account Details</button>
            <button type="button" 
             className={
              "btn " + (this.state.paymentFlag ? "orange-fill-btn" : "gray-border-btn")
            }  onClick={this.activeDetailtoggle}>Payments Received/Sent</button>
          </div>
          {this.state.detailFlag && (
            <div className="detail-wrapper">
            <div className="left-block">
              <Formik
              initialValues={{      
                email :this.state.accDetail?.email || "",
                first_name :this.state.accDetail?.first_name || "",
                middle_name :this.state.accDetail?.middle_name || "",
                last_name :this.state.accDetail?.last_name || "",
                profile_picture :this.state.accDetail?.profile_picture || null,
                address_line :this.state.accDetail?.address?.address_line || "",
                city :this.state.accDetail?.address?.city || "",
                state :this.state.accDetail?.address?.state || "",
                zip_code :this.state.accDetail?.address?.zip_code || "",
                address_line_2 :this.state.accDetail?.address?.address_line_2 || ""
              }}
            validationSchema={Yup.object().shape(
              this.state.accountDetailSchema
            )}
            onSubmit={(values) => this.doAccountSetting(values)}
            enableReinitialize
              >
              {({ values, setFieldValue, errors }) => (
                <Form className="form-wrapper fill-detail">
                  <div className="upload-profile">
                     <img 
                     src={values.profile_picture ? 
                            (typeof values.profile_picture === "object"? URL.createObjectURL(values.profile_picture) : values.profile_picture) 
                            : imageUser 
                         } 
                     alt="profile_picture" 
                     />
                   <div className="upload-block">
                      <img src={imageUpload} />
                       <input type="file" onChange={(e)=> {
                         this.setProfileImg(e.target.files[0],setFieldValue)
                         }}/>
                   </div>
                  </div>
                 <div className="person-name">{`${values.first_name} ${values.last_name}`}</div>
                     <label>Personal Details</label>
                    <div className="name-wrapper">
                    <div className="field-wrapper  first-name">
                      <Field
                        type="text"
                        name="first_name"
                        placeholder={configJSON.firstNamePlaceHolder}
                        className="custom-input secondary-input"
                      />
                      <span className="error">
                      <ErrorMessage name="first_name" />
                      </span>
                    </div>
                    <div className="field-wrapper last-name">
                      <Field
                        type="text"
                        name="last_name"
                        placeholder={configJSON.lastNamePlaceHolder}
                        className="custom-input secondary-input "
                      />
                      <span className="error">
                      <ErrorMessage name="last_name" />
                      </span>
                    </div>
                    <div className="field-wrapper surname">
                      <Field
                        type="text"
                        name="middle_name"
                        placeholder={configJSON.middleNamePlaceHolder}
                        className="custom-input secondary-input "
                      />
                      <span className="error">
                      <ErrorMessage name="middle_name" />
                      </span>
                    </div>
                  </div>
                  <div className="field-wrapper">
                    <Field
                      type="text"
                      name="email"
                      placeholder={configJSON.emailPlaceHolder}
                      className="custom-input secondary-input"
                      disabled
                    />
                     <span className="error">
                    <ErrorMessage name="email" />
                    </span>
                  </div>
                  <div className="field-wrapper">
                    <Field
                      type="password"
                      name="password"
                      value="password"
                      placeholder={configJSON.passwordPlaceHolder}
                      className="custom-input secondary-input"
                      disabled
                    />
                    <span className="error">
                    <ErrorMessage name="password" />
                    </span>
                    <Link to={this.props?.location?.pathname} className="update-password">
                      Update Password
                    </Link>
                  </div>
                  <label>Address</label>
                  <div className="field-wrapper">
                    <Field
                      type="text"
                      name="address_line"
                      placeholder={configJSON.addressLinePlaceHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                    <ErrorMessage name="address_line" />
                    </span>
                  </div>
                  <div className="field-wrapper">
                    <Field
                      type="text"
                      name="address_line_2"
                      placeholder={configJSON.addressLine2PlaceHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                    <ErrorMessage name="address_line_2" />
                    </span>
                  </div>
                  <div className="field-outer-flex">
                  <div className="field-wrapper width-33">
                    <label className="mb-1">City</label>
                    <Field
                      type="text"
                      name="city"
                      placeholder={configJSON.cityPlaceHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="city" />
                    </span>
                  </div>
                  <div className="field-wrapper width-33">
                    <label className="mb-1">State</label>
                    <Field
                      type="text"
                      name="state"
                      placeholder={configJSON.statePlaceHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="state" />
                    </span>
                  </div>
                  <div className="field-wrapper width-33">
                    <label className="mb-1">Zip</label>
                    <Field
                      type="text"
                      name="zip_code"
                      placeholder={configJSON.zipCodePlaceHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="zip_code" />
                    </span>
                  </div>
                </div>
                <div className="btn-wrapper">
                  <button type="submit" className="rounded-fill-btn">Save</button>
                </div>
                  {/* {JSON.stringify(values,null,2)}
                  {JSON.stringify(errors,null,2)} */}
                </Form>
              )}
             </Formik>
            </div>
            <div className="right-block"> 
              <div className="title">Bank Accounts</div>
                   <PlaidLink
                    className="btn orange-fill-btn"
                    token={this.state.token}
                    onSuccess={this.onSuccess}
                    onExit={()=> console.log("Exit")}
                    onEvent={()=> console.log("Event")}
                  >
                   Add Bank Account
                  </PlaidLink>
              {!isEmpty(this.state.accDetail?.bank_accounts?.data) ? this.state.accDetail?.bank_accounts?.data?.map(bank=>(<div className="account-detail" key={bank.id}>
                <div className="left-detail">
                  <span className="label">{bank.attributes.institution_name}</span>
                  <span className="acount-number">********{bank.attributes.account_number_mask}</span>
                </div>
                <div className="right-detail">
                  <i>
                    <img src={imageEdit} alt="" />
                  </i>
                  <i className="delete-btn" onClick={()=>this.doDeleteBankAcc(bank.id)}>
                    <img src={imageDelete} alt="" />
                  </i>
                </div>
              </div>
              )): <h2>No Bank Link</h2>}
            </div>
          </div>
          )}
          {this.state.paymentFlag && (
          <div className="payment-block">
            <div className="account-detail-wrapper">
              <div className="top-block">
                <div className="left-block">
                  <p className="title">Total Income</p>
                  <p className="value">$50,000</p>
                </div>        
                {/* chart start */}
                <MDBContainer className="chart-wrapper">
                  <div>
                      <span className="content">January</span>
                      <Doughnut data={this.state.DoughnutValue} height="100px" width="100px" />
                  </div>
                </MDBContainer>

                {/* chart end */}
              </div>
              <div className="bottom-block">
                <div className="content"> 
                <p className="title">Account Holder Name</p>
                <p className="value">John Doe</p>
                </div>
                <div className="content"> 
                <p className="title">Account Number</p>
                <p className="value">9876 5432 1123 1123</p>
                </div>
                <div className="content"> 
                <p className="title">Bank Name</p>
                <p className="value">Union Bank</p>
                </div>
                <Link to="/" className="sychronize-bank">
                  Synchronize Bank Account
                </Link>
              </div>
            </div>
            <div className="transaction-outer">
              <Formik>
                <Form className="form-wrapper">
                  <div className="field-wrapper date-picker">
                    <DatePicker minDate={new Date()} />
                  </div>
                  <div className="field-wrapper">
                    <Field
                      type="text"
                      name="address"
                      placeholder={configJSON.addressLinePlaceHolder}
                      className="custom-input secondary-input search-block"
                    />
                  </div>
                </Form>
              </Formik>
              <div className="list-btn">
                <button className="transaction-btn active">
                  All Transactions
                </button>
                <button className="transaction-btn">Not Assigned</button>
                <button className="transaction-btn">Assigned</button>
                <button className="transaction-btn">Ignored</button>
                <button className="transaction-btn">Suggetions</button>
              </div>
              <div className="table-outer">
                <TableContainer>
                  <Table className="custom-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Transaction ID</TableCell>
                        <TableCell>Note</TableCell>
                        <TableCell>Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {row.date}
                          </TableCell>
                          <TableCell>{row.id}</TableCell>
                          <TableCell>{row.note}</TableCell>
                          <TableCell className={`${row.type==="debit" ? "red-text" : "green-text"}`}>{row.amount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
       {/* Snackbar for display success and failed messages. */}
         <Snackbar
            open={this.state.snackBar.show}
            autoHideDuration={3000}
            onClose={this.closeSnackBarHandler}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.closeSnackBarHandler}
              severity={this.state.snackBar.type}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar>
      </>
    );
  }
}

// Customizable Area Start
export default withRouter(AccountSetting);

// Customizable Area End
