import React from "react";

// Customizable Area Start

import { sortIconWhite } from "../assets";
import { Formik, Form, Field } from "formik";
import PopupsController, { Props } from "./PopupsController.web";

// Customizable Area End


class SortDropdown extends PopupsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        return (
            <>
                <div className="form-wrapper search-wrapper">
                    {/* <div className="field-wrapper">
                                <Field
                                    type="text"
                                    name="address"
                                    placeholder="Search Transactions…"
                                    className="custom-input secondary-input search-block"
                                />
                            </div> */}
                    <button
                        className="sort-btn"
                        onClick={() => this.doSortToggle()}
                    >
                        <span>Sort</span>
                        <img src={sortIconWhite} alt="" />
                    </button>
                    {this.state.sortFlag && (
                        <div className="sort-content">
                            <ul className="inner-links">
                                <li className="sort-item" title="Address" onClick={() => this.handleSortChild("address")}>
                                    <button type="button">
                                        <span>Address</span>
                                        {this.sortPrams?.addressSortType == "asc" ? <span className="value active">A to Z</span> : ""}
                                        {this.sortPrams?.addressSortType == "desc" ? <span className="value active">Z to A</span> : ""}
                                    </button>
                                    {this.state.sortBy.addressShow && (
                                        <ul className="sub-sort-list" >
                                            <li
                                                className={`sub-sort-list-item ${this.sortPrams?.addressSortType == "asc" ? "active" : ""}`}
                                                onClick={() => this.handleSortSubChild("asc")}
                                            >
                                                A to Z
                                            </li>
                                            <li
                                                className={`sub-sort-list-item ${this.sortPrams?.addressSortType == "desc" ? "active" : ""}`}
                                                onClick={() => this.handleSortSubChild("desc")}
                                            >
                                                Z to A
                                            </li>
                                        </ul>)}
                                </li>
                                <li className="sort-item" title="Status" onClick={() => this.handleSortChild("status")}>
                                    <button type="button">
                                        <span>Status</span>
                                        {this.sortPrams?.statusSortType == "active" ? <span className="value active">Active</span> : ""}
                                        {this.sortPrams?.statusSortType == "inactive" ? <span className="value active">In Active</span> : ""}
                                    </button>
                                    {this.state.sortBy.statusShow && (
                                        <ul className="sub-sort-list">
                                            <li
                                                className={`sub-sort-list-item ${this.sortPrams?.statusSortType == "active" ? "active" : ""}`}
                                                onClick={() => this.handleSortSubChild("active")}
                                            >
                                                Active
                                            </li>
                                            <li
                                                className={`sub-sort-list-item ${this.sortPrams?.statusSortType == "inactive" ? "active" : ""}`}
                                                onClick={() => this.handleSortSubChild("inactive")}
                                            >
                                                In Active
                                            </li>
                                        </ul>)}
                                </li>
                                <li className="sort-item" title="Reset" onClick={() => this.handleSortChild("clear")}>
                                    <button type="button">
                                        <span>Reset</span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default SortDropdown;
// Customizable Area End
