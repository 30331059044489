Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PlaidApiIntegrations";
exports.labelBodyText = "PlaidApiIntegrations Body";

exports.btnExampleTitle = "CLICK ME";

exports.linkTokenApiContentType = "application/json";
exports.linkTokenApiEndPoint = "/create-link-token";
exports.linkTokenApiMethod = "GET";
exports.putApiMethodType = "PUT";
exports.tokenExchangeApiContentType = "application/json";
exports.tokenExchangeApiEndPoint = "/token-exchange";
exports.tokenExchangeApiMethod = "POST";
exports.createTokenApiEndPoint =
  "bx_block_plaidapiintegrations/plaid/create_link_token";
exports.bankAccountApiEndPoint = "bx_block_plaidapiintegrations/bank_accounts";
exports.setPendingLimitApiEndPoint = "bx_block_profile/profile";

//Instant Verification Start Text
exports.titleForInstantVerification = "Instant Verification";
exports.labelForInstantVerification1 =
  "Sparen uses Plaid to instantly verify your identity, and periodically review your bank balance to make sure you have enough funds to complete your monthly payments";
exports.labelForInstantVerification2 =
  "We secure your informattion with the smae 256-bit encryption that banks use.";

//Instant Verification Completion Text1
exports.titleForSuccess = "$2,500";
exports.labelForSuccess1 =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi enim erat, maximus nec pulvinar auctor.";
exports.labelForSuccess2 = "But what does that mean?";
exports.btnContinue = "Continue";

//Instant Verification Completion Text2
exports.titleForContinue = "The 30% Rule";
exports.labelForContinue =
  "When you're trying to figure out how much you can afford to spend on housing, 30% of your income on rent is the Golden Rule.";
exports.btnHome = "Search Homes";

//Signup Success Text
exports.titleForRegSuccess = "Registration Successful";
exports.subTitleRegSuccess = "Select Spending Limit";
exports.labelForRegSuccess =
  "If you are a buyer then select spending limit which will help us to show proper results.";
exports.minimum = "Minimum";
exports.maximum = "Maimum";

// Customizable Area End
