// @ts-nocheck

import React from "react";
// Customizable Area Start

import ChatParentController, { Props } from "./ChatParentController.web";
import { viewBuyerImage, imageMail } from "../assets";
import { isEmpty } from "lodash";
import moment from "moment";
// Customizable Area End
class ChatList extends ChatParentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start   
        return (
            <>
                {!isEmpty(this.props.chatList) && this.props.chatList.map(conv => <>
                    <div
                        className={`chat-block ${this.props.coversationId === conv.id ? "active-chat" : ""}`}
                        key={conv.id}
                        onClick={() => {
                            this.props.setRecipientId(conv?.attributes?.recipient_id);
                            this.props.setConversationId(conv.id);
                        }
                        }>
                        <span className="image-block">
                            <img src={viewBuyerImage} alt="" />
                        </span>
                        <div className="right-block">
                            <div className="top-block">
                                <span className="name">{`${conv?.attributes?.communicator?.data?.attributes?.first_name ? conv?.attributes?.communicator?.data?.attributes?.first_name : "John"} ${conv?.attributes?.communicator?.data?.attributes?.last_name ? conv?.attributes?.communicator?.data?.attributes?.last_name : "Dow"}`}</span>
                                <span className="mail-icon">
                                    <img src={conv?.attributes?.communicator?.data?.attributes?.profile_picture ? conv?.attributes?.communicator?.data?.attributes?.profile_picture : imageMail} alt="" />
                                </span>
                                <span className="msg-time">{moment(conv?.attributes?.last_message?.data?.attributes?.created_at).format("h:mm A")}</span>
                            </div>
                            <div className="msg-content">
                                <p>
                                    {
                                        conv?.attributes?.last_message?.data?.attributes?.body
                                            ? conv?.attributes?.last_message?.data?.attributes?.body
                                            : (
                                                conv?.attributes?.last_message?.data?.attributes?.offer_data
                                                    ? (localStorage.getItem("role") == "seller" ? "You have got an offer" : "Your offer sent")
                                                    : (conv?.attributes?.last_message?.data?.attributes?.tour_data
                                                        ? (localStorage.getItem("role") == "seller" ? "You have got a vist request" : "Your visit request sent")
                                                        : "No Message"
                                                    )
                                            )
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </>)}

            </>
        );
        // Customizable Area End
    }

}

// Customizable Area Start
export default ChatList;

// Customizable Area End
