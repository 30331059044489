// @ts-nocheck
import React from "react";
import { withRouter } from "react-router-dom";
// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "../DashboardBuyerController.web";
import {
  iconAddCircle,
  iconRemoveCircle,
} from "../../assets";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

export interface Props { }
interface S { }

class InformationBuyer extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Formik
        initialValues={{
          buyerName: this.props.fieldData?.buyerName || "",
          buyerEmail: this.props.fieldData?.buyerEmail || "",
          buyerContact: this.props.fieldData?.buyerContact || "",
          cobuyerName: this.props.fieldData?.cobuyerName || "",
          cobuyerEmail: this.props.fieldData?.cobuyerEmail || "",
          showCobuyer: this.props.fieldData
            ? this.props.fieldData?.showCobuyer
            : true
        }}
        validationSchema={Yup.object().shape(
          this.state.buyerSchemaArray[this.props.currentStep]
        )}
        onSubmit={(values) => {
          this.props.paymentValues(values);
          this.props.nextStep();

        }}
        enableReinitialize
      >
        {({ values, setFieldValue, errors }) => {

          return (
            <Form>

              {/* Step2 */}

              <div className="step-2">
                <div className="step-datil">
                  <p className="question-block">
                    Fill Your Details
                  </p>

                  <div className="form-wrapper fill-detail">
                    <div className="field-wrapper">
                      <Field
                        type="text"
                        value={values.buyerName}
                        name="buyerName"
                        placeholder="Full Name"
                        className="custom-input secondary-input"
                      />
                      <span className="error">
                        <ErrorMessage name="buyerName" />
                      </span>
                    </div>

                    <div className="field-wrapper">
                      <Field
                        type="email"
                        name="buyerEmail"
                        placeholder="Your E-mail"
                        className="custom-input secondary-input"
                      />
                      <span className="error">
                        <ErrorMessage name="buyerEmail" />
                      </span>
                    </div>

                    <div className="field-wrapper">
                      <Field
                        type="number"
                        name="buyerContact"
                        placeholder="Your Phone Number"
                        className="custom-input secondary-input"
                      />
                      <span className="error">
                        <ErrorMessage name="buyerContact" />
                      </span>
                    </div>

                    {values.showCobuyer && (<>
                      <div className="field-wrapper">
                        <Field
                          type="text"
                          name="cobuyerName"
                          value={values.cobuyerName}
                          placeholder="Cobuyer Name"
                          className="custom-input secondary-input"
                        />
                        <span className="error">
                          <ErrorMessage name="cobuyerName" />
                        </span>
                      </div>

                      <div className="field-wrapper">
                        <Field
                          type="email"
                          name="cobuyerEmail"
                          placeholder="Cobuyer E-mail"
                          className="custom-input secondary-input"
                        />
                        <span className="error">
                          <ErrorMessage name="cobuyerEmail" />
                        </span>
                      </div>
                    </>)}

                    <div className="btn-wrapper">
                      <button className="border-btn" type="button"
                        onClick={() => {
                          // this.toggleShowBuyer(setFieldValue);
                          setFieldValue("showCobuyer", !values.showCobuyer)
                          values.showCobuyer == false && setFieldValue("cobuyerName", "");
                          values.showCobuyer == false && setFieldValue("cobuyerEmail", "")
                        }}
                      >
                        {values.showCobuyer ? (<>
                          <span className="img-icon">
                            <img src={iconAddCircle} alt="" />
                          </span>
                          <span>Remove Cobuyer</span>
                        </>) : (<>
                          <span className="img-icon">
                            <img src={iconRemoveCircle} alt="" />
                          </span>
                          <span>Add Cobuyer</span>
                        </>)}

                      </button>
                      <button className="border-btn" type="button">
                        <span className="img-icon">
                          <img src={iconAddCircle} alt="" />
                        </span>
                        <span>Buying as an LLC</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="step-footer">
                  <button
                    type="button"
                    className="btn gray-fill-btn"
                    onClick={this.props.previousStep}
                  >
                    Back
                  </button>
                  <button
                    className="rounded-fill-btn"
                    type="submit"
                  >
                    Next
                  </button>
                </div>
              </div>
              {/*{JSON.stringify(errors, null, 2)}
                          {JSON.stringify(values, null, 2)} */}

            </Form>
          )
        }}
      </Formik>

    );
  }
}

export default withRouter(InformationBuyer);
