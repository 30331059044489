// @ts-nocheck
import React from "react";
import { withRouter } from "react-router-dom";
// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "../DashboardBuyerController.web";

import { Formik, Form, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
export interface Props { }
interface S {}
class PropertyVisitBuyer extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
 }

 render() {
    if(this.state.step1Submit){
      this.props.nextStep()
      this.setState({step1Submit:false})
    }
    return (
      <Formik
                  initialValues={{
                    isVisited: this.props.fieldData
                      ?this.props.fieldData?.isVisited
                      :this.state.stepNext,
                    // Tour
                    scheduleTour: this.props.fieldData?.scheduleTour || false,
                    type:this.props.fieldData?.type || "",
                    date:this.props.fieldData?.date || this.handleDateFormat(new Date()),
                    showDate: this.props.fieldData?.showDate 
                    ? new Date(this.props.fieldData?.showDate) 
                    : new Date(),
                    time: this.props.fieldData?.time || new Date().toLocaleTimeString("it-IT"),
                    note: this.props.fieldData?.note || "",
                    showtime:this.props.fieldData?.showtime 
                    ? new Date(this.props.fieldData?.showtime) 
                    : new Date(),
                  }}
                  validationSchema={Yup.object().shape(
                    this.state.buyerSchemaArray[this.props.currentStep]
                  )}
                  onSubmit={(values) => {
                    //If want to submit form at very begining
                    // if(this.state.currentStep===1){
                    //    this.props.tourStep(); 
                    //   }
                    //  values.scheduleTour ?
                    //   this.doBuyerTour(values, this.props.propertyId)
                    //   :this.props.nextStep();
                      
                      this.props.paymentValues(values);
                      this.props.nextStep()
                  }}
                  enableReinitialize
                >
                  {({ values, setFieldValue, errors}) => {
                    
                    return(
                    <Form>
                      {/* Step1 */}
                            <div className="step-1">
                              <div className="question-block">
                                Have you visited this property?
                              </div>
                              <div className="ans-block">
                                <button
                                  className={`select-ans ${values.isVisited &&
                                    "active"}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setFieldValue("isVisited", true);
                                    setFieldValue("scheduleTour", false);

                                    
                                  }}
                                >
                                  Yes
                                </button>
                                <button
                                  className={`select-ans ${!values.isVisited &&
                                    "active"}`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setFieldValue("isVisited", false);
                                  }}
                                >
                                  No
                                </button>
                              </div>

                              {!values.isVisited && (
                                <div className="step-datil">
                                  <div className="question-block">
                                    Do you want to schedule a tour?
                                  </div>
                                  <div className="ans-block">
                                    <button
                                      className={`select-ans ${values.scheduleTour &&
                                        "active"}`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFieldValue("scheduleTour", true);
                                      }}
                                    >
                                      Yes
                                    </button>
                                    <button
                                      className={`select-ans ${!values.scheduleTour &&
                                        "active"}`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setFieldValue("scheduleTour", false);
                                      }}
                                    >
                                      No
                                    </button>
                                  </div>
                                  <div>
                                    <ErrorMessage
                                      className="error"
                                      component="div"
                                      name="scheduleTour"
                                    />
                                  </div>
                                  {values.scheduleTour && (
                                    <>
                                      <div className="question-block">
                                        How you want to visit?
                                      </div>
                                      <div className="tour-ans-block">
                                        <button
                                          className={`select-ans ${values.type ===
                                            "in_person" && "active"}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setFieldValue("type", "in_person");
                                          }}
                                        >
                                          In Person
                                        </button>
                                        <button
                                          className={`select-ans ${values.type ===
                                            "live_video" && "active"}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setFieldValue("type", "live_video");
                                          }}
                                        >
                                          Live Video
                                        </button>
                                        
                                      </div>
                                      <div className="tour-ans-block-error">
                                        <span className="error">
                                          <ErrorMessage name="type" />
                                        </span>
                                      </div>
                                      
                                      <div className="form-wrapper">
                                        <div className="field-wrapper width-50 date-picker">
                                          <label>Select Date</label>
                                          
                                          <DatePicker
                                            minDate={new Date()}
                                            name="showDate"
                                            selected={
                                              values.date
                                                ? values.showDate
                                                : new Date()
                                            }
                                            value={values.date
                                              ? values.showDate
                                              : new Date()}
                                            onChange={(dt) => {
                                              let date =
                                                dt.getFullYear() +
                                                "-" +
                                                (dt.getMonth() + 1) +
                                                "-" +
                                                dt.getDate();
                                              setFieldValue("date", date);
                                              setFieldValue("showDate", dt);
                                            }}
                                          />
                                          <span className="error">
                                            <ErrorMessage name="showDate" />
                                          </span>
                                        </div>
                                        <div className="field-wrapper width-50 time-picker">
                                          <label>Select Time</label>
                                          <DatePicker
                                            minDate={new Date()}
                                            selected={
                                              values.showtime
                                                ? values.showtime
                                                : new Date()
                                            }
                                            onChange={(time) => {
                                              let t = time.toLocaleTimeString(
                                                "it-IT"
                                              );
                                              setFieldValue(`time`, t);
                                              setFieldValue("showtime", time);
                                            }}
                                            min={new Date()}
                                            // minTime={new Date()
                                            //   .hours(new Date().hour())
                                            //   .minutes(new Date().minutes())}
                                            // maxTime={new Date()
                                            //   .hours(23)
                                            //   .minutes(45)}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={15}
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            filterTime={(t)=>this.filterPassedTime(t, values.showDate)}
                                          />
                                           <span className="error">
                                               <ErrorMessage name="showtime" />
                                           </span>
                                        </div>
                                        <div className="field-wrapper">
                                          <label>Note</label>
                                          <Field
                                            type="text"
                                            name="note"
                                            placeholder="Write note if any...."
                                            className="custom-input secondary-input"
                                          />
                                           <span className="error">
                                               <ErrorMessage name="note" />
                                           </span>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                              <div className="step-footer">
                          
                                <button
                                  variant="primary"
                                  type="submit"
                                  className="rounded-fill-btn"
                                  >
                                  Next
                                </button>
                              </div>
                            </div>
                       
                          {/* {JSON.stringify(errors, null, 2)}
                          {JSON.stringify(values, null, 2)} */}

                    </Form>
                  )}}
                </Formik>
                    
    );
  }
}

export default withRouter(PropertyVisitBuyer);
