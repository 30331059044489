// @ts-nocheck

import React from "react";
// Customizable Area Start

import ChatParentController, { Props } from "./ChatParentController.web";
import { attachmentImg, sendImg } from "../assets";
import { Formik, Form, Field } from "formik";
// Customizable Area End
class ChatSearch extends ChatParentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        return (
            <Formik
                initialValues={{
                    conversation_id: this.props.coversationId,
                    recipient_id: this.props.recipientId,
                    message: "",
                    attachments: [],
                }}
                // validationSchema={Yup.object().shape(this.state.EmailSchema)}
                onSubmit={(values): any => {
                    console.log("MESSAGE", values)
                    if (values.message.length != "" && values.message.trim().length != 0 || values.attachments.length > 0)
                        this.props.sendMessageData(values);
                    values.message = "";
                    values.attachments = [];
                }}
                enableReinitialize
            >
                {({ values, setFieldValue, errors }) => (
                    <Form>
                        <div className="message-sec">
                            <div className="attachment-div">
                                <label htmlFor="attachmentId">
                                    <img alt="Attachment" className="btn-image" src={attachmentImg} />
                                </label>
                                <input
                                    type="file"
                                    name="attachments"
                                    id="attachmentId"
                                    className="custom-input secondary-input attachmentId"
                                    onChange={(event) => {
                                        this.handleAttachement(event, setFieldValue);
                                    }}
                                    multiple
                                />
                            </div>
                            <div className="message-div">
                                <Field
                                    type="text"
                                    name="message"
                                    placeholder="Message @Stephen Cross"
                                    className="custom-input secondary-input"
                                />
                            </div>
                            <div className="sendBtn-div">
                                <button type="submit" className="sendbtn">
                                    <img alt="sendImg" src={sendImg} />
                                </button>
                            </div>
                        </div>
                        <div>
                            {values.attachments.map(x => <span style={{ margin: "10px" }}>{x.name}</span>)}
                        </div>
                        {/* {JSON.stringify(values,null,null)} */}
                    </Form>
                )}
            </Formik>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default ChatSearch;
// Customizable Area End
