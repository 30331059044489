// @ts-nocheck
import React from "react";
// import { Link, withRouter } from "react-router-dom";
import { Link, withRouter, Route, Redirect } from "react-router-dom";

// Customizable Area Start
import { Modal } from "react-bootstrap";

import {
    arrowButtonImg,
    roomImg,
    refreshImg,
    profieImg,
    replyImg,
    imageDownload,
    noImgAvailable
} from "../assets";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBSimpleChart,
} from "mdbreact";
import { Doughnut } from "react-chartjs-2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { isEmpty } from "lodash";
import _ from 'lodash';
import PopupsController, { Props } from "./PopupsController.web";

class PropertyItemDetail extends PopupsController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        if (!isEmpty(this.props?.propertyDetail)) {
            console.log("rendered", this.props?.propertyDetail?.id)
            return (<>
                <Modal
                    show={this.props.propItemDetailShow}
                    onHide={this.props.handlepropItemDetailShow}
                    dialogClassName="propertyItemDetail-modal summary-lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        <div className="seller-dashboard buyer-dashboard">
                            {/* Billing Account */}
                            <div className="content-block-wrapper">
                                <div className="left-block">
                                    <div className="block-title">
                                        <h2>
                                            Billing Account Details
                                        </h2>
                                        <div className="refresh-block">
                                            <img src={refreshImg} className="grid-img" />
                                            <span>Refresh</span>
                                        </div>
                                    </div>
                                    <div className="detail-wrapper">
                                        <h5 className="title">Account Holder Name</h5>
                                        <p className="value">John Doe</p>
                                    </div>
                                    <div className="detail-wrapper">
                                        <h5 className="title">Account Number</h5>
                                        <p className="value">9876 5432 1123 1123</p>
                                    </div>
                                    <div className="detail-wrapper">
                                        <h5 className="title">Bank Name</h5>
                                        <p className="value">Citi Bank</p>
                                    </div>
                                    <div className="sec-card-btn">
                                        <button className="btn rounded-fill-btn">
                                            View Details
                                        </button>
                                    </div>
                                </div>
                                <div className="right-block">
                                    <div className="block-wrapper">
                                        <div className="left-block">
                                            <div className="label">Payments Made</div>
                                            <div className="value">$50,000</div>
                                            <Link to="/" className="link-wrapper">
                                                <span>Financial Overview</span>
                                                <img src={arrowButtonImg} alt="" />
                                            </Link>
                                        </div>
                                        <MDBContainer className="chart-wrapper">
                                            <div>
                                                <span className="content">January</span>
                                                <Doughnut
                                                    data={this.state.DoughnutValue}
                                                    height="100px"
                                                    width="100px"
                                                />
                                            </div>
                                        </MDBContainer>
                                    </div>
                                    <div className="block-wrapper">
                                        <div className="left-block">
                                            <div className="label">Remaining Payments</div>
                                            <div className="value">$50,000</div>
                                            <Link to="/" className="link-wrapper">
                                                <span>Financial Overview</span>
                                                <img src={arrowButtonImg} alt="" />
                                            </Link>
                                        </div>
                                        <MDBContainer className="chart-wrapper">
                                            <div>
                                                <span className="content">January</span>
                                                <Doughnut
                                                    data={this.state.DoughnutValue}
                                                    height="100px"
                                                    width="100px"
                                                />
                                            </div>
                                        </MDBContainer>
                                    </div>
                                    <div className="rented-property-block">
                                        <h2 className="grid-sec-title marb-16">
                                            Property
                                        </h2>
                                        <div className="detail-wrapper">
                                            <div className="image-block">
                                                <img src={this.props?.propertyDetail?.attributes?.images[0] ? this.props?.propertyDetail?.attributes?.images[0]?.url : noImgAvailable} alt="propertyImg" />
                                            </div>
                                            <div className="content">
                                                <h5>{this.props?.propertyDetail?.attributes?.address?.address_line + " " + this.props?.propertyDetail?.attributes?.address?.address_line_2}</h5>

                                                <p className="address-detail">
                                                    {`${this.props?.propertyDetail?.attributes?.address?.city}, ${this.props?.propertyDetail?.attributes?.address?.state} ${this.props?.propertyDetail?.attributes?.address?.zip_code}`}
                                                </p>

                                                <div className="room-detail">
                                                    <span className="room-span">{this.props?.propertyDetail?.attributes?.bedrooms ? this.props?.propertyDetail?.attributes?.bedrooms : 0} Bed Rooms</span>
                                                    <span className="room-span">{this.props?.propertyDetail?.attributes?.bathrooms ? this.props?.propertyDetail?.attributes?.bathrooms : 0} Baths</span>
                                                    <span className="room-span">{this.props?.propertyDetail?.attributes?.finished_sq_ft} {this.props?.propertyDetail?.attributes?.lot_unit}</span>
                                                </div>
                                            </div>
                                            <div className="btn-wrapper">
                                                <button className="btn rounded-fill-btn">
                                                    View Details
                                                </button>
                                            </div>
                                        </div>
                                        <div className="display-payment-detail">
                                            <div className="left-block">
                                                <div className="detail-block">
                                                    <div className="label">Terms:</div>
                                                    <div className="value">{this.props?.propertyDetail?.attributes?.term_period} Years</div>
                                                </div>
                                                <div className="detail-block">
                                                    <div className="label">Monthly Payment:</div>
                                                    <div className="value">$ 4,500</div>
                                                </div>
                                                <div className="detail-block">
                                                    <div className="label">Down Payment:</div>
                                                    <div className="value">$ {this.props?.propertyDetail?.attributes?.min_down_payment}</div>
                                                </div>
                                            </div>
                                            <div className="right-block">
                                                <div className="detail-block">
                                                    <div className="label">Seller Name :</div>
                                                    <div className="value">{localStorage.getItem("first_name") + " " + localStorage.getItem("last_name")}</div>
                                                </div>
                                                <div className="detail-block">
                                                    <div className="label">Agreement:</div>
                                                    <div className="value">
                                                        <button className="btn download-btn"><img src={imageDownload} alt="" /><span>Download</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Transaction */}
                            <TableContainer className="custom-table-wrapper">
                                <div className="top-block">
                                    <p className="title">Transactions</p>
                                    <button className="btn orange-fill-btn">View All</button>
                                </div>
                                <Table className="custom-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Transaction ID</TableCell>
                                            <TableCell>Note</TableCell>
                                            <TableCell>Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.rows.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row" >
                                                    {row.date}
                                                </TableCell>
                                                <TableCell>{row.id}</TableCell>
                                                <TableCell>{row.note}</TableCell>
                                                <TableCell
                                                    className={`${row.type === "debit" ? "red-text" : "green-text"
                                                        }`}
                                                >
                                                    {row.amount}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {/* Messages */}
                            <div className="bottom-block">
                                <div className="left-block">
                                    <div className="head-block">
                                        <h3 className="title">Messages</h3>
                                        <button className="btn orange-fill-btn">
                                            View All
                                        </button>
                                    </div>
                                    <div className="content-wrapper">
                                        <div className="content-block">
                                            <div className="top-block">
                                                <div className="block-detail">
                                                    <img src={profieImg} />
                                                    <div className="name-block">
                                                        <span>John Doe</span>
                                                        <p>Subject Name</p>
                                                    </div>
                                                </div>
                                                <span className="time-block">1:13pm</span>
                                            </div>
                                            <p className="info">
                                                Lorem ipsu dolor sit amet, consecte udipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                            </p>
                                            <span className="reply-image">
                                                <img src={replyImg} />
                                            </span>
                                        </div>
                                        <div className="content-block">
                                            <div className="top-block">
                                                <div className="block-detail">
                                                    <img src={profieImg} />
                                                    <div className="name-block">
                                                        <span>John Doe</span>
                                                        <p>Subject Name</p>
                                                    </div>
                                                </div>
                                                <span className="time-block">1:13pm</span>
                                            </div>
                                            <p className="info">
                                                Lorem ipsu dolor sit amet, consecte udipiscing elit, sed
                                                do eiusmod tempor incididunt ut
                                            </p>
                                            <span className="reply-image">
                                                <img src={replyImg} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Content ENd */}
                        </div>
                    </Modal.Body>
                </Modal>
            </>)
        } else {
            return <></>
        }

    }
}

export default PropertyItemDetail;
