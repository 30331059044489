// @ts-nocheck

import React from "react";
// Customizable Area Start
import ChatParentController, { Props } from "../ChatParentController.web";
import { imageAttachment } from "../../assets";
import moment from "moment";
// Customizable Area End
class IIChatTourItem extends ChatParentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        return (
            <div className={`pay-wrap ${localStorage.getItem("role") == "seller" ? "mv-l" : "mv-r"}`}>
                <div className="payment-block">
                    <div className="head-block">
                        <div className="profile-block">
                            <img src={localStorage.getItem("profile_image") ? localStorage.getItem("profile_image") : imageAttachment} alt="" />
                        </div>
                        <span className="profile-name">{`${localStorage.getItem("first_name")} ${localStorage.getItem("last_name")}`}</span>
                    </div>
                    <div className="content">
                        <div className="left-block">
                            <img src={this.props.message?.attributes?.tour_data?.data?.attributes?.property?.images?.[0]?.url ? this.props.message?.attributes?.tour_data?.data?.attributes?.property?.images?.[0]?.url : imageAttachment} alt="" />
                        </div>
                        <div className="right-block">
                            <p className="invite-tile">hii, {this.props.message?.attributes?.tour_data?.data?.attributes?.visitor?.first_name ? this.props.message?.attributes?.tour_data?.data?.attributes?.visitor?.first_name : "jhone"} {this.props.message?.attributes?.tour_data?.data?.attributes?.visitor?.last_name ? this.props.message?.attributes?.tour_data?.data?.attributes?.visitor?.last_name : "dow"} wants to visit your</p>
                            <span className="time-block">Date: {moment(this.props.message?.attributes?.tour_data?.data?.attributes?.tour_at).format("D MMMM yyyy A")}</span>
                            {/* <span className="time-block">Date: {this.props.message?.attributeszz?.tour_data?.data?.attributes?.tour_at ? moment(this.props.message?.attributes?.tour_data?.data?.attributes?.tour_at).format(""): "10 january, 2020 Morning"}</span> */}
                            <div className="note">
                                <span className="label">Note:</span>
                                <p className="description">{this.props.message?.attributes?.tour_data?.data?.attributes?.note ? this.props.message?.attributes?.tour_data?.data?.attributes?.note : "No Note Found"}</p>
                            </div>
                            <div className="btn-wrapper">
                                {this.props.message?.attributes?.tour_data?.data?.attributes?.status === "pending"
                                    && Number(this.props.message?.attributes?.tour_data?.data?.attributes?.scheduler_id) !== Number(localStorage.getItem("id"))
                                    ?
                                    <>
                                        <button type="button" className="btn orange-fill-btn"
                                            onClick={() => {
                                                this.doScheduler(this.props.message?.attributes?.tour_data?.data?.attributes?.property_id, this.props.message?.attributes?.tour_data?.data?.id)
                                            }
                                            }>
                                            Accept
                                        </button>
                                        <button
                                            type="button"
                                            className="gray-border-btn"
                                            onClick={() => {
                                                this.dosetTourId(this.props.message?.attributes?.tour_data?.data?.id)
                                                this.handleShowTourModal(this.props.message?.attributes?.tour_data?.data?.attributes?.property_id);
                                            }}
                                        >
                                            Other Time
                                        </button>
                                    </>
                                    : this.props.message?.attributes?.tour_data?.data?.attributes?.status === "declined"
                                        && Number(this.props.message?.attributes?.tour_data?.data?.attributes?.scheduler_id) !== Number(localStorage.getItem("id"))
                                        ? <button type="button" className="btn orange-fill-btn"
                                            disabled
                                        >
                                            Declined
                                        </button> : this.props.message?.attributes?.tour_data?.data?.attributes?.status === "accepted"
                                        && Number(this.props.message?.attributes?.tour_data?.data?.attributes?.scheduler_id) !== Number(localStorage.getItem("id"))
                                        &&
                                        <button type="button" className="btn orange-fill-btn"
                                            disabled
                                        >
                                            Accepted
                                        </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default IIChatTourItem;
// Customizable Area End
