import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { isEqual } from "lodash";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  recentPropertyList: any;
  searchHome: any;
  buyerPurchasePrice: any;
  safespendEstimate: any;
  sellerSliderValue: any;
  sellerPurchasePrice: any;
  emailSubscription: any;
  snackBar: {
    show: boolean;
    message?: string;
    type?: "success" | "info" | "warning" | "error" | undefined;
  };
  toggleMenu: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HomepagemainController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPropertyListId: string = "";
  getSearchListId: string = "";
  getEmailSubscribeCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess)];
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      recentPropertyList: [],
      searchHome: "",

      buyerPurchasePrice: {
        term: 10,
        savings: 0,
        price: 10,
        purchased: 0,
      },
      snackBar: {
        show: false,
      },
      sellerPurchasePrice: {
        tradeFee: 0.06,
        spareFee: 396,
        price: 10,
        savings: 0,
      },
      safespendEstimate: {
        safeSpend: "",
        monthlyIncome: 10,
        monthlyExpenses: 10,
        monthlyHousingCost: 10,
      },
      sellerSliderValue: [
        {
          value: 0,
          label: "50,000",
        },
        {
          value: 100,
          label: "50,00,000",
        },
      ],
      emailSubscription: "",
      toggleMenu: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getNewPropertyList();
    await this.buyerPurchaseCalcu(this.state.buyerPurchasePrice)
    await this.safespendEstCalcu(this.state.safespendEstimate)
    await this.sellerPurchaseCalcu(this.state.sellerPurchasePrice)
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (!isEqual(prevState.buyerPurchasePrice, this.state.buyerPurchasePrice)) {
      this.buyerPurchaseCalcu(this.state.buyerPurchasePrice)
    }
    if (!isEqual(prevState.safespendEstimate, this.state.safespendEstimate)) {
      this.safespendEstCalcu(this.state.safespendEstimate)
    }

    //  if(!isEqual(prevState.sellerPurchasePrice, this.state.sellerPurchasePrice)){
    //   this.sellerPurchaseCalcu( this.state.sellerPurchasePrice)
    // }
  }

  buyerPurchaseCalcu = (x: any) => {
    const { term, price, purchased } = x
    let data = Object.assign({}, x)
    // console.log("price", price)
    let value = ((price * 5000) - (0.05 * (price * 5000))) / (term * 12)
    // mortgage payment (@ Superadm int rate) less ([price-deposit]/term) 
    // mortgage pmt = ppmt(int rate, 360 months, Price - Deposit)
    // ppmt = is calculated by a mortgage / financial calculator using all the other assumptions here;
    // int rate is a global variable set by Superadmin(in Adm Panel)
    data.savings = value.toLocaleString("en-IN", { minimumFractionDigits: 2 })
    this.setState({
      ...this.state,
      buyerPurchasePrice: data
    })
  }

  safespendEstCalcu = (x: any) => {
    const { monthlyIncome, monthlyExpenses, monthlyHousingCost } = x
    let data = Object.assign({}, x)
    // (inc - exp) + ((exp - housing) * .2) + 50
    let value = (monthlyIncome * 5000 - monthlyExpenses * 5000) + ((monthlyExpenses * 5000 - monthlyHousingCost * 5000) * 0.2) + 50
    data.safeSpend = value.toLocaleString("en-IN", { minimumFractionDigits: 2 })
    this.setState({
      ...this.state,
      safespendEstimate: data
    })
  }

  sellerPurchaseCalcu = (x: any) => {
    const { spareFee, price } = x
    let data = Object.assign({}, x)
    // [Price] * 0.06 - 396
    let value = (price * 50000) * 0.06 - spareFee
    let fee = ((price * 50000) * 0.06)
    data.savings = value.toLocaleString("en-IN", { minimumFractionDigits: 2 })
    data.tradeFee = fee.toLocaleString("en-IN", { minimumFractionDigits: 2 })
    this.setState({
      ...this.state,
      sellerPurchasePrice: data
    })
  }

  setTerm = (value: any) => {
    let data = Object.assign({}, this.state.buyerPurchasePrice);
    data.term = value;
    this.setState({
      ...this.state,
      buyerPurchasePrice: data,
    });
  };

  getNewPropertyList = () => {
    const headers = {
      "Content-Type": configJSON.dashboarContentType,
      // token: localStorage.getItem("auth"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPropertyListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllNewPropertyListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  searchChange = (value: any) => {
    this.setState({
      ...this.state,
      searchHome: value,
    });
  };

  purchasePriceSlider = (value: any) => {
    let data = Object.assign({}, this.state.buyerPurchasePrice);
    data.price = value;
    this.setState({
      ...this.state,
      buyerPurchasePrice: data,
    });
  };

  sellerPurchasePriceSlider = (value: any) => {
    let data = Object.assign({}, this.state.sellerPurchasePrice);
    data.price = value;
    this.setState({
      ...this.state,
      sellerPurchasePrice: data,
    }, () => this.sellerPurchaseCalcu(this.state.sellerPurchasePrice));
  };

  safespendEstimateSlider = (name: any, value: any) => {
    let data = Object.assign({}, this.state.safespendEstimate);
    // @ts-ignore
    data[name] = value;
    this.setState({
      ...this.state,
      safespendEstimate: data,
    });
  };

  doSearch = () => {
    // @ts-ignore
    this.props?.history?.push({
      pathname: "/properties",
      search: `?search=${this.state.searchHome}`,
    });
  };

  convertPrice = (value: any) => {
    let datavalue = value.toLocaleString(
      "en-IN", // leave undefined to use the visitor's browser
      // locale or a string like 'en-US' to override it.
      { minimumFractionDigits: 2 }
    );

    return datavalue;
  };

  scale = (value: any) => {
    const previousMarkIndex = Math.floor(value / 25);
    const previousMark = this.state.sellerSliderValue[previousMarkIndex];
    const remainder = value % 25;
    if (remainder === 0) {
      return previousMark?.scaledValue;
    }
    const nextMark = this.state.sellerSliderValue[previousMarkIndex + 1];
    const increment = (nextMark?.scaledValue - previousMark?.scaledValue) / 25;
    return remainder * increment + previousMark?.scaledValue;
  };

  //Subscription API
  doSubscribe = (values: any) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const attr = values

    const httpBody = {
      data: attr,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getEmailSubscribeCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEmailSubscriptionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("runEngine.debugLog", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          // New Property List API
          if (
            apiRequestCallId === this.getPropertyListId &&
            responseJson !== undefined
          ) {
            this.setState({
              ...this.state,
              recentPropertyList: responseJson.data,
            });
          }
          // Email Subsciption API
          if (
            apiRequestCallId === this.getEmailSubscribeCallId &&
            responseJson !== undefined
          ) {
            this.openSnackBarHandler("success", responseJson.message);
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        // console.log("responseJson", responseJson)
        const errors = responseJson && responseJson?.errors;
        if (errors?.[0].message) {
          this.openSnackBarHandler("error", errors?.[0].message);
        }
        if (errors?.[0].email) {
          this.openSnackBarHandler("error", errors?.[0].email);
        }
      }
    }
  }


  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };

  toggleClass = () => {
    this.setState({ toggleMenu: !this.state.toggleMenu })
    document.body.classList.toggle("menu-open");
    this.forceUpdate();
  };
  // Customizable Area Start

  // Customizable Area End
}
