// @ts-nocheck

import React from "react";
// Customizable Area Start
import { Modal } from "react-bootstrap";
export const configJSON = require("../../config");
import { successUploadImg } from "../../assets";
import { Link, withRouter } from "react-router-dom";

// Customizable Area End
import DashboardController, { Props } from "../DashboardSellerController.web";

class SuccessPublishedProperty extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.onHide}
        dialogClassName="success-property-modal"
        backdrop="static"
        aria-labelledby="property-success-property-modal"
        centered
      >
        <Modal.Body>
          <div className="success-div">
            <div className="success-img">
              <img src={successUploadImg} />
            </div>
            <div className="success-content">
              <h1>Successfully Uploaded</h1>
              <p>Property is successfully uploaded.</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            variant="primary"
            className="rounded-fill-btn"
            onClick={() => {
              this.props.onHide;
              // @ts-ignore
              this.props?.history?.push({
                pathname: "/dashboard-seller/",
              });
            }}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

// Customizable Area Start
export default withRouter(SuccessPublishedProperty);

// Customizable Area End
