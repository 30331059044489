import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import * as Yup from "yup";
import React from "react";

// Customizable Area Start
import { isEqual, isEmpty, property } from "lodash";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    propertyItemsList: any[];
    refreshProperty: () => void;
    isDeleteSuccess: boolean;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    loading: boolean;
    HeaderDropdownFlag: boolean;

    snackBar: {
        show: boolean;
        message: string;
        type: any
    };
    amanitiesList: any;
    showCheckList: boolean;
    propertyCategory: string;
    showPropItemDetail: boolean;
    propertyList: any[];
    selectedProperty: any;

    //Infinite scroll
    photos: any[];
    infiniteLoading: boolean;
    totalProperty: any;
    hasMore: boolean;
    page: any;
    prevY: any;
    accDetail: any;
    agreementSchema: any;
    agreementState: {
        reviewAgreement: boolean;
        billingDetail: boolean;
        paymentMethod: boolean;
    };
    reviewAgreementState: {
        agreement: boolean;
        ownerDetails: boolean;
        buyerDetails: boolean;
    };
    completeAgreementState: {
        review: boolean;
        billing: boolean;
        payment: boolean;
    };
    sucessAgreementShow: boolean;
    ownerDetails: any;
    buyerDetails: any;
    sortBy: {
        addressShow: boolean;
        statusShow: boolean;
        addressSortType: string;
        statusSortType: string;
    },
    deleteSuccess: boolean;
    underContractProperty: any

}
interface SS {
    id: any;
}



export default class HandOverController extends BlockComponent<Props, S, SS> {

    getAmanitiesListId: string = "";
    apiCheckListCallId: string = "";
    getAllPropertyListId: string = "";
    observer: any;
    lastPropertyItem: any;
    getOwnerDetailId: string;
    getBuyerDetailId: string;
    agreementSignCallId: string;
    accDetailId: string;
    agreementCallId: string;
    deletePropertyId: string;
    underContractPropertyId: string;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.observer = React.createRef();


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        let agreementSchema = {
            owner_sign: Yup.string().required(configJSON.errorOwnerSignNotValid),
            buyer_sign: Yup.string().required(configJSON.errorBuyerSignNotValid),
            agreementCheck: Yup.bool().oneOf([true], 'Please Accept Terms & Conditions'),
        };

        this.state = {
            loading: false,
            HeaderDropdownFlag: false,
            snackBar: {
                show: false,
                message: "",
                type: "",
            },
            amanitiesList: [],
            showCheckList: false,
            propertyCategory: "propertyList",
            showPropItemDetail: false,
            propertyList: [],
            selectedProperty: {},


            //Infinite scroll
            photos: [],
            infiniteLoading: false,
            totalProperty: 0,
            hasMore: true,
            page: 1,
            prevY: 1,
            accDetail: [],
            underContractProperty: [],
            agreementSchema: agreementSchema,
            agreementState: {
                reviewAgreement: true,
                billingDetail: false,
                paymentMethod: false,
            },
            reviewAgreementState: {
                agreement: true,
                ownerDetails: false,
                buyerDetails: false,
            },
            completeAgreementState: {
                review: false,
                billing: false,
                payment: false,
            },
            sucessAgreementShow: false,
            ownerDetails: {},
            buyerDetails: {},
            sortBy: {
                addressShow: false,
                statusShow: false,
                addressSortType: "",
                statusSortType: "",
            },
            deleteSuccess: false
        };


        this.lastPropertyItem = (node: any) => {
            if (this.observer.current) this.observer.current.disconnect();

            this.observer.current = new IntersectionObserver(entries => {
                if (this.state.infiniteLoading) return;

                if (entries[0].isIntersecting && this.state.hasMore) {
                    this.setState(prev => ({ page: prev.page + 1 }), () => {
                        if (localStorage.getItem("role") === "seller" && this.state.propertyCategory == "propertyList") {
                            this.getPropertyLists();

                        } else {
                            this.getContractProperty();
                        }
                    });

                }
            });
            if (node) this.observer.current.observe(node);
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {

        super.componentDidMount();
        // this.doAgreement();

    }

    async componentDidUpdate(prevProps: any, prevState: any, snapshot: any) { }


    doToggleHeaderDropdown = () => {
        this.setState({
            ...this.state,
            HeaderDropdownFlag: !this.state.HeaderDropdownFlag
        })
    }


    //Get Amanities
    getAmanitiesLists = (id: any) => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };



        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAmanitiesListId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAmentieswithPropertyIdAPIEndPoints + id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // CheckList API
    doCheckList = (values: any): boolean => {
        const formData = new FormData();
        !values.remove_appraisal_document && typeof values.appraisalDoc !== "string" && formData.append("data[appraisal_document]", values.appraisalDoc);
        !values.remove_inspection_document && typeof values.inspectionDoc !== "string" && formData.append("data[inspection_document]", values.inspectionDoc);
        !values.remove_title_report && typeof values.titleDoc !== "string" && formData.append("data[title_report]", values.titleDoc);
        values.remove_title_report && formData.append("data[remove_title_report]", values.remove_title_report);
        values.remove_inspection_document && formData.append("data[remove_inspection_document]", values.remove_inspection_document);
        values.remove_appraisal_document && formData.append("data[remove_appraisal_document]", values.remove_appraisal_document);
        values.propertyAmenities.map((item: any, index: any) => {
            return formData.append(`data[property_amenities_attributes][${index}][id]`, item.id);
        });
        values.propertyAmenities.map((item: any, index: any) => {
            return formData.append(`data[property_amenities_attributes][${index}][amenity_id]`, item.amenity_id);
        });
        values.propertyAmenities.map((item: any, index: any) => {
            return formData.append(`data[property_amenities_attributes][${index}][status]`, item.status);
        });
        values.propertyAmenities.map((item: any, index: any) => {
            return formData.append(`data[property_amenities_attributes][${index}][note]`, item.note);
        });


        const header = {
            // "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const attrs = formData;

        const httpBody = {
            data: attrs,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCheckListCallId = requestMessage.messageId;

        const endpoint =
            configJSON.getAllCheckListApiEndPoints +
            localStorage.getItem("property_id");

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //PripertyList API
    getPropertyLists = () => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: isEmpty(localStorage.getItem("auth")) ? "" : localStorage.getItem("auth"),

        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllPropertyListId = requestMessage.messageId;

        let paramsString: any = localStorage.getItem("sortParam");
        let address = "";
        let status = "";
        if (paramsString) {
            let params = JSON.parse(paramsString);
            address = `${params && params?.addressSortType && params?.addressSortType != "" ? "&address=" + params?.addressSortType : ""}`;
            status = `${params && params?.statusSortType && params?.statusSortType != "" ? "&status=" + params?.statusSortType : ""}`;
        }

        let endPoint = `${configJSON.getAllPropertiesApiEndPoints}?page=${this.state.page}&per_page=5${address}${status}`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.dashboarApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        this.setState({
            ...this.state,
            infiniteLoading: true
        })

        return true;
    };

    //Get Owner/Buyer Detail Details
    getUserDetail = (id: any, type: string) => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        let endpoint;
        if (type == "owner") {
            this.getOwnerDetailId = requestMessage.messageId;
            endpoint = configJSON.getOwnerDetailApiEndPoints;
        }
        else if (type == "buyer") {
            this.getBuyerDetailId = requestMessage.messageId;
            endpoint = configJSON.getBuyerDetailApiEndPoints;

        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint + id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // Create agreement
    doAgreement = (values: any): boolean => {
        console.log("Value Aggrement", values)
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            // token: localStorage.getItem("auth"),
        };

        const attrs = {
            agreement: {
                date_of_agreement: values.dateOfAgreement,
                seller_id: values.sellerId,
                seller_address: values.sellerAddress,
                buyer_id: values.buyerId,
                buyer_address: values.buyerAddress,
                property_address: values.propertyAddress,
                purchase_price: values.purchasePrice,
                earnest_money: values.earnestMoney,
                closing_date: values.closingDate,
                governing_law: values.govLaw
            }
        };

        // const attrs = {
        //     agreement: {
        //         date_of_agreement: "Tue, 12 Dec 2021",
        //         seller_id: 2,
        //         seller_address: "Indore",
        //         buyer_id: 1,
        //         buyer_address: "Indore",
        //         property_address: "Indore",
        //         purchase_price: 15.5,
        //         earnest_money: 5626,
        //         closing_date: "Tue, 28 Sep 2021",
        //         governing_law: ""
        //     }
        // }


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.agreementCallId = requestMessage.messageId;


        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.agreementApiEndPoint);

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(attrs)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // Signature agreement
    doAgreementSign = (values: any, id: any): boolean => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            // token: localStorage.getItem("auth"),
        };

        const attrs = {
            agreement_id: id,
            signature:
            {
                buyer_signature: values?.buyer_sign,
                seller_signature: values?.owner_sign
            }
        };


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.agreementSignCallId = requestMessage.messageId;


        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.agreementSignApiEndPoints
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(attrs)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Get Account Details
    getAccountDetail = () => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.accDetailId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAccountDetailApiEndPoints
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Delete Property
    deleteProperty = (id: any) => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deletePropertyId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.propertiesApiEndPoint + id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };


    // Get contract property
    getContractProperty = () => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.underContractPropertyId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getContractPropertyApiEndPoints
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };



    async receive(from: string, message: Message) {
        // Customizable Area Start
        // runEngine.debugLog("runEngine.debugLog", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId != null) {

                    //Amanities API Get
                    if (
                        apiRequestCallId === this.getAmanitiesListId &&
                        responseJson !== undefined
                    ) {
                        this.setState({
                            ...this.state,
                            amanitiesList: responseJson.data,
                        });
                        this.openSnackBarHandler("success", configJSON.successLogin);
                    }

                    //CheckList API 
                    if (
                        apiRequestCallId === this.apiCheckListCallId &&
                        responseJson !== undefined
                    ) {
                        this.openSnackBarHandler("success", configJSON.successLogin);

                    }
                    // Property List
                    if (
                        apiRequestCallId === this.getAllPropertyListId &&
                        responseJson !== undefined
                    ) {
                        this.setState(prev => ({ propertyList: [...prev.propertyList, ...responseJson?.data] }));
                        this.setState({ infiniteLoading: false })
                        this.setState({ hasMore: responseJson.data.length > 0 })
                        // this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    // Buyer Detail
                    if (
                        apiRequestCallId === this.getBuyerDetailId &&
                        responseJson !== undefined
                    ) {
                        this.setState({
                            ...this.state,
                            buyerDetails: responseJson?.data?.attributes,
                        });
                    }
                    // Agreement Create
                    if (
                        apiRequestCallId === this.agreementCallId &&
                        responseJson !== undefined
                    ) {
                        console.log("aggreemnt", responseJson)
                        //@ts-ignore
                        this.props.history.push({
                            pathname: "/agreement",
                            state: { aggrementData: responseJson.data }
                        })
                    }
                    // Agreement Signature
                    if (
                        apiRequestCallId === this.agreementSignCallId &&
                        responseJson !== undefined
                    ) {
                        console.log("Signed", responseJson)
                        this.getAccountDetail();
                    }
                    // Get User Account Detail 
                    if (
                        apiRequestCallId === this.accDetailId &&
                        responseJson !== undefined
                    ) {
                        this.setState({
                            ...this.state,
                            accDetail: responseJson?.data?.attributes,
                        });
                    }
                    //Delete Property
                    if (
                        apiRequestCallId === this.deletePropertyId &&
                        responseJson !== undefined
                    ) {
                        if (responseJson?.status != 500) {
                            this.openSnackBarHandler("success", responseJson?.message);
                            this.setState({ deleteSuccess: true }, () => { this.props.refreshProperty() });
                        }
                    }
                    // Get under contract property 
                    if (
                        apiRequestCallId === this.underContractPropertyId &&
                        responseJson !== undefined
                    ) {
                        this.setState({
                            ...this.state,
                            underContractProperty: responseJson?.data,
                        });
                    }
                }
            } else {
                this.parseApiCatchErrorResponse(errorReponse);
                const errors = responseJson?.errors;
                if (errors?.property !== undefined) {
                    this.openSnackBarHandler("error", errors.property);
                } else if (errors && errors[0]?.message) {
                    this.openSnackBarHandler("error", errors[0].message);
                } else if (errors && errors[0]?.properties) {
                    this.openSnackBarHandler("error", errors[0].properties);
                }
            }
        }
    }

    // Open View Details Modal
    openSnackBarHandler = (
        type: "success" | "info" | "warning" | "error" | undefined,
        message: string
    ): void => {
        this.setState({
            snackBar: {
                show: true,
                message: message,
                type,
            },
        });
    };

    // Close View Details Modal
    closeSnackBarHandler = () => {
        this.setState({
            snackBar: {
                show: false,
                message: this.state.snackBar.message,
                type: this.state.snackBar.type,
            },
        });
    };

    handleCheckList = (id: any) => {
        this.setState(prev => ({ showCheckList: !prev.showCheckList }));
        this.getAmanitiesLists(id);
    }

    handlePropertyCategory = (type: string) => {
        this.setState({ propertyCategory: type });
        localStorage.removeItem("sortParam");
    }

    handleShowPropItemDetail = () => {
        this.setState(prev => ({ showPropItemDetail: !prev.showPropItemDetail }))
    }

    setSelectedProperty = (property: any) => {
        this.setState({ selectedProperty: property });
    }

    handleSortChange = () => {
        this.setState({ page: 1 });
        this.setState({ propertyList: [] }, () => this.getPropertyLists());
    }
}
