import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import * as Yup from "yup";
import React, { createRef } from "react";

// Customizable Area Start
import { isEqual, isEmpty, property } from "lodash";
// Customizable Area End

export const configJSON = require("../../config.js");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    setStep: (step: number, data: any) => void
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    loading: boolean;
    step: number;
    stateList: any;
    cityList: any;
    homeTypeList: any;
    statusList: any;
    snackBar: {
        show: boolean;
        message?: string;
        type?: "success" | "info" | "warning" | "error" | undefined;
    };
    addressDetailSchema: any;
    basicDetailsSchema: any;
    uploadMediaSchema: any;
    pricingSchema: any;
    checkListSchema: any;
    publishSchema: any;
    propertyAllDetailData: any;
    termsAndConditionShow: boolean;
    propertyTermsServicesContent: any;
    roomDetailList: any;
    file: any;
    amanitiesList: any;
    propertiesDoc: {
        appraisal: boolean;
        inspection: boolean;
        title: boolean;
    };
    docFile: any;
    sucessPropertyShow: boolean;
    working_items: number;
    not_working_items: number;
    address_details: any;
    basic_details: any;
    upload_media: any;
    pricing: any;
    checklist: any;
    property_id: any;
    showFile: any;
    documentUploadAlertShow: boolean;

}
interface SS {
    id: any;
}



export default class AddPropertyFlowController extends BlockComponent<Props, S, SS> {

    urlReg: RegExp;
    phoneRegExp: RegExp;

    apiAddressDetailCallId: string = "";
    apiBasicDetailCallId: string = "";
    apiUploadMediaCallId: string = "";
    apiPricingCallId: string = "";
    apiCheckListCallId: string = "";
    getReviewId: string = "";

    getHomeTypeListId: string = "";
    getStateListId: string = "";
    getPropertyListId: string = "";
    getPropertyDetailId: string = "";
    getPropertyTermsOfServicesContentId: string = "";


    getAmanitiesListId: string = "";

    fileUpload: React.RefObject<HTMLInputElement>;
    files: any = [];

    publishPropertyId: string;
    deleteImageId: string;
    cityListId: any;
    getCityListId: string;
    formRef = createRef();
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start


        // this.handleWorkingCountIncrement=this.handleWorkingCountIncrement.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        let addressDetailSchema = {
            address: Yup.string().required(configJSON.errorAddressNotValid),
            city: Yup.string().required(configJSON.errorCityNotValid),
            state: Yup.string().required(configJSON.errorStateNotValid),
            zip: Yup.string().required(configJSON.errorZipNotValid),
            homeType: Yup.string().required(configJSON.errorHomeTypeNotValid),
            status: Yup.string().required(configJSON.errorStatusNotValid),
            latitude: Yup.string(),
            longitude: Yup.string(),
        };

        let basicDetailsSchema = {
            totalRoom: Yup.number()
                .required(configJSON.errortotalRoomNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            bedrooms: Yup.number()
                .required(configJSON.errorbedroomsNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            floorNo: Yup.number()
                .required(configJSON.errorfloorNoNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            unit: Yup.number()
                .required(configJSON.errorunitNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            stories: Yup.number()
                .required(configJSON.errorstoriesNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            fullBaths: Yup.number()
                .required(configJSON.errorfullBathsNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            baths: Yup.number()
                .required(configJSON.errorbathsNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            style: Yup.string()
                .required(configJSON.errorstyleNotValid),
            squareFeet: Yup.number()
                .required(configJSON.errorsquareFeetNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            basement: Yup.number()
                .required(configJSON.errorbasementNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            lotSize: Yup.number()
                .required(configJSON.errorlotSizeNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            garage: Yup.number()
                .required(configJSON.errorgarageNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            yearBuilt: Yup.number()
                .required(configJSON.erroryearBuiltNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive)
            ,
            remodelYear: Yup.number()
                .required(configJSON.errorremodelYearNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            describeYourPlace: Yup.string()
                .required(
                    configJSON.errordescribeYourPlaceNotValid
                ),
            loveAbout: Yup.string()
                .required(configJSON.errorloveAboutNotValid),
        };

        let uploadMediaSchema = {
            media: Yup.string(),
            videoUrlWalkthrough: Yup.string()
                .matches(this.urlReg, configJSON.errorEnterCorrectURL)
                .required(configJSON.errorVideoUrlWalkthroughNotValid),
            videoUrlVirtualTour: Yup.string()
                .matches(this.urlReg, configJSON.errorEnterCorrectURL)
                .required(configJSON.errorVideoUrlVirtualTourNotValid),
        };

        let pricingSchema = {
            price: Yup.number().
                required(configJSON.errorPriceNotValid)
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            currency: Yup.string().required(configJSON.errorCurrencyNotValid),
            minDownpay: Yup.number()
                .required(configJSON.errorMinDownpayNotValid)
                .when('price', (price: any) => Yup.number().max(price, configJSON.errorDownpayMustBeLess))
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            termPeriod: Yup.string().required(configJSON.errorTermNotValid),
            contactNumber: Yup.string()
                // .required()
                .matches(/^[0-9]+$/, configJSON.errorInteger)
                .min(4, configJSON.errorContactMinValue)
                .max(12, configJSON.errorContactMaxValue)
                // contactNumber: Yup.number()
                //   .integer(configJSON.errorInteger)
                //   .positive(configJSON.errorPositive)
                //   .test('len', 'Must be exactly 4 characters', val => val.length < 4)
                //   .test('len', 'Must be exactly 12 characters', val => val.length <= 12)
                // .min(4, configJSON.errorContactMinValue)
                // .max(12, configJSON.errorContactMaxValue)
                // .test(
                //   'is-decimal',
                //   configJSON.errorContactNumberNotValid,
                //   value => (value + "").match(this.phoneRegExp),
                // ),
                // .matches(this.phoneRegExp, configJSON.errorContactNumberNotValid)
                .required(
                    configJSON.errorContactNumberNotValid
                ),
            termsConditions: Yup.bool().oneOf([true], 'Please Accept Terms & Conditions'),
            taxes: Yup.number()
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            hoaFees: Yup.number()
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            homeOwnerInsurance: Yup.number()
                .integer(configJSON.errorInteger)
                .positive(configJSON.errorPositive),
            additional_details: Yup.array().of(
                Yup.object().shape({
                    name: Yup.string().required("Name required"),
                    value: Yup.number()
                        .integer(configJSON.errorInteger)
                        .positive(configJSON.errorPositive),
                })
            )
        };

        let checkListSchema = {
        };

        let publishSchema = {
            termsAndConditionAccepted: Yup.bool().oneOf([true], 'Please Accept Terms & Conditions'),

        };

        this.state = {
            snackBar: {
                show: false,
            },
            loading: false,
            step: 1,
            stateList: [],
            cityList: [],
            homeTypeList: [],
            statusList: [
                {
                    label: "Active",
                    value: "active",
                },
                {
                    label: "In-Active",
                    value: "inactive",
                },
            ],
            addressDetailSchema: addressDetailSchema,
            basicDetailsSchema: basicDetailsSchema,
            uploadMediaSchema: uploadMediaSchema,
            pricingSchema: pricingSchema,
            checkListSchema: checkListSchema,
            roomDetailList: [],
            file: [],
            amanitiesList: [],
            propertyAllDetailData: [],
            propertiesDoc: {
                appraisal: false,
                inspection: false,
                title: false,
            },
            docFile: "",
            termsAndConditionShow: false,
            documentUploadAlertShow: false,
            sucessPropertyShow: false,
            working_items: 0,
            not_working_items: 0,
            address_details: {},
            basic_details: {},
            upload_media: {},
            pricing: {},
            checklist: {},
            property_id: "",
            showFile: [],
            publishSchema: publishSchema,
            propertyTermsServicesContent: {},

        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() { }
    async componentDidUpdate(prevProps: any, prevState: any, snapshot: any) { }


    // Customizable Area Start

    //Stepper Steps
    doStepSet = (step: number, data?: any) => {
        this.setState({ step: step });
        if (data && !isEmpty(data)) {
            this.setState({ propertyAllDetailData: data });
        }
    };

    //Step 1: Address Detail
    doAddressDetails = (values: any, id: any): boolean => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const attrs = {
            latitude: 12.3456,
            longitude: 34.5678,
            address_line: values.address,
            city: values.city,
            state: values.state,
            zip_code: values.zip,
        };

        const data = {
            address_attributes: attrs,
            home_type_id: values.homeType,
            status: values.status,
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiAddressDetailCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.propertiesApiEndPoint}${id ? id : ""}`
            // `${configJSON.propertiesApiEndPoint}${localStorage.getItem("property_id") ? localStorage.getItem("property_id") : ""}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            id ? configJSON.putApiMethodType : configJSON.postApiMethodType
        )

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Step 2: Basic Details
    doBasicDetails = (values: any, id: any): boolean => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const attrs = {
            total_rooms: values.totalRoom,
            bedrooms: values.bedrooms,
            floor_number: values.floorNo,
            number_of_unit: values.unit,
            number_of_stories: values.stories,
            full_baths: values.fullBaths,
            half_baths: values.baths,
            style: values.style,
            finished_sq_ft: values.squareFeet,
            lot_size: values.lotSize,
            lot_unit: values.lotSqft,
            basement_sq_ft: values.basement,
            garage_space: values.garage,
            year_built: values.yearBuilt,
            structural_remodel_year: values.remodelYear,
            description: values.describeYourPlace,
            about: values.loveAbout,
            parking_space: values.parkingSpaces,
            details: values.details,
            property_open_houses_attributes: values.openHouse,
        };

        const data = attrs;

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiBasicDetailCallId = requestMessage.messageId;
        const endpoint =
            configJSON.propertiesApiEndPoint + id;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Step 3: Upload Media
    doUploadMedia = (values: any, id: any): boolean => {
        const formData = new FormData();
        formData.append("data[video_walkthrough_url]", values.videoUrlWalkthrough);
        formData.append("data[virtual_tour_url]", values.videoUrlVirtualTour);
        // formData.append("data[images]", Object.entries(values.media||[]));
        // let media: [] = values.media;

        values.media.map((img: any, index: any) => {
            return formData.append(`data[images][]`, img);
        });

        const header = {
            // "Content-Type": configJSON.fileContentType,
            token: localStorage.getItem("auth"),
        };

        // const attrs = {
        //   video_walkthrough_url: values.videoUrlWalkthrough,
        //   virtual_tour_url: values.videoUrlVirtualTour,
        //   images: values.media,
        // };
        const attrs = formData;

        const httpBody = {
            data: attrs,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiUploadMediaCallId = requestMessage.messageId;

        const endpoint =
            configJSON.propertiesApiEndPoint + id;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Step 4: Pricing API
    doPricing = (values: any, id: any): boolean => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const attrs = {
            price: values.price,
            currency: values.currency,
            min_down_payment: values.minDownpay,
            term_period: values.termPeriod,
            property_additional_details_attributes: values.additional_details,
            contact_number: values.contactNumber,
            taxes: values.taxes,
            hoa_fees: values.hoaFees,
            homeowner_insurance: values.homeOwnerInsurance,
            agreed_terms: values.termsConditions,
            step_number: values.stepNumber,
        };

        const data = attrs;

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiPricingCallId = requestMessage.messageId;

        const endpoint =
            configJSON.propertiesApiEndPoint + id;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Step 5: CheckList API
    doCheckList = (values: any, id: any): boolean => {
        const formData = new FormData();
        !values.remove_appraisal_document && typeof values.appraisalDoc !== "string" && formData.append("data[appraisal_document]", values.appraisalDoc);
        !values.remove_inspection_document && typeof values.inspectionDoc !== "string" && formData.append("data[inspection_document]", values.inspectionDoc);
        !values.remove_title_report && typeof values.titleDoc !== "string" && formData.append("data[title_report]", values.titleDoc);
        values.remove_title_report && formData.append("data[remove_title_report]", values.remove_title_report);
        values.remove_inspection_document && formData.append("data[remove_inspection_document]", values.remove_inspection_document);
        values.remove_appraisal_document && formData.append("data[remove_appraisal_document]", values.remove_appraisal_document);
        values.propertyAmenities.map((item: any, index: any) => {
            return formData.append(`data[property_amenities_attributes][${index}][id]`, item.id);
        });
        values.propertyAmenities.map((item: any, index: any) => {
            return formData.append(`data[property_amenities_attributes][${index}][amenity_id]`, item.amenity_id);
        });
        values.propertyAmenities.map((item: any, index: any) => {
            return formData.append(`data[property_amenities_attributes][${index}][status]`, item.status);
        });
        values.propertyAmenities.map((item: any, index: any) => {
            return formData.append(`data[property_amenities_attributes][${index}][note]`, item.note);
        });


        const header = {
            // "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const attrs = formData;

        const httpBody = {
            data: attrs,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiCheckListCallId = requestMessage.messageId;

        const endpoint =
            configJSON.propertiesApiEndPoint + id;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Step 6: Review Data
    getPropertyDetails = (id: string | number) => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getPropertyDetailId = requestMessage.messageId;
        // let endpoint = `${configJSON.propertiesApiEndPoint}${localStorage.getItem("property_id") ? localStorage.getItem("property_id") : ""}`

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.propertiesApiEndPoint + id
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Step 7: Privacy Policy
    getPropertyTermsOfServicesContent = () => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getPropertyTermsOfServicesContentId = requestMessage.messageId;
        // let endpoint = `${configJSON.getAllPropertyTermsOfServicesContentApiEndPoints}}`
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllPropertyTermsOfServicesContentApiEndPoints
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Step 8 : Publish API
    doPublish = (values: any, id: any) => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const attr = {
            terms_and_condition_accepted: values.termsAndConditionAccepted,
            step_number: values.stepNumber,
        }
        const httpBody = {
            data: attr,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.publishPropertyId = requestMessage.messageId;
        const endpoint =
            configJSON.publishPropertyApiEndPoints + id + "/publish";
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Home Type
    getHomeType = () => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getHomeTypeListId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllHomeTypeListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //State List
    getStateList = () => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getStateListId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getStateApiEndPoints
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //City List
    getCityList = (state: any) => {
        console.log("state", state)
        // const headers = {
        //     "Content-Type": configJSON.dashboarContentType,
        //     token: localStorage.getItem("auth"),
        // };
        // const httpBody = {
        //     state_code: state,
        //     country_code: "US"
        // }
        // const requestMessage = new Message(
        //     getName(MessageEnum.RestAPIRequestMessage)
        // );

        // this.getCityListId = requestMessage.messageId;

        // requestMessage.addData(
        //     getName(MessageEnum.RestAPIResponceEndPointMessage),
        //     configJSON.getCityApiEndPoints
        // );

        // requestMessage.addData(
        //     getName(MessageEnum.RestAPIRequestHeaderMessage),
        //     JSON.stringify(headers)
        // );
        // requestMessage.addData(
        //     getName(MessageEnum.RestAPIRequestBodyMessage),
        //     JSON.stringify(httpBody)
        // );
        // requestMessage.addData(
        //     getName(MessageEnum.RestAPIRequestMethodMessage),
        //     configJSON.getApiMethodType
        // );

        // runEngine.sendMessage(requestMessage.id, requestMessage);

        // return true;
    };

    //Property Requirement List    
    getPropertyLists = () => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getPropertyListId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllPropertyListApiEndPoints
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Get Amanities
    getAmanitiesLists = () => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAmanitiesListId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllAmanitiesListApiEndPoints
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Delete Image
    doDeleteImage = (propertyId: number, imgId: number) => {
        const headers = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteImageId = requestMessage.messageId;
        const endpoint =
            configJSON.propertiesApiEndPoint + propertyId + "/property_images/" + imgId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };


    async receive(from: string, message: Message) {
        // Customizable Area Start
        // runEngine.debugLog("runEngine.debugLog", message);
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId != null) {
                    // Home Type List API
                    if (
                        apiRequestCallId === this.getHomeTypeListId &&
                        responseJson !== undefined
                    ) {
                        this.openSnackBarHandler("success", configJSON.successLogin);
                        this.setState({
                            ...this.state,
                            homeTypeList: responseJson.data,
                        });
                    }
                    // State List
                    if (
                        apiRequestCallId === this.getStateListId &&
                        responseJson !== undefined
                    ) {
                        this.setState({
                            ...this.state,
                            stateList: responseJson.states,
                        });
                    }
                    // City List
                    if (
                        apiRequestCallId === this.getCityListId &&
                        responseJson !== undefined
                    ) {
                        console.log("CityResp", responseJson)
                        this.setState({ cityList: [...responseJson?.city] });
                    }
                    // Address API
                    if (
                        apiRequestCallId === this.apiAddressDetailCallId &&
                        responseJson !== undefined
                    ) {
                        this.props.setStep(2, responseJson?.data);
                        this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    // Property List
                    if (
                        apiRequestCallId === this.getPropertyListId &&
                        responseJson !== undefined
                    ) {
                        this.setState({
                            ...this.state,
                            roomDetailList: responseJson,
                        });
                        this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    // Basic API
                    if (
                        apiRequestCallId === this.apiBasicDetailCallId &&
                        responseJson !== undefined
                    ) {
                        this.props.setStep(3, responseJson?.data);
                        this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    //Upload Media
                    if (
                        apiRequestCallId === this.apiUploadMediaCallId &&
                        responseJson !== undefined
                    ) {
                        this.props.setStep(4, responseJson?.data);
                        this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    //Amanities API Get
                    if (
                        apiRequestCallId === this.getAmanitiesListId &&
                        responseJson !== undefined
                    ) {
                        this.setState({
                            ...this.state,
                            amanitiesList: responseJson.data,
                        });
                        this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    //Pricing API
                    if (
                        apiRequestCallId === this.apiPricingCallId &&
                        responseJson !== undefined
                    ) {
                        this.props.setStep(5, responseJson?.data);
                        this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    //CheckList API 
                    if (
                        apiRequestCallId === this.apiCheckListCallId &&
                        responseJson !== undefined
                    ) {
                        this.props.setStep(6, responseJson?.data);
                        this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    //Property API Get
                    if (
                        apiRequestCallId === this.getPropertyDetailId &&
                        responseJson !== undefined
                    ) {
                        this.setState({ propertyAllDetailData: responseJson.data });
                        this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    //Property Terms of Services API Get
                    if (
                        apiRequestCallId === this.getPropertyTermsOfServicesContentId &&
                        responseJson !== undefined
                    ) {
                        this.setState({
                            ...this.state,
                            propertyTermsServicesContent: responseJson.data.attributes,

                        });
                    }
                    //Publish property
                    if (
                        apiRequestCallId === this.publishPropertyId &&
                        responseJson !== undefined
                    ) {
                        this.handleShowSuccessModal();
                        this.handleHideTermsAndCondition();
                        sessionStorage.removeItem("propertyId");
                        sessionStorage.removeItem("type");
                        this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    //Delete Image
                    if (
                        apiRequestCallId === this.deleteImageId &&
                        responseJson !== undefined
                    ) {
                        this.openSnackBarHandler("success", responseJson.message);
                    }
                }
            } else {
                this.handleHideSuccessModal();
                // console.log("ERRROR",responseJson)
                this.parseApiCatchErrorResponse(errorReponse);
                const errors = responseJson?.errors;
                if (errors?.property !== undefined) {
                    this.openSnackBarHandler("error", errors?.property);
                } else if (errors && errors[0]?.message) {
                    this.openSnackBarHandler("error", errors[0]?.message);
                }
            }
        }
    }


    // Open View Details Modal
    openSnackBarHandler = (
        type: "success" | "info" | "warning" | "error" | undefined,
        message: string
    ): void => {
        this.setState({
            snackBar: {
                show: true,
                message: message,
                type,
            },
        });
    };

    // Close View Details Modal
    closeSnackBarHandler = () => {
        this.setState({
            snackBar: {
                show: false,
                message: this.state.snackBar.message,
                type: this.state.snackBar.type,
            },
        });
    };

    handleShowTermsAndCondition = () => {
        this.setState({ termsAndConditionShow: true });
    };

    handleHideTermsAndCondition = () => {
        this.setState({ termsAndConditionShow: false });
    };
    handleShowDocumentUploadAlert = () => {
        this.setState({ documentUploadAlertShow: true });
    };
    handleHideDocumentUploadAlertShow = () => {
        this.setState({ documentUploadAlertShow: false });
    };

    handleShowSuccessModal = () => {
        this.setState({ sucessPropertyShow: true });
    };

    handleHideSuccessModal = () => {
        this.setState({ sucessPropertyShow: false });
    };

    //Basic Details
    doDetail = (detail: any) => {
        let newData = [];
        if (!isEmpty(detail)) {
            if (!isEmpty(detail.basement)) {
                newData.push({ type: "Basement", id: detail.basement.id });
            }
            if (!isEmpty(detail.floors)) {
                let floor = Object.assign([], detail.floors);
                newData.push(...floor.map((r: any) => ({ type: "Floor", id: +r.id })));
            }
            // -------------------------
            if (!isEmpty(detail.cooling_types)) {
                let ct = Object.assign([], detail.cooling_types);
                newData.push(...ct.map((r: any) => ({ type: "CoolingType", id: +r.id })));
            }
            if (!isEmpty(detail.heating_fuels)) {
                let hf = Object.assign([], detail.heating_fuels);
                newData.push(...hf.map((r: any) => ({ type: "HeatingFuel", id: +r.id })));
            }
            if (!isEmpty(detail.heating_types)) {
                let ht = Object.assign([], detail.heating_types);
                newData.push(...ht.map((r: any) => ({ type: "HeatingType", id: +r.id })));
            }
            if (!isEmpty(detail.indoor_features)) {
                let inf = Object.assign([], detail.indoor_features);
                newData.push(...inf.map((r: any) => ({ type: "IndoorFeature", id: +r.id })));
            }
            if (!isEmpty(detail.outdoor_amenities)) {
                let oua = Object.assign([], detail.outdoor_amenities);
                newData.push(...oua.map((r: any) => ({ type: "OutdoorAmenity", id: +r.id })));
            }
            if (!isEmpty(detail.outside_views)) {
                let ouv = Object.assign([], detail.outside_views);
                newData.push(...ouv.map((r: any) => ({ type: "OutsideView", id: +r.id })));
            }
            if (!isEmpty(detail.appliances)) {
                let apl = Object.assign([], detail.appliances);
                newData.push(...apl.map((r: any) => ({ type: "Appliance", id: +r.id })));
            }
            if (!isEmpty(detail.parkings)) {
                let park = Object.assign([], detail.parkings);
                newData.push(...park.map((r: any) => ({ type: "Parking", id: +r.id })));
            }
            if (!isEmpty(detail.roofs)) {
                let roof = Object.assign([], detail.roofs);
                newData.push(...roof.map((r: any) => ({ type: "Roof", id: +r.id })));
            }
            if (!isEmpty(detail.rooms)) {
                let room = Object.assign([], detail.rooms);
                newData.push(...room.map((r: any) => ({ type: "Room", id: +r.id })));
            }
        }
        return newData;
    };

    // Upload Images
    handleImageChange = (e: any, setFieldValue: any) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files).map(
                (file: any, index: number) => {
                    return file;
                }
            );
            this.setState({ file: this.state.file.concat(filesArray) }, () => {
                setFieldValue("media", this.state.file);
            });
        }
    };

    //Delete Images from recent upload
    deleteFile = (e: any, setFieldValue: any) => {
        const s = this.state.file.filter((item: any, index: any) => index !== e);
        this.setState({ file: s }, () => setFieldValue("media", this.state.file));
    };

    handleNewDataReview = (data: any) => {
        this.props.setStep(6, data);
    }

    //Submit check
    handleCurrentFormIsValid = (step: number, currentStep: number) => {
        const form: any = this.formRef.current;
        const nextStep = currentStep - step;
        if (nextStep > 0) { this.doStepSet(step) }
        else {
            if (nextStep == -1) if (form?.isValid) {
                form.submitForm();
            }
        }
    }
    // Customizable Area End
}
