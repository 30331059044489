// @ts-nocheck

import React from "react";
// Customizable Area Start

import SidebarBuyer from "../../../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../../../components/src/Sidebar";
import {
    sliderProperty1,
    sliderProperty2,
    sortIconWhite,
    checked
} from "../../assets";
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import HandOverController, { Props } from "../HandOverController.web";
import Header from "../../Seller/AddPropertyStepper/Header.web";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import PropertyUnderContractItem from "./PropertyUnderContractItem.web";

class OldPropertyList extends HandOverController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        return (
            <>
                <div className="flex">
                    <div>
                        {/* Sidebar  */}
                        {localStorage.getItem("role") === "buyer" ? (
                            <SidebarBuyer menuCollapsed={true} />
                        ) : (
                            <SidebarSeller menuCollapsed={true} />
                        )}
                    </div>
                    <div className="stepper-outer">
                        <Header
                            flag={this.state.HeaderDropdownFlag}
                            toggle={this.doToggleHeaderDropdown}
                        />
                        <div className="seller-property-wrapper">
                            <div className="btn-wrapper">
                                <button className="btn orange-fill-btn">Properties</button>
                                {/* <button className="btn gray-border-btn">
                                    Rented Properties
                                </button> */}
                            </div>
                            <div className="content-wrapper">
                                <div className="list-outer">
                                    <PropertyUnderContractItem />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

// Customizable Area Start
export default withRouter(OldPropertyList);

// Customizable Area End
