Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LiveChat";
exports.labelBodyText = "LiveChat Body";

exports.btnExampleTitle = "CLICK ME";
exports.getAllConversationListApiEndPoint = "bx_block_message/conversations";
exports.getMessagesApiEndPoint = "bx_block_message/messages?conversation_id=";
exports.sendMessagesApiEndPoint = "bx_block_message/messages";
exports.deleteConversationApiEndPoint = "bx_block_message/conversations/";
exports.AcceptRejectSchedulerApiEndPoint = "bx_block_postcreation/properties";
exports.dashboarContentType = "application/json";
exports.DeleteAPiMethod = "DELETE";
exports.PutAPiMethod = "PUT";
exports.setBuyerTourApiEndPoints = "bx_block_postcreation/properties/";
exports.postApiMethodType = "POST";

exports.counterOfferApiEndPoint = "bx_block_postcreation/counter_offers";
exports.errorTermNotValid = "Please Select Term";
exports.errorDownPayNotValid = "Please Enter Down Payment Amount";
exports.multipartContentType = "multipart/form-data";

// Customizable Area End
