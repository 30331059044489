import React from "react";

//Customizable Area Start
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";

import * as Yup from "yup";
import ForgotPasswordController, {
  Props,
} from "./ForgotPasswordController.web";
import { Modal, Button } from "react-bootstrap";
import { forgetImg } from "./assets";
export const configJSON = require("./config");

//Customizable Area End

export default class ForgotPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    return (
      <>
        <Modal
          show={true}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="modal-content">
              <div className="image-block">
                <img src={forgetImg} alt="forgetImg" className="" />
              </div>
              <div className="content-block">
                <h3 className="content-head">Forget Password?</h3>
                <p className="discription-tex">
                  Please enter your registered email address.
                </p>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                    rememberMe: false,
                  }}
                  validationSchema={Yup.object().shape(this.state.emailSchema)}
                  onSubmit={(values) => console.log(values)}
                  enableReinitialize
                >
                  {({ values, setFieldValue, errors }) => (
                    //@ts-ignore
                    <Form>
                      <div className="fg-form-wrapper">
                        <div className="fg-form-div">
                          <Field
                            type="text"
                            name="email"
                            placeholder={configJSON.placeHolderEmail}
                            className="fg-form-input"
                          />
                          <i className="las la-envelope line-icons fg-form-icon" />
                        </div>
                        <span className="error">
                          <ErrorMessage name="email" />
                        </span>
                      </div>
                      <div className="flex fg-footer-btn">
                        <button type="button" className="fg-btn-cancel">
                          <Link to="/signin">Cancel</Link>
                        </button>
                        <button type="submit" className="fg-btn-next">
                          Next
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
