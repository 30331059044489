export const galleryImg = require("../assets/image-gallery@3x.png");
export const fileImg = require("../assets/file@3x.png");
export const deleteImg = require("../assets/image-delete@3x.png");
export const errorImg = require("../assets/image-alert@3x.png");
export const fixImg = require("../assets/image-fix@3x.png");
export const workingImg = require("../assets/image-working@3x.png");
export const arrowDown = require("../../../web/images/arrow-down.svg");
export const successUploadImg = require("../assets/image-home@3x.png");
export const clockImg = require("../assets/wall-clock@3x.png");
export const videoImg = require("../assets/icon-feather-video@3x.png");
export const refreshImg = require("../assets/image-refresh@3x.png");
export const replyImg = require("../assets/image-reply@3x.png");
export const roomImg = require("../assets/room.jpg");
export const downloadImg = require("../assets/image-download@3x.png");
export const arrowButtonImg = require("../assets/image-arrowbutton@3x.png");
export const profieImg = require("../assets/profile.jpeg");

export const imageDollar = require("../../../web/images/image-dollar.png");
export const imageDollarSeller = require("../../../web/images/image-dollar-seller.png");
export const homePercentage = require("../../../web/images/home-percentage-img.png");
export const buyerLogo = require("../../../web/images/logo-green-text.png");
export const logoWhite = require("../../../web/images/logo-white.png");
export const imageView = require("../../../web/images/image-views.png");
export const imageLikeRed = require("../../../web/images/image-like-red.png");
export const imageLike = require("../../../web/images/image-like.png");
export const siginImage = require("../../../web/images/siginImage.png");
export const buyerSkew = require("../../../web/images/buyer-skew.png");
export const footerLogo = require("../../../web/images/footer-logo.png");
export const filterLogo = require("../../../web/images/filter.svg");
export const sortIcon = require("../../../web/images/sort-icon.svg");
export const tabClose = require("../../../web/images/tab-close.svg");
export const iconSearch = require("../../../web/images/iconSearch.svg");
export const arrowBottom = require("../../../web/images/arrow-bottom.svg");
export const viewMap = require("../../../web/images/view-map.png");
export const imageHeart = require("../../../web/images/image-heart.png");
export const imageArrow = require("../../../web/images/image-arrow.png");
export const sliderProperty1 = require("../../../web/images/slider-property1.png");
export const sliderProperty2 = require("../../../web/images/slider-property2.png");
export const mainPropertySlider = require("../../../web/images/main-property-slider.png");
export const instaImage = require("../../../web/images/insta_image.png");
export const fbImage = require("../../../web/images/fb_image.png");
export const twitterImage = require("../../../web/images/twitter_image.png");
export const imageLikeActive = require("../../../web/images/image-like-active.png");
export const paymentWithSparen = require("../../../web/images/payment-with-sparen.png");
export const imageCash = require("../../../web/images/image-cash.png");
export const imageHomecare = require("../../../web/images/image-homecare.png");
export const insurance = require("../../../web/images/image-insurance.png");
export const homecare = require("../../../web/images/image-homecare.png");
export const sliderThumbnil = require("../../../web/images/image-property-thumbnil.png");
export const areaSquare = require("../../../web/images/area-square-Ft.svg");
export const squareFt = require("../../../web/images/square-ft.svg");
export const bathroom = require("../../../web/images/bathroom.svg");
export const bedroom = require("../../../web/images/bedroom.svg");
export const forSale = require("../../../web/images/for-sale.svg");
export const soldProperty = require("../../../web/images/sold.svg");
export const forSaleWhite = require("../../../web/images/for-sale-white.svg");
export const soldPropertyWhite = require("../../../web/images/sold-white.svg");
export const imageLocation = require("../../../web/images/image-location.png");
export const imagePrice = require("../../../web/images/image-price.png");
export const imageCalender = require("../../../web/images/image-calender.png");
export const imageDetail = require("../../../web/images/image-contactdetails.png");
export const imageRoundedArrow = require("../../../web/images/image-arrow-orange.png");
export const iconAddCircle = require("../../../web/images/icon-add-circle.svg");
export const iconRemoveCircle = require("../../../web/images/icon-remove-circle.svg");
export const profileImage = require("../../../web/images/view-buyerimage.png");
export const locationBlue = require("../../../web/images/image-location-blue.png");
export const imagePhone = require("../../../web/images/image-phone.png");
export const imageTelephone = require("../../../web/images/image-telephone.png");
export const imageEdit = require("../../../web/images/image-edit.png");
export const imageDelete = require("../../../web/images/image-delete.png");
export const imageUser = require("../../../web/images/image-user.png");
export const imageUpload = require("../../../web/images/image-upload.png");
export const imageSetting = require("../../../web/images/image-setting.png");
export const imageLikeFill = require("../../../web/images/image-like-fill.png");
export const imageLogout = require("../../../web/images/image-logout.png");
export const imageDashboard = require("../../../web/images/image-dashboard.png");
export const sortIconWhite = require("../../../web/images/sort-icon-white.svg");
export const creditCard = require("../../../web/images/credit-card.svg");
export const imageDownload = require("../../../web/images/image-download.png");
export const imageArrowBg = require("../../../web/images/image-arrow-bg.png");
export const imageAttachment = require("../../../web/images/image-attachment.png");
export const signature = require("../../../web/images/signature.svg");
export const noImgAvailable = require("../../../web/images/no-img-available.png");
export const checked = require("../../../web/images/checked.svg")
export const imgClose = require("../../../web/images/image-close.png");
export const infoImg = require("../../../web/images/image-info.png");
export const homeRepair = require("../../../web/images/image-home-repair.png")
export const pricetagImg = require("../../../web/images/image-pricetag.png");
export const screwdriveImg = require("../../../web/images/image-screwdrive.png");
export const propertyOnHoldImg = require("../../../web/images/image-home-search.png");



