// @ts-nocheck
import React from "react";
// Customizable Area Start
import GoogleMapReact from "google-map-react";
import FilterPropertyFlowController, {
    Props,
} from "./FilterPropertyFlowController.web";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
// Customizable Area End
export interface Props { }
interface S { }
class MapForFilter extends FilterPropertyFlowController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: "AIzaSyDWk8PILwgIKmq7bxxGEsOdEZx8NRQJR7E",
                }}
                defaultCenter={{
                    lat: 59.95,
                    lng: 30.33,
                }}
                defaultZoom={11}
            >
                <AnyReactComponent
                    lat={59.955413}
                    lng={30.337844}
                    text="My Marker"
                />
            </GoogleMapReact>
        );
    }
}

export default MapForFilter;
