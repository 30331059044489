// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "../DashboardBuyerController.web";
import {
  buyerLogo,
  siginImage,
  iconAddCircle,
  iconRemoveCircle,
} from "../../assets";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";


export interface Props { }
interface S {}
class MoveInDateBuyer extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
    this.moveIndate = new Date(this.props.fieldData?.moveInDate);
    this.date = this.moveIndate.getDate() + "/" +
      (this.moveIndate.getMonth() + 1) + "/" +
      this.moveIndate.getFullYear()
  }
  render() {

    return (
     <Formik
                  initialValues={{
                   
                    moveInDate: this.props.fieldData?.moveInDate ||  this.handleDateFormat(new Date()),
                    showMoveInDate: this.props.fieldData?.showMoveInDate 
                    ? new Date(this.props.fieldData?.showMoveInDate) 
                    : new Date(),
                   
                  }}
                  validationSchema={Yup.object().shape(
                    this.state.buyerSchemaArray[this.props.currentStep]
                  )}
                  onSubmit={(values) => {
                    this.props.paymentValues(values);
                  }}
                  enableReinitialize
                >
                  {({ values, setFieldValue, errors}) => {
                    
                    return(
                    <Form>
                         {/* Step4 */}
                       
                            <div className="step-4">
                              <div className="step-datil">
                                <p className="question-block">
                                  When would you like to move in?
                                </p>
                                <div className="content-wrapper">
                                  <div className="calender-wrapper">
                                    <div className="date-picker calenderStyle">
                                      <DatePicker
                                        minDate={new Date()}
                                        inline
                                        shouldCloseOnSelect={false}
                                        selected={
                                          values.showMoveInDate
                                            ? values.showMoveInDate
                                            : new Date()
                                        }
                                        onChange={(dt) => {
                                          let date =
                                            dt.getDate() +
                                            "/" +
                                            (dt.getMonth() + 1) +
                                            "/" +
                                            dt.getFullYear();
                                          setFieldValue("moveInDate", date);
                                          setFieldValue("showMoveInDate", dt);
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="step-footer">
                                <button
                                  type="button"
                                  className="btn gray-fill-btn"
                                  onClick={this.props.previousStep}
                                >
                                  Back
                                </button>
                                <button
                                  variant="primary"
                                  className="rounded-fill-btn"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                         
                          {/* {JSON.stringify(errors, null, 2)}
                          {JSON.stringify(values, null, 2)} */}

                    </Form>
                  )}}
                </Formik>
             
            
    );
  }
}

export default withRouter(MoveInDateBuyer);
