// @ts-nocheck

import React from "react";
// Customizable Area Start
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import DashboardController, { Props } from "../DashboardSellerController.web";
import { Formik, Form, Field } from "formik";
import Header from "./Header.web";

import SidebarBuyer from "../../../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../../../components/src/Sidebar";
import {
  creditCard,
  imageDownload
} from "../../assets";
import "react-datepicker/dist/react-datepicker.css";
class Financials extends DashboardController {
  // detailFlag: boolean = false;
  // paymentFlag: boolean = true;
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="flex">
        <div>
          {localStorage.getItem("role") === "buyer" ? (
            <SidebarBuyer />
          ) : (
            <SidebarSeller />
          )}
        </div>
        <div className="stepper-outer contact-detail-page">
          <Header
            flag={this.state.HeaderDropdownFlag}
            toggle={this.doToggleHeaderDropdown}
          />
          <div className="btn-wrapper">
            <button
              className={
                "btn " +
                (this.state.upcomingPayment
                  ? "orange-fill-btn"
                  : "gray-border-btn")
              }
              onClick={this.paymentToggle}
            >
              Account Details
            </button>
            <button
              type="button"
              className={
                "btn " +
                (this.state.PaymentHistory
                  ? "orange-fill-btn"
                  : "gray-border-btn")
              }
              onClick={this.paymentToggle}
            >
              Payments Received/Sent
            </button>
          </div>
          {this.state.upcomingPayment && (
            <div className="financial-wrapper">
              <div className="record">
                <div className="block-wrapper">
                  <p className="title">Payment Amount</p>
                  <p className="value green-text">$2,000</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">Due Date</p>
                  <p className="value">1 March, 2021</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">For</p>
                  <p className="value">May Month Rent</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">To</p>
                  <p className="value">Owner Name</p>
                </div>
                <div className="block-wrapper btn-wrapper">
                  <button type="button" className="gray-border-btn">
                    View Details
                  </button>
                  <button className="rounded-fill-btn">
                    <img src={creditCard} alt="" /> <span>Pay Now</span>
                  </button>
                </div>
              </div>
            </div>
          )}
          {this.state.PaymentHistory && (
            <div className="financial-wrapper">
              <div className="record">
                <div className="block-wrapper">
                  <p className="title">Payment Amount</p>
                  <p className="value green-text">$2,000</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">Due Date</p>
                  <p className="value">1 March, 2021</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">For</p>
                  <p className="value">May Month Rent</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">To</p>
                  <p className="value">Owner Name</p>
                </div>
                <div className="block-wrapper btn-wrapper">
                  <button type="button" className="gray-border-btn">
                    View Details
                  </button>
                  <button className="download-btn">
                    <img src={imageDownload} alt="" /> <span></span>
                  </button>
                </div>
              </div>
              <div className="record">
                <div className="block-wrapper">
                  <p className="title">Payment Amount</p>
                  <p className="value green-text">$2,000</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">Due Date</p>
                  <p className="value">1 March, 2021</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">For</p>
                  <p className="value">May Month Rent</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">To</p>
                  <p className="value">Owner Name</p>
                </div>
                <div className="block-wrapper btn-wrapper">
                  <button type="button" className="gray-border-btn">
                    View Details
                  </button>
                  <button className="download-btn">
                    <img src={imageDownload} alt="" /> <span></span>
                  </button>
                </div>
              </div>
              <div className="record">
                <div className="block-wrapper">
                  <p className="title">Payment Amount</p>
                  <p className="value green-text">$2,000</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">Due Date</p>
                  <p className="value">1 March, 2021</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">For</p>
                  <p className="value">May Month Rent</p>
                </div>
                <div className="block-wrapper">
                  <p className="title">To</p>
                  <p className="value">Owner Name</p>
                </div>
                <div className="block-wrapper btn-wrapper">
                  <button type="button" className="gray-border-btn">
                    View Details
                  </button>
                  <button className="download-btn">
                    <img src={imageDownload} alt="" /> <span></span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

// Customizable Area Start
export default withRouter(Financials);

// Customizable Area End
