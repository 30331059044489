import PropTypes from "prop-types";
import React, { Component } from "react";

// @ts-ignore
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {Button} from "react-bootstrap";
import { facebookImage } from "./assets";
import { configJSON } from "./SocialMediaAccountWebController";

type Props = {
  testID: string;
  appId: string;
  loginFacebookButtonText: string;
  callback: (response: any) => void;
  onPress: () => void;
  // Customizable Area Start
  // Customizable Area End
};

export default class CustomFacebookLogInButton extends Component<Props> {
  static propTypes = {
    testID: PropTypes.string,
    appId: PropTypes.string,
    loginFacebookButtonText: PropTypes.string,
    callback: PropTypes.func.isRequired,
    onPress: PropTypes.func.isRequired
    // Customizable Area Start
    // Customizable Area End
  };

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <FacebookLogin
        // Customizable Area Start
        fields="name,email,picture"
        scope="email,public_profile"
        // Customizable Area End
        appId={this.props.appId}
        callback={this.props.callback}
        render={(renderProps: any) => (
 <Button className="social-media-icon facebook-icon"  onClick={() => {
              renderProps.onClick();
              this.props.onPress();
            }}
>
{this.props.loginFacebookButtonText}

                </Button>
        )}
      />
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
