//@ts-nocheck
import React from "react";
// Customizable Area Start
import { Modal } from "react-bootstrap";
import PopupsController, { Props } from "./PopupsController.web";
import { propertyOnHoldImg, imgClose } from "../assets";
import { Link } from "react-router-dom";

// Customizable Area End
class DocumentUploadAlert extends PopupsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        console.log("WOrked")
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
            <Modal
                show={this.props.documentUploadAlertShow}
                onHide={this.props.handleDocumentUploadAlertShow}
                dialogClassName="onhold-property-modal"
                backdrop="static"
                aria-labelledby="property-onhold-modal"
                centered
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="onhold-div">
                        <div className="onhold-img">
                            <img src={propertyOnHoldImg} />
                        </div>
                        <div className="onhold-content">
                            <h1>Sorry! Property In On Hold</h1>
                            <p>Please upload the required properties documents in order to successfully publish the property</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Link to="/property-list">
                        <button
                            variant="primary"
                            className="rounded-fill-btn"
                            onClick={this.props.handlesucessAgreementShow}
                        >
                            Go To Property List
                        </button>
                    </Link>
                </Modal.Footer>
            </Modal>
        );
    }
}

// Customizable Area Start
export default DocumentUploadAlert;

// Customizable Area End
