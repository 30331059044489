// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "./DashboardBuyerController.web";
import {
  imageLikeActive,
  imageLike,
  imageLikeRed,
  imageView,
  siginImage,
  buyerSkew,
  buyerLogo,
  imageDashboard,
  imageSetting,
  imageLikeFill,
  imageLogout,
  profieImg,
  noImgAvailable
} from ".././assets";
import { isEmpty } from "lodash";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export interface Props {}
interface S {
  FilterFlag: any;
}
class FavoriteHomes extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <header className="site-header fixed-header">
          <div className="container-main">
            <div className="left-header">
              <Link to={
localStorage.getItem("role") === "buyer"
? "/dashboard-buyer"
: "/dashboard-seller"
}
 className="page-logo" title="Sparen Home">
                <img src={buyerLogo} alt="Logo" />
              </Link>
              <div className="link-wrapper">
                <ul>
                  <li>
                    <Link to="/home-page-buyer" title="Buy" className="active">
                      Buy
                    </Link>
                  </li>
                  <li>
                    <Link to="/home-page-seller" title="Sell">
                      Sell
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} title="How It Works">
                      How It Works
                    </Link>
                  </li>
                  <button
                    className="rounded-fill-btn blue-fill sm-visible"
                    onClick={() => this.props?.history?.push("signin")}
                  >
                    Log In
                  </button>
                  <button
                    className="rounded-fill-btn blue-fill sm-visible"
                    onClick={() => this.props?.history?.push("signup")}
                  >
                    Sign Up
                  </button>
                </ul>
              </div>
            </div>
            <div className="right-header">
              <div className="profile-wrapper"  onClick={this.doToggleHeaderDropdown}>
                <div className="icon">
                  <span />
                </div>
                <div className="content">
                  <div className="name">Hello ,  {`${
                      !isEmpty(localStorage.getItem("first_name")) &&
                      localStorage.getItem("first_name") !== "null"
                        ? localStorage.getItem("first_name")
                        : localStorage.getItem("email").split("@")[0]
                    } ${
                      !isEmpty(localStorage.getItem("last_name")) &&
                      localStorage.getItem("last_name") !== "null"
                        ? localStorage.getItem("last_name")
                        : ""
                    }`}</div>
                </div>
                <div className="profile-image">
                 <img 
                  src={!isEmpty(localStorage.getItem("profile_image")) &&
                      localStorage.getItem("profile_image") !== "null"
                        ? localStorage.getItem("profile_image")
                        : profieImg} 
                  alt="profile_image" 
                  />
                </div>
                
               {/* DropDown  */}
                {this.state.HeaderDropdownFlag &&
                <ul className="dropdown-list">
                <li>
                  <Link to={
                        !isEmpty(localStorage.getItem("role")) && localStorage.getItem("role") === "buyer"
                          ? "/dashboard-buyer"
                          : "/dashboard-seller"
                      } className="buyer-link">
                    Go to Buyer Account
                  </Link>
                </li>
                <li>
                  <Link  to={
                        !isEmpty(localStorage.getItem("role")) && localStorage.getItem("role") === "buyer"
                          ? "/dashboard-buyer"
                          : "/dashboard-seller"
                      }>
                    <img src={imageDashboard} alt="" />
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li>
                  <Link to="/account-setting">
                    <img src={imageSetting} alt="" />
                    <span>Account Settings</span>
                  </Link>
                </li>
                <li>
                  <Link to="/favorite-homes">
                    <img src={imageLikeFill} alt="" />
                    <span>Favorite Homes</span>
                  </Link>
                </li>
                <li>
                  <Link to="/" onClick={() => {
                        localStorage.clear();
                        this.props?.history?.push("/signin");
                      }}>
                    <img src={imageLogout} alt="" /> <span>Log out</span>
                  </Link>
                </li>
              </ul>
                }
              </div>
            </div>
            <div className="mobile-menu" onClick={this.toggleClass}>
              <ul className={this.toggleMenu ? "active" : ""}>
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
        </header>
        <section className="favrite-property-list">
          <div className="container-main">
            <h3 className="title">Favorite Homes</h3>
            <div className="property-wrapper">
            {!isEmpty(this.state.favouriteList) ? this.state.favouriteList?.map(fav =>  <div className="property-block" key={fav.id}>
                <div className="top-block">
                  <div className="profile-block">
                    <i>
                      <img src={buyerSkew} alt="" />
                    </i>
                  <span>{`${fav.attributes.owner.first_name ? fav.attributes.owner.first_name : "Unknown"} ${fav.attributes.owner.last_name ? fav.attributes.owner.last_name : ""}`}</span>
                  </div>
                  <div className="like-block" onClick={()=>this.doRemoveFavoriteHouse(fav.id)}>
                    <img src={imageLikeActive} alt="Liked image" />
                  </div>
                </div>
                <i className="property-image">
                  <img src={fav.attributes.images[0] ? fav.attributes.images[0]?.url : noImgAvailable} alt="Property Image" />

                  {/* <img src={fav.attributes.images?.[0]?.url}  alt="Property Image" /> */}
                </i>
                <div className="like-view-block">
                  <div className="left-block">
                    <p className="lable">List Price</p>
                    <p className="price">{fav.attributes.formatted_price}</p>
                  </div>
                  <div className="right-block">
                    <div className="view">
                      <i>
                        <img src={imageView} alt="" />
                      </i>
                      <span>{fav.attributes.view_count}</span>
                    </div>
                    <div className="like">
                      <i>
                        <img src={imageLikeRed} alt="" />
                      </i>
                      <span>{fav.attributes.favorited_count}</span>
                    </div>
                  </div>
                </div>
                <div className="more-detail">
                  <p className="property-name">{fav.attributes.address.address_line}</p>
                  <p className="property-address">{`${fav.attributes.address.state} , ${fav.attributes.address.city} ${fav.attributes.address.zip_code}`}</p>
                  <ul>
                    <li>{fav.attributes.bedrooms} Beds</li>
                    <li>{fav.attributes.full_baths} Baths</li>
                    <li>{fav.attributes.finished_sq_ft} Sq ft</li>
                  </ul>
                </div>
              </div>): <h2>No Favorite Houses Found</h2>}
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(FavoriteHomes);
