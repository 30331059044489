// @ts-nocheck

import React from "react";
// Customizable Area Start

import Sidebar from "../../../../../components/src/Sidebar";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
export const configJSON = require("../../config");
import { fileImg, errorImg, deleteImg, fixImg, workingImg, arrowDown } from "../../assets";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import GoogleMapReact from "google-map-react";
import StepperMainWeb from "./StepperMain.web";
import TermsAndConditions from "./TermsAndConditions.web";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

// Customizable Area End
import DashboardController, { Props } from "../DashboardSellerController.web";


const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ReviewUpload extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start

    // const notWorkingAmenities=
    // this.props?.location.state?.data?.attributes?.property_amenities.filter(nw=>nw.status==="not_working").map(nw=>+nw.amenity_id );
    // const workingAmenities=
    // this.props?.location.state?.data?.attributes?.property_amenities.filter(w=>w.status==="not_working").map(w=>+w.amenity_id );
    return (
      <>
        <StepperMainWeb>
          <h3 className="page-title">Review Upload</h3>
          <div className="stepper-sections">
            {/* Amenities  */}

            <div className="reviews-div">
              <div className="media-heading">
                <span className="green-title">Review Property</span>
              </div>
              {this.state.reviewList && (
                // Object.keys(this.state.reviewList).map(
                //   (review: any, index: number) => (
                <>
                  {/* Address Details */}
                  <div className="sub-section">
                    {/* Address*/}
                    <div className="sub-div">
                      <div className="review-sec">
                        <h5 className="sec-head">Address</h5>
                        <p>{this.state.reviewList.attributes?.address.address_line}</p>
                      </div>
                      <div className="review-sec">
                        <h5 className="sec-head">Property Type</h5>
                        <p>{this.state.reviewList.attributes?.home_type.title}</p>
                      </div>
                      <div className="review-sec">
                        <h5 className="sec-head">Property ID</h5>
                        <p>{this.state.reviewList.id} </p>
                      </div>
                    </div>

                    {/* Map */}
                    <div className="address-map">
                      {/* <div className="map"/> */}
                      <div className="map-block-wrapper">
                        <GoogleMapReact
                          bootstrapURLKeys={{
                            key: "AIzaSyA48nVKp-UOKiw0cQftEKX6h4BVSSA8kWE",
                          }}
                          defaultCenter={{
                            lat: 59.95,
                            lng: 30.33,
                          }}
                          defaultZoom={11}
                        >
                          <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text="My Marker"
                          />
                        </GoogleMapReact>
                      </div>
                    </div>
                  </div>

                  {/* Basic Details */}
                  <div className="sub-section">
                    <div className="sub-div">
                      <div className="review-sec">
                        <h5 className="sec-head">Bathoom</h5>
                        <p>{`${this.state.reviewList?.attributes?.full_baths + this.state.reviewList?.attributes?.half_baths}`}</p>
                      </div>
                      <div className="review-sec">
                        <h5 className="sec-head">Bedrooms</h5>
                        <p>{this.state.reviewList?.attributes?.bedrooms}</p>
                      </div>
                      <div className="review-sec">
                        <h5 className="sec-head">Garages</h5>
                        <p>{this.state.reviewList?.attributes?.garage_space}</p>
                      </div>
                      <div className="review-sec">
                        <h5 className="sec-head">Land Sizes</h5>
                        <p>{this.state.reviewList.attributes?.finished_sq_ft}</p>
                      </div>
                      <div className="review-sec">
                        <h5 className="sec-head">Year Built</h5>
                        <p>{this.state.reviewList?.attributes?.year_built}</p>
                      </div>

                    </div>
                  </div>

                  {/* Upload Media */}
                  <div className="sub-section">

                    <div className="field-wrapper preview">
                      <ul>
                        {this.state.reviewList?.attributes?.images.map((img, index) => (
                          <li key={img.id}>
                            <img
                              src={img.url}
                              alt=""
                              className="uploaded-image"
                            />
                            <button type="button" className="del-btn" onClick={() => {
                              const s = this.state.reviewList?.attributes?.images.filter(
                                (item: any, index: any) => img.id !== item.id);
                              this.setState({ reviewList: { attributes: { ...this.state.reviewList?.attributes, images: s } } });

                              this.doDeleteImage(img.id)
                            }
                            }>
                              <img src={deleteImg} alt="deleteImg" />
                            </button>
                          </li>
                        ))}

                      </ul>
                    </div>


                    <div className="field-wrapper">
                      <h2 className="green-title">Video Walkthrough</h2>
                      <p className="vidUrl">
                        {this.state.reviewList?.attributes?.video_walkthrough_url || ""}
                      </p>
                    </div>


                    <div className="field-wrapper">
                      <h2 className="green-title">Virtual Tour URL</h2>
                      <p className="vidUrl">
                        {this.state.reviewList?.attributes?.virtual_tour_url || ""}
                      </p>
                    </div>
                  </div>

                  {/* Pricing */}
                  <div className="sub-section2">
                    <div className="review-price">
                      <h6>Price</h6>
                      <p>{this.state.reviewList?.attributes?.price}</p>
                    </div>
                    <div className="review-price">
                      <h6>List Price</h6>
                      <p>{this.state.reviewList?.attributes?.price}</p>
                    </div>
                    <div className="review-price">
                      <h6>Downpayment</h6>
                      <p>{this.state.reviewList?.attributes?.min_down_payment}</p>
                    </div>
                  </div>


                  {/* Check List */}


                  <div className="sub-section">
                    <Accordion>
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0">
                          <div className="left-title">
                            <div className="image-block">
                              <img src={fixImg} alt="" />
                            </div>
                            <span>Need To Fix</span>
                          </div>
                          <div className="right-title">
                            <div className="count-block">
                              {this.state.reviewList?.attributes
                                ?.property_amenities?.filter(
                                  (working) => working.status === "not_working").length
                              }

                            </div>
                            <span>Items</span>
                            <div className="arrow-icon">
                              <img src={arrowDown} alt="" />
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            {this.state.reviewList?.attributes?.property_amenities?.map((working) => {
                              return working.status === "not_working" ? (
                                <div className="amenities-status" key={working.id}>
                                  <p>{working.name}</p>
                                </div>) : ""
                            })}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="1">
                          <div className="left-title">
                            <div className="image-block">
                              <img src={workingImg} alt="" />
                            </div>
                            <span>Working</span>
                          </div>
                          <div className="right-title">
                            <div className="count-block">
                              {this.state.reviewList?.attributes
                                ?.property_amenities?.filter(
                                  (working) => working.status === "working").length
                              }
                            </div>
                            <span>Items</span>
                            <div className="arrow-icon">
                              <img src={arrowDown} alt="" />
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            {this.state.reviewList?.attributes?.property_amenities?.map((working) => {
                              if (working.status === "working") {
                                return (
                                  <div className="amenities-status" key={working.id}>
                                    <p>{working.name}</p>
                                  </div>)
                              }
                            })}

                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </>)}
            </div>
            {/* Next  */}
            <div className="btn-wrapper flex justify-end">
              <button type="button" className="btn gray-fill-btn" onClick={() => {
                this.doStepSet(5);
                // @ts-ignore
                this.props?.history?.push({
                  pathname: "/add-property/checklist",
                  state: { steps: this.state.step, data: this.state.reviewList?.attributes },
                });
              }}>
                Back
              </button>
              <button type="button" className="rounded-fill-btn ml-3" onClick={() => {

                this.handleShowTermsAndCondition();
                this.getPropertyTermsOfServicesContent();
              }}>
                Publish
              </button>
            </div>
          </div>
          <TermsAndConditions show={this.state.termsAndConditionShow} onHide={this.handleHideTermsAndCondition} content={this.state.propertyTermsServicesContent} />

        </StepperMainWeb>
        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </>
    );
    // Customizable Area End

  }
}

// Customizable Area Start
export default withRouter(ReviewUpload);

// Customizable Area End
