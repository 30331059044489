// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "../DashboardBuyerController.web";
import {
  imageLocation,
  imagePrice,
  imageCalender,
  imageDetail,
  buyerLogo,
  mainPropertySlider,
  imageRoundedArrow,
  bedroom,
  areaSquare,
  squareFt,
} from "../../assets";

export interface Props { }
interface S {
  FilterFlag: any;
}
class BuyerApplicationDetail extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
    this.propertyid = new URLSearchParams(this.props.location.search);
    this.params = Object.fromEntries(this.propertyid.entries());
    this.details = this.props.location?.state?.data;
    this.moveIndate = new Date(this.details?.moveInDate);
    this.date = this.moveIndate.getDate() + "/" +
      (this.moveIndate.getMonth() + 1) + "/" +
      this.moveIndate.getFullYear()
  }
  componentDidMount() {
    this.setState({
      propertyId: +this.params.id
    }, () => this.getPropertyDetail(this.state.propertyId)
    );
  }
  render() {
    return (
      <>
        <header className="site-header fixed-header buyer-application-header">
          <div className="container-main">
            <div className="left-header">
              <Link
                to={
                  localStorage.getItem("role") === "buyer"
                    ? "/dashboard-buyer"
                    : "/dashboard-seller"
                }
                className="page-logo"
                title="Sparen Home"
              >
                <img src={buyerLogo} alt="Logo" />
              </Link>
            </div>
            <div className="right-header">
              <Link to={`/property-detail?id=${this.state.propertyId}`}
              
              >Back</Link>
            </div>
            <div className="mobile-menu" onClick={this.toggleClass}>
              <ul className={this.toggleMenu ? "active" : ""}>
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
        </header>
        <section className="buyer-application-dashboard">
          <div className="image-block-wrapper">
            <div className="image-block">
              <img src={mainPropertySlider} alt="" />
            </div>
            <div className="image-block">
              <img src={mainPropertySlider} alt="" />
            </div>
            <div className="image-block">
              <img src={mainPropertySlider} alt="" />
            </div>
            <div className="image-block">
              <img src={mainPropertySlider} alt="" />
            </div>
          </div>
          <div className="tab-outer-container">
            <ul className="tab-header">
              <li className="active">
                <i>1</i>
                <span>Details</span>
              </li>
              <li className={this.props?.location?.state?.success ? "active" : ""}>
                <i>2</i>
                <span>Review</span>
              </li>
              <li>
                <i>3</i>
                <span>Submit</span>
              </li>
            </ul>

            {this.props?.location?.state?.success ? (
              <div className="tab-content">
                <p className="tab-title">Please fill below details</p>

                {/* Property Details */}
                <div className="detail-block">
                  <div className="image-block">
                    <img src={imageLocation} alt="" />
                  </div>
                  <div className="content-block">
                    <p className="title">Property Details</p>
                    <div className="list-wrapper">
                      <div className="list-block">
                        <span className="gray-text">Address:</span>
                        <p className="value">
                          {this.state.propertyDetails?.address?.address_line + " " +
                            this.state.propertyDetails?.address?.state + ", " +
                            this.state.propertyDetails?.address?.city + " " +
                            this.state.propertyDetails?.address?.zip_code}
                        </p>
                      </div>
                      <div className="list-block">
                        <span className="gray-text">Price:</span>
                        <div className="value-block">
                          <span className="value">{"$" + this.state.propertyDetails?.price}</span>
                          <strike>{"$" + this.state.propertyDetails?.price}</strike>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button type="button" className="arrow-btn"
                      onClick={() => {
                     
                        // @ts-ignore
                        this.props?.history?.push({
                          pathname: "/property-tour",
                          search: `id=${this.state.propertyId}`,
                          state: {
                            propertyID: this.state.propertyId,
                            price: this.state.propertyDetails?.price,
                            data: this.details,
                            step: 0
                          }
                        })

                      }}
                    >
                      <img src={imageRoundedArrow} alt="" />
                    </button>
                  </div>
                </div>

                {/* Your Details */}
                <div className="detail-block">
                  <div className="image-block">
                    <img src={imageDetail} alt="" />
                  </div>
                  <div className="content-block">
                    <p className="title">Your Details</p>
                    <p className="gray-text">
                      Please give your some information
                    </p>
                    <div className="review-content">
                      <div className="block-33">
                        <div className="content">
                          <p className="gray-text">Full Name:</p>
                          <p className="value">{this.details?.buyerName}</p>
                        </div>
                        {this.details.showCobuyer && (
                        <div className="content">
                          <p className="gray-text">Cobuyer Full Name:</p>
                          <p className="value">{this.details?.cobuyerName}</p>
                        </div>
                        )}
                      </div>
                      <div className="block-33">
                        <div className="content">
                          <p className="gray-text">E-mail</p>
                          <p className="value">{this.details?.buyerEmail}</p>
                        </div>

                        {this.details.showCobuyer && (
                          <div className="content">
                            <p className="gray-text">E-mail</p>
                            <p className="value">{this.details?.cobuyerEmail}</p>
                          </div>
                        )}

                      </div>
                      <div className="block-33">
                        <div className="content">
                          <p className="gray-text">Phone Number</p>
                          <p className="value">{this.details?.buyerContact}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button type="button" className="gray-border-btn"
                      onClick={() => {
                        // @ts-ignore
                        this.props?.history?.push({
                          pathname: "/property-tour",
                          search: `id=${this.state.propertyId}`,
                          state: {
                            propertyID: this.state.propertyId,
                            price: this.state.propertyDetails?.price,
                            data: this.details,
                            step: 1
                          }
                        })

                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>

                {/* Price and Payment */}
                <div className="detail-block">
                  <div className="image-block">
                    <img src={imagePrice} alt="" />
                  </div>
                  <div className="content-block">
                    <p className="title">Price and Payment</p>
                    <p className="gray-text">
                      Let us know if you have price in your mind and how will
                      you pay
                    </p>
                    <div className="review-content">
                      <div className="block-33">
                        <div className="content">
                          <p className="gray-text">Payment Method</p>
                          <p className="value">{this.details?.paymentMethod}</p>
                        </div>
                        <div className="content">
                          <p className="gray-text">Year Terms</p>
                          <p className="value">{this.details?.termPeriod} Years</p>
                        </div>
                      </div>
                      <div className="block-33">
                        <div className="content">
                          <p className="gray-text">Downpayment</p>
                          <p className="value">${this.details?.downPayment}</p>
                        </div>
                      </div>
                      <div className="block-33">
                        <div className="content">
                          <p className="gray-text">Offer Amount</p>
                          <p className="value">{this.state.propertyDetails?.price}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button type="button" className="gray-border-btn"
                      onClick={() => {
                        // @ts-ignore
                        this.props?.history?.push({
                          pathname: "/property-tour",
                          search: `id=${this.state.propertyId}`,
                          state: {
                            propertyID: this.state.propertyId,
                            price: this.state.propertyDetails?.price,
                            data: this.details,
                            step: 2
                          }
                        })

                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>

                {/* Move In Date */}
                <div className="detail-block">
                  <div className="image-block">
                    <img src={imageCalender} alt="" />
                  </div>
                  <div className="content-block">
                    <p className="title">Move In Date</p>
                    <p className="gray-text">
                      What time you will take to buy property
                    </p>
                    <div className="review-content">
                      <div className="block-33">
                        <div className="content">
                          <p className="gray-text">Move-In Date</p>
                          <p className="value">
                            {this.details?.moveInDate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button type="button" className="gray-border-btn"
                      onClick={() => {
                        // @ts-ignore
                        this.props?.history?.push({
                          pathname: "/property-tour",
                          search: `id=${this.state.propertyId}`,
                          state: {
                            propertyID: this.state.propertyId,
                            price: this.state.propertyDetails?.price,
                            data: this.details,
                            step: 3
                          }
                        })

                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>

                {/* Submit Field */}
                <div className="end-button">
                  <div className="btn-wrapper">
                    <button type="button" className="green-fill-btn"
                      onClick={() => {
                      this.doPropertyApplication(this.details, this.state.propertyId);
                      this.details.scheduleTour && this.doBuyerTour(this.details, this.state.propertyId);
                        // @ts-ignore
                        // this.props.history.push({
                        //   pathname: "/dashboard-buyer"
                        // })

                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="tab-content">
                <p className="tab-title">Please fill below details</p>
                <div className="detail-block">
                  <div className="image-block">
                    <img src={imageLocation} alt="" />
                  </div>
                  <div className="content-block">
                    <p className="title">Property Details</p>
                    <div className="list-wrapper">
                      <div className="list-block">
                        <span className="gray-text">Address:</span>
                        <p className="value">
                          {this.state.propertyDetails?.address?.address_line + " " +
                            this.state.propertyDetails?.address?.state + ", " +
                            this.state.propertyDetails?.address?.city + " " +
                            this.state.propertyDetails?.address?.zip_code}
                        </p>
                      </div>
                      <div className="list-block">
                        <span className="gray-text">Price:</span>
                        <div className="value-block">
                          <span className="value">{`$ ${this.state.propertyDetails?.price||0}`}</span>
                          <strike>{`$ ${this.state.propertyDetails?.price||0}`}</strike>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <button
                      type="button"
                      className="arrow-btn"
                      onClick={() => {
                        this.state.propertyDetails?.price?
                        // @ts-ignore
                        this.props?.history?.push({
                          pathname: "/property-tour",
                          search: `id=${this.state.propertyId}`,
                          state: {
                            propertyID: this.state.propertyId,
                            price: this.state.propertyDetails?.price
                          }
                        }):this.openSnackBarHandler("error", "No price Available")

                      }}
                    >
                      <img src={imageRoundedArrow} alt="" />
                    </button>
                  </div>
                </div>
                <div className="detail-block">
                  <div className="image-block">
                    <img src={imageDetail} alt="" />
                  </div>
                  <div className="content-block">
                    <p className="title">Your Details</p>
                    <p className="gray-text">
                      Please give your some information
                    </p>
                  </div>
                  <div className="btn-wrapper">
                    <button type="button" className="gray-border-btn">
                      Pending
                    </button>
                  </div>
                </div>
                <div className="detail-block">
                  <div className="image-block">
                    <img src={imagePrice} alt="" />
                  </div>
                  <div className="content-block">
                    <p className="title">Price and Payment</p>
                    <p className="gray-text">
                      Let us know if you have price in your mind and how will
                      you pay
                    </p>
                  </div>
                  <div className="btn-wrapper">
                    <button type="button" className="gray-border-btn">
                      Pending
                    </button>
                  </div>
                </div>
                <div className="detail-block">
                  <div className="image-block">
                    <img src={imageCalender} alt="" />
                  </div>
                  <div className="content-block">
                    <p className="title">Move In Date</p>
                    <p className="gray-text">
                      What time you will take to buy property
                    </p>
                  </div>
                  <div className="btn-wrapper">
                    <button type="button" className="gray-border-btn">
                      Pending
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(BuyerApplicationDetail);
