Object.defineProperty(exports, "__esModule", {
  value: true,
});

//ID
exports.clientID =
  "649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com";

//Text
exports.placeHolderEmail = "Email";
exports.signupplaceHolderEmail = "xyz@gmail.com";
exports.placeHolderPassword = "at least 8 Characters";
exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";
exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.facebookButtonText = "Sign up With Facebook";
exports.googleButtonText = "Sign up With Google";

exports.urlGetValidations = "profile/validations";

//API
exports.accountsAPiEndPoint = "account/accounts";
exports.signupAPiEndPoint = "account_block/account";
exports.roleAPiEndPoint = "bx_block_profile/profile/set_role";

//Content Type
exports.contentTypeApiAddDetail = "application/json";
exports.validationApiContentType = "application/json";
exports.signUpApiContentType = "application/json";

//Method Type
exports.apiMethodTypeAddDetail = "POST";
exports.validationApiMethodType = "GET";
exports.putApiMethodType = "PUT";

// Messages
exports.successSignUp = "Signup Successfully";
exports.passwordValidation =
  "Password Must be 8 Characters long,Contain Both UpperCase and LowerCase Characters,at least one digit, and one special Character ";

// Error
exports.errorSignupEmailNotValid = "Please Enter Valid Email";
exports.errorEmailRequired = "Please Enter Email";
exports.errorSignupPasswordNotValid = "Please Enter Password";
exports.errorPasswordNotValid = "Password not valid.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorRoleRequired = "Please Select Account Type";
