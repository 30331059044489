// @ts-nocheck

import React from "react";
// Customizable Area Start
import Sidebar from "../../../../../components/src/Sidebar";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";
export const configJSON = require("../../config");
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// Customizable Area End
import DashboardController, { Props } from "../DashboardSellerController.web";
import GoogleMapReact from "google-map-react";
import StepperMainWeb from "./StepperMain.web";
import Map from "./Map.web";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class AddressDetail extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      <StepperMainWeb>
        <h3 className="page-title">Address Detail</h3>
        <div className="">
          <Formik
            initialValues={
              {
                address: this.props?.location.state?.data?.address.address_line || "",
                city: this.props?.location.state?.data?.address.city || "",
                state: this.props?.location.state?.data?.address.state || "",
                zip: this.props?.location.state?.data?.address.zip_code || "",
                homeType: this.props?.location.state?.data?.home_type.id || "",
                status: this.props?.location.state?.data?.status || "",
                latitude: this.props?.location.state?.data?.address.latitude || "",
                longitude: this.props?.location.state?.data?.address.longitude || "",
              }
            }
            validationSchema={Yup.object().shape(
              this.state.addressDetailSchema
            )}
            onSubmit={(values) => this.doAddressDetails(values)}
            enableReinitialize
          >
            {({ values, setFieldValue, errors }) => (
              //@ts-ignore
              <Form>
                {/* Address  */}
                <div className="field-wrapper">
                  <label className="green-title">What's the address?</label>
                  <Field
                    type="text"
                    name="address"
                    placeholder={configJSON.addressHolder}
                    className="custom-input secondary-input"
                  />
                  <span className="error">
                    <ErrorMessage name="address" />
                  </span>
                </div>

                {/* City / State / Zip */}
                <div className="field-outer-flex">
                  <div className="field-wrapper width-33">
                    <label className="mb-1">City</label>
                    <Field
                      type="text"
                      name="city"
                      placeholder={configJSON.cityHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="city" />
                    </span>
                  </div>

                  <div className="field-wrapper width-33">
                    <label className="mb-1">State</label>
                    <Field
                      type="text"
                      as="select"
                      name="state"
                      autoComplete="off"
                      defaultValue=""
                      className="custom-input secondary-input"
                    >
                      <option value="" disabled>
                        Select State
                      </option>
                      {Object.entries(this.state.stateList)?.map(([key, value]: any, index) => (<>
                        {/* {console.log("state", key, value)} */}
                        <option key={index} value={key}>
                          {value.toString()}
                        </option>
                      </>
                      ))}

                    </Field>
                    <span className="error">
                      <ErrorMessage name="state" />
                    </span>
                  </div>
                  <div className="field-wrapper width-33">
                    <label className="mb-1">Zip</label>
                    <Field
                      type="number"
                      name="zip"
                      placeholder={configJSON.zipHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="zip" />
                    </span>
                  </div>
                </div>

                {/* Map  */}
                <div className="address-map">
                  <h2 className="green-text">
                    600 W 141st St #54A-1, New York, NY 10031
                  </h2>
                  <div className="currently-select">
                    <div>Is this an accurate location of your home?</div>
                    <div>Currently selected: 40.82575, -73.952824</div>
                  </div>
                  <div className="map-block-wrapper">
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyCR33vY5s87FEH8qvuJVAGMBnxUAmh9BM0",
                      }}
                      defaultCenter={{
                        lat: 59.95,
                        lng: 30.33,
                      }}
                      defaultZoom={11}
                    >
                      <AnyReactComponent
                        lat={59.955413}
                        lng={30.337844}
                        text="My Marker"
                      />
                    </GoogleMapReact>
                    {/* <Map
                        google={this.props.google}
                        center={{lat: 18.5204, lng: 73.8567}}
                        height='300px'
                        zoom={15}
                    /> */}
                  </div>
                  <p className="more-info">
                    Move your home to the correct location
                  </p>
                  <p className="more-info">
                    Tap or click the correct location, and the home will be
                    placed there.
                  </p>
                </div>

                {/* Home Type / Status */}
                <div className="field-outer-flex">
                  <div className="field-wrapper width-33">
                    <label>Home Type</label>
                    <Field
                      type="text"
                      as="select"
                      name="homeType"
                      autoComplete="off"
                      defaultValue=""
                      className="custom-input secondary-input"
                    >
                      <option value="" disabled>
                        Select Home Type
                      </option>

                      {this.state.homeTypeList.map((homeType: any, index) => (
                        <option key={homeType.id} value={homeType.id}>
                          {homeType.attributes.title}
                        </option>
                      ))}
                    </Field>
                    <span className="error">
                      <ErrorMessage name="homeType" />
                    </span>
                  </div>

                  <div className="field-wrapper width-33">
                    <label>Status</label>
                    <Field
                      type="text"
                      as="select"
                      name="status"
                      autoComplete="off"
                      defaultValue=""
                      className="custom-input secondary-input"
                    >
                      <option value="" disabled>
                        Select Status
                      </option>

                      {this.state.statusList.map((status: any, index) => (
                        <option key={index} value={status.value}>
                          {status.label}
                        </option>
                      ))}
                    </Field>
                    <span className="error">
                      <ErrorMessage name="status" />
                    </span>
                  </div>
                </div>

                {/* Next  */}
                <div className="btn-wrapper flex justify-end">
                  <button type="submit" className="rounded-fill-btn">
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* Snackbar for display success and failed messages. */}
        {/* <Snackbar
            open={this.state.snackBar.show}
            autoHideDuration={3000}
            onClose={this.closeSnackBarHandler}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.closeSnackBarHandler}
              severity={this.state.snackBar.type}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar> */}
      </StepperMainWeb >
    );
  }
}

// Customizable Area Start
export default withRouter(AddressDetail);

// Customizable Area End
