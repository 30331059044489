import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { object } from "yup";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  show: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  completeFlag: boolean;
  limit: number | number[];
  verifyAccountFlag: boolean;
  sucessVerifyAccountFlag: boolean;
  checkVerifyFlag: boolean;
  loader: any;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class InstantVerificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  apiBankAccountCallId: string = "";
  apiLinkTokenCallId: string = "";
  setPendingLimitId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),

      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      token: "",
      completeFlag: false,
      limit: 0,
      verifyAccountFlag: false,
      sucessVerifyAccountFlag: false,
      checkVerifyFlag: false,
      loader: false,
    };
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // this.fetchLinkToken();
    // console.log("Worked", this.props.show);
    this.setState(
      { checkVerifyFlag: true }
      //  () =>console.log("In Start mount", this.state.checkVerifyFlag)
    );
  }
  componentDidUpdate() {
    if (
      this.props.show != this.state.verifyAccountFlag &&
      this.state.checkVerifyFlag
    ) {
      this.setState(
        {
          verifyAccountFlag: this.props.show,
          checkVerifyFlag: false,
        }
        // () => console.log("in Did Update", this.state.verifyAccountFlag)
      );
      this.fetchLinkToken();
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      // let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // this.setState({ token: token });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          // Create link
          if (
            apiRequestCallId === this.apiLinkTokenCallId &&
            responseJson !== undefined
          ) {
            // this.openSnackBarHandler("success", configJSON.successLogin);
            this.setState({
              ...this.state,
              token: responseJson.plaid_response.link_token,
            });
          }
          //token-exchange
          if (
            apiRequestCallId === this.apiBankAccountCallId &&
            responseJson !== undefined
          ) {
            this.toggleVerifyAccountFlag();
            this.toggleSuccessVerifyAccountFlag();
            // this.openSnackBarHandler("success", configJSON.successLogin);
            // this.setState({
            //   ...this.state,
            //   token: responseJson.plaid_response.link_token,
            // });
            this.setState({
              ...this.state,
              loader: false,
            });
          }
          //Spending Limit
          if (
            apiRequestCallId === this.setPendingLimitId &&
            responseJson !== undefined
          ) {
            // console.log("done", responseJson.data.attributes.role);
            if (localStorage.getItem("role") === "buyer") {
              // @ts-ignore
              this.props.history.push("properties");
            } else {
              // @ts-ignore
              this.props?.history?.push({
                pathname: "dashboard-seller",
                state: "Welcome",
              });
              // this.props?.history?.push("dashboard-seller");
            }
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson.errors;
        if (errors[0].message) {
          // this.openSnackBarHandler("error", errors[0].message);
        }
      }
    }
  }

  // Spending Limit
  doSpendingPricing = (value: number) => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: localStorage.getItem("auth"),
    };

    const attrs = {
      max_spending_limit: value,
    };

    const httpBody = {
      data: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setPendingLimitId = requestMessage.messageId;

    const endpoint = configJSON.setPendingLimitApiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  fetchLinkToken() {
    // @ts-ignore
    const header = {
      "Content-Type": configJSON.linkTokenApiContentType,
      // @ts-ignore
      // token :  this.props?.location?.state?.token
      token: localStorage.getItem("auth"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiLinkTokenCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createTokenApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.tokenExchangeApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // onSuccess(publicToken: string, metadata: object) {
  //   const header = {
  //     "Content-Type": configJSON.tokenExchangeApiContentType,
  //     "token":"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDQsImV4cCI6MTYyNTU2NjQxOH0.15lJ_qaWtOzus3iko32z7VEFebpDipQWsTE0Gt2DeHwwTvBVbx7UMHblID1y764QcnAY8Tjg7ixxt82T3KqYRw"

  //   };
  //   const attrs = {
  //     public_token:publicToken,
  //     metadata:metadata
  //     // "metadata": {"institution":{"name":"Wells Fargo","institution_id":"ins_4"},"account":{"id":"8X9gn63Vbyhp6D4L38L1Hl6Pn5x5LlcwM5A15","name":"Plaid Checking","type":"depository","subtype":"checking","mask":"0000"},"account_id":"8X9gn63Vbyhp6D4L38L1Hl6Pn5x5LlcwM5A15","accounts":[{"id":"8X9gn63Vbyhp6D4L38L1Hl6Pn5x5LlcwM5A15","name":"Plaid Checking","mask":"0000","type":"depository","subtype":"checking"},{"id":"EXkJq71Edlhle6y7Rm71TVnDWvbvRVtXGzwR3","name":"Plaid Saving","mask":"1111","type":"depository","subtype":"savings"}],"link_session_id":"4027b4fa-8f42-42cd-b230-97365372e37b","public_token":"public-sandbox-0173ce11-1c34-481a-983b-6ac168590365"}
  //   }

  //   const httpBody = {
  //     data: attrs,

  //   };

  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   this.apiBankAccountCallId = requestMessage.messageId;

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.bankAccountApiEndPoint
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     JSON.stringify(httpBody)
  //   );

  //   // requestMessage.addData(
  //   //   getName(MessageEnum.RestAPIRequestMethodMessage),
  //   //   configJSON.tokenExchangeApiMethod
  //   // );

  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  // }

  onSuccess = (publicToken: string, metadata: object) => {
    const header = {
      "Content-Type": configJSON.tokenExchangeApiContentType,
      token: localStorage.getItem("auth"),
    };

    const attrs = { public_token: publicToken, metadata: metadata };

    // const data = {
    //   type: "email_account",
    //   attributes: attrs,
    // };

    const httpBody = {
      data: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiBankAccountCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bankAccountApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.tokenExchangeApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      ...this.state,
      loader: true,
    });
    return true;
  };
  onEvent(metadata: object) {
    console.log(metadata);
  }

  onExit(error: string, metadata: object) {
    console.log(error, metadata);
  }

  toggleCompleteFlag = () => {
    this.setState({
      token: "",
      completeFlag: true,
    });
  };

  setLimitHandler = (event: any, newLimit: number) => {
    this.setState({
      limit: newLimit,
    });
  };

  setLimitLabel = (limit: number) => {
    return `$${limit}`;
  };

  toggleVerifyAccountFlag = () => {
    this.setState({
      ...this.state,
      verifyAccountFlag: false,
    });
  };
  toggleSuccessVerifyAccountFlag = () => {
    this.setState({
      ...this.state,
      sucessVerifyAccountFlag: !this.state.sucessVerifyAccountFlag,
    });
  };
}
