//@ts-nocheck
import React from "react";
// Customizable Area Start
import { Modal } from "react-bootstrap";
import PopupsController, { Props } from "./PopupsController.web";
import { successUploadImg } from "../assets";
import { Link } from "react-router-dom";

// Customizable Area End
class SuccessAgreement extends PopupsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        console.log("WOrked")
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
            <Modal
                show={this.props.sucessAgreementShow}
                onHide={this.props.handlesucessAgreementShow}
                dialogClassName="success-property-modal"
                backdrop="static"
                aria-labelledby="property-success-property-modal"
                centered
            >
                <Modal.Body>
                    <div className="success-div">
                        <div className="success-img">
                            <img src={successUploadImg} />
                        </div>
                        <div className="success-content">
                            <h1>Payment Successful</h1>
                            <p>Property is successfully bought.</p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Link to="/property-list">
                        <button
                            variant="primary"
                            className="rounded-fill-btn"
                            onClick={this.props.handlesucessAgreementShow}
                        >
                            OK
                        </button>
                    </Link>
                </Modal.Footer>
            </Modal>
        );
    }
}

// Customizable Area Start
export default SuccessAgreement;

// Customizable Area End
