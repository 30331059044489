Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

// API
exports.getAllNewPropertyListApiEndPoint =
  "bx_block_postcreation/public_properties/newly_added";
exports.getEmailSubscriptionApiEndPoint =
  "bx_block_emailnotifications/email_subscriptions";

// Method
exports.getApiMethodType = "GET";
exports.postApiMethodType = "POST";

exports.btnExampleTitle = "CLICK ME";
exports.errorEmailNotValid = "Please Enter Valid Email Address";
exports.errorEmailReq = "Please Enter Email Address";

// Customizable Area End
