// @ts-nocheck

import React from "react";
// Customizable Area Start
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
export const configJSON = require("../../config");
import SuccessPublishedProperty from "./SuccessPublishedProperty.web";

// Customizable Area End
import AddPropertyFlowController, { Props } from "./AddPropertyFlowController.web";

class TermsAndConditions extends AddPropertyFlowController {
  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      <>
        <Modal
          show={this.props.show}
          onHide={() => this.props.onHide}
          dialogClassName="modal-90w terms-condition-modal"
          backdrop="static"
          aria-labelledby="property-terms-condition-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="property-terms-condition-modal">
              {this.props.content.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="terms-condition-div">
              <div
                className="terms-items"
                dangerouslySetInnerHTML={{ __html: this.props.content.content }}
              />
            </div>
            <div className="terms-checkbox-div">
              <Formik
                initialValues={{
                  termsAndConditionAccepted: false,
                  stepNumber: 6,
                }}
                validationSchema={Yup.object().shape(this.state.publishSchema)}
                onSubmit={(values) => {
                  this.doPublish(values, this.props.propertyId);
                  this.props.onHide(this.state.termsAndConditionShow);
                }}
                enableReinitialize
              >
                {({ values }) => (
                  <Form>
                    <div className="field-outer-flex">
                      <div className="checkbox-wrapper">
                        <label className="more-info" htmlFor="prop-terms-con">
                          <Field
                            type="checkbox"
                            id="prop-terms-con"
                            name="termsAndConditionAccepted"
                            className="input-checkbox"
                          />
                          Check here to indicate that you have read and agree to
                          terms and condition of the Sparen
                          <span className="checkmark" />
                        </label>
                        <div>
                          <span className="error">
                            <ErrorMessage name="termsAndConditionAccepted" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        variant="primary"
                        type="submit"
                        className="rounded-fill-btn"
                      // onClick={() => {
                      //   this.props.onHide(this.state.termsAndConditionShow);
                      //   this.doPublish();
                      // }}
                      >
                        Submit
                      </button>
                      <button
                        variant="secondary"
                        type="button"
                        className="btn gray-fill-btn"
                        onClick={this.props.onHide}
                      >
                        Cancel
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal.Body>
        </Modal>
        {this.state.sucessPropertyShow ? (
          <SuccessPublishedProperty
            show={this.state.sucessPropertyShow}
            onHide={this.handleHideSuccessModal}
          />
        ) : null}
      </>
    );
  }
}

// Customizable Area Start
export default TermsAndConditions;

// Customizable Area End
