// @ts-nocheck

import React from "react";
// Customizable Area Start
import ChatParentController, { Props } from "../ChatParentController.web";
import { imageAttachment } from "../../assets";
import moment from "moment";
import CounterOffer from "../CounterOffer.web";
// Customizable Area End
class IIIChatPropertyOfferItem extends ChatParentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        return (
            <>
                {(this.props.message?.attributes?.offer_data) &&
                    // <div class={`pay-wrap ${(this.props.message?.attributes?.offer_data?.data?.type == "buyer_offer"
                    //     && localStorage.getItem("role") == "buyer")
                    //     || (this.props.message?.attributes?.offer_data?.data?.type == "seller_offer"
                    //         && localStorage.getItem("role") == "seller")
                    //     ? "mv-r"
                    //     : "mv-l"}`}>
                    <div class={`pay-wrap ${this.props.message?.attributes?.account?.data?.attributes?.role == localStorage.getItem("role")
                        ? "mv-r"
                        : "mv-l"}`}>
                        <div className="payment-block">
                            <div className="head-block">
                                <div className="profile-block">
                                    <img src={localStorage.getItem("profile_image") ? localStorage.getItem("profile_image") : imageAttachment} alt="" />
                                </div>
                                <span className="profile-name">{`${localStorage.getItem("first_name")} ${localStorage.getItem("last_name")}`}</span>
                            </div>

                            <div className="content">
                                <div className="left-block">
                                    <img src={imageAttachment} alt="" />
                                </div>
                                <div className="right-block">
                                    <span className="heading">
                                        {this.props.message?.attributes?.message_type === "property_offer" ?
                                            this.props.message?.attributes?.account?.data?.attributes?.role == 'seller' ? 'Seller Made An Offer' : 'Buyer Made An Offer' : ''}

                                        {this.props.message?.attributes?.message_type === "property_counter_offer" ?
                                            this.props.message?.attributes?.account?.data?.attributes?.role == 'seller' ? 'Seller Made Counter Offer' : 'Buyer Made Counter Offer' : ''}

                                        {/* {this.props.message?.attributes?.message_type === "property_counter_offer" ? 'Seller' : 'Buyer'} Made Counter Offer */}
                                    </span>
                                    <div className="value-wrapper">
                                        <div className="title">Monthly payment : </div>
                                        <div className="value">${(+this.props.message?.attributes.offer_data?.data?.attributes?.property?.data?.attributes?.price - +this.props.message?.attributes.offer_data?.data?.attributes?.down_payment) / +this.props.message?.attributes.offer_data?.data?.attributes?.term_period}</div>
                                    </div>
                                    <div className="value-wrapper">
                                        <div className="title">Down payment : </div>
                                        <div className="value">${this.props.message?.attributes.offer_data?.data?.attributes?.down_payment}</div>
                                    </div>
                                    {console.log("________________________________this.props.message______________________>", this.props.message)}

                                    {this.props.message?.attributes?.offer_data?.data?.attributes?.status == "accepted" ? (
                                        <>
                                            <div className="btn-wrapper">
                                                <button type="button" className="btn orange-fill-btn"
                                                    disabled
                                                >
                                                    Accepted
                                                </button>
                                            </div>
                                        </>
                                    ) : Number(this.props.message?.attributes?.offer_data?.data?.attributes?.offerer_id) !== Number(localStorage.getItem("id")) ?
                                        <div className="btn-wrapper">
                                            <button type="button" className="btn orange-fill-btn"
                                                onClick={() => {
                                                    this.setState({ coversationId: this.props.message?.attributes?.conversation_id });
                                                    let buyerOfferId, counterOfferId;
                                                    if (this.props.message?.attributes?.message_type == 'property_counter_offer') {
                                                        buyerOfferId = this.props.message?.attributes.offer_data?.data?.attributes?.buyer_offer_id;
                                                        counterOfferId = this.props.message?.attributes.offer_data?.data?.id;
                                                    } else {
                                                        buyerOfferId = this.props.message?.attributes.offer_data?.data?.id;
                                                        counterOfferId = '';
                                                    }
                                                    this.doAcceptOffer(this.props.message?.attributes.offer_data?.data?.attributes?.property?.data?.id,
                                                        buyerOfferId, counterOfferId);

                                                }}
                                            >
                                                Accept
                                            </button>
                                            <button type="button" className="gray-border-btn"
                                                onClick={() => {
                                                    this.setState({ counterOfferShow: true })
                                                }}
                                            >
                                                Counter Offer
                                            </button>
                                        </div>
                                        : <div className="btn-wrapper">
                                            <button type="button" className="btn orange-fill-btn"
                                                disabled
                                            >
                                                Offered
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/* Counter Offer */}
                <CounterOffer
                    showCounterOfferModal={this.state.counterOfferShow}
                    onHideCounterOfferModal={this.onHideCounterOfferModal}
                    messageType={this.props.message?.attributes?.message_type}
                    counterId={this.props.message?.attributes.offer_data?.data?.id}
                    price={this.props.message?.attributes.offer_data?.data?.attributes?.property?.data?.attributes?.price}
                    downPayment={this.props.message?.attributes.offer_data?.data?.attributes?.down_payment}
                    coversationId={this.props.message?.attributes?.conversation_id}

                />
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default IIIChatPropertyOfferItem;
// Customizable Area End
