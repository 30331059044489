// @ts-nocheck
import React from "react";
// import { Link, withRouter } from "react-router-dom";
import { Link, withRouter, Route, Redirect } from "react-router-dom";

// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "./DashboardBuyerController.web";
import Header from "./Header.web";
import SidebarBuyer from "../../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../../components/src/Sidebar";
import {
  arrowButtonImg,
  roomImg,
  refreshImg,
  profieImg,
  replyImg,
  imageDownload
} from ".././assets";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBSimpleChart,
} from "mdbreact";
import { Doughnut } from "react-chartjs-2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { isEmpty } from "lodash";
import _ from 'lodash';
const AnyReactComponent = ({ text }) => <div>{text}</div>;

export interface Props { }
interface S {
  FilterFlag: any;
}
class BuyerDashboard extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return localStorage.getItem("role") == "seller" ? (
      <Route><Redirect to={{ pathname: '/dashboard-seller', state: { from: this.props?.location } }} /></Route>) : (
      <>
        <div className="flex">
          <div>
            {/* Slider  */}
            {localStorage.getItem("role") === "buyer" ? (
              <SidebarBuyer />
            ) : (
              <SidebarSeller />
            )}
          </div>
          <div className="stepper-outer">
            <Header
              flag={this.state.HeaderDropdownFlag}
              toggle={this.doToggleHeaderDropdown}
            />
            <div className="seller-dashboard buyer-dashboard">
              <div className="page-title">
                Welcome Back,
                {`${!isEmpty(localStorage.getItem("first_name")) &&
                  localStorage.getItem("first_name") !== "null"
                  ? localStorage.getItem("first_name")
                  : localStorage.getItem("email")?.split("@")[0]
                  } ${!isEmpty(localStorage.getItem("last_name")) &&
                    localStorage.getItem("last_name") !== "null"
                    ? localStorage.getItem("last_name")
                    : ""
                  }`}
              </div>
              {/* Welcome */}
              <div className="welcome-section">
                <h2>Welcome to Sparen!</h2>
                <div className="left-block">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim
                  </p>
                  <button
                    className="btn rounded-fill-btn"
                    onClick={() => this.props?.history?.push("/properties")}>
                    Search Property
                  </button>
                </div>
              </div>

              {/* Upcoming Payment */}
              <div className="section-card">
                <div className="sec-grid-item">
                  <h5 className="sec-grid-title">Upcoming Payment</h5>
                  <p className="sec-grid-text"> $4,000</p>
                </div>
                <div className="sec-grid-item">
                  <h5 className="sec-grid-title">Due Date</h5>
                  <p className="sec-grid-text">02/02/2020</p>
                </div>
                <div className="sec-grid-item">
                  <h5 className="sec-grid-title">To</h5>
                  <p className="sec-grid-text">John Doe</p>
                </div>
                <div className="sec-grid-item">
                  <h5 className="sec-grid-title">Description</h5>
                  <p className="sec-grid-text">Rent </p>
                </div>
                <div className="sec-card-btn">
                  <button className="btn rounded-fill-btn">View Details</button>
                </div>
              </div>

              {/* Billing Account */}
              <div className="content-block-wrapper">
                <div className="left-block">
                  <div className="block-title">
                    <h2>
                      Billing Account Details
                    </h2>
                    <div className="refresh-block">
                      <img src={refreshImg} className="grid-img" />
                      <span>Refresh</span>
                    </div>
                  </div>
                  <div className="detail-wrapper">
                    <h5 className="title">Account Holder Name</h5>
                    <p className="value">John Doe</p>
                  </div>
                  <div className="detail-wrapper">
                    <h5 className="title">Account Number</h5>
                    <p className="value">9876 5432 1123 1123</p>
                  </div>
                  <div className="detail-wrapper">
                    <h5 className="title">Bank Name</h5>
                    <p className="value">Citi Bank</p>
                  </div>
                  <div className="sec-card-btn">
                    <button className="btn rounded-fill-btn">
                      View Details
                    </button>
                  </div>
                </div>
                <div className="right-block">
                  <div className="block-wrapper">
                    <div className="left-block">
                      <div className="label">Rent Income</div>
                      <div className="value">$50,000</div>
                      <Link to="/" className="link-wrapper">
                        <span>Financial Overview</span>
                        <img src={arrowButtonImg} alt="" />
                      </Link>
                    </div>
                    <MDBContainer className="chart-wrapper">
                      <div>
                        <span className="content">January</span>
                        <Doughnut
                          data={this.state.DoughnutValue}
                          height="100px"
                          width="100px"
                        />
                      </div>
                    </MDBContainer>
                  </div>
                  <div className="block-wrapper">
                    <div className="left-block">
                      <div className="label">Rent Income</div>
                      <div className="value">$50,000</div>
                      <Link to="/" className="link-wrapper">
                        <span>Financial Overview</span>
                        <img src={arrowButtonImg} alt="" />
                      </Link>
                    </div>
                    <MDBContainer className="chart-wrapper">
                      <div>
                        <span className="content">January</span>
                        <Doughnut
                          data={this.state.DoughnutValue}
                          height="100px"
                          width="100px"
                        />
                      </div>
                    </MDBContainer>
                  </div>
                  <div className="rented-property-block">
                    <h2 className="grid-sec-title marb-16">
                      Rented Property
                    </h2>
                    <div className="detail-wrapper">
                      <div className="image-block">
                        <img src={roomImg} />
                      </div>
                      <div className="content">
                        <h5>37577 Susan St.</h5>
                        <p className="address-detail">
                          Sterling Heights, MI 48310
                        </p>
                        <div className="room-detail">
                          <span className="room-span">3 Beds</span>
                          <span className="room-span">3 Baths</span>
                          <span className="room-span">3,000 Sq ft</span>
                        </div>
                      </div>
                      <div className="btn-wrapper">
                        <button className="btn rounded-fill-btn">
                          View Details
                        </button>
                      </div>
                    </div>
                    <div className="display-payment-detail">
                      <div className="left-block">
                        <div className="detail-block">
                          <div className="label">Terms:</div>
                          <div className="value">10 Years</div>
                        </div>
                        <div className="detail-block">
                          <div className="label">Monthly Payment:</div>
                          <div className="value">$ 4,500</div>
                        </div>
                        <div className="detail-block">
                          <div className="label">Down Payment:</div>
                          <div className="value">$ 10,000</div>
                        </div>
                      </div>
                      <div className="right-block">
                        <div className="detail-block">
                          <div className="label">Seller Name :</div>
                          <div className="value">John Dow</div>
                        </div>
                        <div className="detail-block">
                          <div className="label">Agreement:</div>
                          <div className="value">
                            <button className="btn download-btn"><img src={imageDownload} alt="" /><span>Download</span></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Transaction */}
              <TableContainer className="custom-table-wrapper">
                <div className="top-block">
                  <p className="title">Transactions</p>
                  <button className="btn orange-fill-btn">View All</button>
                </div>
                <Table className="custom-table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Transaction ID</TableCell>
                      <TableCell>Note</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.rows.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row" >
                          {row.date}
                        </TableCell>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.note}</TableCell>
                        <TableCell
                          className={`${row.type === "debit" ? "red-text" : "green-text"
                            }`}
                        >
                          {row.amount}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Messages */}
              <div className="bottom-block">
                <div className="left-block">
                  <div className="head-block">
                    <h3 className="title">Messages</h3>
                    <button className="btn orange-fill-btn">View All</button>
                  </div>
                  <div className="content-wrapper">
                    <div className="content-block">
                      <div className="top-block">
                        <div className="block-detail">
                          <img src={profieImg} />
                          <div className="name-block">
                            <span>John Doe</span>
                            <p>Subject Name</p>
                          </div>
                        </div>
                        <span className="time-block">1:13pm</span>
                      </div>
                      <p className="info">
                        Lorem ipsu dolor sit amet, consecte udipiscing elit, sed
                        do eiusmod tempor incididunt ut
                      </p>
                      <span className="reply-image">
                        <img src={replyImg} />
                      </span>
                    </div>
                    <div className="content-block">
                      <div className="top-block">
                        <div className="block-detail">
                          <img src={profieImg} />
                          <div className="name-block">
                            <span>John Doe</span>
                            <p>Subject Name</p>
                          </div>
                        </div>
                        <span className="time-block">1:13pm</span>
                      </div>
                      <p className="info">
                        Lorem ipsu dolor sit amet, consecte udipiscing elit, sed
                        do eiusmod tempor incididunt ut
                      </p>
                      <span className="reply-image">
                        <img src={replyImg} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Content ENd */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(BuyerDashboard);
