//@ts-nocheck
import React from "react";

// Customizable Area Start
import { personImage, sparenLogo, homeImg, arrowImg } from "./assets";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
export const configJSON = require("./config");
// Customizable Area End

import CustomFacebookLogInButton from "../../social-media-account/src/FacebookLogInButton.web";
import CustomGoogleLogInButton from "../../social-media-account/src/GoogleLogInButton.web";

import SignupController, { Props } from "./SignupController.web";
import InstantVerification from "../../PlaidApiIntegrations/src/InstantVerification.web";

class Signup extends SignupController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine DefaultContainer
      <>
        <div className="login-wrapper">
          <div className="left-block">
            {/* logo */}
            <a href="#" className="logo-block">
              <img
                src={sparenLogo}
                alt="sparenLogo"
                className="sparen-signup-logo"
              />
            </a>
            <div className="prelogin-form sign-up-form">
              {/* Sign Up Div */}
              <h2 className="title">Sign Up</h2>
              <p className="subtitle">
                Already have an account?
                <a href="/signin" className="link-text">
                  Log In
                </a>
              </p>
              {/* social-media btn */}
              <div className="social-media-wrapper">
                <button className="social-media-icon apple-icon">
                  Sign in with Apple
                </button>
                <CustomFacebookLogInButton
                  testID="btnFacebookLogIn" //Merge Engine::From BDS
                  appId="170982444234877" //Merge Engine::From SDS
                  loginFacebookButtonText={configJSON.facebookButtonText} //UI Engine::From Sketch
                  {...this.btnFacebookLogInProps} //Merge Engine::From BDS - {...this.testIDProps}
                />

                <CustomGoogleLogInButton
                  testID="btnGoogleLogIn" //Merge Engine::From BDS
                  // style={styles.googleStyle} //UI Engine::From Sketch
                  loginGoogleButtonText={configJSON.googleButtonText} //UI Engine::From Sketch
                  // googleButtonImageStyle={styles.googleButtonImageStyle} //UI Engine::From Sketch
                  // googleButtonTextStyle={styles.googleButtonTextStyle} //UI Engine::From Sketch
                  {...this.btnGoogleLogInProps} //Merge Engine::From BDS - {...this.testIDProps}
                />
                {/* <button className="social-media-icon google-icon">
                  Sign in with Google
                </button>
                <button className="social-media-icon facebook-icon">
                  Sign in with Facebook
                </button> */}
              </div>
              <div className="signup-hr signup-light-grey">
                <span>OR</span>
              </div>
              {/* Formik */}
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  role: "",
                  activated: true,
                  termsConditions: false,
                }}
                validationSchema={Yup.object().shape(this.state.EmailSchema)}
                onSubmit={(values): any => {
                  this.doEmailSignUp(values);
                }}
                enableReinitialize
              >
                {({ values, setFieldValue, errors }) => (
                  //@ts-ignore
                  <Form>
                    <div className="field-wrapper">
                      <label className="input-label">Email Address</label>
                      <Field
                        type="text"
                        name="email"
                        placeholder={configJSON.signupplaceHolderEmail}
                        className="custom-input type-mail"
                      />
                      <span className="error">
                        <ErrorMessage name="email" />
                      </span>
                    </div>
                    <div className="field-wrapper">
                      <label className="input-label">Password</label>
                      <Field
                        type="password"
                        name="password"
                        placeholder={configJSON.placeHolderPassword}
                        className="custom-input type-password"
                      />
                      <span className="error">
                        <ErrorMessage name="password" />
                      </span>
                    </div>
                    <div className="thick-line" />
                    {/* {JSON.stringify(values, null, 2)}
                            {JSON.stringify(errors, null, 2)} */}
                    {/* signup-buy-btn */}
                    <div className="field-wrapper">
                      <p className="input-label">Account Type</p>
                      <div className="select-type">
                        <button
                          className={
                            values.role == "buyer"
                              ? "rounded-border-btn active"
                              : "rounded-border-btn"
                          }
                          onClick={(event) => {
                            event.preventDefault();
                            setFieldValue("role", "buyer");
                          }}
                        >
                          Buyer
                        </button>
                        <button
                          className={
                            values.role == "seller"
                              ? "rounded-border-btn active"
                              : "rounded-border-btn"
                          }
                          onClick={(event) => {
                            event.preventDefault();
                            setFieldValue("role", "seller");
                          }}
                        >
                          Seller
                        </button>
                      </div>
                      <span className="error">
                        <ErrorMessage name="role" />
                      </span>
                    </div>
                    {/* Checkbox  */}
                    <div className="checkbox-wrapper">
                      <label htmlFor="test1">
                        <Field
                          type="checkbox"
                          name="termsConditions"
                          className="input-checkbox"
                          id="test1"
                        />
                        By creating an account you agree to the{" "}
                        <a href="#" title="terms of use">
                          terms of use
                        </a>{" "}
                        and our{" "}
                        <a href="#" title="privacy policy">
                          privacy policy
                        </a>
                        .
                        <span className="checkmark" />
                      </label>
                      <span className="error">
                        <ErrorMessage name="termsConditions" />
                      </span>
                    </div>
                    {/* create-acc-btn */}
                    <div className="btn-wrapper">
                      <button type="submit" className="rounded-fill-btn">
                        Create Account
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              {/* <button
                type="button"
                className="rounded-fill-btn"
                onClick={() => {
                  this.setState({ instantVerifyShow: true });
                }}
              >
                Account
              </button> */}
            </div>
          </div>
          <div className="right-block">
            <img
              src={personImage}
              alt="personImage"
              className="signup-personImage"
            />
          </div>
        </div>
        {/* Role selection */}
        <Modal
          show={this.state.roleModalShow}
          onHide={this.toggleroleUpdateFlag}
          dialogClassName="verification-modal role-select"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div>
              <div className="modal-wrapper">
                <div className="image-wrapper">
                  <img src={homeImg} alt="cashIg" className="" />
                </div>
                <div className="content-wrapper">
                  <Formik
                    initialValues={{
                      role: "",
                    }}
                    validationSchema={Yup.object().shape(this.state.roleSchema)}
                    onSubmit={(values): any => {
                      this.doRoleUpdate(values);
                    }}
                    enableReinitialize
                  >
                    {({ values, setFieldValue, errors }) => (
                      //@ts-ignore
                      <Form>
                        <h1 className="head-title">Account Type</h1>
                        <p className="content">
                          Please select the account type
                        </p>
                        <div className="select-type">
                          <button
                            className={
                              values.role == "buyer"
                                ? "rounded-border-btn active"
                                : "rounded-border-btn"
                            }
                            onClick={(event) => {
                              event.preventDefault();
                              setFieldValue("role", "buyer");
                            }}
                          >
                            Buyer
                          </button>
                          <button
                            className={
                              values.role == "seller"
                                ? "rounded-border-btn active"
                                : "rounded-border-btn"
                            }
                            onClick={(event) => {
                              event.preventDefault();
                              setFieldValue("role", "seller");
                            }}
                          >
                            Seller
                          </button>
                        </div>
                        <div className="btn-wrapper">
                          <button
                            type="submit"
                            className="btn orange-fill-btn with-icon"
                          >
                            <span>Continue </span>
                            <img src={arrowImg} alt="arrowImg" className="" />
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* Instant Verification */}
        {/* {console.log("in signup", this.state.instantVerifyShow)} */}

        <InstantVerification show={this.state.instantVerifyShow} />

        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </>
      //Merge Engine End DefaultContainer
    );
  }
}

export default withRouter(Signup);
