// @ts-nocheck
import React from "react";

//Customizable Area Start

import { Link, withRouter } from "react-router-dom";
import Slider from "@material-ui/core/Slider";
import InstantVerificationController, {
  Props,
} from "./InstantVerificationController.web";
import { Modal, Button } from "react-bootstrap";
import { confettieImg, cashImg, arrowImg, logoImg, homeImg } from "./assets";
import InstantVerification from "./InstantVerification.web";
export const configJSON = require("./config");

//Customizable Area End

class InstantVerificationSuccess extends InstantVerificationController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  render() {
    return (
      <>
        {/*  <Modal
          show={true}
          dialogClassName="verification-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div>
              <div className="modal-wrapper">
                <div className="image-wrapper">
                  {this.state.completeFlag ? (
                    <img src={cashImg} alt="cashIg" className="" />
                  ) : (
                    <img src={confettieImg} alt="congettieImg" className="" />
                  )}
                  <ul className="dots-wrapper">
                    <li className={this.state.completeFlag ? '' : 'active'} />
                    <li className={this.state.completeFlag ? 'active' : ''} />
                  </ul>
                </div>
                <div className="content-wrapper">
                  <h1 className="head-title">
                    {this.state.completeFlag
                      ? configJSON.titleForContinue
                      : configJSON.titleForSuccess}
                  </h1>
                  <p className="content">
                    {this.state.completeFlag
                      ? configJSON.labelForContinue
                      : configJSON.labelForSuccess1}
                  </p>
                  {!this.state.completeFlag ? (
                    <p className="content">{configJSON.labelForSuccess2}</p>
                  ) : null}
                  <div className="btn-wrapper">
                   {!this.state.completeFlag ? (
		      <button
                      className="btn orange-fill-btn with-icon"
                      onClick={this.toggleCompleteFlag}
                    >
                      <span>Continue </span>
                      <img src={arrowImg} alt="arrowImg" className="" />
                    </button>):(<Link to="/dashboard-seller" className="btn rounded-fill-btn with-icon">
                      <img src={logoImg} alt="arrowImg" className="" />
			 <span>Search Homes</span>

			</Link>
		   )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal> */}

        {/* Successful Registered*/}

        <Modal
          show={true}
          dialogClassName="signup-success-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div>
              <div className="modal-wrapper">
                <div className="content-wrapper">
                  <h1 className="head-title">
                    {configJSON.titleForRegSuccess}
                  </h1>
                  <div className="image-wrap">
                    <img src={homeImg} alt="homeImg" />
                  </div>
                  <p className="sub-title">{configJSON.subTitleRegSuccess}</p>
                  <p className="content">{configJSON.labelForRegSuccess}</p>

                  <Slider
                    value={this.state.limit}
                    step={1}
                    scale={(x) => x * 1000}
                    aria-labelledby="discrete-slider-always"
                    getAriaValueText={this.setLimitLabel}
                    valueLabelFormat={this.setLimitLabel}
                    onChange={this.setLimitHandler}
                    valueLabelDisplay="on"
                  />

                  <div className="btn-wrapper">
                    <div
                      onClick={() =>
                        this.doSpendingPricing(this.state.limit * 1000)
                      }
                      // to={
                      //   localStorage.getItem("role") === "buyer"
                      //     ? "/dashboard-buyer"
                      //     : "/dashboard-seller"
                      // }
                      className="btn rounded-fill-btn with-icon"
                    >
                      <img src={logoImg} alt="logoImg" className="" />
                      <span>Search Homes</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
export default withRouter(InstantVerificationSuccess);
