// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "../DashboardBuyerController.web";
import PropertyVisitBuyer from "./PropertyVisitBuyer.web";
import InformationBuyer from "./InformationBuyer.web";
import PaymentBuyer from "./PaymentBuyer.web";
import MoveInDateBuyer from "./MoveInDateBuyer.web";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  buyerLogo,
  siginImage,
  iconAddCircle,
  iconRemoveCircle,
} from "../../assets";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import Slider from "@material-ui/core/Slider";
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from "yup";
// import TextField from "@material-ui/core/TextField";
// import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
// import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
// import StaticDatePicker from "@material-ui/lab/StaticDatePicker";

export interface Props { }
interface S {
  FilterFlag: any;
  notVisted: false;
}
class SchedulePropertyTour extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
    this.propertyid = new URLSearchParams(this.props.location.search);
    this.params = Object.fromEntries(this.propertyid.entries());
  }
  componentDidMount() {
    this.setState(
      {
        propertyId: +this.params.id,
        currentStep: this.props.location?.state?.step || 0,
        buyerAppValues: this.props.location?.state?.data
      },
      () => this.getPropertyDetail(this.state.propertyId)
    );
  }

  render() {
    return (
      <>
        <header className="site-header fixed-header buyer-application-header">
          <div className="container-main">
            <div className="left-header">
              <Link
                to={
                  localStorage.getItem("role") === "buyer"
                    ? "/dashboard-buyer"
                    : "/dashboard-seller"
                }
                className="page-logo"
                title="Sparen Home"
              >
                <img src={buyerLogo} alt="Logo" />
              </Link>
            </div>
            <div className="right-header">
              <Link to={`/property-detail?id=${this.state.propertyId}`}>Back</Link>
            </div>
            <div className="mobile-menu" onClick={this.toggleClass}>
              <ul className={this.toggleMenu ? "active" : ""}>
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
        </header>
        <section className="buyer-application-dashboard">
          <div className="container-main">
            <div className="step-wrapper">
              <div className="left-block">
                <img src={siginImage} alt="" />
              </div>
              <div className="right-block">
                <div className="not-visited">
                  {/* Step1 */}
                  {this.state.currentStep == 0 && (
                    <PropertyVisitBuyer
                      paymentValues={this.handleBuyerFormData}
                      currentStep={this.state.currentStep}
                      nextStep={this.handleNextStep}
                      previousStep={this.handlePreviousStep}
                      tourStep={this.handleCurrentStep}
                      fieldData={this.state.buyerAppValues}
                      propertyId={this.state.propertyId}
                    />
                  )}

                  {/* Step2 */}
                  {this.state.currentStep == 1 && (
                    <InformationBuyer
                      paymentValues={this.handleBuyerFormData}
                      currentStep={this.state.currentStep}
                      nextStep={this.handleNextStep}
                      previousStep={this.handlePreviousStep}
                      fieldData={this.state.buyerAppValues}
                    />
                  )}

                  {/* Step3 */}
                  {this.state.currentStep == 2 && (
                    <PaymentBuyer
                      paymentValues={this.handleBuyerFormData}
                      currentStep={this.state.currentStep}
                      nextStep={this.handleNextStep}
                      previousStep={this.handlePreviousStep}
                      price={+this.state.propertyDetails?.price}
                      fieldData={this.state.buyerAppValues}
                    />
                  )}

                  {/* Step4 */}
                  {this.state.currentStep == 3 && (
                    <MoveInDateBuyer
                      paymentValues={this.handleBuyerFormSubmit}
                      currentStep={this.state.currentStep}
                      nextStep={this.handleNextStep}
                      previousStep={this.handlePreviousStep}
                      fieldData={this.state.buyerAppValues}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>


      </>
    );
  }
}

export default withRouter(SchedulePropertyTour);
