// @ts-nocheck

import React from "react";
// Customizable Area Start
import ChatParentController, { Props } from "../ChatParentController.web";
// Customizable Area End
class IChatNormalItem extends ChatParentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        return (
            <>
                <div className="chat-message-wrapper">
                    <div className={`${localStorage.getItem("id") === this.props.message?.attributes?.account?.data?.id ? "recive-message" : "send-message"}`}>
                        <p>
                            {this.props.message?.attributes?.body}
                        </p>
                        <div className='attachment-img'>
                            {this.props.message?.attributes?.attachments?.map(data =>
                                <img src={data.url} alt="attachment" />
                            )}
                        </div>
                    </div>
                </div>

            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default IChatNormalItem;
// Customizable Area End
