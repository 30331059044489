import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import googleController, {
  GoogleWebDelegate,
} from "../../social-media-account/src/GoogleWebController";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
import { isEmpty } from "lodash";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  EmailSchema: any;
  loading: boolean;
  isRegistration: boolean;
  snackBar: {
    show: boolean;
    message?: string;
    type?: "success" | "info" | "warning" | "error" | undefined;
  };
  instantVerifyShow: boolean;
  roleModalShow: boolean;
  roleSchema: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SignupController extends BlockComponent<Props, S, SS>
  implements GoogleWebDelegate {
  // Customizable Area Start
  apiEmailSignUpCallId: string = "";
  validationApiCallId: string = "";
  apiRoleUpdateCallId: string = "";
  emailReg: RegExp;
  pwdReg: RegExp;
  labelTitle: string = "";

  createAccountAPICallId: any;
  googleUser: any;
  userProfileData: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.pwdReg = new RegExp(
      /^(?=.*[A-Z])(?=.*[#!@$&*?<>',\[\]}{=\-)(^%`~+.:;_])(?=.*[0-9])(?=.*[a-z]).{8,}$/
    );

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    let EmailSchema = {
      email: Yup.string()
        .email(configJSON.errorEmailNotValid)
        .required(configJSON.errorEmailRequired),
      password: Yup.string()
        .matches(this.pwdReg, configJSON.passwordValidation)
        .required(configJSON.errorSignupPasswordNotValid),
      role: Yup.string().required(configJSON.errorRoleRequired),
      termsConditions: Yup.bool().oneOf(
        [true],
        "Please Accept Terms & Conditions"
      ),
    };
    let roleSchema = {
      role: Yup.string().required(configJSON.errorRoleRequired),
    };

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      EmailSchema: EmailSchema,
      loading: false,
      isRegistration: false,
      snackBar: {
        show: false,
      },
      instantVerifyShow: false,
      roleModalShow: false,
      roleSchema: roleSchema,
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  //When google sends back the reponse this gets called
  googleUserStatusChanged(userInfo: any): void {
    if (this.state.isRegistration) {
      this.createAccountFromSocial(userInfo.email, userInfo.id, this.props);
    } else {
      this.logInWithSocial(
        userInfo.email,
        userInfo.email,
        userInfo.id,
        this.props
      );
    }
  }

  startLoading = () => {
    this.setState({ loading: true });
  };

  stopLoading = () => {
    this.setState({ loading: false });
  };

  responseFacebook = (response: any) => {
    this.stopLoading();
    if (this.state.isRegistration) {
      this.createAccountFromSocial(response.email, response.id, this.props);
    }
    runEngine.debugLog(response);
  };

  googleLogIn = (isRegistration: boolean) => {
    const self = this;
    //@ts-ignore
    googleController.googleLogIn(this).then(
      function() {
        self.stopLoading();
        runEngine.debugLog("User SIGNED IN.");
      },
      function(error: any) {
        self.stopLoading();
        if (error.error === "popup_closed_by_user") {
          //handle window closed event
        }
      }
    );
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.validationApiCallId &&
          responseJson !== undefined
        ) {
          var arrayholder = responseJson.data;

          if (arrayholder && arrayholder.length !== 0) {
            let regexData = arrayholder[0];

            if (regexData && regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        }
        if (responseJson && !responseJson.errors) {
          if (apiRequestCallId === this.apiEmailSignUpCallId) {
            if (responseJson.meta && responseJson.meta.token) {
              runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
              // this.saveLoggedInUserData(responseJson);
              // this.sendLoginSuccessMessage();
              // this.openInfoPage();
              this.openSnackBarHandler("success", configJSON.successSignUp);
              localStorage.clear();
              localStorage.setItem("id", responseJson.data.id);
              localStorage.setItem("auth", responseJson.meta.token);
              localStorage.setItem("role", responseJson.data.attributes.role);
              localStorage.setItem("email", responseJson.data.attributes.email);
              this.setState({ instantVerifyShow: true });
              // @ts-ignore
              // this.props.history.push({
              //   pathname: "instant-verification",
              //   state: {
              //     role: responseJson.data.attributes.role,
              //     token: responseJson.meta.token,
              //   },
              // });
            }
          }

          //Social Media
          if (
            apiRequestCallId === this.createAccountAPICallId &&
            responseJson !== undefined
            // this.createAccountAPICallId != null &&
            // apiRequestCallId === this.createAccountAPICallId
          ) {
            if (responseJson.meta && responseJson.meta.token) {
              this.openSnackBarHandler("success", configJSON.successSignUp);

              localStorage.setItem("id", responseJson.data.id);
              localStorage.setItem("auth", responseJson.meta.token);
              localStorage.setItem("email", responseJson.data.attributes.email);
              localStorage.setItem(
                "first_name",
                responseJson.data.attributes.first_name
              );
              localStorage.setItem(
                "last_name",
                responseJson.data.attributes.last_name
              );
              this.toggleroleUpdateFlag();

              // localStorage.setItem("role", "seller");
              //localStorage.setItem('auth', responseJson.meta.token)
              // @ts-ignore
              // this.props.history.push("instant-verification");
              // this.saveLoggedInUserData(responseJson);
              // this.setState({ instantVerifyShow: true });
              // console.log(" this.userProfileData", this.userProfileData);
            }
          }
          // Role Update API
          if (
            apiRequestCallId === this.apiRoleUpdateCallId &&
            responseJson !== undefined
          ) {
            this.openSnackBarHandler("success", configJSON.successLogin);
            localStorage.setItem("role", responseJson.data.attributes.role);
            this.toggleroleUpdateFlag();
            this.setState({ instantVerifyShow: true });
          }
        } else {
          //Check Error Response
          this.parseApiCatchErrorResponse(errorReponse);
          // this.parseApiErrorResponse(responseJson);
          //this.sendLoginFailMessage();
          const errors = responseJson.errors;
          // localStorage.setItem("auth", "");
          if(errors?.[0]?.account){
          //@ts-ignore
          this.openSnackBarHandler("error", errors?.[0]?.account);
          }     
          if(errors?.[0]?.email){
            //@ts-ignore
            this.openSnackBarHandler("error", errors?.[0]?.email);
          }     

          // this.props.history.push("/signup");
        }
      }
    }

    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };

  doEmailSignUp = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.signUpApiContentType,
    };

    const attrs = {
      email: values.email,
      password: values.password,
      role: values.role,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailSignUpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  async createAccountFromSocial(
    incomingEmail: String,
    incomingId: String,
    props: Props
  ) {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": configJSON.urlHeaderTypeJSON,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupAPiEndPoint
    );

    const data = {
      type: "social_account",
      attributes: {
        first_name:
          !isEmpty(localStorage.getItem("first_name")) &&
          localStorage.getItem("first_name") !== "null"
            ? localStorage.getItem("first_name")
            : "",
        last_name:
          !isEmpty(localStorage.getItem("last_name")) &&
          localStorage.getItem("last_name") !== "null"
            ? localStorage.getItem("last_name")
            : "",
        email: incomingEmail,
        unique_auth_id: incomingId,
      },
    };

    const httpBody = {
      data: data,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async logInWithSocial(
    incomingEmail: string,
    incomingPassword: string,
    incomingId: string,
    props: Props
  ) {
    if (
      !incomingEmail ||
      incomingEmail.length === 0 ||
      !incomingId ||
      incomingId.length === 0
    ) {
      runEngine.debugLog("createAccountFromSocial empty info");
      return;
    }

    this.startLoading();

    const header = {
      "Content-Type": "application/json",
    };

    const attrs = {
      email: incomingEmail,
      password: incomingPassword,
      unique_auth_id: incomingId,
    };

    const data = {
      type: "social_account",
      attributes: {
        first_name:
          !isEmpty(localStorage.getItem("first_name")) &&
          localStorage.getItem("first_name") !== "null"
            ? localStorage.getItem("first_name")
            : "",
        last_name:
          !isEmpty(localStorage.getItem("last_name")) &&
          localStorage.getItem("last_name") !== "null"
            ? localStorage.getItem("last_name")
            : "",
        email: incomingEmail,
        unique_auth_id: incomingId,
      },
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // navigate() {
  //   runEngine.debugLog("this.isRegistration");
  //   runEngine.debugLog(this.state.isRegistration);
  //   if (this.state.isRegistration) {
  //     runEngine.debugLog("Registration");
  //     runEngine.debugLog(this.state.isRegistration);
  //     this.navigateToSignup();
  //   } else {
  //     runEngine.debugLog("Registration");
  //     runEngine.debugLog(this.state.isRegistration);
  //     this.navigateToLogin();
  //   }
  // }

  // navigateToSignup() {
  //   const msg: Message = new Message(
  //     getName(MessageEnum.NavigateEmailSignUpMessage)
  //   );
  //   msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  //   this.send(msg);
  // }

  // navigateToLogin() {
  //   const msg: Message = new Message(
  //     getName(MessageEnum.NavigationEmailLogInMessage)
  //   );
  //   msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  //   this.send(msg);
  // }

  // saveLoggedInUserData(responseJson: any) {
  //   if (responseJson && responseJson.meta && responseJson.meta.token) {
  //     const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

  //     msg.addData(
  //       getName(MessageEnum.SessionResponseData),
  //       JSON.stringify(responseJson)
  //     );

  //     msg.addData(
  //       getName(MessageEnum.SessionResponseToken),
  //       responseJson.meta.token
  //     );

  //     this.send(msg);
  //   }
  // }

  // openInfoPage() {
  //   const msg = new Message(
  //     getName(
  //       this.state.isRegistration
  //         ? MessageEnum.AccoutResgistrationSuccess
  //         : MessageEnum.AccoutLoginSuccess
  //     )
  //   );
  //   msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  //   this.send(msg);
  // }

  //Create response callback.
  responseInfoCallback(error: any, result: any) {
    if (error) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorDescription + error.toString()
      );
    } else {
      runEngine.debugLog("Result Name: " + result.name);
    }
  }

  btnFacebookLogInProps = {
    onPress: () => {
      this.startLoading();
    },
    callback: this.responseFacebook,
  };

  btnGoogleLogInProps = {
    onPress: () => {
      this.googleLogIn(this.state.isRegistration);
      this.startLoading();
    },
  };

  // btnNavigateProps = {
  //   onPress: () => this.navigate(),
  // };

  doRoleUpdate = (values: any) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: localStorage.getItem("auth"),
    };

    const attrs = {
      role: values.role,
    };

    const httpBody = {
      data: attrs,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRoleUpdateCallId = requestMessage.messageId;

    const endpoint = configJSON.roleAPiEndPoint;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  toggleroleUpdateFlag = () => {
    this.setState({
      ...this.state,
      roleModalShow: !this.state.roleModalShow,
    });
  };
}
