//@ts-nocheck
import React from "react";
// Customizable Area Start
import {
  sliderProperty1,
  sliderProperty2,
  sortIconWhite,
  checked,
  noImgAvailable
} from "../../assets";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { PlaidLink } from "react-plaid-link";
import Checklist from "../../Popups/Checklist.web";
import HandOverController, { Props } from "../HandOverController.web";
import PropertyItemDetail from "../../Popups/PropertyItemDetail.web";
// Customizable Area End

class PropertyUnderContractItem extends HandOverController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  async componentDidMount() {
    this.getContractProperty();
  }

  render() {
    // Customizable Area Start
    console.log(this.props.accDetail, "this.props.accDetail")
    return (
      <>
        {
          this.state.underContractProperty.map((property) => (
            <div className="list-block" onClick={localStorage.getItem("role") == "seller" && this.handleShowPropItemDetail}>
              <div className="property-detail">
                <div className="image-block">
                  <img src={property.attributes.images[0] ? property.attributes.images[0].url : noImgAvailable} alt="propertyImg" />
                </div>
                <div className="content">
                  <p className="title">{property.attributes.home_type.title}</p>
                  <p className="sub-title">{`${property.attributes.address.city}, ${property.attributes.address.state} ${property.attributes.address.zip_code}`}</p>
                  <span className="status under-process">Under Process</span>
                </div>
              </div>
              <div className="tab-wrapper prop-list">
                <p className="title" style={{ position: "relative", bottom: "40px", left: "60px", color: "#ff8800" }}>
                  {property.attributes.agreement.agreement_status === "todo" && property.attributes.checklist.checklist === "" && property.attributes.payment.payment_status === "" ? "Offer is accepted. Please sign agreement and then proceed further." : ""}
                  {property.attributes.agreement.agreement_status === "done" && property.attributes.checklist.checklist === "todo" ? "Finish Checklist before payment" : ""}
                  {property.attributes.agreement.agreement_status === "done" && property.attributes.checklist.checklist === "done" ? "Checklist successfull you can do payment now" : ""}
                </p>
                {/* <Link to="/agreement"> */}
                <div className={property.attributes.agreement.agreement_status === "todo" ? "status-tab in-progress" : "status-tab done stat-middle"}
                  onClick={() => {
                    let propertyAddress = property?.attributes?.address?.address_line + property?.attributes?.address?.address_line_2 +
                      ", " + property?.attributes?.address?.city + ", " + property?.attributes?.address?.state + ', ' + property?.attributes?.address?.zip_code;
                    let date = new Date();
                    let agreementData = {
                      dateOfAgreement: new Date(),
                      sellerId: property?.attributes?.seller_account_details?.data?.id,
                      sellerAddress: property?.attributes?.seller_account_details?.data?.data?.attributes?.country_code,
                      buyerId: property?.attributes?.buyer_account_details?.data?.id,
                      buyerAddress: property?.attributes?.buyer_account_details?.data?.attributes?.country_code,
                      propertyAddress: propertyAddress,
                      purchasePrice: property?.attributes?.price,
                      earnestMoney: property?.attributes?.min_down_payment,
                      closingDate: date.setDate(date.getDate() + 10),
                      govLaw: ""
                    }
                    this.doAgreement(agreementData);

                  }}
                >Agreement</div>
                {/* </Link> */}
                <div className={property.attributes.checklist.checklist === "todo" ? "status-tab in-progress" : "status-tab done stat-middle"} onClick={() => this.handleCheckList(property?.id)}>
                  {property.attributes.agreement.agreement_status === "done" || property.attributes.checklist.checklist === "done" || property.attributes.payment.payment_status === "done" ? <img src={checked} /> : null}Check List</div>

                {property.attributes.payment.payment_status === "todo" ?
                  <div className={property.attributes.payment.payment_status === "todo" ? "status-tab in-progress" : "status-tab done stat-middle"} onClick={this.getAccountDetail}>
                    {property.attributes.payment.payment_status === "done" || property.attributes.payment.payment_status === "done" || property.attributes.payment.payment_status === "done" ? <img src={checked} /> : null}Pay now</div>
                  : <div className="status-tab">Payment</div>}
              </div>
            </div>

          ))
        }

        <div className="right-block">
          <p className="title">Payment Details</p>
          <Formik
            initialValues={{
              downpayAcc: "",
              monthlyAcc: ""
            }}
            // validationSchema={Yup.object().shape(this.state.agreementSchema)}
            onSubmit={(values) => {

              this.setState({
                agreementState: {
                  billingDetail: true,
                  paymentMethod: true
                }
              });
              this.setState({
                completeAgreementState: {
                  billing: true,
                  payment: true
                }
              });
              this.setState({ sucessAgreementShow: true });
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <div className="block-wrapper">
                  <div className="left-block">
                    <span className="label">Down Payment</span>
                    <span className="value">$12,750</span>
                  </div>
                  {!isEmpty(this.props.accDetail?.bank_accounts) ?
                    (<div className="right-block">
                      <div className="field-wrapper">
                        <Field
                          type="text"
                          as="select"
                          name="downpayAcc"
                          value={values.downpayAcc ? values.downpayAcc : "Select Account"}
                          autoComplete="off"
                          className="custom-input secondary-input "
                        >
                          <option value="Select Account">
                            Select Account
                          </option>
                          {this.props.accDetail?.bank_accounts?.data.map((acc, i) => (
                            <option value={acc?.attributes?.institution_name} key={i}>
                              {acc?.attributes?.institution_name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>) : ""
                  }
                </div>
                {isEmpty(this.props.accDetail?.bank_accounts) ?
                  (<div className="link-bank">
                    {/* <PlaidLink
                                className="btn orange-fill-btn"
                                token={this.state.token}
                                onSuccess={this.onSuccess}
                                onExit={() => console.log("Exit")}
                                onEvent={() => console.log("Event")}
                              >
                                Link Bank Account
                              </PlaidLink> */}
                  </div>) : ""
                }
                <div className="block-wrapper">
                  <div className="left-block">
                    <span className="label">Monthly Payment</span>
                    <span className="value">$12,750</span>
                  </div>
                  {!isEmpty(this.props.accDetail?.bank_accounts) ?
                    (<div className="right-block">
                      <div className="field-wrapper">
                        <Field
                          type="text"
                          as="select"
                          name="monthlyAcc"
                          value={values.monthlyAcc ? values.monthlyAcc : "Select Account"}
                          autoComplete="off"
                          className="custom-input secondary-input "
                        >
                          <option value="Select Account">
                            Select Account
                          </option>
                          {this.props.accDetail?.bank_accounts?.data.map((acc, i) => (
                            <option value={acc?.attributes?.institution_name} key={i}>
                              {acc?.attributes?.institution_name}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>) : ""
                  }
                </div>
                {isEmpty(this.props.accDetail?.bank_accounts) ?
                  (<div className="link-bank">
                    {/* <PlaidLink
                                className="btn orange-fill-btn"
                                token={this.state.token}
                                onSuccess={this.onSuccess}
                                onExit={() => console.log("Exit")}
                                onEvent={() => console.log("Event")}
                              >
                                Link Bank Account
                              </PlaidLink> */}
                  </div>) : ""
                }
                <div className="btn-wrapper">
                  <button className="rounded-fill-btn" type="submit" disabled={!values.downpayAcc || !values.monthlyAcc}>
                    Pay Now
                  </button>
                  <span className="more-detail">
                    Notes : Pay for Property
                  </span>
                </div>
              </Form>
            )}
          </Formik>
        </div>


        {/* <div className="list-block">
                    <div className="property-detail">
                        <div className="image-block">
                            <img src={sliderProperty1} alt="" />
                        </div>
                        <div className="content">
                            <p className="title">37577 Susan St.</p>
                            <p className="sub-title">Sterling Heights, MI 48310</p>
                            <span className="status on-rent">On Rent</span>
                        </div>
                    </div>
                    <div className="tab-wrapper prop-list">
                        <div className="status-tab in-progress maintanance">Maintanance Request</div>
                    </div>

                </div> */}
        <Checklist checkListShow={this.state.showCheckList} handleCheckList={this.handleCheckList} amanitiesList={this.state.amanitiesList} />
        <PropertyItemDetail propItemDetailShow={this.state.showPropItemDetail} handlepropItemDetailShow={this.handleShowPropItemDetail} />
      </>
    );
    // Customizable Area End       
  }
}

// Customizable Area Start
export default withRouter(PropertyUnderContractItem);

// Customizable Area End
