// @ts-nocheck

import React from "react";
// Customizable Area Start
import { withRouter, Link } from "react-router-dom";
import HomepagemainController, { Props } from "./HomepagemainController.web";
import {
  footerLogo,
  fbImage,
  instaImage,
  twitterImage,
  logoWhite,
} from "./assets";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
export const configJSON = require("./config.js");
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
class Homepagemain extends HomepagemainController {
  // toggleMenu: boolean = false;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  // toggleClass = () => {
  //   this.toggleMenu = !this.toggleMenu;
  //   document.body.classList.toggle("menu-open");
  //   this.forceUpdate();
  // };
  render() {
    return (
      //Merge Engine DefaultContainer
      <>
        <header className="site-header pre-login">
          <div className="container-main">
            <div className="left-header">
              <Link to="" className="page-logo" title="Sparen Home">
                <img src={logoWhite} alt="Logo" />
              </Link>
            </div>
            <div className="right-header">
              <div className="link-wrapper">
                <ul>
                <li onClick={()=>{
                      //@ts-ignore
                      this.props.history.push("home-page-buyer");
                      this.toggleClass();
                    }}>
                      {/* <Link to="/home-page-buyer" title="Buy"> */}
                       <a> Buy</a>
                      {/* </Link> */}
                    </li>
                    <li onClick={()=>{
                      //@ts-ignore
                      this.props.history.push("home-page-seller");
                      this.toggleClass();
                    }}>
                      {/* <Link
                        to="/home-page-seller"
                        title="Sell"
                        className="active"
                      > */}
                       <a>Sell</a> 
                      {/* </Link> */}
                    </li>
                    <li onClick={()=>{
                      //@ts-ignore
                      this.props.history.push(this.props?.location?.pathname);
                      this.toggleClass();
                    }}>
                      {/* <Link to={this.props?.location?.pathname} title="How It Works"> */}
                      <a>How It Works</a>
                      {/* </Link> */}
                    </li>
                  
                  <li>
                    <Link to="/signin" title="Log in">
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link to="/signup">
                      <button className="rounded-fill-btn blue-fill">
                        Sign Up
                      </button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mobile-menu" onClick={this.toggleClass}>
              <ul className={this.toggleMenu ? "active" : ""}>
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
        </header>
        <div className="home-banner-wrapper">
          <div className="container-main">
            <div className="content-block">
              <h1>Welcome Home</h1>
              <input
                className="search-input"
                placeholder="Search by City, State, or Zip Code"
                onChange={(e) => this.searchChange(e.target.value)}
              />
              <button
                className="btn orange-fill-btn"
                onClick={() => this.doSearch()}
              >
                Find Homes
              </button>
              <p>
                Fast.Simple.Flexible <br /> Real estate without hassle.
              </p>
            </div>
          </div>
        </div>
        <footer>
          <div className="container-main">
            <div className="left-footer">
              <div className="logo">
                <i>
                  <img
                    src={footerLogo}
                    alt="footerLogo"
                    className="footer-logo"
                  />
                </i>
                <span>
                  KEEP UP WITH THE <br /> NEIGHBORHOOD.
                </span>
              </div>
                  <Formik
              initialValues={{      
                email : "",
              }}
              validationSchema={Yup.object().shape({
                  email: Yup.string().email(configJSON.errorEmailNotValid).required(configJSON.errorEmailReq),
              })}
              onSubmit={(values) => this.doSubscribe(values)}
              enableReinitialize
              >
              {({ values, setFieldValue, errors }) => (
                <Form className="form-wrapper fill-detail">
              <div className="field-wrapper">
                 <Field
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  className="custom-input type-mail mb-2"
                />
                  <div className="error ml-3">
                    <ErrorMessage name="email" />
                  </div>
                <button className="btn white-fill-btn mt-3" type="submit">
                  SUBSCRIBE
                </button>
              </div>
                </Form> )}
              </Formik>
            </div>
            <div className="right-footer">
              <div className="footer-links">
                <h3>COMPANY</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="About us">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="How it Works">
                      How it Works
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Referrals">
                      Referrals
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Careers">
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Contact Us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links">
                <h3>SOLUTIONS</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Buy A Home">
                      Buy A Home
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Sell A Home">
                      Sell A Home
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Keeper">
                      Keeper
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links">
                <h3>RESOURCES</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Blog">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Support">
                      Support
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Partners">
                      Partners
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links larg-width">
                <h3>BUYERS</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Recently Listed">
                      Recently Listed
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="How It Works">
                      How It Works
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Savings Calculator">
                      Savings Calculator
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="SafeSpend Estimator">
                      SafeSpend Estimator
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Buyer FAQ">
                      Buyer FAQ
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links">
                <h3>SELLERS</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Recently Sold">
                      Recently Sold
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="How It Works">
                      How It Works
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Savings Calculator">
                      Savings Calculator
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Seller FAQ">
                      Seller FAQ
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bottom-footer">
              <div className="media-icon">
                <div className="icon-wrapper">
                  <img src={instaImage} alt="Instagram Logo" />
                </div>
                <div className="icon-wrapper facebook-icon">
                  <img src={fbImage} alt="Facebook Logo" />
                </div>
                <div className="icon-wrapper">
                  <img src={twitterImage} alt="Twitter Logo" />
                </div>
              </div>
              <p className="copy-right">&copy;THE SPAREN COMPANY 2021</p>
              <div className="link-wrapper">
                <Link to={this.props?.location?.pathname} title="TERMS OF SERVICE">
                  TERMS OF SERVICE
                </Link>
                <Link to={this.props?.location?.pathname} title="PRIVACY POLICY">
                  PRIVACY POLICY
                </Link>
              </div>
            </div>
          </div>
        </footer>

        {/* Snackbar for display success and failed messages. */}
          <Snackbar
            open={this.state.snackBar.show}
            autoHideDuration={3000}
            onClose={this.closeSnackBarHandler}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.closeSnackBarHandler}
              severity={this.state.snackBar.type}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar>
      </>
      //Merge Engine End DefaultContainer
    );
  }
}
// @ts-ignore
export default withRouter(Homepagemain);

// Customizable Area Start

// Customizable Area End
