// @ts-nocheck
// Customizable Area Start
import React from "react";
import { forgetImg, siginImage, sparenLogo, imagePassword } from "./assets";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Modal } from "react-bootstrap";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AppLoader from "../../../components/src/AppLoader.web";

import * as Yup from "yup";
export const configJSON = require("./config");
//@ts-ignore
// Customizable Area End

import ForgotPwd from "./forgot-password/ForgotPwd.web";
import SigninController, { Props } from "./SigninController.web";
import ForgotPasswordController from "../../forgot-password/src/ForgotPasswordController.web"; // Props,

class Signin extends SigninController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      //Merge Engine DefaultContainer
      <>
        <div>
          <div className="login-wrapper">
            <div className="left-block">
              {/* logo */}
              <a href="/" className="logo-block">
                <img
                  src={sparenLogo}
                  alt="sparenLogo"
                  className="sparen-signup-logo"
                />
              </a>
              <div className="prelogin-form">
                {/* Sign Up Div */}
                <h2 className="title">Log in</h2>
                <p className="subtitle">
                  Don't have an account?
                  <a href="/signup" className="link-text">
                    Sign Up
                  </a>
                </p>
                {/* Formik */}
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                    rememberMe: false,
                  }}
                  validationSchema={Yup.object().shape(this.state.EmailSchema)}
                  onSubmit={(values): any => this.doEmailLogIn(values)}
                  enableReinitialize
                >
                  {({ values, setFieldValue, errors }) => (
                    //@ts-ignore
                    <Form>
                      <div className="field-wrapper">
                        <label className="input-label">Email Address</label>
                        <Field
                          type="text"
                          name="email"
                          placeholder={configJSON.placeHolderEmail}
                          className="custom-input type-mail"
                        />
                        <span className="error">
                          <ErrorMessage name="email" />
                        </span>
                      </div>
                      <div className="field-wrapper">
                        <label className="input-label">Password</label>
                        <Field
                          type="password"
                          name="password"
                          placeholder={configJSON.placeHolderPassword}
                          className="custom-input type-password"
                        />
                        <span className="error">
                          <ErrorMessage name="password" />
                        </span>
                      </div>
                      <div className="checkbox-wrapper">
                        <label htmlFor="test1">
                          <input
                            type="checkbox"
                            id="test1"
                            name="rememberMe"
                            className="input-checkbox"
                            onClick={() => {
                              setFieldValue("rememberMe", !values.rememberMe);
                            }}
                          />
                          Keep me signed in
                          <span className="checkmark" />
                        </label>
                      </div>
                      {/* {JSON.stringify(values, null, 2)}
                            {JSON.stringify(errors, null, 2)} */}
                      {/* Login-acc-btn */}
                      <div className="btn-wrapper">
                        <button type="submit" className="rounded-fill-btn">
                          Log in
                        </button>
                        <button
                          type="button"
                          className="forgot-password"
                          onClick={() => this.toggleForgetFlag()}
                        >
                          Forgot password?
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="right-block">
              <img
                src={siginImage}
                alt="siginImage"
                className="signup-personImage"
              />
            </div>
          </div>
          {/* Snackbar for display success and failed messages. */}
          <Snackbar
            open={this.state.snackBar.show}
            autoHideDuration={3000}
            onClose={this.closeSnackBarHandler}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.closeSnackBarHandler}
              severity={this.state.snackBar.type}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar>

          {/* Forgot Password  */}
          <Modal
            show={this.state.forgetFlag}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <div className="modal-content-wrapper">
                <div className="image-block">
                  <img src={forgetImg} alt="forgetImg" className="" />
                </div>
                <div className="content-block">
                  <h3>Forgot Password?</h3>
                  <p className="discription-text">
                    Please enter your registered email address.
                  </p>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={Yup.object().shape(
                      this.state.emailSchema
                    )}
                    onSubmit={(values) => this.doForgotPassword(values)}
                    enableReinitialize
                  >
                    {({ values, setFieldValue, errors }) => (
                      //@ts-ignore
                      <Form>
                        <div className="field-wrapper">
                          <Field
                            type="text"
                            name="email"
                            placeholder={configJSON.placeHolderEmail}
                            className="custom-input type-mail"
                          />
                          <span className="error">
                            <ErrorMessage name="email" />
                          </span>
                        </div>
                        <div className="btn-wrapper">
                          <button
                            type="button"
                            className="btn gray-fill-btn"
                            onClick={() => this.toggleForgetCancelFlag()}
                          >
                            Cancel
                          </button>
                          <button type="submit" className="rounded-fill-btn">
                            Next
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Verify Password  */}
          <Modal
            show={this.state.verifyFlag}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <div className="modal-content-wrapper">
                <div className="image-block">
                  <img src={forgetImg} alt="forgetImg" className="" />
                </div>
                <div className="content-block">
                  <h3>Verify Email</h3>
                  <p className="discription-text">
                    Please enter your verification code sent on your registered
                    email address.
                  </p>
                  <Formik
                    initialValues={{
                      otp: "",
                    }}
                    validationSchema={Yup.object().shape(
                      this.state.verifyOtpSchema
                    )}
                    onSubmit={(values) => this.doVerifyOtps(values)}
                    enableReinitialize
                  >
                    {({ values, setFieldValue, errors }) => (
                      //@ts-ignore
                      <Form>
                        <div className="field-wrapper">
                          <Field
                            type="number"
                            name="otp"
                            placeholder={configJSON.verifyCodeHolder}
                            className="custom-input"
                          />
                          <span className="error">
                            <ErrorMessage name="otp" />
                          </span>
                        </div>
                        <div className="btn-wrapper">
                          <button
                            type="button"
                            className="btn gray-fill-btn"
                            onClick={() => this.toggleForgetFlag()}
                          >
                            Back
                          </button>
                          <button type="submit" className="rounded-fill-btn">
                            Next
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Confirm Password  */}
          <Modal
            show={this.state.createPwdFlag}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <div className="modal-content-wrapper">
                <div className="image-block">
                  <img src={forgetImg} alt="forgetImg" className="" />
                </div>
                <div className="content-block">
                  <h3>Create New Password</h3>
                  <p className="discription-text">
                    Create new password which must contain 8 digit.
                  </p>
                  <Formik
                    initialValues={{
                      password: "",
                      confirmPassword: "",
                    }}
                    validationSchema={Yup.object().shape(
                      this.state.passwordSchema
                    )}
                    onSubmit={(values) => this.doCreatePassword(values)}
                    enableReinitialize
                  >
                    {({ values, setFieldValue, errors }) => (
                      //@ts-ignore
                      <Form>
                        <div className="fg-form-wrapper">
                          <div className="field-wrapper no-margin">
                            <Field
                              type="text"
                              name="password"
                              placeholder={configJSON.placeHolderNewPwd}
                              className="custom-input"
                            />
                            <span className="error">
                              <ErrorMessage name="password" />
                            </span>
                          </div>
                          <div className="field-wrapper">
                            <Field
                              type="password"
                              name="confirmPassword"
                              placeholder={configJSON.placeHolderConfirmPwd}
                              className="custom-input"
                            />
                            <span className="error">
                              <ErrorMessage name="confirmPassword" />
                            </span>
                          </div>
                        </div>
                        {/* {JSON.stringify(values, null, 2)}
                              {JSON.stringify(errors, null, 2)} */}
                        <div className="btn-wrapper">
                          <button
                            type="button"
                            className="btn gray-fill-btn"
                            onClick={() => this.toggleVerifyFlag()}
                          >
                            Back
                          </button>
                          <button type="submit" className="rounded-fill-btn">
                            Next
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Password Updated successfully  */}
          <Modal
            show={this.state.updatedFlag}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <div className="modal-content-wrapper">
                <div className="image-block">
                  <img src={forgetImg} alt="forgetImg" className="" />
                </div>
                <div className="content-block">
                  <i className="icon-wrapper">
                    <img src={imagePassword} alt="password icon" />
                  </i>
                  <h3>Password Updated</h3>
                  <p className="discription-text">
                    Your password is updated successfully.
                    <br />
                    Now you can log in with new password.
                  </p>
                  <div className="btn-wrapper">
                    <button
                      type="submit"
                      className="rounded-fill-btn"
                      onClick={() => {
                        this.toggleCloseFlag();
                      }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* Loader  */}
          {this.state.loader && <AppLoader title="Loading" />}
        </div>
      </>
      //Merge Engine End DefaultContainer
    );
  }
}
export default withRouter(Signin);
