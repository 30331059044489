// @ts-nocheck

import { Formik, Form, Field, ErrorMessage } from "formik";
import React from "react";
// Customizable Area Start
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { clockImg, videoImg } from ".././assets";

// import { Link, withRouter } from "react-router-dom";

// Customizable Area End
import DashboardBuyerController, {
  Props,
} from "./DashboardBuyerController.web";

class ScheduleTour extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      <Modal
        show={this.props.showTourModalProp}
        onHide={this.props.onHideTourModal}
        dialogClassName="schedule-tour-modal"
        backdrop="static"
        aria-labelledby="buyer-schedule-tour-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Schedule Tour
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            type: "",
            date: "",
            time: new Date(),
            note: "",
            showtime: "",
          }}
          validationSchema={Yup.object().shape(this.state.tourSchema)}
          onSubmit={(values) => {
            this.doBuyerTour(values, this.props.propertyId, this.props.tourId);
          }}
          enableReinitialize
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <Modal.Body>
                <div className="schedule-div">
                  {/* Schedule Type Buttons */}
                  <div className="schedule-type-btn">
                    <div className="sche-btn-wrap">
                      <button
                        className={`btn ${values.type === "in_person"
                            ? "orange-fill-btn"
                            : "gray-fill-btn"
                          }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldValue("type", "in_person");
                        }}
                      >
                        <span>In Person</span>
                      </button>
                      <button
                        className={`btn ${values.type === "live_video"
                            ? "orange-fill-btn"
                            : "gray-fill-btn"
                          }`}
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldValue("type", "live_video");
                        }}
                      >
                        <img src={videoImg} />
                        <span>Live Video</span>
                      </button>
                    </div>
                    <span className="error">
                      <ErrorMessage name="type" />
                    </span>
                  </div>

                  {/* Select Date */}
                  <div className="select-date-div">
                    <h6 className="schedule-title">Select Date</h6>

                    <ul className="select-date-ul">
                      {this.state.nextSevenDays.map((d) => (
                        <li
                          key={d[0]}
                          className={`delect-date-li ${values.date == d[1] ? "green-bg-li" : ""
                            }`}
                          onClick={() => setFieldValue("date", d[1])}
                        >
                          <p className="day">{d[3]}</p>
                          <p className="date">{d[2]}</p>
                        </li>
                        //    <li
                        //   key={d.fullDate}
                        //   className={`delect-date-li ${
                        //     values.date == d.fullDate ? "green-bg-li" : ""
                        //   }`}
                        //   onClick={() => setFieldValue("date", d.fullDate)}
                        // >
                        //   <p className="day">{d.day}</p>
                        //   <p className="date">{d.date}</p>
                        // </li>
                      ))}
                    </ul>
                    <span className="error">
                      <ErrorMessage name="date" />
                    </span>
                  </div>

                  {/* Select Time */}
                  <div className="select-time-div">
                    <h6 className="schedule-title">Select Time</h6>

                    {/* <DatePicker
                  // selected={x.start_time}
                  onChange={(date) => setFieldValue(`time`, date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                /> */}
                    <DatePicker
                      selected={values.showtime ? values.showtime : new Date()}
                      onChange={(time) => {
                        let t = time.toLocaleTimeString("it-IT");
                        setFieldValue(`time`, t);
                        setFieldValue("showtime", time);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      dateFormat="HH:mm aa"
                    />
                    <span className="error">
                      <ErrorMessage name="time" />
                    </span>
                  </div>

                  {/* Note */}
                  <div className="note-div">
                    <h6 className="schedule-title">Note</h6>

                    <div className="field-wrapper">
                      <Field
                        type="text"
                        name="note"
                        // placeholder={configJSON.styleHolder}
                        className="custom-input secondary-input"
                      />
                      <span className="error">
                        <ErrorMessage name="note" />
                      </span>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  variant="primary"
                  type="submit"
                  className="rounded-fill-btn"
                // onClick={this.props.onHide}
                >
                  Schedule Tour
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      // <Modal
      //   show={this.props.show}
      //   onHide={this.props.onHide}
      //   dialogClassName="schedule-tour-modal"
      //   backdrop="static"
      //   aria-labelledby="buyer-schedule-tour-modal"
      //   centered
      // >
      //   <Modal.Header closeButton>
      //     <Modal.Title id="contained-modal-title-vcenter">
      //       Schedule Tour
      //     </Modal.Title>
      //   </Modal.Header>
      //   <Formik
      //     initialValues={{
      //       type: "",
      //       date: "",
      //       time: "",
      //       note: "",
      //       showtime: "",
      //     }}
      //     validationSchema={Yup.object().shape(this.state.tourSchema)}
      //     onSubmit={(values) => {
      //       this.doBuyerTour(values, 1);
      //     }}
      //     enableReinitialize
      //   >
      //     {({ values, setFieldValue, errors }) => (
      //       <Form>
      //         <Modal.Body>
      //           <div className="schedule-div">
      //             {/* Schedule Type Buttons */}
      //             <div className="schedule-type-btn">
      //               <div className="sche-btn-wrap">
      //                 <button
      //                   className={`btn ${
      //                     values.type === "in_person"
      //                       ? "orange-fill-btn"
      //                       : "gray-fill-btn"
      //                   }`}
      //                   onClick={(e) => {
      //                     e.preventDefault();
      //                     setFieldValue("type", "in_person");
      //                   }}
      //                 >
      //                   <span>In Person</span>
      //                 </button>
      //                 <button
      //                   className={`btn ${
      //                     values.type === "live_video"
      //                       ? "orange-fill-btn"
      //                       : "gray-fill-btn"
      //                   }`}
      //                   onClick={(e) => {
      //                     e.preventDefault();
      //                     setFieldValue("type", "live_video");
      //                   }}
      //                 >
      //                   <img src={videoImg} />
      //                   <span>Live Video</span>
      //                 </button>
      //               </div>
      //               <span className="error">
      //                 <ErrorMessage name="type" />
      //               </span>
      //             </div>

      //             {/* Select Date */}
      //             <div className="select-date-div">
      //               <h6 className="schedule-title">Select Date</h6>
      //               <ul className="select-date-ul">
      //                 {this.state.nextSevenDays.map((d) => (
      //                   <li
      //                     key={d.fullDate}
      //                     className={`delect-date-li ${
      //                       values.date == d.fullDate ? "green-bg-li" : ""
      //                     }`}
      //                     onClick={() => setFieldValue("date", d.fullDate)}
      //                   >
      //                     <p className="day">{d.day}</p>
      //                     <p className="date">{d.date}</p>
      //                   </li>
      //                 ))}
      //               </ul>
      //               <span className="error">
      //                 <ErrorMessage name="date" />
      //               </span>
      //             </div>

      //             {/* Select Time */}
      //             <div className="select-time-div">
      //               <h6 className="schedule-title">Select Time</h6>

      //               {/* <DatePicker
      //                 // selected={x.start_time}
      //                 onChange={(date) => setFieldValue(`time`, date)}
      //                 showTimeSelect
      //                 showTimeSelectOnly
      //                 timeIntervals={15}
      //                 timeCaption="Time"
      //                 dateFormat="h:mm aa"
      //               /> */}
      //               <DatePicker
      //                 selected={values.showtime ? values.showtime : new Date()}
      //                 onChange={(time) => {
      //                   let t = time.toLocaleTimeString("it-IT");
      //                   setFieldValue(`time`, t);
      //                   setFieldValue("showtime", time);
      //                 }}
      //                 showTimeSelect
      //                 showTimeSelectOnly
      //                 timeFormat="HH:mm"
      //                 timeIntervals={15}
      //                 dateFormat="HH:mm aa"
      //               />
      //               <span className="error">
      //                 <ErrorMessage name="time" />
      //               </span>
      //             </div>

      //             {/* Note */}
      //             <div className="note-div">
      //               <h6 className="schedule-title">Note</h6>

      //               <div className="field-wrapper">
      //                 <Field
      //                   type="text"
      //                   name="note"
      //                   // placeholder={configJSON.styleHolder}
      //                   className="custom-input secondary-input"
      //                 />
      //                 <span className="error">
      //                   <ErrorMessage name="note" />
      //                 </span>
      //               </div>
      //             </div>
      //           </div>
      //         </Modal.Body>
      //         <Modal.Footer>
      //           <button
      //             variant="primary"
      //             type="submit"
      //             className="rounded-fill-btn"
      //             // onClick={this.props.onHide}
      //           >
      //             Schedule Tour
      //           </button>
      //         </Modal.Footer>
      //       </Form>
      //     )}
      //   </Formik>
      // </Modal>
    );
  }
}

// Customizable Area Start
export default ScheduleTour;

// Customizable Area End
