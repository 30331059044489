// @ts-nocheck

import { Formik, Form, Field, ErrorMessage } from "formik";
import React from "react";
// Customizable Area Start
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Slider from "@material-ui/core/Slider";

// import { Link, withRouter } from "react-router-dom";

// Customizable Area End
import ChatParentController, {
    Props,
} from "./ChatParentController.web";

class CounterOffer extends ChatParentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.price = 0;
        this.pricePerUnit = 0;
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        this.price = +this.props.price;
        this.pricePerUnit = +(Math.round(this.price / 100 + "e+2") + "e-2");
        return (
            <Modal
                show={this.props.showCounterOfferModal}
                onHide={this.handleCloseCounterModal}
                dialogClassName="schedule-tour-modal"
                backdrop="static"
                aria-labelledby="buyer-schedule-tour-modal"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Counter Offer
                    </Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        term: "",
                        downPayment: this.pricePerUnit,
                        offerId: +this.props.counterId,
                        downPayLimit: 1,
                        downPayType: "dollar",
                        messageType: this.props.messageType,
                    }}
                    validationSchema={Yup.object().shape(this.state.counterOfferSchema)}
                    onSubmit={values => {
                        console.log(values);
                        this.setState({ coversationId: this.props.coversationId })
                        this.doCounterOffer(values);
                    }}
                    enableReinitialize
                >
                    {({ values, setFieldValue, errors }) => (
                        <Form>
                            <Modal.Body>
                                <div className="schedule-div counter-popup">
                                    {/* Schedule Type Buttons */}
                                    <div className="schedule-type-btn">
                                        <h6 className="schedule-title">Terms</h6>

                                        <div className="sche-btn-wrap counter-btn-wrap">
                                            <button
                                                className={`btn ${values.term === 10
                                                    ? "orange-fill-btn"
                                                    : "gray-fill-btn"
                                                    }`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setFieldValue("term", 10);
                                                }}
                                            >
                                                <span>10 Years</span>
                                            </button>
                                            <button
                                                className={`btn ${values.term === 15
                                                    ? "orange-fill-btn"
                                                    : "gray-fill-btn"
                                                    }`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setFieldValue("term", 15);
                                                }}
                                            >
                                                <span>15 Years</span>
                                            </button>
                                            <button
                                                className={`btn ${values.term === 20
                                                    ? "orange-fill-btn"
                                                    : "gray-fill-btn"
                                                    }`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setFieldValue("term", 20);
                                                }}
                                            >
                                                <span>20 Years</span>
                                            </button>
                                        </div>
                                        <span className="error">
                                            <ErrorMessage name="term" />
                                        </span>
                                    </div>

                                    {/* Down Payment */}
                                    {/* <div className="note-div">
                                        <h6 className="schedule-title">Down Payment</h6>

                                        <div className="field-wrapper">
                                            <Field
                                                type="number"
                                                name="downPayment"
                                                // placeholder={configJSON.styleHolder}
                                                className="custom-input secondary-input"
                                            />
                                            <span className="error">
                                                <ErrorMessage name="downPayment" />
                                            </span>
                                        </div>
                                    </div>
                                 */}
                                    <div className="content-wrapper">
                                        <div className="slider-wrapper">
                                            <div className="sub-title">
                                                <h6 className="schedule-title">Down Payment</h6>

                                                <div className="right-block">
                                                    <span className="title">
                                                        Equal to:
                                                    </span>
                                                    <span className="value">
                                                        ${values.downPayment ? values.downPayment : this.pricePerUnit}
                                                    </span>
                                                </div>
                                            </div>
                                            {values.downPayType == "dollar" && (
                                                <Slider
                                                    className="priceSlider"
                                                    value={values.downPayLimit}
                                                    // defaultValue={values.downPayLimit}
                                                    min={1}
                                                    step={1}
                                                    // max={100}
                                                    scale={(x) => (x * this.price)}
                                                    aria-labelledby="discrete-slider-always"
                                                    getAriaValueText={this.setLimitLabel}
                                                    valueLabelFormat={this.setLimitLabel}
                                                    onChange={(e, value) => {
                                                        this.setLimitHandler(
                                                            e,
                                                            value,
                                                            setFieldValue,
                                                            this.pricePerUnit,
                                                            "downPayment"
                                                        );
                                                        setFieldValue("downPayLimit", (100 * values.downPayment / this.price))
                                                    }
                                                    }
                                                    valueLabelDisplay="auto"

                                                />
                                            )}
                                            {values.downPayType == "percentage" && (
                                                <Slider
                                                    // defaultValue={values.downPayLimit}
                                                    value={values.downPayLimit}
                                                    aria-labelledby="discrete-slider-custom"
                                                    min={1}
                                                    step={1}
                                                    // max={100}
                                                    onChange={(e, value) =>
                                                        this.setPercentHandler(
                                                            e,
                                                            value,
                                                            setFieldValue,
                                                            this.pricePerUnit
                                                        )
                                                    }
                                                    valueLabelDisplay="auto"

                                                />
                                            )}
                                            <div className="value-wrapper">
                                                <div className="left-block">
                                                    {values.downPayType == "dollar" && "$" + values.downPayment}
                                                    {values.downPayType == "percentage" && values.downPayLimit + "%"}
                                                </div>
                                                <div className="right-block">
                                                    <button
                                                        type="button"
                                                        className={`btn ${values.downPayType === "dollar"
                                                            ? "orange-fill-btn"
                                                            : "gray-fill-btn"
                                                            }`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setFieldValue(
                                                                "downPayType",
                                                                "dollar"
                                                            );
                                                        }}
                                                    >
                                                        Dollar
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className={`btn ${values.downPayType ===
                                                            "percentage"
                                                            ? "orange-fill-btn"
                                                            : "gray-fill-btn"
                                                            }`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setFieldValue(
                                                                "downPayType",
                                                                "percentage"
                                                            );
                                                        }}
                                                    >
                                                        Percentage
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    variant="primary"
                                    type="submit"
                                    className="rounded-fill-btn"
                                // onClick={this.props.onHide}
                                >
                                    Submit
                                </button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>


        );
    }
}

// Customizable Area Start
export default CounterOffer;

// Customizable Area End
