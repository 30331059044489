// @ts-nocheck

import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start

import HomepagemainController, { Props } from "./HomepagemainController.web";
import {
  familyImage,
  logoWithNameImage,
  imageDollarSeller,
  homePercentage,
  imageLike,
  imageLikeRed,
  imageView,
  siginImage,
  buyerSkew,
  footerLogo,
  fbImage,
  instaImage,
  twitterImage,
  logoWhite,
  noImgAvailable
} from "./assets";
import Slider from "@material-ui/core/Slider";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
export const configJSON = require("./config.js");
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Carousel } from "react-bootstrap";

class Homepageseller extends HomepagemainController {
  // toggleMenu: boolean = false;
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  // toggleClass = () => {
  //   this.toggleMenu = !this.toggleMenu;
  //   document.body.classList.toggle("menu-open");
  //   this.forceUpdate();
  // };
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.location.search?.split("=")?.[1] !== this.props.location.search?.split("=")?.[1]) {
      location.reload()
    }

  }
  render() {
    return (
      <div class="hm-wrap">
        <div className="page-wrapper">
          <header className="site-header seller-page-header">
            <div className="container-main">
              <div className="left-header">
                <Link to="/" className="page-logo" title="Sparen Home">
                  <img src={logoWithNameImage} alt="logoWithNameImage" />
                </Link>
                <div className="link-wrapper">
                  <ul>
                    <li onClick={() => {
                      //@ts-ignore
                      this.props.history.push("home-page-buyer");
                      this.toggleClass();
                    }}>
                      {/* <Link to="/home-page-buyer" title="Buy"> */}
                      <a> Buy</a>
                      {/* </Link> */}
                    </li>
                    <li className="active" onClick={() => {
                      //@ts-ignore
                      this.props.history.push("home-page-seller");
                      this.toggleClass();
                    }}>
                      {/* <Link
                        to="/home-page-seller"
                        title="Sell"
                        className="active"
                      > */}
                      <a>Sell</a>
                      {/* </Link> */}
                    </li>
                    <li onClick={() => {
                      //@ts-ignore
                      this.props.history.push(this.props?.location?.pathname);
                      this.toggleClass();
                    }}>
                      {/* <Link to={this.props?.location?.pathname} title="How It Works"> */}
                      <a>How It Works</a>
                      {/* </Link> */}
                    </li>
                    <button
                      className="rounded-fill-btn blue-fill sm-visible"
                      onClick={() => this.props?.history?.push("signin")}
                    >
                      Log In
                    </button>
                    <button
                      className="rounded-fill-btn blue-fill sm-visible"
                      onClick={() => this.props?.history?.push("signup")}
                    >
                      Sign Up
                    </button>
                  </ul>
                </div>
              </div>
              <div className="right-header">
                <button
                  className="text-btn blue-text-btn"
                  onClick={() => this.props?.history?.push("signin")}
                >
                  Log In
                </button>
                <button
                  className="rounded-fill-btn blue-fill"
                  onClick={() => this.props?.history?.push("signup")}
                >
                  Sign Up
                </button>
              </div>
              <div className="mobile-menu" onClick={this.toggleClass}>
                <ul className={this.toggleMenu ? "active" : ""}>
                  <li />
                  <li />
                  <li />
                  <li />
                </ul>
              </div>
            </div>
          </header>
          <section className="buyer-seller-banner seller-banner">
            <div className="container-main">
              <div className="left-block">
                <h1>
                  Be the bank,
                  <br />
                  <span>keep the cash.</span>
                </h1>
                <p>
                  Why pay up to 6% in fees for something you <br />
                  can do yourself?
                </p>
                <div className="search-box">
                  <input
                    type="text"
                    placeholder="Search by State,City,or Zip Code"
                    onChange={(e) => this.searchChange(e.target.value)}
                  />
                  <button type="button" onClick={() => this.doSearch()}>Find Homes</button>
                </div>
              </div>
              <div className="right-block banner-image">
                <img src={familyImage} alt="familyImage" />
              </div>
            </div>
          </section>
        </div>
        <section className="buyer-seller-content seller-page-content">
          <div className="container-main">
            <div className="left-block">
              <p className="heading">Effortless Home Buying</p>
              <p className="content">
                Buying a house is hard. The underwriting process is invasive,
                financing options are limited, and an unexpected change in
                financial.
              </p>
              <p className="title">It’s Lightening fast</p>
              <p className="content">
                From near instant decisions to easy setup, we use digital data
                rather than manual processes so you can find your dream home and
                start making memories sooner.
              </p>
              <p className="title">It’s simple</p>
              <p className="content">
                Rather than waiting weeks, our 100% digital application gives a
                decision in a matter of minutes so you can find your dream home
                and start making memories sooner.
              </p>
              <p className="title">And it’s extra bendy flexible</p>
              <p className="content">
                Life isn’t fixed-rate so why should your payments be? If you
                have a financial emergency, let us know and we’ll work with you
                to reduce your monthly payments.
              </p>
            </div>
            <div className="right-block">
              <img src={imageDollarSeller} alt="imageDollar" />
            </div>
          </div>
        </section>
        <section className="saving-section seller-saving">
          <div className="container-main">
            <i className="image-block">
              <img src={homePercentage} alt="homePercentage" />
            </i>
            <h2 className="text-center">How much will you save?</h2>
            <div className="terms-price-block">
              <div className="slider-wrapper orange-slider">
                <div className="title">
                  <span>Your Purchase Price</span>
                </div>
                <div className="total-saving">$ {this.convertPrice(this.state.sellerPurchasePrice.price * 50000)}</div>
                <Slider
                  key={`slider-${this.state.sellerSliderValue.value}`}
                  defaultValue={this.state.sellerPurchasePrice.price}
                  // aria-valuetext={0}
                  scale={x => x * 50000}
                  aria-labelledby="discrete-slider-custom"
                  step={1}
                  min={1}
                  valueLabelDisplay="auto"
                  marks={this.state.sellerSliderValue}
                  onChange={(e, newValue) =>
                    this.sellerPurchasePriceSlider(newValue)
                  }
                />
              </div>
              <div className="count-wrapper">
                <div className="count-block">
                  <div className="title">
                    <span>Traditional 6% Fee</span>
                  </div>
                  <div className="total-saving">$ {this.state.sellerPurchasePrice.tradeFee}</div>
                </div>
                <div className="count-block">
                  <div className="title">
                    <span>Sparen’s Fee</span>
                  </div>
                  <div className="total-saving">$ {this.state.sellerPurchasePrice.spareFee}</div>
                </div>
                <div className="count-block">
                  <div className="title">
                    <span>Saving</span>
                  </div>
                  <div className="total-saving">$ {this.state.sellerPurchasePrice.savings}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="skew-section seller-skew-block">
          <div className="text-block">
            <p>Ready to sell?</p>
          </div>
          <div className="image-block">
            <div className="white-border-skew" />
            <button type="button" className="btn white-fill-btn">
              Get Started
            </button>
          </div>
        </section>
        <section className="property-list-section">
          <div className="container-main">
            <h2 className="title">Newly Added</h2>
            <div className="property-wrapper">
              {this.state.recentPropertyList?.map((property, index) => <div className="property-block" key={index}>
                <div className="top-block">
                  <div className="profile-block">
                    <i>
                      <img src={buyerSkew} alt="" />
                    </i>
                    <span>{`${property.attributes.owner.first_name ? property.attributes.owner.first_name : "Unknown"} ${property.attributes.owner.last_name ? property.attributes.owner.last_name : ""}`}</span>
                  </div>
                  <div className="like-block">
                    <img src={imageLike} alt="Like image" />
                  </div>
                </div>
                <i className="property-image">
                  <img src={property.attributes.images[0] ? property.attributes.images[0]?.url : noImgAvailable} alt="Property Image" />

                  {/* <img src={property.attributes.images?.[0]?.url} alt="Property Image" /> */}
                </i>
                <div className="like-view-block">
                  <div className="left-block">
                    <p className="lable">List Price</p>
                    <p className="price">${property.attributes.price}</p>
                  </div>
                  <div className="right-block">
                    <div className="view">
                      <i>
                        <img src={imageView} alt="" />
                      </i>
                      <span>{property.attributes.view_count}</span>
                    </div>
                    <div className="like">
                      <i>
                        <img src={imageLikeRed} alt="" />
                      </i>
                      <span>{property.attributes.favorited_count}</span>
                    </div>
                  </div>
                </div>
                <div className="more-detail">
                  <Link to={{
                    pathname: "/property-detail",
                    search: `id=${property.id}`,
                    state: { propertyID: property.id }
                  }}
                  >
                    <p className="property-name">{property.attributes.address.address_line}</p>
                  </Link>
                  <p className="property-address">{`${property.attributes.address.state} , ${property.attributes.address.city} ${property.attributes.address.zip_code}`}</p>
                  <ul>
                    <li>{property.attributes.bedrooms} Beds</li>
                    <li>{property.attributes.full_baths} Baths</li>
                    <li>{property.attributes.finished_sq_ft} Sq ft</li>
                  </ul>
                </div>
              </div>)}
            </div>
          </div>
        </section>
        <footer>
          <div className="container-main">
            <div className="left-footer">
              <div className="logo">
                <i>
                  <img
                    src={footerLogo}
                    alt="footerLogo"
                    className="footer-logo"
                  />
                </i>
                <span>
                  KEEP UP WITH THE <br /> NEIGHBORHOOD.
                </span>
              </div>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email(configJSON.errorEmailNotValid).required(configJSON.errorEmailReq),
                })}
                onSubmit={(values) => this.doSubscribe(values)}
                enableReinitialize
              >
                {({ values, setFieldValue, errors }) => (
                  <Form className="form-wrapper fill-detail">
                    <div className="field-wrapper">
                      <Field
                        type="text"
                        name="email"
                        placeholder="Email Address"
                        className="custom-input type-mail mb-2"
                      />
                      <div className="error ml-3">
                        <ErrorMessage name="email" />
                      </div>
                      <button className="btn white-fill-btn mt-3" type="submit">
                        SUBSCRIBE
                      </button>
                    </div>
                  </Form>)}
              </Formik>
            </div>
            <div className="right-footer">
              <div className="footer-links">
                <h3>COMPANY</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="About us">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="How it Works">
                      How it Works
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Referrals">
                      Referrals
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Careers">
                      Careers
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Contact Us">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links">
                <h3>SOLUTIONS</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Buy A Home">
                      Buy A Home
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Sell A Home">
                      Sell A Home
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Keeper">
                      Keeper
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links">
                <h3>RESOURCES</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Blog">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Support">
                      Support
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Partners">
                      Partners
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links larg-width">
                <h3>BUYERS</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Recently Listed">
                      Recently Listed
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="How It Works">
                      How It Works
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Savings Calculator">
                      Savings Calculator
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="SafeSpend Estimator">
                      SafeSpend Estimator
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Buyer FAQ">
                      Buyer FAQ
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-links">
                <h3>SELLERS</h3>
                <ul>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Recently Sold">
                      Recently Sold
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="How It Works">
                      How It Works
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Savings Calculator">
                      Savings Calculator
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} titlt="Seller FAQ">
                      Seller FAQ
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="bottom-footer">
              <div className="media-icon">
                <div className="icon-wrapper">
                  <img src={instaImage} alt="Instagram Logo" />
                </div>
                <div className="icon-wrapper facebook-icon">
                  <img src={fbImage} alt="Facebook Logo" />
                </div>
                <div className="icon-wrapper">
                  <img src={twitterImage} alt="Twitter Logo" />
                </div>
              </div>
              <p className="copy-right">&copy;THE SPAREN COMPANY 2021</p>
              <div className="link-wrapper">
                <Link to={this.props?.location?.pathname} title="TERMS OF SERVICE">
                  TERMS OF SERVICE
                </Link>
                <Link to={this.props?.location?.pathname} title="PRIVACY POLICY">
                  PRIVACY POLICY
                </Link>
              </div>
            </div>
          </div>
        </footer>

        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </div>
    );
  }
}

export default withRouter(Homepageseller);
