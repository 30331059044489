// @ts-nocheck

import React from "react";
// Customizable Area Start
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import DashboardController, { Props } from "../DashboardSellerController.web";
import Header from "./Header.web";
import SidebarBuyer from "../../../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../../../components/src/Sidebar";

import {
  profileImage,
  locationBlue,
  imagePhone,
  imageTelephone,
} from "../../assets";

class Contact extends DashboardController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="flex">
        <div>
          {localStorage.getItem("role") === "buyer" ? (
            <SidebarBuyer />
          ) : (
            <SidebarSeller />
          )}
        </div>
        <div className="stepper-outer contact-page">
          <Header
            flag={this.state.HeaderDropdownFlag}
            toggle={this.doToggleHeaderDropdown}
          />

          <button className="btn orange-fill-btn">Contacts</button>
          <div className="content-wrapper">
            <div className="contact-card">
              <div className="profile-block">
                <div className="img-block">
                  <img src={profileImage} alt="" />
                </div>
                <div className="content">
                  <span className="name">John Dow</span>
                  <span className="email">abc@xyz.com</span>
                </div>
              </div>
              <div className="more-info">
                <span className="img-block">
                  <img src={imagePhone} alt="" />
                </span>
                <span className="value">+1 234567890</span>
              </div>
              <div className="more-info">
                <span className="img-block phone-icon">
                  <img src={imageTelephone} alt="" />
                </span>
                <span className="value">+1 234567890</span>
              </div>
              <div className="more-info">
                <span className="img-block">
                  <img src={locationBlue} alt="" />
                </span>
                <span className="value">
                  37577 Susan St. <br />
                  Sterling Heights, MI 48310
                </span>
              </div>
              <div className="btn-wrapper">
                <Link to={this.props?.location?.pathname}>View Details</Link>
              </div>
            </div>
            <div className="contact-card">
              <div className="profile-block">
                <div className="img-block">
                  <img src={profileImage} alt="" />
                </div>
                <div className="content">
                  <span className="name">John Dow</span>
                  <span className="email">abc@xyz.com</span>
                </div>
              </div>
              <div className="more-info">
                <span className="img-block">
                  <img src={imagePhone} alt="" />
                </span>
                <span className="value">+1 234567890</span>
              </div>
              <div className="more-info">
                <span className="img-block phone-icon">
                  <img src={imageTelephone} alt="" />
                </span>
                <span className="value">+1 234567890</span>
              </div>
              <div className="more-info">
                <span className="img-block">
                  <img src={locationBlue} alt="" />
                </span>
                <span className="value">
                  37577 Susan St. <br />
                  Sterling Heights, MI 48310
                </span>
              </div>
              <div className="btn-wrapper">
                <button>View Details</button>
              </div>
            </div>
            <div className="contact-card">
              <div className="profile-block">
                <div className="img-block">
                  <img src={profileImage} alt="" />
                </div>
                <div className="content">
                  <span className="name">John Dow</span>
                  <span className="email">abc@xyz.com</span>
                </div>
              </div>
              <div className="more-info">
                <span className="img-block">
                  <img src={imagePhone} alt="" />
                </span>
                <span className="value">+1 234567890</span>
              </div>
              <div className="more-info">
                <span className="img-block phone-icon">
                  <img src={imageTelephone} alt="" />
                </span>
                <span className="value">+1 234567890</span>
              </div>
              <div className="more-info">
                <span className="img-block">
                  <img src={locationBlue} alt="" />
                </span>
                <span className="value">
                  37577 Susan St. <br />
                  Sterling Heights, MI 48310
                </span>
              </div>
              <div className="btn-wrapper">
                <button>View Details</button>
              </div>
            </div>
            <div className="contact-card">
              <div className="profile-block">
                <div className="img-block">
                  <img src={profileImage} alt="" />
                </div>
                <div className="content">
                  <span className="name">John Dow</span>
                  <span className="email">abc@xyz.com</span>
                </div>
              </div>
              <div className="more-info">
                <span className="img-block">
                  <img src={imagePhone} alt="" />
                </span>
                <span className="value">+1 234567890</span>
              </div>
              <div className="more-info">
                <span className="img-block phone-icon">
                  <img src={imageTelephone} alt="" />
                </span>
                <span className="value">+1 234567890</span>
              </div>
              <div className="more-info">
                <span className="img-block">
                  <img src={locationBlue} alt="" />
                </span>
                <span className="value">
                  37577 Susan St. <br />
                  Sterling Heights, MI 48310
                </span>
              </div>
              <div className="btn-wrapper">
                <button>View Details</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Customizable Area Start
export default withRouter(Contact);

// Customizable Area End
