import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  // Customizable Area End
  EmailSchema: any;
  emailSchema: any;
  verifyOtpSchema: any;
  passwordSchema: any;
  forgetFlag: any;
  verifyFlag: any;
  createPwdFlag: any;
  updatedFlag: any;
  snackBar: {
    show: boolean;
    message?: string;
    type?: "success" | "info" | "warning" | "error" | undefined;
  };
  loader: any;
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SigninController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  apiforgotPwdCallId: string = "";
  apiverifyOtpCallId: string = "";
  apicreatePwdCallId: string = "";


  emailReg: RegExp;
  pwdReg: RegExp;

  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.pwdReg = new RegExp(/^(?=.*[A-Z])(?=.*[#!@$&*?<>',\[\]}{=\-)(^%`~+.:;_])(?=.*[0-9])(?=.*[a-z]).{8,}$/)


    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    let EmailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.errorEmailRequired),
      password: Yup.string().required(configJSON.errorPasswordNotValid),
    };

    //email schema
    let emailSchema = {
      email: Yup.string()
        .email(configJSON.pleaseEnterAValidEmail)
        .required(configJSON.emailIsRequired),
    };

    // VerifyOtp
    let verifyOtpSchema = {
      otp: Yup.number()
        .required(configJSON.verifyOtpIsRequired),
    };

    //passwordSchema
    let passwordSchema = {
      password: Yup.string()
        .matches(this.pwdReg, configJSON.passwordValidation)
        .required(configJSON.pleaseEnterAPassword),
      confirmPassword: Yup.string()
        .required(configJSON.pleaseConfirmYourPassword)
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            configJSON.passwordsMustMatch
          ),
        }),
    };

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      EmailSchema: EmailSchema,
      emailSchema: emailSchema,
      verifyOtpSchema: verifyOtpSchema,
      passwordSchema: passwordSchema,
      forgetFlag: false,
      verifyFlag: false,
      createPwdFlag: false,
      updatedFlag: false,
      snackBar: {
        show: false,
      },
      loader: false
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("runEngine.debugLog", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //Sigin API
          if (
            apiRequestCallId === this.apiEmailLoginCallId &&
            responseJson !== undefined
          ) {
            this.openSnackBarHandler("success", configJSON.successLogin);
            localStorage.setItem('id', responseJson.data.id);
            localStorage.setItem('auth', responseJson.meta.token);
            localStorage.setItem('role', responseJson.data.attributes.role);
            localStorage.setItem('email', responseJson.data.attributes.email);
            localStorage.setItem('first_name', responseJson.data.attributes.first_name);
            localStorage.setItem('last_name', responseJson.data.attributes.last_name);
            localStorage.setItem("profile_image", responseJson.data.attributes.profile_picture);
            localStorage.setItem('expiry', new Date().toString());

            this.setState({
              ...this.state,
              loader: false
            })
            if (responseJson.data.attributes.role === 'buyer') {
              // @ts-ignore
              this.props?.history?.push({
                pathname: "dashboard-buyer",
                state: { greetings: "Welcome Back" },
              });
              //  this.props?.history?.push("dashboard-buyer")
            } else {
              // @ts-ignore
              //  this.props?.history?.push("dashboard-seller")
              // @ts-ignore
              this.props?.history?.push({
                pathname: "/dashboard-seller",
                state: {
                  statusType: "login"
                },
              });
            }
          }
          // Forgot API
          if (
            apiRequestCallId === this.apiforgotPwdCallId &&
            responseJson !== undefined
          ) {
            this.setState({
              ...this.state,
              loader: false
            })
            localStorage.setItem('auth', responseJson.meta.token)
            this.toggleVerifyFlag()
          }
          // Verify OTP API
          if (
            apiRequestCallId === this.apiverifyOtpCallId &&
            responseJson !== undefined
          ) {
            this.setState({
              ...this.state,
              loader: false
            })
            this.openSnackBarHandler('success', responseJson.messages[0].otp);
            this.toggleCreatePwdFlag()
          }
          // Create Pwd API
          if (
            apiRequestCallId === this.apicreatePwdCallId &&
            responseJson !== undefined
          ) {
            this.setState({
              ...this.state,
              loader: false
            })
            this.openSnackBarHandler('success', responseJson.messages);
            this.togglePwdUpdateFlag()
          }
        }
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson.errors;
        this.setState({
          ...this.state,
          loader: false
        })
        if (errors[0].failed_login) {
          localStorage.setItem("auth", "");
          //@ts-ignore
          this.props?.history.push("/signin");
          this.openSnackBarHandler('error', errors[0].failed_login);
        }
        // if (errors[0].token === 'Invalid token' || errors[0].token === 'Token has Expired') {failed_login
        //   localStorage.setItem("auth", "");
        //   //@ts-ignore
        //   this.props?.history.push("/login");
        //  }
        if (errors[0].message) {
          this.openSnackBarHandler('error', errors[0].message);
        }
        if (errors[0].otp) {
          this.openSnackBarHandler('error', errors[0].otp);
        }
      }
    }
  }

  // Open View Details Modal
  openSnackBarHandler = (
    type: "success" | "info" | "warning" | "error" | undefined,
    message: string
  ): void => {
    this.setState({
      snackBar: {
        show: true,
        message: message,
        type,
      },
    });
  };

  // Close View Details Modal
  closeSnackBarHandler = () => {
    this.setState({
      snackBar: {
        show: false,
        message: this.state.snackBar.message,
        type: this.state.snackBar.type,
      },
    });
  };

  doEmailLogIn = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = values;

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signinApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      ...this.state,
      loader: true
    })

    return true;
  };

  doForgotPassword = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = values;

    const data = {
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiforgotPwdCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      ...this.state,
      loader: true
    })

    return true;
  };

  doVerifyOtps = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      token: localStorage.getItem('auth'),
      "otp_code": values.otp
    };

    const data = attrs;

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiverifyOtpCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      ...this.state,
      loader: true
    })

    return true;
  };

  doCreatePassword = (values: any): boolean => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      token: localStorage.getItem('auth'),
      "new_password": values.password,
      "confirm_password": values.confirmPassword,
    };

    const data = attrs;

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apicreatePwdCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createPwdApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      ...this.state,
      loader: true
    })

    return true;
  };

  toggleForgetFlag() {
    this.setState({
      ...this.state,
      forgetFlag: true,
      verifyFlag: false,
      createPwdFlag: false,
      updatedFlag: false,
    });
  }

  toggleForgetCancelFlag() {
    this.setState({
      ...this.state,
      forgetFlag: false,
      verifyFlag: false,
      createPwdFlag: false,
      updatedFlag: false,
    });
  }

  toggleVerifyFlag() {
    this.setState({
      ...this.state,
      forgetFlag: false,
      verifyFlag: true,
      createPwdFlag: false,
      updatedFlag: false,
    });
  }

  toggleCreatePwdFlag() {
    this.setState({
      ...this.state,
      verifyFlag: false,
      forgetFlag: false,
      createPwdFlag: true,
      updatedFlag: false,
    });
  }

  togglePwdUpdateFlag() {
    this.setState({
      ...this.state,
      verifyFlag: false,
      forgetFlag: false,
      createPwdFlag: false,
      updatedFlag: true,
    });
  }

  toggleCloseFlag() {
    this.setState({
      ...this.state,
      verifyFlag: false,
      forgetFlag: false,
      createPwdFlag: false,
      updatedFlag: false,
    });
  }
}
