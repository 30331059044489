// @ts-nocheck

import React from "react";
// Customizable Area Start
import SidebarBuyer from "../../../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../../../components/src/Sidebar";
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import Header from "../AddPropertyStepper/Header.web";
import AddPropertyFlowController, { Props } from "./AddPropertyFlowController.web";
import CreatePropertyParentWeb from "./CreatePropertyParent.web ";

class EditPropertyParent extends AddPropertyFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    console.log("edit PYUU", this.props)
    // Customizable Area End
  }
  // Customizable Area Start
  // componentDidMount(){}
  // Customizable Area End

  render() {
    return (
      <div className="flex">
        <div>
          {/* Slider  */}
          {
            localStorage.getItem("role") === "buyer"
              ? <SidebarBuyer menuCollapsed={true} />
              : <SidebarSeller menuCollapsed={true} />
          }
        </div>
        <div className="stepper-outer">
          <Header
            flag={this.state.HeaderDropdownFlag}
            toggle={this.doToggleHeaderDropdown}
          />
          <h2 className="step-header-title">Edit Property</h2>
          <CreatePropertyParentWeb propertyId={this.props?.location?.state} />
        </div>
      </div>
    );
  }
}

// Customizable Area Start
export default withRouter(EditPropertyParent);

// Customizable Area End
