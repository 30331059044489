// @ts-nocheck

import React from "react";
// Customizable Area Start
import Sidebar from "../../../../../components/src/Sidebar";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
export const configJSON = require("../../config");
import { galleryImg, deleteImg } from "../../assets";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// Customizable Area End
import AddPropertyFlowController, { Props } from "./AddPropertyFlowController.web";
class StepIIIUploadMedia extends AddPropertyFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidMount() {
    if (this.props?.fieldData?.attributes?.images) {
      let a = [...this.props?.fieldData?.attributes?.images]
      this.setState({ showFile: [...a] });
    }
  }
  // Customizable Area End

  render() {
    return (
      <>
        <h3 className="page-title">Upload Media</h3>
        <div className="stepper-sections upload-media">
          <div className="media-heading">
            <span className="green-title">Upload Image</span>
            <span className="sub-title">
              (Image resolution need to be 1280 px X 700 px)
            </span>
          </div>
          <Formik
            innerRef={this.props.formRef}
            initialValues={{
              media: [],
              videoUrlWalkthrough: this.props?.fieldData?.attributes?.video_walkthrough_url || "",
              videoUrlVirtualTour: this.props?.fieldData?.attributes?.virtual_tour_url || "",
            }}
            validationSchema={Yup.object().shape(this.state.uploadMediaSchema)}
            onSubmit={(values) => {
              // this.doStepSet(4);
              // this.props.history.push({
              //   pathname: "/add-property/pricing",
              //   state: { steps: this.state.step,data:this.props?.fieldData },
              // });
              this.doUploadMedia(values, this.props?.fieldData?.id);
            }}
            enableReinitialize
            validateOnMount
          >
            {({ values, setFieldValue, errors }) => (
              //@ts-ignore
              <Form>
                <div className="field-wrapper preview">
                  <ul>
                    {this.state.file.length > 0 &&
                      this.state.file.map((item, index) => {
                        return (
                          <li key={index}>
                            <img
                              src={URL.createObjectURL(item)}
                              alt=""
                              className="uploaded-image"
                            />
                            <button
                              type="button"
                              onClick={() => this.deleteFile(index, setFieldValue)
                                // {
                                // const s = this.state.showFile.filter(
                                //   (item: any, index: any) => index !== e);
                                //             this.setState({ showFile: s });
                                // }
                              }
                              className="del-btn"
                            >
                              <img src={deleteImg} alt="deleteImg" />
                            </button>
                          </li>
                        );
                      })}
                    {this.state.showFile.length > 0 &&
                      this.state.showFile.map((item, index) => {
                        return (
                          <li key={item.id}>
                            <img
                              src={item.url}
                              alt=""
                              className="uploaded-image"
                            />
                            <button
                              type="button"
                              onClick={(e) => {
                                const s = this.state.showFile.filter(
                                  (img: any, index: any) => img.id !== item.id);
                                this.setState({ showFile: s });
                                this.doDeleteImage(item.id)

                              }
                              }
                              className="del-btn"
                            >
                              <img src={deleteImg} alt="deleteImg" />
                            </button>
                          </li>
                        );
                      })}
                    <li>
                      <label htmlFor="mediaId" onClick={this.showFileUpload}>
                        <img alt="ddd" className="btn-image" src={galleryImg} />
                      </label>
                      <input
                        type="file"
                        name="media"
                        id="mediaId"
                        disabled={this.state.file.length === 5}
                        className="custom-input secondary-input uploadImage file1"
                        onChange={(event) => {
                          this.handleImageChange(event, setFieldValue);
                        }}
                        ref={this.fileUpload}
                        multiple
                      />
                      <span className="error">
                        <ErrorMessage name="media" />
                      </span>
                    </li>
                  </ul>
                </div>

                {/* Video URL  */}
                <div className="field-wrapper">
                  <h2 className="green-title">Video Walkthrough</h2>
                  <label className="">Allow Youtube OR Vimeo Video URL</label>
                  <Field
                    type="text"
                    name="videoUrlWalkthrough"
                    placeholder={configJSON.videoUrlHolder}
                    className="custom-input secondary-input"
                  />
                  <span className="error">
                    <ErrorMessage name="videoUrlWalkthrough" />
                  </span>
                </div>

                {/* Video URL  */}
                <div className="field-wrapper">
                  <h2 className="green-title">Virtual Tour URL</h2>
                  <label className="">Allow Youtube OR Vimeo Video URL</label>
                  <Field
                    type="text"
                    name="videoUrlVirtualTour"
                    placeholder={configJSON.videoUrlHolder}
                    className="custom-input secondary-input"
                  />
                  <span className="error">
                    <ErrorMessage name="videoUrlVirtualTour" />
                  </span>
                </div>

                {/* Next  */}
                <div className="btn-wrapper flex justify-end">
                  <button
                    type="button"
                    className="btn gray-fill-btn"
                    onClick={() => {
                      console.log('this.props?.fieldData2', this.props?.fieldData)
                      this.props.setStep(2, this.props?.fieldData);
                      // this.doStepSet(2);
                      // @ts-ignore
                      // this.props?.history?.push({
                      //   pathname: "/add-property/basic-detail",
                      //   state: { steps: this.state.step, data: this.props?.fieldData },
                      // });
                    }}
                  >
                    Back
                  </button>
                  <button type="submit" className="rounded-fill-btn ml-3">
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* Snackbar for display success and failed messages. */}
        {/* <Snackbar
            open={this.state.snackBar.show}
            autoHideDuration={3000}
            onClose={this.closeSnackBarHandler}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.closeSnackBarHandler}
              severity={this.state.snackBar.type}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar> */}
      </>
    );
  }
}

// Customizable Area Start
export default withRouter(StepIIIUploadMedia);

// Customizable Area End
