// @ts-nocheck

import React from "react";
// Customizable Area Start
import ChatParentController, { Props } from "./ChatParentController.web";
import { imageAttachment } from "../assets";
import moment from "moment";
import IIChatTourItem from "./ChatAllItems/IIChatTourItem.web";
import IIIChatPropertyOfferItem from "./ChatAllItems/IIIChatPropertyOfferItem.web";
import IChatNormalItem from "./ChatAllItems/IChatNormalItem.web";
// Customizable Area End
class ChatItem extends ChatParentController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        return (
            <>
                {this.props.message?.data?.map(x =>
                    x?.attributes?.message_type === "normal" ?
                        <IChatNormalItem message={x} />
                        : x?.attributes?.message_type === "scheduled_tour" ?
                            <IIChatTourItem message={x} />
                            : x?.attributes?.message_type === "property_offer" || x?.attributes?.message_type === "property_counter_offer" ?
                                <IIIChatPropertyOfferItem message={x} />
                                : <div>{console.log("x", x)}New Type</div>
                )}
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default ChatItem;
// Customizable Area End
