// @ts-nocheck

import React from "react";
// Customizable Area Start

import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import {
  buyerLogo,
  siginImage,
  successUploadImg
} from "../../assets";
import { isEmpty } from "lodash";
import { PlaidLink } from "react-plaid-link";
import SuccessAgreement from "../../Popups/SuccessAgreement.web"
import HandOverController, { Props } from "../HandOverController.web";
class Agreement extends HandOverController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  componentDidMount() {
    console.log("this", this.props.location)
    // this.doAgreement();
  }

  // Customizable Area End

  render() {

    return (
      <>
        <header className="site-header fixed-header buyer-application-header">
          <div className="container-main">
            <div className="left-header">
              <Link
                to={
                  localStorage.getItem("role") === "buyer"
                    ? "/dashboard-buyer"
                    : "/dashboard-seller"
                }
                className="page-logo"
                title="Sparen Home"
              >
                <img src={buyerLogo} alt="Logo" />
              </Link>
            </div>
            <div className="right-header">
              <Link to="/property-list">Back</Link>
            </div>
            <div className="mobile-menu" onClick={this.toggleClass}>
              <ul className={this.toggleMenu ? "active" : ""}>
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
        </header>
        <section className="tab-block-wrapper agreement-wrapper">
          <div className="container-main">
            <ul className="tab-header">
              <li>
                <a
                  href="#"
                  className="tab-link complete active"
                >
                  Property Selection
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="tab-link complete active"
                >
                  Sign In
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={
                    "tab-link " + (this.state.agreementState.reviewAgreement ? "active " : "")
                    + (this.state.completeAgreementState.review ? "complete" : "")
                  }
                  onClick={() => {
                    this.setState({ agreementState: { reviewAgreement: true, billingDetail: false, paymentMethod: false } });
                  }}
                >
                  Review Agrement
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={
                    "tab-link " + (this.state.agreementState.billingDetail ? "active " : "")
                    + (this.state.completeAgreementState.billing ? "complete" : "")

                  }
                  onClick={() => {
                    this.getAccountDetail();
                    this.setState({ agreementState: { reviewAgreement: false, billingDetail: true, paymentMethod: true } });
                  }}
                >
                  Billing Details
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={
                    "tab-link " + (this.state.agreementState.paymentMethod ? "active" : "")
                    + (this.state.completeAgreementState.payment ? "complete" : "")

                  }
                  onClick={() => {
                    this.getAccountDetail();
                    this.setState({ agreementState: { reviewAgreement: false, billingDetail: true, paymentMethod: true } });
                  }}
                >
                  Payment Method
                </a>
              </li>
            </ul>
          </div>
          <div className="content-block">
            <div className="container-main">
              {/* for property block  */}
              {/* {values.propertySection && (
                      <div className="filter-content">property</div>
                    )} */}
              {/* for sign in block  */}
              {/* {values.signIn && (
                      <div className="filter-content">sign in</div>
                    )} */}
              {/* for review agreement block  */}
              {this.state.agreementState.reviewAgreement && (
                <div className="review-agreement">
                  <div className="left-block">
                    <div className="container-main">
                      <ul className="heading">
                        <li>
                          <a
                            href="#"
                            className={
                              "tab-link " + (this.state.reviewAgreementState.agreement ? "active" : "")
                            }
                            onClick={() => {
                              this.setState({ reviewAgreementState: { agreement: true, ownerDetails: false, buyerDetails: false } })
                            }}
                          >
                            Agreement
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className={"tab-link " + (this.state.reviewAgreementState.ownerDetails ? "active" : "")}
                            onClick={() => {
                              this.setState({ reviewAgreementState: { agreement: false, ownerDetails: true, buyerDetails: false } })
                              this.getUserDetail(44, "owner");
                            }}
                          >
                            Owner Details
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            className={
                              "tab-link " + (this.state.reviewAgreementState.buyerDetails ? "active" : "")
                            }
                            onClick={() => {
                              this.setState({ reviewAgreementState: { agreement: false, ownerDetails: false, buyerDetails: true } })
                              this.getUserDetail(45, "buyer");
                            }}
                          >
                            Buyer Details
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="inner-tab-content">
                      <div className="container-main">
                        {/* for agreement block  */}
                        {this.state.reviewAgreementState.agreement && (
                          <div className="filter-content">
                            <div className="content-wrapper">
                              <div className="content-item">
                                <h3>Real Estate Purchase Agreement</h3>
                                {this.props.location?.state?.aggrementData?.html}
                              </div>
                              {/* <div className="content-item">
                                <h3>Real Estate Purchase Agreement</h3>



                                THIS AGREEMENT is made on _________________, between Jane Seller (hereinafter "Seller"), of 123 Main St, Detroit, Michigan 48127, and Joe Buyer (hereinafter "Buyer"), of _________________, _________________, _________________ _________________ for the sale of the below described property pursuant to the following terms and conditions:<br />

                                Legal Description and Address

                                The real property located at _________________, _________________, _________________ _________________, together with all buildings, improvements and fixtures constructed or located on the Land (Buildings) and all easements and rights benefiting or appurtenant to the Land (collectively the Real Property). <br />The Real Property is legally described as: _________________

                                Purchase Price<br />

                                <br /> Subject to the following conditions, Buyer shall make final payment for the property at closing in the total amount of $0.00 which accounts for the previously deposited sum of $0.00 in earnest moneys for a total purchase price of $0.00. Buyer agrees to pay the entire amount at closing. Concurrent with the execution of this Agreement, Buyer has deposited with Seller or Seller's Escrow Agent the sum of $0.00, (hereinafter referred to as "Earnest Money") as earnest money and a partial payment of the consideration under the Contract. In the event that the purchase and sale shall be consummated pursuant to the terms of said contract, Seller's Escrow Agent shall, at such closing, deliver to Seller the Earnest Money, and Buyer shall be given credit toward the purchase price for the payment of the Earnest Money. Unless the Buyer does not make a good faith effort to obtain financing, the Buyer shall be returned their earnest money if closing does not occur.

                                Closing Costs <br />

                                In closing this transaction, Seller shall be charged with the following:<br />

                                (a) The amount of real estate taxes and assessments prorated up to and including the date of transfer of title; <br />

                                (b) The cost of any transfer tax;<br />

                                (c) The cost of a title report, title search, and title guaranty policy in the amount of the purchase price;<br />

                                (d) The cost of paying off and satisfying any mortgage indebtedness for which Seller is liable, together with the cost of any mortgage cancellation, recording fee or other costs incident to the satisfaction of any such indebtedness;<br />

                                (e) The cost of any required brokerage commission;<br />

                                (f) One half of the escrow fee; and<br />

                                (g) All other charges properly borne by Seller consistent with the terms of this Agreement;<br />

                                and immediately thereafter shall deliver to Seller the balance of the funds in its hands due, and all documents due Seller.<br />

                                On closing, Buyer shall be charged with the following:

                                <br /> (a) Any cost of financing this transaction which Buyer arranges for;

                                <br /> (b) The cost of filing the deed for record;

                                <br />(c) The amount by which the cost of furnishing the title insurance exceeds the cost of furnishing a title guaranty policy, if Buyer elects to be furnished with title insurance;

                                <br />(d) One half of the escrow fee; and

                                <br /> (e) All other charges properly borne by Buyer consistent with the terms of this Agreement;

                                <br /> and immediately thereafter, the escrow agent shall deliver to Buyer the title guaranty or title insurance, as the case may be, the record deed or Recorders' receipt, any prorations to which Buyer is entitled, and all other funds or documents due Buyer.

                                <br /> Title Requirements

                                <br /> Seller shall convey to Buyer good and marketable title to the property, by good and sufficient general warranty deed with all dower rights released. The deed shall be deposited into escrow on or before the closing date.

                                Seller shall accompany Seller's deed with a title guaranty policy in the amount of the purchase price issued by a general title company ("title company") in its customary form, guaranteeing record title to the Property to be good in Buyer subject only to the exceptions to be contained in the deed. However, Buyer may elect to be furnished with an Owner's Fee Policy of title insurance ("title insurance"), in the amount of the purchase price, insuring marketable title to be good in Buyer, subject only to the exceptions to be contained in the deed. In such event, in lieu of a title guaranty policy, Seller shall furnish Buyer with title insurance. Seller shall pay that portion of the costs which would have been incurred had a title guaranty policy been issued, and Buyer shall pay the remaining costs.

                                Immediately upon the execution hereof, Seller shall order from the title company a preliminary title report, with a special tax search included, in the form of a commitment to issue the required title policy requested by Buyer ("title report"). A copy of the title report shall be delivered to Buyer. Within 10 days after Buyer receives the title report, he shall notify Seller and the title company of all restrictions, reservations, limitations, easements, and conditions of record ("title defects") disclosed in the title report that are objectionable to Buyer. If Buyer notifies Seller of title defects, Seller shall cure or remove the same by the closing date. Seller shall remove all objectionable title defects that may be removed by the payment of money, and shall take reasonable steps necessary to remove all other title defects.

                                On the closing date, the escrow agent shall notify the parties whether the title company can issue its title guaranty or title insurance, showing as exceptions only those items in the title report to which Buyer did not object. If the escrow agent notifies the parties that (a) the title company will issue such title guaranty or title insurance, this transaction shall be consummated in accordance with the terms and provisions of this Agreement, or (b) the title company will not issue such title guaranty or title insurance, and if Buyer does not immediately waive the title defects claimed by the escrow agent to prevent such issuance or Seller does not cure the defects within the permitted period, this Agreement shall be null and void, the escrow agent shall return to the parties all funds and documents previously deposited by them into escrow, and the parties shall be fully released from any liability or obligation hereunder, except that Seller shall pay the full cost of the escrow and the title company's charges.

                                If Buyer waives the title defects preventing issuance of the title guaranty or title insurance by notifying the title company and the escrow agent, or if Seller has cured the title defects, the obligations of the parties shall not be affected by them.

                                Warranties

                                <br /> Seller warrants and represents to Buyer as follows:

                                <br /> (a) Encroachments. All improvements now on the Property are entirely within the boundary lines of the land described as the Real Property, and no other adjoining property encroaches upon the land;

                                <br /> (b) Mechanic's liens. All work and labor performed and all materials furnished with respect to any improvements or repairs to the Real Property have been paid in full, and there will be no mechanic's liens or the possibility of any in connection with any such work, labor and materials performed on or furnished to the Real Property;

                                <br /> (c) Violations. Seller has no knowledge of any outstanding notices or orders from any governmental authority with respect to the condition of the Property or its repair, or with respect to any claim of a violation of any laws, ordinances, zoning codes, building codes or orders;

                                <br /> (d) Structural soundness. To Seller's best knowledge and belief, the personal property included as a part of this transaction and the improvements on the Property are structurally sound and in good condition and repair, including, without limitation, the roof, foundation, walls, heating and cooling system, plumbing, water and electrical systems; and

                                <br />(e) Assessments. All improvements made by the local government that now benefit or will benefit the Property upon completion have been assessed against it as of the execution date of this Agreement.

                                <br /> All of Seller's foregoing warranties and representations shall be continuing in nature, shall be effective on the closing date, and shall survive Seller's delivery of the deed to Buyer and the consummation of all matters and things at the closing.

                                <br />  Inspection and Acceptance

                                <br /> Prior to the closing date, Seller agrees to correct, at Seller's expense, any violations found in connection with the inspection done to comply with the point-of-sale or similar ordinance required by the local governmental authority, if any. Evidence in writing that the corrections were made to the satisfaction of the local governing authority shall be given to Buyer prior to the closing date.

                                Prorations

                                <br />  All general and special real estate taxes and assessments shall be prorated by the escrow agent as of the date the deed is filed for record, using the rate and valuation shown on the last available tax duplicate. If the proration does not fully reimburse Buyer for all real estate taxes and assessments accrued and unpaid with respect to the Property as of the record date of transfer of title, Seller shall promptly reimburse Buyer that amount accrued in excess of the proration credit upon the official certification of the real estate tax duplicate for the year in which transfer of title occurs. The escrow agent shall prorate any rents as of the date the deed is filed for record.

                                There shall be no proration of utilities. Seller shall cause the meters to be read for water, electricity, gas and other utilities, if any, as of the date of delivery of possession, and shall pay all utility charges to the date of the readings, after which the charges shall accrue to Buyer. The sum of $200 shall be held in escrow as security for the payment of utilities until Seller deposits into escrow receipts evidencing such payment.

                                <br />   Financing

                                <br />This Agreement is not contingent upon Buyer obtaining the necessary finance for the consummation of this Agreement.

                                <br /> Closing

                                <br /> Closing is the date on which the Seller-executed deed is released to Buyer. The closing date is designated as _________________, provided there are no unforeseen delays such as clearing title. Time is of the essence, and in no event shall closing be later than 0 calendar days after the designated closing date, unless an extension is agreed upon in writing between Buyer and Seller.

                                <br /> Escrow Agent

                                <br />  This transaction shall be closed in escrow with an escrow agent of Buyer's choice ("escrow agent"). Buyer shall deposit with the escrow agent an executed counterpart of this Agreement, which shall serve as the escrow instructions. The escrow agent may attach its standard conditions of acceptance, but if they are inconsistent or conflict with the terms of this Agreement, this Agreement shall control.

                                <br />  Closing Documents and Funds

                                <br /> If on the closing date<br /> (1) the escrow agent then has on hand all necessary funds and documents to complete the transaction, and <br />(2) the title company has stated that it shall be in a position to and will issue and deliver, upon the filing of the deed for record, the required title guaranty policy or title insurance, the escrow agent shall thereupon record the deed and all other required instruments and shall deliver to each party the funds and documents to which it is entitled, together with the agent's escrow statement.

                                <br /> Both Buyer and Seller have to submit all documentation and other information requested by the title company/escrow agent needed to close the transaction. The parties may have to fix a date and time with the title company/escrow agent to close the transaction.

                                <br /> Possession

                                <br />  Seller shall deliver possession of the Property as of the closing date, in the condition as warranted by Seller and in no event in any worse condition than the condition as of the date of execution of this Agreement, less reasonable wear and tear.

                                <br />  Risk of Loss

                                <br /> Risk of loss or damage to the property by fire or other casualty occurring up to time of closing is assumed by Seller. If the building or buildings or any other improvements on the Property are damaged or destroyed before transfer of record title, and the damages are $3,000 or less, this Agreement shall continue in full force and effect and Seller shall immediately assign to Buyer all of Seller's rights to the resulting insurance proceeds. If the damages are greater than $3,000, Buyer may either (a) continue this Agreement in full force and effect, and Seller shall immediately assign to Buyer all of Seller's rights to the insurance proceeds, or (b) rescind this Agreement, and all money, papers, or documents deposited by the parties shall be returned to them. Buyer shall assume risk of loss after record title to the Property is transferred to him. There shall be no proration of insurance. Seller shall retain his insurance until record title is transferred to Buyer, and Buyer shall procure his own policies of insurance to be effective from and after the date title to the Property is transferred to Buyer or his nominee.

                                Release of Dower
                                <br />
                                <br /> Seller's spouse (if Seller is married), if not already a party, joins in the signing of this Agreement to evidence his or her consent to the terms and provisions hereof, including the obligation to release of all dower and other marital rights in the Property upon its conveyance to Buyer.

                                <br /> Notice

                                <br /> All notices under this Agreement shall be deemed to be sufficiently given if personally delivered or sent by certified or registered mail, postage prepaid, return receipt requested, and addressed to the parties abovementioned addresses.

                                <br /> Miscellaneous Provisions

                                <br /> (a) Governing Law: The laws of the State of _________________ shall govern the Agreement.

                                <br />(b) Parties Bound: This Agreement shall be binding on and inure to the benefit of the parties to this Agreement and their respective heirs, executors, administrators, legal representatives, successors and assigns as permitted by this Agreement.

                                <br />(c) Severability: In the event that any one or more of the provisions contained in this Agreement shall for any reason be held invalid, illegal, or unenforceable in any respect, that invalidity, illegality, or unenforceability shall not affect any other provision. This Agreement shall be construed as if the invalid, illegal, or unenforceable provision had never been contained in it.

                                <br />(d) Merger Clause: This Agreement, when executed by both Buyer and Seller, shall contain the entire understanding and agreement between Buyer and Seller and Agent, if any, with respect to the matters referred to herein and shall supersede all prior or contemporaneous agreements, representations and understanding with respect to such matters.

                                <br />(e) Force Majeure: If performance of this Agreement or any obligation under this Agreement is prevented, restricted, or interfered with by causes beyond either party's reasonable control <br />("Force Majeure"), and if the party unable to carry out its obligations gives the other party prompt written notice of such event, then the obligations of the party invoking this provision shall be suspended to the extent necessary by such event. The term Force Majeure shall include, without limitation, acts of God, plague, epidemic, pandemic, outbreaks of infectious disease or any other public health crisis, including quarantine or other employee restrictions, fire, explosion, vandalism, storm or other similar occurrence, orders or acts of military or civil authority, or by national emergencies, insurrections, riots, or wars, or strikes, lock-outs, work stoppages or other labor disputes, or supplier failures. Lack of financial resources on the part of either party shall not be a Force Majeure Event. The excused party shall use reasonable efforts under the circumstances to avoid or remove such causes of non-performance and shall proceed to perform with reasonable dispatch whenever such causes are removed or ceased. An act or omission shall be deemed within the reasonable control of a party if committed, omitted, or caused by such party, or its employees, officers, agents, or affiliates.

                                <br />(f) Amendments. This Agreement may be amended by the parties only by a written agreement.

                                <br />(g) Attorneys' Fees: If any action at law or in equity is brought to enforce or interpret the provisions of this Agreement, the prevailing party will be entitled to reasonable attorneys' fees in addition to any other relief to which that party may be entitled.

                                <br />(h) Headings: Headings used in this Agreement are provided for convenience only and shall not be used to construe meaning or intent.

                                <br />In witness of the mutual promises made above, Seller and Buyer have executed this contract.
                              </div> */}

                            </div>
                          </div>
                        )}
                        {/* for owner detail block  */}
                        {this.state.reviewAgreementState.ownerDetails && (
                          <div className="filter-content">
                            <div className="content-wrapper">
                              <div className="content">
                                <span className="title">Name :</span>
                                <span className="value">{`${this.state.ownerDetails?.first_name} ${this.state.ownerDetails?.middle_name ? this.state.ownerDetails?.middle_name + " " : ""}${this.state.ownerDetails?.last_name}`}</span>
                              </div>
                              <div className="content">
                                <span className="title">Address :</span>
                                <div className="value">
                                  <p>{this.state.ownerDetails?.address?.address_line}</p>
                                  <p>{this.state.ownerDetails?.address?.address_line2}</p>
                                  <p className="more-info">
                                    {this.state.ownerDetails?.address?.city}, {this.state.ownerDetails?.address?.state} {this.state.ownerDetails?.address?.zip_code}
                                  </p>
                                </div>
                              </div>
                              <div className="content">
                                <span className="title">Phone Number :</span>
                                <span className="value">{this.state.ownerDetails?.country_code} {this.state.ownerDetails?.full_phone_number}</span>
                              </div>
                              <div className="content">
                                <span className="title">Date of Birth :</span>
                                <span className="value">{this.state.ownerDetails?.date_of_birth}</span>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* for buyer detail block  */}
                        {this.state.reviewAgreementState.buyerDetails && (
                          <div className="filter-content">
                            <div className="content-wrapper">
                              <div className="content">
                                <span className="title">Name :</span>
                                <span className="value">{`${this.state.buyerDetails?.first_name} ${this.state.buyerDetails?.middle_name ? this.state.buyerDetails?.middle_name + " " : ""}${this.state.buyerDetails?.last_name}`}</span>
                              </div>
                              <div className="content">
                                <span className="title">Address :</span>
                                <div className="value">
                                  <p>{this.state.buyerDetails?.address?.address_line}</p>
                                  <p>{this.state.buyerDetails?.address?.address_line2}</p>
                                  <p className="more-info">
                                    {this.state.buyerDetails?.address?.city}, {this.state.buyerDetails?.address?.state} {this.state.buyerDetails?.address?.zip_code}
                                  </p>
                                </div>
                              </div>
                              <div className="content">
                                <span className="title">Phone Number :</span>
                                <span className="value">{this.state.buyerDetails?.country_code} {this.state.buyerDetails?.full_phone_number}</span>
                              </div>
                              <div className="content">
                                <span className="title">Date of Birth :</span>
                                <span className="value">{this.state.buyerDetails?.date_of_birth}</span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="right-block">
                    <div className="title">
                      <span>Notes</span>
                    </div>
                    <p className="content">
                      In witness of the mutual promises made above, Seller and Buyer have executed this contract.
                    </p>
                    <Formik
                      initialValues={{
                        owner_sign: this.props.location?.state?.aggrementData?.ownerSignature?.sign_done ? this.props.location?.state?.aggrementData?.ownerSignature?.name : "",
                        buyer_sign: this.props.location?.state?.aggrementData?.buyerSignature?.sign_done ? this.props.location?.state?.aggrementData?.buyerSignature?.name : "",
                        agreementCheck: false,
                      }}
                      validationSchema={Yup.object().shape(this.state.agreementSchema)}
                      onSubmit={(values) => {
                        this.doAgreementSign(values, 1);
                        this.getAccountDetail();
                        this.setState({
                          agreementState: {
                            billingDetail: true,
                            paymentMethod: true
                          }
                        });
                        this.setState({
                          completeAgreementState: {
                            review: true,
                            billing: true
                          }
                        });
                      }}
                      enableReinitialize
                    >
                      {({ values, setFieldValue, errors }) => (
                        <Form>
                          <div className="title">
                            <span>Owner Signature</span>
                          </div>
                          <div className="field-wrapper">
                            <Field
                              type="text"
                              name="owner_sign"
                              placeholder="write your Name…"
                              className="custom-input secondary-input"
                            />
                            <span className="error">
                              <ErrorMessage name="owner_sign" />
                            </span>
                          </div>
                          <div className="title">
                            <span>Buyer Signature</span>
                          </div>
                          <div className="field-wrapper">
                            <Field
                              type="text"
                              name="buyer_sign"
                              placeholder="write your Name…"
                              className="custom-input secondary-input"
                            />
                            <span className="error">
                              <ErrorMessage name="buyer_sign" />
                            </span>
                          </div>
                          <div className="checkbox-wrapper">
                            {/* <label>
                              <input
                                type="checkbox"
                                id="agreement"
                                name="agreementCheck"
                                className="input-checkbox"
                              />
                              Check here to indicate that you have read and
                              agree to Agreement.
                              <span className="checkmark" />
                            </label>
                            <span className="error">
                              <ErrorMessage name="agreementCheck" />
                            </span> */}

                            <label htmlFor="agreement">
                              <Field
                                type="checkbox"
                                id="agreement"
                                name="agreementCheck"
                                className="input-checkbox"
                              />
                              Check here to indicate that you have read and
                              agree to Agreement.
                              <span className="checkmark" />
                            </label>
                            <div>
                              <span className="error">
                                <ErrorMessage name="agreementCheck" />
                              </span>
                            </div>

                          </div>
                          <div className="btn-wrapper">
                            <button
                              type="button"
                              className="btn gray-fill-btn"
                            >
                              Update Agreement
                            </button>
                            <button className="rounded-fill-btn" type="submit">
                              Next
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
              {/* for billing detail block  */}
              {/* {values.billingDetail && (
                      <div className="filter-content">Billing detail</div>
                    )} */}
              {/* for payment method block  */}
              {this.state.agreementState.billingDetail && (
                <div className="payment-method-wrapper">
                  <div className="left-block">
                    <p className="title">Property Details</p>
                    <div className="property-view">
                      <div className="left-block">
                        <div className="image-block">
                          <img src={siginImage} alt="" />
                        </div>
                        <div className="property-detail">
                          <p className="title">37577 Susan St.</p>
                          <p className="sub-tile">
                            Sterling Heights, MI 48310
                          </p>
                          <p className="rent-tag">For Rent</p>
                        </div>
                      </div>
                      <div className="right-block">
                        <p className="label">Agreed upon</p>
                        <span className="amount">$234,003</span>
                      </div>
                    </div>
                    <div className="label-count-wrapper">
                      <div className="block-outer">
                        <div className="block-wrapper">
                          <span className="label">Down Payment</span>
                          <span className="value">$12,750</span>
                        </div>
                        <div className="block-wrapper">
                          <span className="label">Principle</span>
                          <span className="value">$242,250</span>
                        </div>
                        <div className="block-wrapper">
                          <span className="label">Interest (APR)</span>
                          <span className="value">0%</span>
                        </div>
                      </div>
                      <div className="block-outer">
                        <div className="block-wrapper">
                          <span className="label">Down Payment</span>
                          <span className="value">$12,750</span>
                        </div>
                        <div className="block-wrapper">
                          <span className="label">Taxes</span>
                          <span className="value">$397/month</span>
                        </div>
                        <div className="block-wrapper">
                          <span className="label">Home Insurance</span>
                          <span className="value">$89/month</span>
                        </div>
                        <div className="block-wrapper">
                          <span className="label">Maintenance</span>
                          <span className="value">$140/month</span>
                        </div>
                        <div className="block-wrapper">
                          <span className="label">Transaction Fee</span>
                          <span className="value">$50/month</span>
                        </div>
                        <div className="block-wrapper">
                          <span className="label">Term</span>
                          <span className="value">20 Years</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-block">
                    <p className="title">Payment Details</p>
                    <Formik
                      initialValues={{
                        downpayAcc: "",
                        monthlyAcc: ""
                      }}
                      // validationSchema={Yup.object().shape(this.state.agreementSchema)}
                      onSubmit={(values) => {

                        this.setState({
                          agreementState: {
                            billingDetail: true,
                            paymentMethod: true
                          }
                        });
                        this.setState({
                          completeAgreementState: {
                            billing: true,
                            payment: true
                          }
                        });
                        this.setState({ sucessAgreementShow: true });
                      }}
                      enableReinitialize
                    >
                      {({ values, setFieldValue, errors }) => (
                        <Form>
                          <div className="block-wrapper">
                            <div className="left-block">
                              <span className="label">Down Payment</span>
                              <span className="value">$12,750</span>
                            </div>
                            {!isEmpty(this.state.accDetail?.bank_accounts) ?
                              (<div className="right-block">
                                <div className="field-wrapper">
                                  <Field
                                    type="text"
                                    as="select"
                                    name="downpayAcc"
                                    value={values.downpayAcc ? values.downpayAcc : "Select Account"}
                                    autoComplete="off"
                                    className="custom-input secondary-input "
                                  >
                                    <option value="Select Account">
                                      Select Account
                                    </option>
                                    {this.state.accDetail?.bank_accounts?.data.map((acc, i) => (
                                      <option value={acc?.attributes?.institution_name} key={i}>
                                        {acc?.attributes?.institution_name}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                              </div>) : ""
                            }
                          </div>
                          {isEmpty(this.state.accDetail?.bank_accounts) ?
                            (<div className="link-bank">
                              <PlaidLink
                                className="btn orange-fill-btn"
                                token={this.state.token}
                                onSuccess={this.onSuccess}
                                onExit={() => console.log("Exit")}
                                onEvent={() => console.log("Event")}
                              >
                                Link Bank Account
                              </PlaidLink>
                            </div>) : ""
                          }
                          <div className="block-wrapper">
                            <div className="left-block">
                              <span className="label">Monthly Payment</span>
                              <span className="value">$12,750</span>
                            </div>
                            {!isEmpty(this.state.accDetail?.bank_accounts) ?
                              (<div className="right-block">
                                <div className="field-wrapper">
                                  <Field
                                    type="text"
                                    as="select"
                                    name="monthlyAcc"
                                    value={values.monthlyAcc ? values.monthlyAcc : "Select Account"}
                                    autoComplete="off"
                                    className="custom-input secondary-input "
                                  >
                                    <option value="Select Account">
                                      Select Account
                                    </option>
                                    {this.state.accDetail?.bank_accounts?.data.map((acc, i) => (
                                      <option value={acc?.attributes?.institution_name} key={i}>
                                        {acc?.attributes?.institution_name}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                              </div>) : ""
                            }
                          </div>
                          {isEmpty(this.state.accDetail?.bank_accounts) ?
                            (<div className="link-bank">
                              <PlaidLink
                                className="btn orange-fill-btn"
                                token={this.state.token}
                                onSuccess={this.onSuccess}
                                onExit={() => console.log("Exit")}
                                onEvent={() => console.log("Event")}
                              >
                                Link Bank Account
                              </PlaidLink>
                            </div>) : ""
                          }
                          <div className="btn-wrapper">
                            <button className="rounded-fill-btn" type="submit" disabled={!values.downpayAcc || !values.monthlyAcc}>
                              Pay Now
                            </button>
                            <span className="more-detail">
                              Notes : Pay for Property
                            </span>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>

        {/* Success Agreement complition */}
        <SuccessAgreement handlesucessAgreementShow={this.sucessAgreementHide} sucessAgreementShow={this.state.sucessAgreementShow} />

      </>
    );
  }
}

// Customizable Area Start
export default withRouter(Agreement);

// Customizable Area End
