//@ts-nocheck
import React from "react";
// Customizable Area Start
import { Modal, InputGroup, FormControl, Button } from "react-bootstrap";

import PopupsController, { Props } from "./PopupsController.web";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";

// Customizable Area End


// const MapWithAMarker = withScriptjs(withGoogleMap(props =>
//     <GoogleMap
//         defaultZoom={8}
//         defaultCenter={{ lat: -34.397, lng: 150.644 }}
//     >
//         <Marker
//             position={{ lat: -34.397, lng: 150.644 }}
//         />
//     </GoogleMap>
// ));

function Map() {
    return (
        <GoogleMap
            defaultZoom={8}
            defaultCenter={{ lat: -34.397, lng: 150.644 }}
        >
            <Marker
                position={{ lat: -34.397, lng: 150.644 }}
            />
        </GoogleMap>
    )
}
const WrappedMap = withScriptjs(withGoogleMap(Map));
class TravelTime extends PopupsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        return (
            <Modal
                show={this.props.timetravelShow}
                onHide={this.props.handleTimetravel}
                dialogClassName="time-travel-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Travel Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="modal-wrapper">
                            <div className="content-wrapper">
                                <div className="map-block-wrapper">
                                    <WrappedMap
                                        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDoEiEjbyqQ4D_5aABleiKOxMqvcG39Wf0"
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `400px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                    />

                                </div>
                                <div className="form-current-loc">
                                    {/* <Form.Control type="text" placeholder="Please Enter Your Current Location" /> */}
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            placeholder="Please Enter Your Current Location"
                                            aria-label="Please Enter Your Current Location"
                                            aria-describedby="search-loc-filed"
                                        />
                                        <Button id="search-loc" />
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

// Customizable Area Start
export default TravelTime;

// Customizable Area End
