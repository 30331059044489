import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import * as Yup from "yup";
import React from "react";

// Customizable Area Start
import { isEqual, isEmpty, property } from "lodash";
// Customizable Area End

export const configJSON = require("../config.js");

export interface Props {
    navigation: any;
    id: string;
    location: any;
    // Customizable Area Start
    onHideCounterOfferModal: (arg: any) => void;
    chatList: any;
    setRecipientId: (id: any) => void;
    setConversationId: (id: any) => void;
    message: any;
    setCounterOfferDetail: (id: any, price: any, down_pay: any, type: any, isCOShow: boolean) => void;
    sendMessageData: (data: any) => void;
    coversationId: any;
    recipientId: any;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    loader: boolean;
    snackBar: {
        show: boolean;
        message?: string;
        type?: "success" | "info" | "warning" | "error" | undefined;
    };
    chats: any;
    coversationId: any;
    message: any;
    recipientId: number;
    file: any;
    scheduleTourShow: boolean;
    propertyId: any;
    search: any;
    displayChat: any;
    nextSevenDays: any;
    tourSchema: any;
    tourId: any;
    counterOfferShow: boolean;
    counterOfferSchema: any;
    counterOfferId: any;
    limit: number;
    propertyPrice: any;
    downPayment: number;
    offerType: any;
    HeaderDropdownFlag: any;

}
interface SS {
    id: any;
}

export default class ChatParentController extends BlockComponent<Props, S, SS> {
    getmessageApiCallId: string = "";
    sendmessageApiCallId: string = "";
    conversationApiCallId: string = "";
    deleteConversationApiCallId: string = "";
    schedulerApiCallId: string = "";
    setBuyerTourId: string = "";
    counterOfferApiCallId: string = "";
    acceptOfferApiCallId: string = "";


    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        let tourSchema = {
            type: Yup.string().required(configJSON.errorTypeNotValid),
            date: Yup.string().required(configJSON.errorDateNotValid),
            time: Yup.string().required(configJSON.errorTimeNotValid),
            note: Yup.string().required(configJSON.errorNoteNotValid),
        };
        let counterOfferSchema = {
            term: Yup.string().required(configJSON.errorTermNotValid),
            downPayment: Yup.string().required(configJSON.errorDownPayNotValid),
        };

        this.state = {
            loader: false,
            snackBar: {
                show: false,
            },
            chats: {},
            message: {},
            coversationId: "",
            recipientId: 0,
            file: [],
            scheduleTourShow: false,
            propertyId: "",
            search: "",
            displayChat: [],
            nextSevenDays: [],
            tourSchema: tourSchema,
            tourId: "",
            counterOfferShow: false,
            counterOfferSchema: counterOfferSchema,
            counterOfferId: "",
            limit: 0,
            propertyPrice: "",
            downPayment: 0,
            offerType: "",
            HeaderDropdownFlag: false,

        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    doSetCounterOfferDetail = (id: any, price: any, down_pay: any, type: any, isCOShow: boolean) => {
        this.setState({
            ...this.state,
            counterOfferId: +id,
            propertyPrice: +price,
            downPayment: +down_pay,
            offerType: type,
            counterOfferShow: isCOShow
        })
    }

    doOfferType = (type: any) => {
        this.setState({
            ...this.state,
            offerType: type
        })
    }

    dosetTourId = (id: any) => {
        this.setState({
            ...this.state,
            tourId: id
        })
    }

    // Accept Scheduler 
    doScheduler = (pid: any, bid: any) => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const attrs = {
            status: "accepted"
        };

        const httpBody = {
            data: attrs,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.schedulerApiCallId = requestMessage.messageId;

        const endpoint = `${configJSON.AcceptRejectSchedulerApiEndPoint}/${pid}/tours/${bid}`

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PutAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    // Accept Offer 
    doAcceptOffer = (pid: any, bid: any, cid: any) => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const attrs = {
            counter_offer_id: cid
        };

        const httpBody = {
            data: attrs,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.acceptOfferApiCallId = requestMessage.messageId;

        const endpoint = `${configJSON.setBuyerTourApiEndPoints}${pid}/buyer_offers/${bid}/accept`


        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.PutAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    // Filter Message 
    doSearch = (value: any) => {
        let newData = Object.assign([], this.state.chats)
        let updateData;
        if (isEmpty(value)) {
            updateData = newData
        } else {
            // @ts-ignore
            updateData = newData.filter(x => x?.attributes?.communicator?.data?.attributes?.first_name && x?.attributes?.communicator?.data?.attributes?.first_name?.toLowerCase()?.includes(value?.toLowerCase()) || x?.attributes?.communicator?.data?.attributes?.last_name?.toLowerCase()?.includes(value?.toLowerCase()))
        }
        this.setState({
            ...this.state,
            search: value,
            displayChat: updateData,
        })
    }

    // componentDidUpdate = (prevProps: any, prevState: any) => {
    //     if (prevState.coversationId !== this.state.coversationId) {
    //         this.getMessages(this.state.coversationId);
    //     }
    // }

    // Buyer Tour
    doBuyerTour = (values: any, id: number) => {
        const header = {
            "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };

        const attrs = {
            tour_type: values.type,
            tour_at: values.date + " " + values.time,
            note: values.note,
        };

        const httpBody = {
            data: attrs,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.setBuyerTourId = requestMessage.messageId;

        const endpoint = `${configJSON.AcceptRejectSchedulerApiEndPoint}${id}/tours/${this.state.tourId}/other_time`;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // Update Conversation Id 
    doConversationId = (id: any) => {
        this.setState({
            ...this.state,
            coversationId: id
        }, () => this.getMessages(this.state.coversationId))
    }

    // Update Recipient Id 
    doRecipientId = (id: any) => {
        this.setState({
            ...this.state,
            recipientId: id
        })
    }

    //  GET ALL CONVERSATION
    getAllConversation = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: isEmpty(localStorage.getItem("auth"))
                ? ""
                : localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.conversationApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllConversationListApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        this.setState({
            ...this.state,
            loader: true,
        });

        return true;
    };

    //  GET ALL MESSAGES
    getMessages = (id: any): boolean => {
        const headers = {
            // "Content-Type": configJSON.validationApiContentType,
            token: isEmpty(localStorage.getItem("auth"))
                ? ""
                : localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getmessageApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getMessagesApiEndPoint}${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        this.setState({
            ...this.state,
            loader: true,
        });

        return true;
    };

    // SEND MESSAGE
    doMessaging = (values: any): boolean => {
        const formData = new FormData();
        formData.append("message[conversation_id]", values.conversation_id);
        formData.append("message[recipient_id]", values.recipient_id);
        formData.append("message[body]", values.message);
        values.attachments.map((img: any, index: any) => {
            return formData.append(`message[attachments][]`, img);
        });
        const header = {
            // "Content-Type": configJSON.loginApiContentType,
            token: isEmpty(localStorage.getItem("auth"))
                ? ""
                : localStorage.getItem("auth"),
        };

        const attrs = formData;


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.sendmessageApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendMessagesApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        this.setState({
            ...this.state,
            loader: true,
        });

        return true;
    };

    // DELETE CONVERSATION
    deleteConv = (id: any): boolean => {
        const headers = {
            // "Content-Type": configJSON.validationApiContentType,
            token: isEmpty(localStorage.getItem("auth"))
                ? ""
                : localStorage.getItem("auth"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteConversationApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteConversationApiEndPoint}${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.DeleteAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        this.setState({
            ...this.state,
            loader: true,
        });

        return true;
    };

    // COUNTER OFFER
    doCounterOffer = (values: any): boolean => {

        const header = {
            // "Content-Type": configJSON.dashboarContentType,
            token: localStorage.getItem("auth"),
        };
        const formData = new FormData();
        formData.append("data[term_in_year]", values.term);
        formData.append("data[down_payment]", values.downPayment);

        if (values.messageType == "property_offer") {
            formData.append("data[buyer_offer_id]", values.offerId);
        } else {
            formData.append("data[counter_offer_id]", values.offerId);
        }

        // const attrs = {
        //   data["term_in_year"]: values.term,
        //   data["down_payment"]: values.downPayment,
        //   data["buyer_offer_id"]: values.offerId,
        // };
        const httpBody = {
            data: formData,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.counterOfferApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.counterOfferApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            // JSON.stringify(httpBody)
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        this.setState({
            ...this.state,
            loader: true,
        });

        return true;
    };


    // Open View Details Modal
    openSnackBarHandler = (
        type: "success" | "info" | "warning" | "error" | undefined,
        message: string
    ): void => {
        this.setState({
            snackBar: {
                show: true,
                message: message,
                type,
            },
        });
    };

    // Close View Details Modal
    closeSnackBarHandler = () => {
        this.setState({
            snackBar: {
                show: false,
                message: this.state.snackBar.message,
                type: this.state.snackBar.type,
            },
        });
    };
    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // runEngine.debugLog("runEngine.debugLog", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId != null) {
                    //Conversation API Get
                    if (
                        apiRequestCallId === this.conversationApiCallId &&
                        responseJson !== undefined
                    ) {
                        this.setState({
                            ...this.state,
                            displayChat: responseJson?.data,
                            chats: responseJson?.data,
                            loader: false,
                            coversationId: responseJson?.data?.[0]?.id,
                            recipientId: responseJson?.data?.[0]?.attributes?.recipient_id
                        }, () => this.getMessages(this.state.coversationId));
                        // this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    //Message API Get
                    if (
                        apiRequestCallId === this.getmessageApiCallId &&
                        responseJson !== undefined
                    ) {
                        this.setState({
                            ...this.state,
                            message: responseJson,
                            loader: false,
                        });
                        // this.openSnackBarHandler("success", configJSON.successLogin);
                    }
                    if (
                        apiRequestCallId === this.deleteConversationApiCallId &&
                        responseJson !== undefined
                    ) {
                        this.setState({
                            ...this.state,
                            loader: false,
                        });
                        this.getAllConversation();
                        this.openSnackBarHandler("success", "Deleted");
                    }
                    if (
                        apiRequestCallId === this.sendmessageApiCallId &&
                        responseJson !== undefined
                    ) {
                        this.doConversationId(responseJson.data?.attributes?.conversation_id);
                        this.getMessages(this.state.coversationId);
                        this.openSnackBarHandler("success", "Sent");
                    }
                    if (
                        apiRequestCallId === this.schedulerApiCallId &&
                        responseJson !== undefined
                    ) {
                        console.log(":::responseJson:::", responseJson)
                    }
                    // Tour API
                    if (
                        apiRequestCallId === this.setBuyerTourId &&
                        responseJson !== undefined
                    ) {
                        this.handleHideTourModal();
                        // this.handleHideScheduleTourModal();
                        this.setState({ scheduleTourShow: false });
                        // this.setState({  });
                        this.openSnackBarHandler("success", responseJson.message);


                    }
                    // Counter Offer API
                    if (
                        apiRequestCallId === this.counterOfferApiCallId &&
                        responseJson !== undefined
                    ) {
                        this.setState({ counterOfferShow: false });
                        this.handleCloseCounterModal();
                        this.getMessages(this.state.coversationId);
                        this.openSnackBarHandler("success", responseJson.message);
                    }
                    // Accept Counter Offer
                    if (
                        apiRequestCallId === this.acceptOfferApiCallId &&
                        responseJson !== undefined
                    ) {
                        // @ts-ignore
                        this.props?.history?.push({
                            pathname: "/property-list",
                        });
                        this.openSnackBarHandler("success", responseJson.message);
                    }
                }
            } else {
                this.parseApiCatchErrorResponse(errorReponse);
                const errors = responseJson?.errors;
                if (errors.property !== undefined) {
                    this.openSnackBarHandler("error", errors.property);
                } else if (errors[0].message) {
                    this.openSnackBarHandler("error", errors[0].message);
                } else if (errors[0].offer) {
                    this.openSnackBarHandler("error", errors[0]?.offer);
                } else if (errors[0]) {
                    this.openSnackBarHandler("error", errors[0]?.[Object.keys(errors[0])[0]]);
                }
                else {
                    this.openSnackBarHandler("error", errors[0]);
                }
            }
        }
    }

    handleAttachement = (e: any, setFieldValue: any) => {
        if (e.target.files) {
            const filesArray = Array.from(e.target.files).map(
                (file: any, index: number) => {
                    return file;
                }
            );
            this.setState({ file: this.state.file.concat(filesArray) }, () => {
                setFieldValue("attachments", this.state.file);
            });
        }
    };

    handleShowScheduleTour = (value: boolean) => {
        this.setState({ scheduleTourShow: value })
    }

    handleShowTourModal = (id: any) => {
        this.setState({
            propertyId: id,
            scheduleTourShow: true
        })
    };

    handleHideTourModal = () => this.setState({ scheduleTourShow: false });
    tourDates = () => {
        const date = new Date();

        let dates: any[] = [];
        let week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        for (var i = 0; i < 7; i++) {
            dates.push([
                i,
                `${date.getFullYear()}/${date.getMonth() +
                1}/${date.getDate() + i}`,
                date.getDate() + i,
                week[(date.getDay() + i) % 7],
            ]);

            //  dates.push({  
            //   id:i,
            //   fullDate: `${date.getFullYear()}/${date.getMonth() +
            //     1}/${date.getDate() + i}`,
            //   date: date.getDate() + i,
            //   day: week[(date.getDay() + i) % 7],
            // });
        }
        this.setState({ nextSevenDays: dates });
    };

    handleDateFormat = (dateVal: any) => {
        return dateVal.getFullYear() + "-" + (dateVal.getMonth() + 1) + "-" + dateVal.getDate();
    }

    handleCloseCounterModal = () => {
        this.props.onHideCounterOfferModal(false)
        return false;
    }

    onHideCounterOfferModal = (val: any) => {
        this.setState({ counterOfferShow: val })
    }


    roundData = (data: any) => {
        //@ts-ignore
        return +(Math.round(data + "e+2") + "e-2");
    }

    setLimitHandler = (event: any, newLimit: number, setFieldValue: any, price: number, field: string) => {
        this.setState(
            {
                limit: newLimit,
            },
            () => {
                setFieldValue(field, `${this.roundData(this.state.limit * price)}`);
            }
        );
    };

    setLimitLabel = (limit: number) => {
        var roundedLimit = Math.round(limit);
        if (roundedLimit > 999 && roundedLimit < 1000000) {
            return (roundedLimit / 1000).toFixed(0) + "K";
        } else if (roundedLimit >= 1000000) {
            return (roundedLimit / 1000000).toFixed(0) + "M";
        } else if (roundedLimit < 1000) {
            return roundedLimit + "$";
        }
        return `$${roundedLimit}`;
    };

    setPercentHandler = (event: any, newLimit: number, setFieldValue: any, price: number) => {
        this.setState(
            {
                limit: newLimit,
            },
            () => {
                setFieldValue("downPayment", `${this.roundData(price * this.state.limit)}`);
                setFieldValue("downPayLimit", this.state.limit)
            }
        );
    };

    percentLimit = (limit: number) => {
        return Math.round(limit) + "%";
    }

    doToggleHeaderDropdown = () => {
        this.setState({
            ...this.state,
            HeaderDropdownFlag: !this.state.HeaderDropdownFlag
        })
    }
    // Customizable Area End
}
