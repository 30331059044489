// @ts-nocheck

import React from "react";
// Customizable Area Start
import Sidebar from "../../../../../components/src/Sidebar";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
export const configJSON = require("../../config");
import { fileImg, errorImg } from "../../assets";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// Customizable Area End
import AddPropertyFlowController, { Props } from "./AddPropertyFlowController.web";

class StepVChecklist extends AddPropertyFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getAmanitiesLists();
  }
  // Customizable Area End

  render() {
    const field = this.props?.fieldData?.attributes?.property_amenities?.data;
    const doc = this.props?.fieldData?.attributes;
    // doc?.appraisal_document?this.setState()
    return (
      < >
        <h3 className="page-title">Check List</h3>
        <div className="stepper-sections">
          <Formik
            innerRef={this.props.formRef}
            initialValues={{
              appraisalDoc: this.props?.fieldData?.attributes?.appraisal_document || null,
              inspectionDoc: this.props?.fieldData?.attributes?.inspection_document || null,
              titleDoc: this.props?.fieldData?.attributes?.title_report || null,
              propertyAmenities: field && field.length != 0 ? field?.map((key) => ({
                id: key?.attributes?.id,
                amenity_id: key?.attributes?.amenity_id,
                status: key?.attributes?.status,
                note: key?.attributes?.note,
              })) :
                this.state.amanitiesList && this.state.amanitiesList != 0 &&
                this.state.amanitiesList.map((amanitiesType: any, index) => ({
                  id: "",
                  amenity_id: +amanitiesType.id,
                  status: "na",
                  note: "",
                })),
              remove_appraisal_document: this.props?.fieldData?.attributes?.appraisal_document ? false : true,
              remove_inspection_document: this.props?.fieldData?.attributes?.inspection_document ? false : true,
              remove_title_report: this.props?.fieldData?.attributes?.title_report ? false : true,
            }}
            // validationSchema={Yup.object().shape(this.state.verifyOtpSchema)}

            onSubmit={(values) => {
              // this.doStepSet(6);
              // this.props.history.push({
              //   pathname: "/add-property/review-upload",
              //   state: { steps: this.state.step,data:this.props?.fieldData?.attributes?},
              // });
              this.doCheckList(values, this.props?.fieldData?.id);

            }}
            enableReinitialize
            validateOnMount
          >
            {({ values, setFieldValue, errors }) => (
              //@ts-ignore
              <Form>
                {/* Amenities  */}

                <div className="checklist-sec">
                  <div className="media-heading">
                    <span className="green-title">Check List</span>
                  </div>

                  <div className="sub-section">
                    <h2 className="sec-title">Amenities</h2>
                    <FieldArray name="propertyAmenities">
                      {({ push, remove }) => (
                        <>
                          {this.state.amanitiesList &&
                            this.state.amanitiesList.map(
                              (amanitiesType: any, index) => (
                                <div
                                  className="field-wrapper"
                                  key={amanitiesType.id}
                                >
                                  <p className="sec-sub-title">
                                    {amanitiesType.attributes.name}
                                  </p>
                                  <button
                                    className={
                                      values.propertyAmenities[index]?.status ==
                                        "working"
                                        ? "btn step-btn orange-btn"
                                        : "btn step-btn bordered-btn"
                                    }
                                    type="button"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      setFieldValue(
                                        `propertyAmenities[${index}].status`,
                                        "working"
                                      );

                                    }}
                                  >
                                    This Works
                                  </button>
                                  <button
                                    className={
                                      values.propertyAmenities[index]?.status ==
                                        "not_working"
                                        ? "btn step-btn orange-btn"
                                        : "btn step-btn bordered-btn"
                                    }
                                    type="button"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      setFieldValue(
                                        `propertyAmenities[${index}].status`,
                                        "not_working"
                                      );

                                    }}
                                  >
                                    Does Not Work
                                  </button>
                                  {values.propertyAmenities[index]?.status ===
                                    "not_working" && (
                                      <Field
                                        type="text"
                                        name={`propertyAmenities[${index}].note`}
                                        placeholder={configJSON.noteHolder}
                                        className="custom-input secondary-input sec-input"
                                      />
                                    )}
                                  <span className="error">
                                    <ErrorMessage
                                      name={`propertyAmenities[${index}]`}
                                    />
                                  </span>
                                </div>
                              )
                            )}
                        </>
                      )}
                    </FieldArray>
                  </div>
                </div>

                {/*Properties Document*/}
                <div className="checklist-sec">
                  <div className="media-heading">
                    <span className="green-title">Properties Document</span>
                  </div>

                  <div className="sub-section">
                    <div className="field-wrapper">
                      <h2 className="sec-title">Do you have Appraisal?</h2>

                      <button
                        className={
                          !values.remove_appraisal_document
                            ? "btn step-btn orange-btn"
                            : "btn step-btn bordered-btn"
                        }
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          this.setState({
                            propertiesDoc: {
                              ...this.state.propertiesDoc,
                              appraisal: true,
                            },
                          });
                          // setFieldValue( delete values.remove_appraisal_document)
                          setFieldValue("remove_appraisal_document", false);


                        }}
                      >
                        Yes
                      </button>
                      <button
                        className={
                          values.remove_appraisal_document
                            ? "btn step-btn orange-btn"
                            : "btn step-btn bordered-btn"
                        }
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          this.setState({
                            propertiesDoc: {
                              ...this.state.propertiesDoc,
                              appraisal: false,
                            },
                          });
                          setFieldValue("appraisalDoc", null);

                          setFieldValue("remove_appraisal_document", true);
                        }}
                      >
                        No
                      </button>
                      {!values.remove_appraisal_document ? (
                        <div className="upload-doc-div">
                          <label
                            htmlFor="appraisal-upload-doc"
                            className="dashed-file-border"
                          // onClick={this.showFileUpload}
                          >
                            <img
                              alt="fileImg"
                              className="btn-image"
                              src={fileImg}
                            />
                            {values.appraisalDoc ? (
                              <span>Re-Upload Document</span>
                            ) : (
                              <span>Upload Document</span>
                            )}
                          </label>
                          <input
                            type="file"
                            name="appraisalDoc"
                            id="appraisal-upload-doc"
                            disabled={this.state.file.length === 5}
                            className="custom-input secondary-input uploadImage upload-doc"
                            // ref={this.fileUpload}
                            onChange={(e) => {
                              setFieldValue("appraisalDoc", e.target.files[0]);
                            }}
                          />
                          <span className="doc-span">
                            {typeof values.appraisalDoc == "string"
                              ? decodeURI(
                                values.appraisalDoc.substring(values.appraisalDoc.lastIndexOf('/') + 1)
                              ) : values.appraisalDoc?.name
                            }
                          </span>
                        </div>
                      ) : (
                        <div className="no-doc">
                          <img
                            alt="errorImg"
                            className="btn-image"
                            src={errorImg}
                          />
                          <span>
                            Submit it before a contract can be finalized.
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="field-wrapper">
                      <h2 className="sec-title">Do you have Inspection?</h2>

                      <button
                        className={
                          !values.remove_inspection_document
                            ? "btn step-btn orange-btn"
                            : "btn step-btn bordered-btn"
                        }
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          this.setState({
                            propertiesDoc: {
                              ...this.state.propertiesDoc,
                              inspection: true,
                            },
                          });
                          // setFieldValue( delete values.remove_inspection_document)
                          setFieldValue("remove_inspection_document", false);

                        }}
                      >
                        Yes
                      </button>
                      <button
                        className={
                          values.remove_inspection_document
                            ? "btn step-btn orange-btn"
                            : "btn step-btn bordered-btn"
                        }
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          this.setState({
                            propertiesDoc: {
                              ...this.state.propertiesDoc,
                              inspection: false,
                            },
                          });
                          setFieldValue("inspectionDoc", null);
                          setFieldValue("remove_inspection_document", true);


                        }}
                      >
                        No
                      </button>
                      {!values.remove_inspection_document ? (
                        <div className="upload-doc-div">
                          <label
                            htmlFor="inspection-upload-doc"
                            className="dashed-file-border"
                          // onClick={this.showFileUpload}
                          >
                            <img
                              alt="fileImg"
                              className="btn-image"
                              src={fileImg}
                            />
                            {values.inspectionDoc ? (
                              <span>Re-Upload Document</span>
                            ) : (
                              <span>Upload Document</span>
                            )}
                          </label>
                          <input
                            type="file"
                            name="inspectionDoc"
                            id="inspection-upload-doc"
                            disabled={this.state.file.length === 5}
                            className="custom-input secondary-input uploadImage upload-doc"
                            // ref={this.fileUpload}
                            // onChange={(e)=>this.handleUploadDocChange(e,setFieldValue,"inspectionDoc")}
                            onChange={(e) => {
                              setFieldValue("inspectionDoc", e.target.files[0]);
                            }}
                          />
                          <span className="doc-span">
                            {/* {values.inspectionDoc?.name} */}
                            {typeof values.inspectionDoc == "string"
                              ? decodeURI(
                                values.inspectionDoc.substring(values.inspectionDoc.lastIndexOf('/') + 1)
                              ) : values.inspectionDoc?.name
                            }
                          </span>
                        </div>
                      ) : (
                        <div className="no-doc">
                          <img
                            alt="errorImg"
                            className="btn-image"
                            src={errorImg}
                          />
                          <span>
                            Submit it before a contract can be finalized.
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="field-wrapper">
                      <h2 className="sec-title">Do you have Title Report?</h2>

                      <button
                        className={
                          !values.remove_title_report
                            ? "btn step-btn orange-btn"
                            : "btn step-btn bordered-btn"
                        }
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          this.setState({
                            propertiesDoc: {
                              ...this.state.propertiesDoc,
                              title: true,
                            },
                          });
                          // setFieldValue( delete values.remove_title_report)
                          setFieldValue("remove_title_report", false);


                        }}
                      >
                        Yes
                      </button>
                      <button
                        className={
                          values.remove_title_report
                            ? "btn step-btn orange-btn"
                            : "btn step-btn bordered-btn"
                        }
                        type="button"
                        onClick={(event) => {
                          event.preventDefault();
                          this.setState({
                            propertiesDoc: {
                              ...this.state.propertiesDoc,
                              title: false,
                            },
                          });
                          setFieldValue("titleDoc", null);
                          setFieldValue("remove_title_report", true);

                        }}
                      >
                        No
                      </button>
                      {!values.remove_title_report ? (
                        <div className="upload-doc-div">
                          <label
                            htmlFor="title-upload-doc"
                            className="dashed-file-border"
                          // onClick={this.showFileUpload}
                          >
                            <img
                              alt="fileImg"
                              className="btn-image"
                              src={fileImg}
                            />
                            {values.titleDoc ? (
                              <span>Re-Upload Document</span>
                            ) : (
                              <span>Upload Document</span>
                            )}
                          </label>
                          <input
                            type="file"
                            name="titleDoc"
                            disabled={this.state.file.length === 5}
                            className="custom-input secondary-input uploadImage upload-doc"
                            // ref={this.fileUpload}
                            id="title-upload-doc"
                            onChange={(e) => {
                              setFieldValue("titleDoc", e.target.files[0]);
                            }}
                          />
                          <span className="doc-span">
                            {/* {values.titleDoc?.name} */}
                            {typeof values.titleDoc == "string"
                              ? decodeURI(
                                values.titleDoc.substring(values.titleDoc.lastIndexOf('/') + 1)
                              ) : values.titleDoc?.name
                            }
                          </span>
                        </div>
                      ) : (
                        <div className="no-doc">
                          <img
                            alt="errorImg"
                            className="btn-image"
                            src={errorImg}
                          />
                          <span>
                            Submit it before a contract can be finalized.
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* Next  */}
                <div className="btn-wrapper flex justify-end">
                  <button type="button" className="btn gray-fill-btn" onClick={() => {
                    console.log('this.props?.fieldData4', this.props?.fieldData)
                    this.props.setStep(4, this.props?.fieldData);
                    // this.doStepSet(4);
                    // @ts-ignore
                    // this.props?.history?.push({
                    //   pathname: "/add-property/pricing",
                    //   state: { steps: this.state.step, data: this.props?.fieldData?.attributes?},
                    // });
                  }}>
                    Back
                  </button>
                  <button type="submit" className="rounded-fill-btn ml-3">
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* Snackbar for display success and failed messages. */}
        {/* <Snackbar
            open={this.state.snackBar.show}
            autoHideDuration={3000}
            onClose={this.closeSnackBarHandler}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.closeSnackBarHandler}
              severity={this.state.snackBar.type}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar> */}
      </>
    );
  }
}

// Customizable Area Start
export default withRouter(StepVChecklist);

// Customizable Area End
