// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import HomepagemainController, { Props } from "./HomepagemainController.web";
import {
  imageLike,
  imageLikeRed,
  imageView,
  siginImage,
  buyerSkew,
  buyerLogo,
  filterLogo,
  sortIcon,
  tabClose,
  iconSearch,
  arrowBottom,
  profieImg
} from "./assets";
import { Modal } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Carousel } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import * as Yup from "yup";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export interface Props {}
interface S {
  FilterFlag: any;
}
class SearchResults extends HomepagemainController {
  constructor(props: Props) {
    super(props);
  }

  oggleFilter() {
    this.setState({
      ...this.state,
      FilterFlag: true,
    });
  }

  render() {
    return (
      <>
        <header className="site-header fixed-header">
          <div className="container-main">
            <div className="left-header">
              <Link to={
                  localStorage.getItem("role") === "buyer"
                  ? "/dashboard-buyer"
                  : "/dashboard-seller"
                  }
                  className="page-logo" title="Sparen Home">
                <img src={buyerLogo} alt="Logo" />
              </Link>
              <div className="link-wrapper">
                <ul>
                  <li>
                    <Link to="/home-page-buyer" title="Buy" className="active">
                      Buy
                    </Link>
                  </li>
                  <li>
                    <Link to="/home-page-seller" title="Sell">
                      Sell
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} title="How It Works">
                      How It Works
                    </Link>
                  </li>
                  <button
                    className="rounded-fill-btn blue-fill sm-visible"
                    onClick={() => this.props?.history?.push("signin")}
                  >
                    Log In
                  </button>
                  <button
                    className="rounded-fill-btn blue-fill sm-visible"
                    onClick={() => this.props?.history?.push("signup")}
                  >
                    Sign Up
                  </button>
                </ul>
              </div>
            </div>
            <div className="right-header">
              <div className="profile-wrapper">
                <div className="icon">
                  <span />
                </div>
                <div className="content">
                  <div className="name">Hello ,  {`${
                      !isEmpty(localStorage.getItem("first_name")) &&
                      localStorage.getItem("first_name") !== "null"
                        ? localStorage.getItem("first_name")
                        : localStorage.getItem("email").split("@")[0]
                    } ${
                      !isEmpty(localStorage.getItem("last_name")) &&
                      localStorage.getItem("last_name") !== "null"
                        ? localStorage.getItem("last_name")
                        : ""
                    }`}</div>
                </div>
                <div className="profile-image">
                  <img 
                  src={!isEmpty(localStorage.getItem("profile_image")) &&
                      localStorage.getItem("profile_image") !== "null"
                        ? localStorage.getItem("profile_image")
                        : profieImg} 
                  alt="profile_image" 
                  />
                </div>
              </div>
            </div>
            <div className="mobile-menu" onClick={this.toggleClass}>
              <ul className={this.toggleMenu ? "active" : ""}>
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
        </header>
        <div className="map-wrapper">
          <div className="left-block">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA48nVKp-UOKiw0cQftEKX6h4BVSSA8kWE",
              }}
              defaultCenter={{
                lat: 59.95,
                lng: 30.33,
              }}
              defaultZoom={11}
            >
              <AnyReactComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
          <div className="right-block">
            <div className="filter-block-wrapper">
              <div className="tab-wrapper">
                <div className="filter-tab">
                  <span>Button</span>
                  <i>
                    <img src={tabClose} alt="" />
                  </i>
                </div>
              </div>
              <div className="right-filter">
                <button
                  className="sort-btn"
                  onClick={() => this.toggleFilter()}
                >
                  Sort
                  <i>
                    <img src={sortIcon} alt="" />
                  </i>
                </button>
                <button
                  className="filter-btn"
                  onClick={() => this.toggleFilter()}
                >
                  Filter
                  <i>
                    <img src={filterLogo} alt="" />
                  </i>
                </button>
              </div>
            </div>
            <div className="property-wrapper">
              <div className="property-block">
                <div className="top-block">
                  <div className="profile-block">
                    <i>
                      <img src={buyerSkew} alt="" />
                    </i>
                    <span>John Doe</span>
                  </div>
                  <div className="like-block">
                    <img src={imageLike} alt="Like image" />
                  </div>
                </div>
                <i className="property-image">
                  <img src={siginImage} alt="Property Image" />
                </i>
                <div className="like-view-block">
                  <div className="left-block">
                    <p className="lable">List Price</p>
                    <p className="price">$233,333</p>
                  </div>
                  <div className="right-block">
                    <div className="view">
                      <i>
                        <img src={imageView} alt="" />
                      </i>
                      <span>1,232</span>
                    </div>
                    <div className="like">
                      <i>
                        <img src={imageLikeRed} alt="" />
                      </i>
                      <span>1,232</span>
                    </div>
                  </div>
                </div>
                <div className="more-detail">
                  <p className="property-name">37577 Susan St.</p>
                  <p className="property-address">Sterling Heights, MI 48310</p>
                  <ul>
                    <li>3 Beds</li>
                    <li>3 Baths</li>
                    <li>3,000 Sq ft</li>
                  </ul>
                </div>
              </div>
              <div className="property-block">
                <div className="top-block">
                  <div className="profile-block">
                    <i>
                      <img src={buyerSkew} alt="" />
                    </i>
                    <span>John Doe</span>
                  </div>
                  <div className="like-block">
                    <img src={imageLike} alt="Like image" />
                  </div>
                </div>
                <i className="property-image">
                  <img src={siginImage} alt="Property Image" />
                </i>
                <div className="like-view-block">
                  <div className="left-block">
                    <p className="lable">List Price</p>
                    <p className="price">$233,333</p>
                  </div>
                  <div className="right-block">
                    <div className="view">
                      <i>
                        <img src={imageView} alt="" />
                      </i>
                      <span>1,232</span>
                    </div>
                    <div className="like">
                      <i>
                        <img src={imageLikeRed} alt="" />
                      </i>
                      <span>1,232</span>
                    </div>
                  </div>
                </div>
                <div className="more-detail">
                  <p className="property-name">37577 Susan St.</p>
                  <p className="property-address">Sterling Heights, MI 48310</p>
                  <ul>
                    <li>3 Beds</li>
                    <li>3 Baths</li>
                    <li>3,000 Sq ft</li>
                  </ul>
                </div>
              </div>
              <div className="property-block">
                <div className="top-block">
                  <div className="profile-block">
                    <i>
                      <img src={buyerSkew} alt="" />
                    </i>
                    <span>John Doe</span>
                  </div>
                  <div className="like-block">
                    <img src={imageLike} alt="Like image" />
                  </div>
                </div>
                <i className="property-image">
                  <img src={siginImage} alt="Property Image" />
                </i>
                <div className="like-view-block">
                  <div className="left-block">
                    <p className="lable">List Price</p>
                    <p className="price">$233,333</p>
                  </div>
                  <div className="right-block">
                    <div className="view">
                      <i>
                        <img src={imageView} alt="" />
                      </i>
                      <span>1,232</span>
                    </div>
                    <div className="like">
                      <i>
                        <img src={imageLikeRed} alt="" />
                      </i>
                      <span>1,232</span>
                    </div>
                  </div>
                </div>
                <div className="more-detail">
                  <p className="property-name">37577 Susan St.</p>
                  <p className="property-address">Sterling Heights, MI 48310</p>
                  <ul>
                    <li>3 Beds</li>
                    <li>3 Baths</li>
                    <li>3,000 Sq ft</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          // show={this.state.FilterFlag}
          show="true"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="filter-modal"
        >
          <Modal.Body>
            <Formik
              initialValues={{
                sliderValue: [20, 40],
              }}
              // validationSchema={Yup.object().shape(this.state.emailSchema)}
              onSubmit={(values) => console.log(values)}
              enableReinitialize
            >
              {({ values, setFieldValue, errors }) => (
                //@ts-ignore
                <Form>
                  <div className="filter-header">
                    <div className="filter-tab">
                      <span>New York</span>
                      <i>
                        <img src={tabClose} alt="" />
                      </i>
                    </div>
                    <i className="search-icon">
                      <img src={iconSearch} alt="" />
                    </i>
                  </div>
                  <div className="filter-body">
                    <div className="clear-filter">
                      <div className="left-filter">filters</div>
                      <div className="right-filter">Clear Filter</div>
                    </div>
                    <div className="filter-wrapper">
                      <div className="filter-tab">
                        <span>For Sale</span>
                        <i>
                          <img src={arrowBottom} alt="" />
                        </i>
                      </div>
                      <div className="filter-tab">
                        <span>1,222$-2,22555</span>
                        <i>
                          <img src={arrowBottom} alt="" />
                        </i>
                      </div>
                      <div className="filter-tab">
                        <span>For Sale</span>
                        <i>
                          <img src={arrowBottom} alt="" />
                        </i>
                      </div>
                      <div className="filter-tab">
                        <span>For Sale</span>
                        <i>
                          <img src={arrowBottom} alt="" />
                        </i>
                      </div>
                      <div className="filter-tab">
                        <span>For Sale</span>
                        <i>
                          <img src={arrowBottom} alt="" />
                        </i>
                      </div>
                    </div>
                    {/* Select Price Range Slider  */}
                    <div className="filter-content">
                      <div className="slider-title">Select Price Range</div>
                      <span className="prize-range">1,000 $ - 3,000$</span>
                      <div className="slider-wrapper orange-slider">
                        <Slider
                          value={values.sliderValue}
                          onChange={(e, newValue) =>
                            setFieldValue("sliderValue", newValue)
                          }
                          valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                        />
                      </div>
                    </div>
                    {/* Select Home Type  */}
                    <div className="filter-content">
                      <div className="slider-title">Select Home Type</div>
                      <div className="check-outer">
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Houses
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Manufactured
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Condos/Co-ops
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Multy-Family
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Apartments
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Lots/Land
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Townhomes
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* MORE  */}
                    <div className="filter-content more-filter">
                      <div className="left-block">
                        <div className="field-wrapper">
                          <label className="mb-1">Max HOA</label>
                          <Field
                            type="text"
                            as="select"
                            name="lotSqft"
                            autoComplete="off"
                            defaultValue="sq_ft"
                            className="custom-input secondary-input "
                          >
                            <option value="sq_ft" disabled>
                              Sq ft
                            </option>

                            <option value="acres" disabled>
                              Acres
                            </option>
                          </Field>
                        </div>
                        <div className="field-wrapper">
                          <label className="mb-1">Parking Spots</label>
                          <Field
                            type="text"
                            as="select"
                            name="lotSqft"
                            autoComplete="off"
                            defaultValue="sq_ft"
                            className="custom-input secondary-input "
                          >
                            <option value="sq_ft" disabled>
                              Sq ft
                            </option>

                            <option value="acres" disabled>
                              Acres
                            </option>
                          </Field>
                        </div>
                        <div className="field-wrapper">
                          <label className="mb-1">Square Feet</label>
                          <div className="select-wrapper">
                            <Field
                              type="text"
                              as="select"
                              name="lotSqft"
                              autoComplete="off"
                              defaultValue="sq_ft"
                              className="custom-input secondary-input "
                            >
                              <option value="sq_ft" disabled>
                                Sq ft
                              </option>

                              <option value="acres" disabled>
                                Acres
                              </option>
                            </Field>
                            <span className="dash" />
                            <Field
                              type="text"
                              as="select"
                              name="lotSqft"
                              autoComplete="off"
                              defaultValue="sq_ft"
                              className="custom-input secondary-input "
                            >
                              <option value="sq_ft" disabled>
                                Sq ft
                              </option>

                              <option value="acres" disabled>
                                Acres
                              </option>
                            </Field>
                          </div>
                        </div>
                        <div className="field-wrapper">
                          <label />
                          <div className="select-wrapper">
                            <Field
                              type="text"
                              as="select"
                              name="lotSqft"
                              autoComplete="off"
                              defaultValue="sq_ft"
                              className="custom-input secondary-input "
                            >
                              <option value="sq_ft" disabled>
                                Sq ft
                              </option>

                              <option value="acres" disabled>
                                Acres
                              </option>
                            </Field>
                            <span className="dash" />
                            <Field
                              type="text"
                              as="select"
                              name="lotSqft"
                              autoComplete="off"
                              defaultValue="sq_ft"
                              className="custom-input secondary-input "
                            >
                              <option value="sq_ft" disabled>
                                Sq ft
                              </option>

                              <option value="acres" disabled>
                                Acres
                              </option>
                            </Field>
                          </div>
                        </div>
                        <div className="field-wrapper">
                          <label className="mb-1">Sold In Last</label>
                          <Field
                            type="text"
                            as="select"
                            name="lotSqft"
                            autoComplete="off"
                            defaultValue="sq_ft"
                            className="custom-input secondary-input "
                          >
                            <option value="sq_ft" disabled>
                              Sq ft
                            </option>

                            <option value="acres" disabled>
                              Acres
                            </option>
                          </Field>
                        </div>
                      </div>
                      <div className="right-block">
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Has Basement
                            <span className="checkmark" />
                          </label>
                          <div className="sub-checkbox">
                            <div className="checkbox-wrapper">
                              <label>
                                <input
                                  type="checkbox"
                                  id="test1"
                                  name="rememberMe"
                                  className="input-checkbox"
                                />
                                Finished
                                <span className="checkmark" />
                              </label>
                            </div>
                            <div className="checkbox-wrapper">
                              <label>
                                <input
                                  type="checkbox"
                                  id="test1"
                                  name="rememberMe"
                                  className="input-checkbox"
                                />
                                Unfinished
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Single Story Only
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="checkbox-title">Other Amenities</div>
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Must have AC
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Must have Pool
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Waterfront
                            <span className="checkmark" />
                          </label>
                        </div>
                        <div className="checkbox-title">View</div>
                        <div className="check-outer">
                          <div className="checkbox-wrapper">
                            <label>
                              <input
                                type="checkbox"
                                id="test1"
                                name="rememberMe"
                                className="input-checkbox"
                              />
                              City
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="checkbox-wrapper">
                            <label>
                              <input
                                type="checkbox"
                                id="test1"
                                name="rememberMe"
                                className="input-checkbox"
                              />
                              Mountain
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="checkbox-wrapper">
                            <label>
                              <input
                                type="checkbox"
                                id="test1"
                                name="rememberMe"
                                className="input-checkbox"
                              />
                              Park
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="checkbox-wrapper">
                            <label>
                              <input
                                type="checkbox"
                                id="test1"
                                name="rememberMe"
                                className="input-checkbox"
                              />
                              Water
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                        <div className="checkbox-wrapper">
                          <label>
                            <input
                              type="checkbox"
                              id="test1"
                              name="rememberMe"
                              className="input-checkbox"
                            />
                            Moved-in ready homes, Evaluated And repaired by
                            Sparen
                            <span className="checkmark" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="btn-wrapper">
                      <button type="button" className="btn gray-fill-btn">
                        Cancel
                      </button>
                      <button type="submit" className="rounded-fill-btn">
                        Search
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withRouter(SearchResults);
