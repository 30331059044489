// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "./DashboardBuyerController.web";
import Header from "./Header.web";
import SidebarBuyer from "../../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../../components/src/Sidebar";
import {
  downloadImg,
  arrowButtonImg,
  roomImg,
  refreshImg,
  profieImg,
  replyImg,
} from ".././assets";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBSimpleChart,
} from "mdbreact";
import { Doughnut } from "react-chartjs-2";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { isEmpty } from "lodash";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

export interface Props { }
interface S {
  FilterFlag: any;
}
class BuyerDashboard extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    function createData(date, id, note, amount, type) {
      return { date, id, note, amount, type };
    }
    const rows = [
      createData("03 July, 2020", 123245674, "For July Rent", "-$400", "debit"),
      createData(
        "03 July, 2020",
        123245674,
        "For July Rent",
        "+$400",
        "credit"
      ),
      createData("03 July, 2020", 123245674, "For July Rent", "-$400", "debit"),
      createData(
        "03 July, 2020",
        123245674,
        "For July Rent",
        "+$400",
        "credit"
      ),
      createData("03 July, 2020", 123245674, "For July Rent", "-$400", "debit"),
    ];
    return (
      <>
        <div className="flex">
          <div>
            {/* Sidebar  */}
            {localStorage.getItem("role") === "buyer" ? (
              <SidebarBuyer />
            ) : (
              <SidebarSeller />
            )}
          </div>
          <div className="stepper-outer">
            <Header
              flag={this.state.HeaderDropdownFlag}
              toggle={this.doToggleHeaderDropdown}
            />
            <div className="w-full h-full m-auto">
              {/* content start */}
              {/* User Name */}
              <h2 className="user-head">
                Welcome Back,{" "}
                {`${!isEmpty(localStorage.getItem("first_name")) &&
                  localStorage.getItem("first_name") !== "null"
                  ? localStorage.getItem("first_name")
                  : localStorage.getItem("email").split("@")[0]
                  } ${!isEmpty(localStorage.getItem("last_name")) &&
                    localStorage.getItem("last_name") !== "null"
                    ? localStorage.getItem("last_name")
                    : ""
                  }`}
              </h2>

              {/* Welcome */}
              <div className="section-card flex bg-blue sec-height-176 marb-30">
                <div className="sec-card-h ">
                  <h2 className="sec-card-title">Welcome to Sparen!</h2>
                  <p className="sec-card-text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Ut enim ad minim
                  </p>
                </div>
                <div className="sec-card-btn">
                  <button
                    className="btn rounded-fill-btn"
                    onClick={() => this.props?.history?.push("/properties")}
                  >
                    Search Property
                  </button>
                </div>
              </div>

              {/* Upcoming Payment */}
              <div className="section-card bg-white flex justify-content-space-between sec-height-176 marb-30">
                <div className="sec-grid-item">
                  <h5 className="sec-grid-title">Upcoming Payment</h5>
                  <p className="sec-grid-text"> $4,000</p>
                </div>
                <div className="sec-grid-item">
                  <h5 className="sec-grid-title">Due Date</h5>
                  <p className="sec-grid-text">02/02/2020</p>
                </div>
                <div className="sec-grid-item">
                  <h5 className="sec-grid-title">To</h5>
                  <p className="sec-grid-text">John Doe</p>
                </div>
                <div className="sec-grid-item">
                  <h5 className="sec-grid-title">Description</h5>
                  <p className="sec-grid-text">Rent </p>
                </div>
                <div className="sec-card-btn">
                  <button className="btn rounded-fill-btn">
                    View Details
                  </button>
                </div>
              </div>

              {/* Billing Account */}
              <div className="wrap-grid flex marb-30">
                <div className="grid-sec-one padr-8 ">
                  <div className="section-card bg-white height-100">
                    <div className="grid-sec-head flex marb-30 justify-content-space-between">
                      <h2 className="grid-sec-title">
                        Billing Account Details
                      </h2>
                      <div className="grid-img-sec flex align-item-center ">
                        <img src={refreshImg} className="grid-img" />
                        <span>Refresh</span>
                      </div>
                    </div>
                    <div className="marb-30">
                      <h5 className="sec-grid-title">
                        Account Holder Name
                      </h5>
                      <p className="sec-grid-text">John Doe</p>
                    </div>
                    <div className="marb-30">
                      <h5 className="sec-grid-title">Account Number</h5>
                      <p className="sec-grid-text">9876 5432 1123 1123</p>
                    </div>
                    <div className="marb-30">
                      <h5 className="sec-grid-title">Bank Name</h5>
                      <p className="sec-grid-text">Citi Bank</p>
                    </div>
                    <div className="sec-card-btn">
                      <button className="btn rounded-fill-btn">
                        View Details
                      </button>
                    </div>
                  </div>
                </div>
                <div className="grid-sec-two padl-8">
                  <div className="grid-sec-two-wrap flex">
                    <div className="width-50 padr-8 marb-16">
                      <div className="section-card flex bg-white height-100">
                        <div className="small-grid-sec width-50 height-100">
                          <div className="sec-grid-item marb-30">
                            <h5 className="sec-grid-title">
                              Monhtly Payment
                            </h5>
                            <p className="sec-grid-text-g">$50,000</p>
                          </div>
                          <div className="sec-grid-item flex align-item-center">
                            <span className="sec-grid-text-b">
                              Financial Overview
                            </span>
                            <img
                              className="grid-img"
                              src={arrowButtonImg}
                            />
                          </div>
                        </div>
                        <div className="small-grid-sec width-50 height-100">
                          {/* chart start */}
                          <MDBContainer className="chart-wrapper-div height-100 div-end">
                            <div className="position-relative height-100">
                              <span className="content">January</span>
                              <Doughnut
                                data={this.state.DoughnutValue}
                                height="100px"
                                width="100px"
                              />
                            </div>
                          </MDBContainer>

                          {/* chart end */}
                        </div>
                      </div>
                    </div>
                    <div className="width-50 padr-8 marb-16">
                      <div className="section-card flex bg-white height-100">
                        <div className="small-grid-sec width-50 height-100">
                          <div className="sec-grid-item marb-30">
                            <h5 className="sec-grid-title">
                              Monhtly Payment
                            </h5>
                            <p className="sec-grid-text-g">$50,000</p>
                          </div>
                          <div className="sec-grid-item flex align-item-center">
                            <span className="sec-grid-text-b">
                              Financial Overview
                            </span>
                            <img
                              className="grid-img"
                              src={arrowButtonImg}
                            />
                          </div>
                        </div>
                        <div className="small-grid-sec width-50 height-100">
                          {/* chart start */}
                          <MDBContainer className="chart-wrapper-div height-100 div-end">
                            <div className="position-relative height-100">
                              <span className="content">January</span>
                              <Doughnut
                                data={this.state.DoughnutValue}
                                height="100px"
                                width="100px"
                              />
                            </div>
                          </MDBContainer>

                          {/* chart end */}
                        </div>
                      </div>
                    </div>

                    {/* <div className="width-50 padl-8 marb-16">
                      <div className="section-card flex bg-white height-100">
                        <div className="small-grid-sec">
                          <div className="sec-grid-item marb-30">
                            <h5 className="sec-grid-title">Monhtly Payment</h5>
                            <p className="sec-grid-text-g">$50,000</p>
                          </div>
                          <div className="sec-grid-item">
                            <p className="sec-grid-text-b">
                              Financial Overview
                            </p>
                          </div>
                        </div>
                        <div className="small-grid-sec"> Graph</div>
                      </div>
                    </div> */}

                    <div className="width-100">
                      <div className="section-card bg-white height-100 ">
                        <h2 className="grid-sec-title marb-16">
                          Rented Property
                        </h2>
                        <div className="flex">
                          <div className="room-img-wrap">
                            <img src={roomImg} />
                          </div>
                          <div className="room-detail-wrap">
                            <h5>37577 Susan St.</h5>
                            <p className="sec-grid-title">
                              Sterling Heights, MI 48310
                            </p>
                            <span className="room-span">3 Beds</span>
                            <span className="room-span">3 Baths</span>
                            <span className="room-span">3,000 Sq ft</span>
                          </div>
                          <div className="sec-card-btn">
                            <button className="btn rounded-fill-btn">
                              View Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Transaction */}
              <div className="section-card bg-white marb-30">
                <div className="grid-sec-head flex marb-30 justify-content-space-between">
                  <h2 className="grid-sec-title">Transactions</h2>
                  <div className="sec-card-btn">
                    <button className="btn orange-fill-btn">
                      View All
                    </button>
                  </div>
                </div>
                <div className="table-outer">
                  <TableContainer>
                    <Table className="custom-table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Transaction ID</TableCell>
                          <TableCell>Note</TableCell>
                          <TableCell>Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              {row.date}
                            </TableCell>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.note}</TableCell>
                            <TableCell
                              className={`${row.type === "debit"
                                ? "red-text"
                                : "green-text"
                                }`}
                            >
                              {row.amount}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>

              {/* Messages */}
              <div className="section-card bg-white marb-30">
                <div className="grid-sec-head flex marb-30 justify-content-space-between">
                  <h2 className="grid-sec-title">Messages</h2>
                  <div className="sec-card-btn">
                    <button className="btn orange-fill-btn">
                      View All
                    </button>
                  </div>
                </div>
                <div className="msg-block-wrap">
                  <div className="message-block brd-btm padb-30  ">
                    <div className="flex justify-content-space-between marb-15">
                      <div className="flex">
                        <div className="profile-img">
                          <img src={profieImg} />
                        </div>
                        <div className="profile-data pl-15">
                          <h5>John Doe</h5>
                          <p>Subject Name</p>
                        </div>
                      </div>
                      <div className="msg-date">
                        <span>1:13pm</span>
                      </div>
                    </div>
                    <div className="flex justify-content-space-between message-sec">
                      <p>
                        Lorem ipsu dolor sit amet, consecte udipiscing elit,
                        sed do eiusmod tempor incididunt ut
                      </p>
                      <img src={replyImg} />
                    </div>
                  </div>
                  <div className="message-block mart-30">
                    <div className="flex justify-content-space-between marb-15">
                      <div className="flex">
                        <div className="profile-img">
                          <img src={profieImg} />
                        </div>
                        <div className="profile-data pl-15">
                          <h5>John Doe</h5>
                          <p>Subject Name</p>
                        </div>
                      </div>
                      <div className="msg-date">
                        <span>1:13pm</span>
                      </div>
                    </div>
                    <div className="flex justify-content-space-between message-sec">
                      <p>
                        Lorem ipsu dolor sit amet, consecte udipiscing elit,
                        sed do eiusmod tempor incididunt ut
                      </p>
                      <img src={replyImg} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Content ENd */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(BuyerDashboard);
