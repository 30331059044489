//@ts-nocheck
import React, { Fragment } from "react";
// Customizable Area Start
import {
    noImgAvailable,
    imageLikeRed,
    imageView,
    imageEdit,
    imageDelete
} from "../../assets";
import HandOverController, { Props } from "../HandOverController.web";
import PropertyItemDetail from "../../Popups/PropertyItemDetail.web";
import { Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// Customizable Area End


class PropertyItem extends HandOverController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        return (
            <>
                <div className="list-block-detail" onClick={() => {
                    this.handleShowPropItemDetail();
                    this.setSelectedProperty(this.props.propertyItemData);
                }}>
                    <div className="property-detail">
                        <div className="image-block">
                            <img src={this.props.propertyItemData?.attributes?.images[0] ? this.props.propertyItemData?.attributes?.images[0]?.url : noImgAvailable} alt="propertyImg" />
                        </div>
                        <div className="content">
                            <p className="title">{this.props.propertyItemData?.attributes?.address?.address_line + this.props.propertyItemData?.attributes?.address?.address_line_2}</p>
                            <p className="sub-title">{`${this.props.propertyItemData?.attributes?.address?.city}, ${this.props.propertyItemData?.attributes?.address?.state} ${this.props.propertyItemData?.attributes?.address?.zip_code}`}</p>
                            <span className="tab">{this.props.propertyItemData?.attributes?.property_state}</span>
                        </div>
                    </div>
                    <div className="tab-wrapper">
                        <div className="tab-block">5 Properties</div>
                        <div className="tab-block">{this.props.propertyItemData?.attributes?.finished_sq_ft} {this.props.propertyItemData?.attributes?.lot_unit}</div>
                        <div className="tab-block">{this.props.propertyItemData?.attributes?.formatted_price}/ m</div>
                    </div>
                    <div className="like-view-block">
                        <p className="like-property">
                            <img src={imageView} alt="" />
                            <span>{this.props.propertyItemData?.attributes?.view_count}</span>
                        </p>
                        <p className="view-property">
                            <img src={imageLikeRed} alt="" />
                            <span>{this.props.propertyItemData?.attributes?.favorited_count}</span>
                        </p>
                    </div>
                </div>
                <div className="edit-icon">
                    <Link to={{ pathname: "/edit-property/", state: this.props.propertyItemData?.id }}><img src={imageEdit} alt="Edit" /></Link>
                    {/* <img src={imageEdit} alt="Edit" onClick={() => this.redirectToEditPage(this.props.propertyItemData?.id)} /> */}
                </div>
                <div className="delete-icon">
                    <img src={imageDelete} alt="" onClick={() => {
                        this.deleteProperty(this.props.propertyItemData?.id);
                    }} />
                </div>

                <PropertyItemDetail propItemDetailShow={this.state.showPropItemDetail} handlepropItemDetailShow={this.handleShowPropItemDetail} propertyDetail={this.state.selectedProperty} />

                {/* Snackbar for display success and failed messages. */}
                <Snackbar
                    open={this.state.snackBar.show}
                    autoHideDuration={3000}
                    onClose={this.closeSnackBarHandler}
                >
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        onClose={this.closeSnackBarHandler}
                        severity={this.state.snackBar.type}
                    >
                        {this.state.snackBar.message}
                    </MuiAlert>
                </Snackbar>
            </>
        );
        // Customizable Area End       
    }
}

// Customizable Area Start
export default PropertyItem;

// Customizable Area End
