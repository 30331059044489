// @ts-nocheck

import React from "react";
// Customizable Area Start
import Sidebar from "../../../../../components/src/Sidebar";
import { Link, withRouter } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
export const configJSON = require("../../config");
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// Customizable Area End
import AddPropertyFlowController, { Props } from "./AddPropertyFlowController.web";
class StepIVPricing extends AddPropertyFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    const array = [];
    const field = this.props?.fieldData?.attributes?.property_additional_details;
    return (
      <>
        <h3 className="page-title">Pricing</h3>
        <div className="stepper-sections">
          <Formik
            innerRef={this.props.formRef}
            initialValues={{
              price: this.props?.fieldData?.attributes?.price || "",
              currency: this.props?.fieldData?.attributes?.currency || "",
              minDownpay: this.props?.fieldData?.attributes?.min_down_payment || "",
              termPeriod: this.props?.fieldData?.attributes?.term_period || "",
              contactNumber: this.props?.fieldData?.attributes?.contact_number || "",
              taxes: this.props?.fieldData?.attributes?.taxes || "",
              hoaFees: this.props?.fieldData?.attributes?.hoa_fees || "",
              homeOwnerInsurance: this.props?.fieldData?.attributes?.homeowner_insurance || "",
              additional_details: field && field.length != 0 ? field.map((key, index) => (
                {
                  id: key.id,
                  name: key.name,
                  value: key.value
                })) : [],
              termsConditions: this.props?.fieldData?.attributes?.agreed_terms || false,
              stepNumber: 4
            }}
            validationSchema={Yup.object().shape(this.state.pricingSchema)}
            onSubmit={(values) => {
              values.additional_details.push(...array);
              this.doPricing(values, this.props?.fieldData?.id);
            }}
            enableReinitialize
            validateOnMount
          >
            {({ values, setFieldValue, errors, setFieldError }) => (
              //@ts-ignore

              <Form>
                <h2 className="green-title">Property Price</h2>

                {/* Price / Minimum Downpayment */}
                <div className="field-outer-flex">
                  <div className="field-wrapper width-50">
                    <label className="mb-1">Price</label>
                    <div className="flex">
                      <div className="field-wrapper width-50 ml-0">
                        <Field
                          type="number"
                          name="price"
                          placeholder={configJSON.priceHolder}
                          className="custom-input secondary-input"
                        />
                        <span className="error">
                          <ErrorMessage name="price" />
                        </span>
                      </div>

                      <div className="field-wrapper width-50">
                        <Field
                          type="text"
                          as="select"
                          name="currency"
                          autoComplete="off"
                          defaultValue=""
                          className="custom-input secondary-input "
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="USD">USD</option>
                        </Field>
                        <span className="error">
                          <ErrorMessage name="currency" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="field-wrapper width-50">
                    <label className="mb-1">Minimum Downpayment</label>
                    <div className="flex">
                      <div className="field-wrapper width-50 ml-0">
                        <Field
                          type="number"
                          name="minDownpay"
                          placeholder={configJSON.priceHolder}
                          className="custom-input secondary-input"
                        // value={values.minDownpay}
                        // onChange={e => {
                        //   console.log("e.target.value====>", e.target.value)
                        //   if (!values.price) {
                        //     console.log("cnd=====> 1")ldError("price", configJSON.errorPriceNotValid)
                        //   }
                        //   if (+values.price < +e.target.value) {
                        //     console.log("cnd=====> 2")

                        //     setFieldError("minDownpay", configJSON.errorMinDwnPayment)
                        //   }
                        //   console.log("cnd=====> 3")

                        //   setFieldValue("minDownpay", e.target.value)
                        //   //  else {
                        //   //   setFieldValue("minDownpay", e.target.value)
                        //   //   setFieldError("minDownpay", "")
                        //   // }
                        // }}
                        />
                        <span className="error">

                          <ErrorMessage name="minDownpay" />
                        </span>
                      </div>

                      <div className="field-wrapper width-50">
                        <Field
                          type="text"
                          as="select"
                          name="min-currency"
                          autoComplete="off"
                          value={values.currency}
                          className="custom-input secondary-input "
                        >
                          <option value="" disabled>
                            Select
                          </option>
                          <option value="USD" disabled>
                            USD
                          </option>
                        </Field>
                        <span className="error">
                          <ErrorMessage name="currency" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {values.price
                  && values.minDownpay
                  && values.price < values.minDownpay
                  && <span className="error">
                    {setFieldError("minDownpay", configJSON.errorMinDwnPayment)}
                    {configJSON.errorMinDwnPayment}
                  </span>} */}
                {/* <pre>
                  {JSON.stringify(errors, 2, 2)}
                </pre>
                <pre>
                  {JSON.stringify(values, 2, 2)}
                </pre> */}

                {/* Term*/}
                <div className="field-outer-flex label-only">
                  <div className="field-wrapper width-50">
                    <label className="mb-1">Term</label>
                  </div>
                </div>

                <div className="field-outer-flex">
                  <button
                    className={
                      values.termPeriod == "10"
                        ? "btn step-btn orange-btn"
                        : "btn step-btn bordered-btn"
                    }
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      setFieldValue("termPeriod", "10");
                    }}
                  >
                    10 Years
                  </button>
                  <button
                    className={
                      values.termPeriod == "15"
                        ? "btn step-btn orange-btn"
                        : "btn step-btn bordered-btn"
                    }
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      setFieldValue("termPeriod", "15");
                    }}
                  >
                    15 Years
                  </button>
                  <button
                    className={
                      values.termPeriod == "20"
                        ? "btn step-btn orange-btn"
                        : "btn step-btn bordered-btn"
                    }
                    type="button"
                    onClick={(event) => {
                      event.preventDefault();
                      setFieldValue("termPeriod", "20");
                    }}
                  >
                    20 Years
                  </button>
                </div>
                <span className="error">
                  <ErrorMessage name="termPeriod" />
                </span>

                <h2 className="green-title mt-5">Addition</h2>

                {/* Taxes / HOA Fees/  Homes Owner Fees*/}
                <div className="field-outer-flex">
                  <div className="field-wrapper width-50">
                    <label className="mb-1">
                      Taxes
                    </label>
                    <Field
                      type="number"
                      name="taxes"
                      placeholder={configJSON.priceHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="taxes" />
                    </span>
                  </div>
                </div>
                {/* Taxes / HOA Fees/  Homes Owner Fees*/}
                <div className="field-outer-flex">
                  <div className="field-wrapper width-50">
                    <label className="mb-1">
                      HOA Fees
                    </label>
                    <Field
                      type="number"
                      name="hoaFees"
                      placeholder={configJSON.priceHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="hoaFees" />
                    </span>
                  </div>
                </div>
                {/* Taxes / HOA Fees/  Homes Owner Fees*/}

                <div className="field-outer-flex">
                  <div className="field-wrapper width-50">
                    <label className="mb-1">
                      Home Owner Insurance
                    </label>
                    <Field
                      type="number"
                      name="homeOwnerInsurance"
                      placeholder={configJSON.priceHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="homeOwnerInsurance" />
                    </span>
                  </div>
                </div>
                {/* All Fees And Taxes */}

                <FieldArray name="additional_details">
                  {({ push, remove }) => (
                    <React.Fragment>

                      {values.additional_details.map((item, index) => (
                        <>
                          <div className="field-outer-flex">
                            <div className="field-wrapper width-25">
                              <Field
                                name={`additional_details[${index}].name`}
                                placeholder={configJSON.otherTaxHolder}
                                className="custom-input secondary-input"
                              />
                              <span className="error">
                                <ErrorMessage name={`additional_details[${index}].name`} />
                              </span>
                            </div>
                            <div className="field-wrapper width-25">
                              <Field
                                type="number"
                                name={`additional_details[${index}].value`}
                                placeholder={configJSON.priceHolder}
                                className="custom-input secondary-input"
                              />
                              <span className="error">
                                <ErrorMessage name={`additional_details[${index}].value`} />
                              </span>
                            </div>
                            <div className="field-wrapper width-25">
                              <button type="button" className="btn gray-fill-btn" onClick={(e) => {
                                e.preventDefault();
                                remove(index);
                                if (values.additional_details[index].id !== "") {
                                  array.push({
                                    "id": values.additional_details[index].id,
                                    "_destroy": true
                                  })
                                }
                              }}>
                                Delete
                              </button>
                            </div>
                          </div>
                        </>
                      ))}
                      <div className="field-wrapper width-50">
                        <button
                          type="button"
                          className="btn orange-fill-btn"
                          onClick={() =>
                            push({
                              id: "",
                              name: "",
                              value: "",
                            })
                          }
                        >
                          Add
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                </FieldArray>

                {/*Utility Details*/}
                <h2 className="green-title mt-5">Utility Details</h2>
                <p className="more-info">{configJSON.utilityText}</p>

                {/* Phone Number*/}
                <div className="field-outer-flex">
                  <div className="field-wrapper width-50">
                    <label className="mb-1">Phone Number</label>
                    <Field
                      type="number"
                      name="contactNumber"
                      placeholder={configJSON.phoneNumberHolder}
                      className="custom-input secondary-input"
                    />
                    <span className="error">
                      <ErrorMessage name="contactNumber" />
                    </span>
                  </div>
                </div>
                <div className="field-outer-flex">
                  <div className="checkbox-wrapper">
                    <label className="more-info" htmlFor="prop-terms">
                      <Field type="checkbox" name="termsConditions" className="input-checkbox" id="prop-terms" />
                      {configJSON.termsCondition}
                      <span className="checkmark" />
                    </label>
                    <span className="error">
                      <ErrorMessage name="termsConditions" />
                    </span>
                  </div>
                </div>
                {/* Next  */}
                <div className="btn-wrapper flex justify-end">
                  <button
                    type="button"
                    className="btn gray-fill-btn"
                    onClick={() => {
                      console.log('this.props?.fieldData3', this.props?.fieldData)
                      this.props.setStep(3, this.props?.fieldData);
                    }}
                  >
                    Back
                  </button>
                  <button type="submit" className="rounded-fill-btn ml-3">
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {/* Snackbar for display success and failed messages. */}
        {/* <Snackbar
            open={this.state.snackBar.show}
            autoHideDuration={3000}
            onClose={this.closeSnackBarHandler}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.closeSnackBarHandler}
              severity={this.state.snackBar.type}
            >
              {this.state.snackBar.message}
            </MuiAlert>
          </Snackbar> */}
      </>
    );
  }
}

// Customizable Area Start
export default withRouter(StepIVPricing);

// Customizable Area End
