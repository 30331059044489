//@ts-nocheck
import React from "react";
// Customizable Area Start
import SidebarBuyer from "../../../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../../../components/src/Sidebar";
import { withRouter } from "react-router-dom";
import HandOverController, { Props } from "../HandOverController.web";
import Header from "../../Seller/AddPropertyStepper/Header.web";
import SortDropdown from "../../Popups/SortDropdown.web";
import PropertyItem from "./PropertyItem.web";
import PropertyUnderContractItem from "./PropertyUnderContractItem.web";
import Loader from '../../Popups/Loader.web';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
// Customizable Area End
class PropertyList extends HandOverController {
  counter: number;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.counter = 0;
    // Customizable Area End
  }
  // Customizable Area Start
  componentWillUnmount = async () => {
    localStorage.removeItem('sortParam');
    sessionStorage.removeItem("propertyId");
    sessionStorage.removeItem("type");
  }
  // Customizable Area End

  render() {
    if (this.counter == 0) {
      // if (this.state.propertyCategory == "propertyUnderContract") {
      //   this.getAmanitiesLists();
      // }
      this.getPropertyLists();
      this.counter++;
    }
    return (
      <>
        <div className="flex">
          <div>
            {/* Sidebar  */}
            {localStorage.getItem("role") === "buyer" ? (
              <SidebarBuyer menuCollapsed={true} />
            ) : (
              <SidebarSeller menuCollapsed={true} />
            )}
          </div>
          <div className="stepper-outer">
            <Header
              flag={this.state.HeaderDropdownFlag}
              toggle={this.doToggleHeaderDropdown}
            />
            <div className="seller-property-wrapper">
              <div className="btn-wrapper">
                {localStorage.getItem("role") === "seller" ? (<>
                  <button className={`btn ${this.state.propertyCategory == "propertyList" ? "orange-fill-btn" : "gray-border-btn"}`} onClick={() => this.handlePropertyCategory("propertyList")}>Properties</button>
                  <button className={`btn ${this.state.propertyCategory == "propertyUnderContract" ? "orange-fill-btn" : "gray-border-btn"}`} onClick={() => this.handlePropertyCategory("propertyUnderContract")}>
                    Under Contract
                  </button></>
                ) :
                  <button className="btn orange-fill-btn">Properties</button>
                }
              </div>
              <div className="content-wrapper">
                {/* Formik */}
                <SortDropdown onSortChange={this.handleSortChange} />
                <div className="list-outer">
                  {localStorage.getItem("role") === "seller" ?
                    this.state.propertyCategory == "propertyList" ?
                      this.state.propertyList?.map((property, index) => (
                        this.state.propertyList?.length == index + 1 ?
                          <div className="list-block" key={property.id} ref={this.lastPropertyItem}>
                            <PropertyItem propertyItemData={property} refreshProperty={this.handleSortChange} isDeleteSuccess={this.state.deleteSuccess} />
                          </div> :
                          <div className="list-block" key={property.id}>
                            <PropertyItem propertyItemData={property} refreshProperty={this.handleSortChange} isDeleteSuccess={this.state.deleteSuccess} />
                          </div>

                      ))
                      : <PropertyUnderContractItem accDetail={this.state.accDetail} /> :
                    <PropertyUnderContractItem accDetail={this.state.accDetail} />
                  }
                  {/* Loader  */}
                  {this.state.infiniteLoading && <Loader />}
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* Snackbar for display success and failed messages. */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>
      </>
    );
  }
}

// Customizable Area Start
export default withRouter(PropertyList);

// Customizable Area End
