// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "./DashboardBuyerController.web";
import {
  imageLike,
  imageLikeRed,
  imageView,
  siginImage,
  buyerSkew,
  buyerLogo,
  filterLogo,
  sortIcon,
  tabClose,
  iconSearch,
  arrowBottom,
  soldProperty,
  forSale,
  forSaleWhite,
  soldPropertyWhite,
  imageDashboard,
  imageSetting,
  imageLikeFill,
  imageLogout, profieImg,
  noImgAvailable
} from ".././assets";
import { Modal } from "react-bootstrap";
import Slider from "@material-ui/core/Slider";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Carousel } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import { isEmpty, isEqual } from "lodash";
import CustomizedHook from "./CustomizedHook.web";
import * as Yup from "yup";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { videoImg } from ".././assets";
import AppLoader from '../../../../components/src/AppLoader.web';
import ScheduleTour from "./ScheduleTour.web";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export interface Props { }
interface S {
  FilterFlag: any;
}
class DashboardBuyer extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
  }

  toggleFilter() {
    this.setState({
      ...this.state,
      FilterFlag: true,
    });
  }

  render() {
    return (
      <>
        <header className="site-header fixed-header">
          <div className="container-main">
            <div className="left-header">
              {localStorage.getItem("auth") ?
                <Link to={
                  localStorage.getItem("role") === "buyer"
                    ? "/dashboard-buyer"
                    : "/dashboard-seller"
                } className="page-logo" title="Sparen Home">
                  <img src={buyerLogo} alt="Logo" />
                </Link>
                : <Link to="/" className="page-logo" title="Sparen Home">
                  <img src={buyerLogo} alt="Logo" />
                </Link>
              }

              {/* // <Link to={
              //   localStorage.getItem("role") === "buyer"
              //   ? "/dashboard-buyer"
              //   : "/dashboard-seller"
              // } className="page-logo" title="Sparen Home">
              //   <img src={buyerLogo} alt="Logo" />
              // </Link> */}
              <div className="link-wrapper">
                <ul>
                  <li>
                    <Link to="/home-page-buyer" title="Buy" className="active">
                      Buy
                    </Link>
                  </li>
                  <li>
                    <Link to="/home-page-seller" title="Sell">
                      Sell
                    </Link>
                  </li>
                  <li>
                    <Link to={this.props?.location?.pathname} title="How It Works">
                      How It Works
                    </Link>
                  </li>
                  <button
                    className="rounded-fill-btn blue-fill sm-visible"
                    onClick={() => this.props.history.push("signin")}
                  >
                    Log In
                  </button>
                  <button
                    className="rounded-fill-btn blue-fill sm-visible"
                    onClick={() => this.props.history.push("signup")}
                  >
                    Sign Up
                  </button>
                </ul>
              </div>
            </div>
            <div className="right-header">
              {localStorage.getItem("auth") ? (
                <div className="profile-wrapper" onClick={this.doToggleHeaderDropdown}>
                  <div className="icon">
                    <span />
                  </div>
                  <div className="content">
                    <div className="name">Hello , {`${!isEmpty(localStorage.getItem("first_name")) &&
                      localStorage.getItem("first_name") !== "null"
                      ? localStorage.getItem("first_name")
                      : localStorage.getItem("email").split("@")[0]
                      } ${!isEmpty(localStorage.getItem("last_name")) &&
                        localStorage.getItem("last_name") !== "null"
                        ? localStorage.getItem("last_name")
                        : ""
                      }`}</div>
                    {/* <div
                  className="logout"
                  onClick={() => {
                    localStorage.clear();
                    this.props.history.push("/signin");
                  }}
                >
                  Logout
                </div> */}
                  </div>
                  <div className="profile-image">
                    <img
                      src={!isEmpty(localStorage.getItem("profile_image")) &&
                        localStorage.getItem("profile_image") !== "null"
                        ? localStorage.getItem("profile_image")
                        : profieImg}
                      alt="profile_image"
                    />
                  </div>
                  {/* DropDown  */}
                  {this.state.HeaderDropdownFlag &&
                    <ul className="dropdown-list">
                      <li>
                        <Link to={
                          !isEmpty(localStorage.getItem("role")) && localStorage.getItem("role") === "buyer"
                            ? "/dashboard-buyer"
                            : "/dashboard-seller"
                        } className="buyer-link">
                          Go to Buyer Account
                        </Link>
                      </li>
                      <li>
                        <Link to={
                          !isEmpty(localStorage.getItem("role")) && localStorage.getItem("role") === "buyer"
                            ? "/dashboard-buyer"
                            : "/dashboard-seller"
                        }>
                          <img src={imageDashboard} alt="" />
                          <span>Dashboard</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/account-setting">
                          <img src={imageSetting} alt="" />
                          <span>Account Settings</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/favorite-homes">
                          <img src={imageLikeFill} alt="" />
                          <span>Favorite Homes</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={() => {
                          localStorage.clear();
                          this.props?.history?.push("/signin");
                        }}>
                          <img src={imageLogout} alt="" /> <span>Log out</span>
                        </Link>
                      </li>
                    </ul>
                  }
                </div>
              ) : (
                <div className="auth-btns-space">
                  <button
                    className="rounded-fill-btn green-fill btn-mr"
                    onClick={() => this.props?.history?.push("/signin")}
                  >
                    Log In
                  </button>
                  <button
                    className="rounded-fill-btn blue-fill"
                    onClick={() => this.props?.history?.push("/signup")}
                  >
                    Sign Up
                  </button>
                </div>
              )}
            </div>
            <div className="mobile-menu" onClick={this.toggleClass}>
              <ul className={this.toggleMenu ? "active" : ""}>
                <li />
                <li />
                <li />
                <li />
              </ul>
            </div>
          </div>
        </header>
        <div className="map-wrapper">
          <div className="left-block">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDWk8PILwgIKmq7bxxGEsOdEZx8NRQJR7E",
              }}
              defaultCenter={{
                lat: 59.95,
                lng: 30.33,
              }}
              defaultZoom={11}
            >
              <AnyReactComponent
                lat={59.955413}
                lng={30.337844}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
          <div className="right-block">
            <div className="filter-block-wrapper">
              <div className="tab-wrapper">
                {/* <div className="filter-tab">
                  <span>Button</span>
                  <i>
                    <img src={tabClose} alt="" />
                  </i>
                </div> */}
              </div>
              <div className="right-filter">
                <button
                  className="sort-btn"
                //   onClick={() => this.toggleFilter()}
                >
                  Sort
                  <i>
                    <img src={sortIcon} alt="" />
                  </i>
                </button>
                <button
                  className="filter-btn"
                  onClick={() => this.toggleFilterFlag()}
                >
                  Filter
                  <i>
                    <img src={filterLogo} alt="" />
                  </i>
                </button>
              </div>
            </div>
            <div className="property-wrapper">
              {!isEmpty(this.state.propertyList) ?
                this.state.propertyList?.map((property, index) =>
                  this.state.propertyList?.length == index + 1 ?
                    <div className="property-block" key={property.id} ref={this.lastPropertyItem}>
                      <div className="top-block">
                        <div className="profile-block">
                          <i>
                            <img src={buyerSkew} alt="" />
                          </i>
                          <span>{`${property.attributes.owner.first_name ? property.attributes.owner.first_name : "Unknown"} ${property.attributes.owner.last_name ? property.attributes.owner.last_name : ""}`}</span>
                        </div>
                        {property.attributes.is_favorited ? (
                          <div className="like-block" onClick={() => this.doRemoveFavoriteHouse(property.id)}>
                            <img src={imageLikeRed} alt="Liked image" />
                          </div>) : (
                          <div className="like-block" onClick={() => this.doMakeFavoriteHouse(property.id)}>
                            <img src={imageLike} alt="Like image" />
                          </div>
                        )}
                      </div>
                      <i className="property-image">
                        <img src={property.attributes.images[0] ? property.attributes.images[0]?.url : noImgAvailable} alt="Property Image" />

                        {/* <img src={property.attributes.images?.[0]?.url} alt="Property Image" /> */}
                      </i>
                      <div className="like-view-block">
                        <div className="left-block">
                          <p className="lable">List Price</p>
                          <p className="price">{property.attributes.formatted_price}</p>
                        </div>
                        <div className="right-block">
                          <div className="view">
                            <i>
                              <img src={imageView} alt="" />
                            </i>
                            <span>{property.attributes.view_count}</span>
                          </div>
                          <div className="like">
                            <i>
                              <img src={imageLikeRed} alt="" />
                            </i>
                            <span>{property.attributes.favorited_count}</span>
                          </div>
                        </div>
                      </div>
                      <div className="more-detail" onClick={() =>
                        localStorage.getItem("auth") &&
                        // @ts-ignore
                        this.props?.history?.push({
                          pathname: "/property-detail",
                          search: `id=${property.id}`,
                          state: { propertyID: property.id }
                        })
                      }>
                        <p className="property-name">{property.attributes.address.address_line}</p>
                        <p className="property-address">{`${property.attributes.address.state} , ${property.attributes.address.city} ${property.attributes.address.zip_code}`}</p>
                        <ul>
                          <li>{property.attributes.bedrooms} Beds</li>
                          <li>{property.attributes.full_baths} Baths</li>
                          <li>{property.attributes.finished_sq_ft} Sq ft</li>
                        </ul>
                      </div>
                      <div className="btn-wrapper">
                        <button
                          type="button"
                          className="rounded-fill-btn"
                          onClick={() => { this.handleShowTourModal(property.id) }}
                        >
                          Schedule Tour
                        </button>
                        {this.state.compareHouseList?.find(home => home.id === property.id) ? (
                          <button
                            type="button"
                            className={`btn gray-fill-btn active `}
                            onClick={() => this.doRemoveCompareHouse(property.id)}
                          >
                            Remove to compare
                          </button>)
                          : (
                            <button
                              type="button"
                              className={`btn gray-fill-btn`}
                              onClick={() => this.doMakeCompareHouse(property.id)}
                            >
                              Add to compare
                            </button>)}
                      </div>
                    </div> : <div className="property-block" key={property.id}>
                      <div className="top-block">
                        <div className="profile-block">
                          <i>
                            <img src={buyerSkew} alt="" />
                          </i>
                          <span>{`${property.attributes.owner.first_name ? property.attributes.owner.first_name : "Unknown"} ${property.attributes.owner.last_name ? property.attributes.owner.last_name : ""}`}</span>
                        </div>
                        {property.attributes.is_favorited ? (
                          <div className="like-block" onClick={() => this.doRemoveFavoriteHouse(property.id)}>
                            <img src={imageLikeRed} alt="Liked image" />
                          </div>) : (
                          <div className="like-block" onClick={() => this.doMakeFavoriteHouse(property.id)}>
                            <img src={imageLike} alt="Like image" />
                          </div>
                        )}
                      </div>
                      <i className="property-image">
                        <img src={property.attributes.images[0] ? property.attributes.images[0]?.url : noImgAvailable} alt="Property Image" />

                        {/* <img src={property.attributes.images?.[0]?.url} alt="Property Image" /> */}
                      </i>
                      <div className="like-view-block">
                        <div className="left-block">
                          <p className="lable">List Price</p>
                          <p className="price">{property.attributes.formatted_price}</p>
                        </div>
                        <div className="right-block">
                          <div className="view">
                            <i>
                              <img src={imageView} alt="" />
                            </i>
                            <span>{property.attributes.view_count}</span>
                          </div>
                          <div className="like">
                            <i>
                              <img src={imageLikeRed} alt="" />
                            </i>
                            <span>{property.attributes.favorited_count}</span>
                          </div>
                        </div>
                      </div>
                      <div className="more-detail" onClick={() =>
                        localStorage.getItem("auth") &&
                        // @ts-ignore
                        this.props?.history?.push({
                          pathname: "/property-detail",
                          search: `id=${property.id}`,
                          state: { propertyID: property.id }
                        })
                      }>
                        <p className="property-name">{property.attributes.address.address_line}</p>
                        <p className="property-address">{`${property.attributes.address.state} , ${property.attributes.address.city} ${property.attributes.address.zip_code}`}</p>
                        <ul>
                          <li>{property.attributes.bedrooms} Beds</li>
                          <li>{property.attributes.full_baths} Baths</li>
                          <li>{property.attributes.finished_sq_ft} Sq ft</li>
                        </ul>
                      </div>
                      <div className="btn-wrapper">
                        <button
                          type="button"
                          className="rounded-fill-btn"
                          onClick={() => { this.handleShowTourModal(property.id) }}
                        >
                          Schedule Tour
                        </button>
                        {this.state.compareHouseList?.find(home => home.id === property.id) ? (
                          <button
                            type="button"
                            className={`btn gray-fill-btn active `}
                            onClick={() => this.doRemoveCompareHouse(property.id)}
                          >
                            Remove to compare
                          </button>)
                          : (
                            <button
                              type="button"
                              className={`btn gray-fill-btn`}
                              onClick={() => this.doMakeCompareHouse(property.id)}
                            >
                              Add to compare
                            </button>)}
                      </div>
                    </div>)
                : <div className="no-data"> <h2>No Data Found</h2></div>
              }
            </div>
            {this.state.compareHouseList?.length > 1 &&
              <button
                className="rounded-fill-btn compare-home-count"
                onClick={() => this.props?.history?.push("/compare-homes")}
              >Compare ({this.state.compareHouseList?.length})</button>
            }
          </div>
        </div>

        {/* Filter  */}
        <Modal
          show={this.state.FilterFlag}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="filter-modal"
          onHide={this.toggleFilterFlag}
        >
          <Modal.Body>
            <Formik
              initialValues={{
                city: this.state.filterData?.city || [],
                sliderValue: this.state.filterData?.sliderValue || [this.state.filterData?.["price[min]"] ? this.state.filterData?.["price[min]"] / 1000 : 10, this.state.filterData?.["price[max]"] ? this.state.filterData?.["price[max]"] / 1000 : 20] || [10, 20],
                property_state: this.state.filterData?.property_state || "",
                bedValue: this.state.filterData?.bathValue || [this.state.filterData?.["bedrooms[min]"] ? this.state.filterData?.["bedrooms[min]"] * 10 : 10, this.state.filterData?.["bedrooms[max]"] ? this.state.filterData?.["bedrooms[max]"] * 10 : 20] || [10, 20],
                bathValue: this.state.filterData?.bathValue || [this.state.filterData?.["bathrooms[min]"] ? this.state.filterData?.["bathrooms[min]"] * 10 : 10, this.state.filterData?.["bathrooms[max]"] ? this.state.filterData?.["bathrooms[max]"] * 10 : 20] || [10, 20],
                saleFlag: true,
                priceFlag: false,
                roomsFlag: false,
                moreFlag: false,
                bathroomFlag: false,
                homeTypeList: this.state.filterData?.homeTypeList || this.state.filterData?.home_type_id || [],
                must_garage: this.state.filterData?.must_garage || false,
                has_basement: {
                  basement: this.state.filterData?.["has_basement[basement]"] || false,
                  finish: this.state.filterData?.["has_basement[finished]"] || false,
                  unfinish: this.state.filterData?.["has_basement[unfinished]"] || false,
                },
                single_story: this.state.filterData?.single_story || "",
                view_id: this.state.filterData?.view_id || [],
                repaired: this.state.filterData?.repaired || false,
                must_have_ac: this.state.filterData?.must_have_ac || false,
                must_have_pool: this.state.filterData?.must_have_pool || false,
                waterfront: this.state.filterData?.waterfront || false,
                hoa: this.state.filterData?.hoa || "",
                parking_spots: this.state.filterData?.parking_spots || "",
                sq_ft_from: this.state.filterData?.sq_ft_from || "",
                sq_ft_to: this.state.filterData?.sq_ft_to || "",
                lotSqft_from: this.state.filterData?.lotSqft_from || "",
                lotSqft_to: this.state.filterData?.lotSqft_to || "",
                built_from: this.state.filterData?.built_from || "",
                built_to: this.state.filterData?.built_to || "",
                last_sold: this.state.filterData?.last_sold || "",
                Keywords: this.state.filterData?.Keywords || "",
                search: this.state.filterData?.search || ""
              }}
              validationSchema={Yup.object().shape(this.state.filterSchema)}
              onSubmit={(values) =>
                // console.log("values=======>",values)
                this.doFilterHouse(values)
              }
              enableReinitialize
            // 
            >
              {({ values, setFieldValue, errors, resetForm }) => (
                //@ts-ignore
                <Form>
                  <div className="filter-header">
                    <Field
                      type="text"
                      name="search"
                      placeholder="Search by Location,pincode..."
                    // className="custom-input secondary-input"
                    />

                    {/* <input type='text'
                      value={values.search}
                      onChange={(e) => setFieldValue("search", e.target.value)}
                    /> */}

                    {/* <CustomizedHook
                      setOptions={this.state.stateDropdown}
                      getValue={(value) =>{
                        if(values.city.length !== value.length){
                          setFieldValue("city",value)
                        }
                        }
                      } 
                    />*/}
                    <i className="search-icon">
                      <img src={iconSearch} alt="" />
                    </i>
                  </div>
                  <div className="filter-body">
                    <div className="clear-filter">
                      <div className="left-filter">filters</div>
                      <div className="right-filter" onClick={() => resetForm()}>Clear Filter</div>
                    </div>
                    <div className="filter-wrapper">
                      <div className={
                        "filter-tab " + (values.saleFlag ? "active" : "")
                      }
                        onClick={() => {
                          setFieldValue("saleFlag", true);
                          setFieldValue("priceFlag", false);
                          setFieldValue("roomsFlag", false);
                          setFieldValue("moreFlag", false);
                          setFieldValue("bathroomFlag", false);
                        }}>
                        <span>For Sale</span>
                        <i>
                          <img src={arrowBottom} alt="" />
                        </i>
                      </div>
                      <div
                        className={
                          "filter-tab " + (values.priceFlag ? "active" : "")
                        }
                        onClick={() => {
                          setFieldValue("saleFlag", false);
                          setFieldValue("priceFlag", true);
                          setFieldValue("roomsFlag", false);
                          setFieldValue("bathroomFlag", false);
                          setFieldValue("moreFlag", false);
                        }}
                      >
                        <span>Price</span>
                        <i>
                          <img src={arrowBottom} alt="" />
                        </i>
                      </div>
                      <div
                        className={
                          "filter-tab " + (values.bathroomFlag ? "active" : "")
                        }
                        onClick={() => {
                          setFieldValue("saleFlag", false);
                          setFieldValue("priceFlag", false);
                          setFieldValue("roomsFlag", false);
                          setFieldValue("bathroomFlag", true);
                          setFieldValue("moreFlag", false);
                        }}
                      >
                        <span>2+ Bd, 2+ Ba</span>
                        <i>
                          <img src={arrowBottom} alt="" />
                        </i>
                      </div>
                      <div
                        className={
                          "filter-tab " + (values.roomsFlag ? "active" : "")
                        }
                        onClick={() => {
                          setFieldValue("saleFlag", false);
                          setFieldValue("priceFlag", false);
                          setFieldValue("roomsFlag", true);
                          setFieldValue("bathroomFlag", false);
                          setFieldValue("moreFlag", false);
                        }}
                      >
                        <span>Home Type</span>
                        <i>
                          <img src={arrowBottom} alt="" />
                        </i>
                      </div>
                      <div
                        className={
                          "filter-tab " + (values.moreFlag ? "active" : "")
                        }
                        onClick={() => {
                          setFieldValue("saleFlag", false);
                          setFieldValue("priceFlag", false);
                          setFieldValue("roomsFlag", false);
                          setFieldValue("bathroomFlag", false);
                          setFieldValue("moreFlag", true);
                        }}
                      >
                        <span>More</span>
                        <i>
                          <img src={arrowBottom} alt="" />
                        </i>
                      </div>
                    </div>
                    {/* for sale block  */}
                    {values.saleFlag && (
                      <div className="filter-content">
                        <div className="sale-block-wrapper">
                          <div
                            className={`sale-block ${values.property_state === "for_sale" && "active"}`}
                            onClick={() => setFieldValue("property_state", "for_sale")}
                          >
                            <img src={soldProperty} alt="" />
                            <img className="active-image" src={soldPropertyWhite} alt="" />
                            <span>For Sale</span>
                          </div>
                          <div
                            className={`sale-block ${values.property_state === "sold" && "active"}`}
                            onClick={() => setFieldValue("property_state", "sold")}
                          >
                            <img src={forSale} alt="" />
                            <img className="active-image" src={forSaleWhite} alt="" />
                            <span>Sold</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Select Price Range Slider  */}
                    {values.priceFlag && (
                      <div className="filter-content">
                        <div className="slider-title">Select Price Range</div>
                        <span className="prize-range">{values.sliderValue?.[0] * 1000} $ - {values.sliderValue?.[1] * 1000} $</span>
                        <div className="slider-wrapper orange-slider">
                          <Slider
                            value={values.sliderValue}
                            scale={(x) => x * 1000}
                            valueLabelFormat={(limit: number) => `$${limit}`}
                            onChange={(e, newValue) =>
                              setFieldValue("sliderValue", newValue)
                            }
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                          />
                        </div>
                      </div>
                    )}
                    {/* Select Bathroom Slider  */}
                    {values.bathroomFlag && (
                      <div className="filter-content">
                        <div className="slider-title">Beds</div>
                        <div className="slider-wrapper orange-slider">
                          <Slider
                            value={values.bedValue}
                            scale={(x) => x / 10}
                            step={10}
                            valueLabelFormat={(limit: number) => `${limit}`}
                            onChange={(e, newValue) =>
                              setFieldValue("bedValue", newValue)
                            }
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                          />
                        </div>
                        <div className="slider-title">Baths</div>
                        <div className="slider-wrapper orange-slider">
                          <Slider
                            value={values.bathValue}
                            scale={(x) => x / 10}
                            step={10}
                            valueLabelFormat={(limit: number) => `${limit}`}
                            onChange={(e, newValue) =>
                              setFieldValue("bathValue", newValue)
                            }
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                          />
                        </div>
                      </div>
                    )}
                    {/* Select Home Type  */}
                    {values.roomsFlag && (
                      <div className="filter-content">
                        <div className="slider-title">Select Home Type</div>
                        <div className="check-outer">
                          {this.state.homeTypeList.map(
                            (homeType: any, index) => (
                              <div className="checkbox-wrapper" key={index}>
                                <label htmlFor={homeType.id}>
                                  <input
                                    type="checkbox"
                                    id={homeType.id}
                                    key={homeType.id}
                                    defaultChecked={values.homeTypeList.includes(homeType.id)}
                                    name={homeType.attributes.title}
                                    className="input-checkbox"
                                    onClick={() => {
                                      let array = Object.assign(
                                        [],
                                        values.homeTypeList
                                      );
                                      const idx = array.findIndex((el) =>
                                        el === homeType.id
                                      );
                                      if (idx === -1) {
                                        array = array.concat(homeType.id);
                                      } else {
                                        array.splice(idx, 1);
                                      }
                                      setFieldValue("homeTypeList", array);
                                    }}
                                  />
                                  {homeType.attributes.title}
                                  <span className="checkmark" />
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}

                    {/* MORE  */}
                    {values.moreFlag && (
                      <div className="filter-content more-filter">
                        <div className="left-block">
                          <div className="field-wrapper">
                            <label className="mb-1">Max HOA</label>
                            <Field
                              type="text"
                              as="select"
                              name="hoa"
                              autoComplete="off"
                              defaultValue=""
                              className="custom-input secondary-input "
                            >
                              <option value="" >
                                Any
                              </option>
                              <option value="no_fee">No HOA Fee</option>
                              <option value="50$">$ 50/month</option>
                              <option value="100$">$ 100/month</option>
                              <option value="200$">$ 200/month</option>
                              <option value="300$">$ 300/month</option>
                            </Field>
                          </div>
                          <div className="field-wrapper">
                            <label className="mb-1">Parking Spots</label>
                            <Field
                              type="text"
                              as="select"
                              name="parking_spots"
                              autoComplete="off"
                              defaultValue=""
                              className="custom-input secondary-input "
                            >
                              <option value="" >
                                Any
                              </option>
                              <option value="1">1+</option>
                              <option value="2">2+</option>
                              <option value="3">3+</option>
                              <option value="4">4+</option>
                            </Field>
                          </div>
                          <div className="field-wrapper">
                            <label />
                            <div className="checkbox-wrapper">
                              <label>
                                <input
                                  type="checkbox"
                                  id="garage"
                                  name="must_garage"
                                  defaultChecked={values.must_garage}
                                  className="input-checkbox"
                                  onClick={() =>
                                    setFieldValue(
                                      "must_garage",
                                      !values.must_garage
                                    )
                                  }
                                />
                                Must have garage
                                <span className="checkmark" />
                              </label>
                            </div>
                          </div>
                          <div className="field-wrapper">
                            <label>Square Feet</label>
                            <div className="select-wrapper">
                              <Field
                                type="text"
                                as="select"
                                name="sq_ft_from"
                                autoComplete="off"
                                defaultValue=""
                                className="custom-input secondary-input "
                              >
                                <option value="" >
                                  Any
                                </option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="750">750</option>
                                <option value="1000">1000</option>
                                <option value="1250">1250</option>
                                <option value="1500">1500</option>
                                <option value="2000">2000</option>
                                <option value="2500">2500</option>
                                <option value="3000">3000</option>
                              </Field>
                              <span className="dash" />
                              <Field
                                type="text"
                                as="select"
                                name="sq_ft_to"
                                autoComplete="off"
                                defaultValue=""
                                className="custom-input secondary-input "
                              >
                                <option value="" >
                                  Any
                                </option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="750">750</option>
                                <option value="1000">1000</option>
                                <option value="1250">1250</option>
                                <option value="1500">1500</option>
                                <option value="2000">2000</option>
                                <option value="2500">2500</option>
                                <option value="3000">3000</option>
                              </Field>
                            </div>
                            <span className="error">
                              <ErrorMessage name="sq_ft_from" />
                            </span>
                            <span className="error">
                              <ErrorMessage name="sq_ft_to" />
                            </span>
                          </div>
                          <div className="field-wrapper">
                            <label>Lot Size </label>
                            <div className="select-wrapper">
                              <Field
                                type="text"
                                as="select"
                                name="lotSqft_from"
                                autoComplete="off"
                                defaultValue=""
                                className="custom-input secondary-input "
                              >
                                <option value="" >
                                  Any
                                </option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="750">750</option>
                                <option value="1000">1000</option>
                                <option value="1250">1250</option>
                                <option value="1500">1500</option>
                                <option value="2000">2000</option>
                                <option value="2500">2500</option>
                                <option value="3000">3000</option>
                              </Field>
                              <span className="dash" />
                              <Field
                                type="text"
                                as="select"
                                name="lotSqft_to"
                                autoComplete="off"
                                defaultValue=""
                                className="custom-input secondary-input "
                              >
                                <option value="" >
                                  Any
                                </option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="750">750</option>
                                <option value="1000">1000</option>
                                <option value="1250">1250</option>
                                <option value="1500">1500</option>
                                <option value="2000">2000</option>
                                <option value="2500">2500</option>
                                <option value="3000">3000</option>
                              </Field>
                            </div>
                            <span className="error">
                              <ErrorMessage name="lotSqft_from" />
                            </span>
                            <span className="error">
                              <ErrorMessage name="lotSqft_to" />
                            </span>
                          </div>
                          <div className="field-wrapper">
                            <label>Year Built </label>
                            <div className="select-wrapper">
                              <Field
                                type="number"
                                name="built_from"
                                placeholder="Min"
                                className="custom-input secondary-input"
                              />
                              <span className="dash" />
                              <Field
                                type="number"
                                name="built_to"
                                placeholder="Max"
                                className="custom-input secondary-input"
                              />
                            </div>
                            <span className="error">
                              <ErrorMessage name="type" />
                            </span>
                          </div>
                          <div className="field-wrapper">
                            <label className="mb-1">Sold In Last</label>
                            <Field
                              type="text"
                              as="select"
                              name="last_sold"
                              autoComplete="off"
                              defaultValue=""
                              className="custom-input secondary-input "
                            >
                              <option value="">
                                Any
                              </option>
                              <option value="1">1 month</option>
                              <option value="6">6 months</option>
                              <option value="12">12 months</option>
                              <option value="24">24 months</option>
                              <option value="36">36 months</option>
                            </Field>
                          </div>
                          <div className="field-wrapper">
                            <label className="mb-1">Keywords</label>
                            <Field
                              type="text"
                              name="Keywords"
                              placeholder="MLS, Yard, etc…"
                              className="custom-input secondary-input"
                            />
                          </div>
                        </div>
                        <div className="right-block">
                          <div className="checkbox-wrapper">
                            <label>
                              <input
                                type="checkbox"
                                id="has_basement.basement"
                                name="has_basement.basement"
                                className="input-checkbox"
                                checked={values.has_basement.basement}
                                onClick={() => {
                                  let obj = Object.assign(
                                    {},
                                    values.has_basement
                                  );
                                  if (obj.basement) {
                                    let data = {
                                      basement: false,
                                      finish: false,
                                      unfinish: false,
                                    };
                                    setFieldValue("has_basement", data);
                                  } else {
                                    obj.basement = true;
                                    setFieldValue("has_basement", obj);
                                  }
                                }}
                              />
                              Has Basement
                              <span className="checkmark" />
                            </label>
                            <div className="sub-checkbox">
                              <div className="checkbox-wrapper">
                                <label>
                                  <input
                                    type="checkbox"
                                    id="has_basement.finish"
                                    name="has_basement.finish"
                                    checked={values.has_basement.finish}
                                    className="input-checkbox"
                                    onClick={() => {
                                      let obj = Object.assign(
                                        {},
                                        values.has_basement
                                      );
                                      if (obj.basement === false) {
                                        let data = {
                                          basement: false,
                                          finish: false,
                                          unfinish: false,
                                        };
                                        setFieldValue("has_basement", data);
                                      } else {
                                        if (obj.unfinish) {
                                          obj.unfinish = !obj.unfinish;
                                        }
                                        obj.finish = !obj.finish;

                                        setFieldValue("has_basement", obj);
                                      }
                                    }}
                                  />
                                  Finished
                                  <span className="checkmark" />
                                </label>
                              </div>
                              <div className="checkbox-wrapper">
                                <label>
                                  <input
                                    type="checkbox"
                                    id="basement"
                                    name="has_basement.unfinish"
                                    checked={values.has_basement.unfinish}
                                    className="input-checkbox"
                                    onClick={() => {
                                      let obj = Object.assign(
                                        {},
                                        values.has_basement
                                      );
                                      if (obj.basement === false) {
                                        let data = {
                                          basement: false,
                                          finish: false,
                                          unfinish: false,
                                        };
                                        setFieldValue("has_basement", data);
                                      } else {
                                        if (obj.finish) {
                                          obj.finish = !obj.finish;
                                        }
                                        obj.unfinish = !obj.unfinish;
                                        setFieldValue("has_basement", obj);
                                      }
                                    }}
                                  />
                                  Unfinished
                                  <span className="checkmark" />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="checkbox-wrapper">
                            <label>
                              <input
                                type="checkbox"
                                id="single_story"
                                name="single_story"
                                className="input-checkbox"
                                checked={values.single_story}
                                onClick={() =>
                                  setFieldValue(
                                    "single_story",
                                    !values.single_story
                                  )
                                }
                              />
                              Single Story Only
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="checkbox-title">Other Amenities</div>
                          <div className="checkbox-wrapper">
                            <label>
                              <input
                                type="checkbox"
                                id="must_have_ac"
                                name="must_have_ac"
                                defaultChecked={values.must_have_ac}
                                className="input-checkbox"
                                onClick={() => {
                                  setFieldValue("must_have_ac", !values.must_have_ac);
                                }}
                              />
                              Must have AC
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="checkbox-wrapper">
                            <label>
                              <input
                                type="checkbox"
                                id="must_have_pool"
                                name="must_have_pool"
                                className="input-checkbox"
                                onClick={() => {
                                  setFieldValue("must_have_pool", !values.must_have_pool);
                                }}
                              />
                              Must have Pool
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="checkbox-wrapper">
                            <label>
                              <input
                                type="checkbox"
                                id="waterfront"
                                name="waterfront"
                                className="input-checkbox"
                                onClick={() => {
                                  setFieldValue("waterfront", !values.waterfront);
                                }}
                              />
                              Waterfront
                              <span className="checkmark" />
                            </label>
                          </div>
                          <div className="check-outer">
                            <div className="checkbox-title w-full">View</div>
                            {this.state.roomDetailList?.outside_views?.data?.map(vw => {
                              return (vw.attributes.name !== "Other" && <div className="checkbox-wrapper" key={vw.id}>
                                <label>
                                  <input
                                    type="checkbox"
                                    id="view_id"
                                    name="view_id"
                                    className="input-checkbox"
                                    onClick={() => {
                                      let array = Object.assign(
                                        [],
                                        values.view_id
                                      );
                                      const idx = array.findIndex((el) =>
                                        el === vw.id
                                      );
                                      if (idx === -1) {
                                        array = array.concat(vw.id);
                                      } else {
                                        array.splice(idx, 1);
                                      }
                                      setFieldValue("view_id", array);
                                    }}
                                  />
                                  {vw.attributes.name}
                                  <span className="checkmark" />
                                </label>
                              </div>
                              )
                            })}
                          </div>
                          <div className="checkbox-wrapper">
                            <label>
                              <input
                                type="checkbox"
                                id="repaired"
                                name="repaired"
                                className="input-checkbox"
                                checked={values.repaired}
                                onClick={() =>
                                  setFieldValue("repaired", !values.repaired)
                                }
                              />
                              Moved-in ready homes, Evaluated And repaired by
                              Sparen
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* {
                      <pre>
                        {JSON.stringify(values, null, 2)}
                      </pre>
                    } */}
                    <div className="btn-wrapper">
                      <button type="submit" className="rounded-fill-btn">
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn gray-fill-btn"
                        onClick={this.toggleFilterFlag}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal.Body>
        </Modal>

        {/* Tour Schedule1 */}
        {/* <ScheduleTour showTourModalProp={this.state.scheduleTourShow} onHideTourModal={this.handleShowScheduleTour} propertyId={this.state.propertyId} /> */}

        {/* Tour Schedule2 */}
        <Modal
          show={this.state.scheduleTourShow}
          onHide={this.handleHideTourModal}
          dialogClassName="schedule-tour-modal"
          backdrop="static"
          aria-labelledby="buyer-schedule-tour-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Schedule Tour
            </Modal.Title>
          </Modal.Header>
          <Formik
            initialValues={{
              type: "",
              date: "",
              time: new Date(),
              note: "",
              showtime: "",
            }}
            validationSchema={Yup.object().shape(this.state.tourSchema)}
            onSubmit={(values) => {
              this.doBuyerTour(values, this.state.propertyId);
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, errors }) => (
              <Form>
                <Modal.Body>
                  <div className="schedule-div">

                    <div className="schedule-type-btn">
                      <div className="sche-btn-wrap">
                        <button
                          className={`btn ${values.type === "in_person"
                            ? "orange-fill-btn"
                            : "gray-fill-btn"
                            }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue("type", "in_person");
                          }}
                        >
                          <span>In Person</span>
                        </button>
                        <button
                          className={`btn ${values.type === "live_video"
                            ? "orange-fill-btn"
                            : "gray-fill-btn"
                            }`}
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue("type", "live_video");
                          }}
                        >
                          <img src={videoImg} />
                          <span>Live Video</span>
                        </button>
                      </div>
                      <span className="error">
                        <ErrorMessage name="type" />
                      </span>
                    </div>


                    <div className="select-date-div">
                      <h6 className="schedule-title">Select Date</h6>
                      <ul className="select-date-ul">
                        {this.state.nextSevenDays.map((d) => (
                          <li
                            key={d[0]}
                            className={`delect-date-li ${values.date == d[1] ? "green-bg-li" : ""
                              }`}
                            onClick={() => setFieldValue("date", d[1])}
                          >
                            <p className="day">{d[3]}</p>
                            <p className="date">{d[2]}</p>
                          </li>
                          // <li
                          //   key={d.fullDate}
                          //   className={`delect-date-li ${values.date == d.fullDate ? "green-bg-li" : ""
                          //     }`}
                          //   onClick={() => setFieldValue("date", d.fullDate)}
                          // >
                          //   <p className="day">{d.day}</p>
                          //   <p className="date">{d.date}</p>
                          // </li>
                        ))}
                      </ul>
                      <span className="error">
                        <ErrorMessage name="date" />
                      </span>
                    </div>


                    <div className="select-time-div">
                      <h6 className="schedule-title">Select Time</h6>

                      <DatePicker
                        selected={values.showtime ? values.showtime : new Date()}
                        onChange={(time) => {
                          let t = time.toLocaleTimeString("it-IT");
                          setFieldValue(`time`, t);
                          setFieldValue("showtime", time);
                        }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="HH:mm aa"
                      />
                      <span className="error">
                        <ErrorMessage name="time" />
                      </span>
                    </div>


                    <div className="note-div">
                      <h6 className="schedule-title">Note</h6>

                      <div className="field-wrapper">
                        <Field
                          type="text"
                          name="note"
                          // placeholder={configJSON.styleHolder}
                          className="custom-input secondary-input"
                        />
                        <span className="error">
                          <ErrorMessage name="note" />
                        </span>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    variant="primary"
                    type="submit"
                    className="rounded-fill-btn"
                  // onClick={this.props.onHide}
                  >
                    Schedule Tour
                  </button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal>

        {/* Loader  */}
        {this.state.loader && <AppLoader title="Loading" />}

        {/* Snackbar for display success and failed messages. */}
        {/* {console.log("message snackBar", this.state.snackBar)} */}
        <Snackbar
          open={this.state.snackBar.show}
          autoHideDuration={3000}
          onClose={this.closeSnackBarHandler}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={this.closeSnackBarHandler}
            severity={this.state.snackBar.type}
          >
            {this.state.snackBar.message}
          </MuiAlert>
        </Snackbar>

      </>
    );
  }
}

export default withRouter(DashboardBuyer);
