// @ts-nocheck

import React from "react";
// Customizable Area Start
import { Link, withRouter } from "react-router-dom";
// Customizable Area End

import StepIAddressDetail from "./StepIAddressDetail.web";
import StepIIBasicDetails from "./StepIIBasicDetails.web";
import StepIIIUploadMedia from "./StepIIIUploadMedia.web";
import StepIVPricing from "./StepIVPricing.web";
import StepVChecklist from "./StepVChecklist.web";
import StepVIReviewUpload from "./StepVIReviewUpload.web";
import AddPropertyFlowController, { Props } from "./AddPropertyFlowController.web";
import { isEmpty } from "lodash";

class CreatePropertyParent extends AddPropertyFlowController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  componentDidMount() {
    if (this.props.propertyId) {
      this.getPropertyDetails(this.props.propertyId);
    } else if (this.state.propertyAllDetailData?.id) {
      this.getPropertyDetails(this.state.propertyAllDetailData?.id);
    } else if (sessionStorage.getItem("type") == 'addP' && sessionStorage.getItem("propertyId")) {
      this.getPropertyDetails(sessionStorage.getItem("propertyId"));
    }
  }


  // Customizable Area End

  render() {
    console.log(this.state.step);

    return (
      <div className="stepper-wrapper">
        <div className="step-count">
          <div onClick={() => this.doStepSet(1)}>
            <div className="count-wrapper active">
              <div className="count">01</div>
              <div className="title-wrapper">
                <p className="step-number">STEP 1</p>
                <p className="step-title">Address Details</p>
              </div>
            </div>
          </div>
          <div onClick={() => {
            this.handleCurrentFormIsValid(2, this.state.step);
          }}>
            <div
              className={`count-wrapper ${this.state.step >= 2 ? "active" : ""}`}
            >
              <div className="count">02</div>
              <div className="title-wrapper">
                <p className="step-number">STEP 2</p>
                <p className="step-title">Basic Details</p>
              </div>
            </div>
          </div>
          <div onClick={() => {
            this.handleCurrentFormIsValid(3, this.state.step);
          }}>
            <div
              className={`count-wrapper ${this.state.step >= 3 ? "active" : ""}`}
            >
              <div className="count">03</div>
              <div className="title-wrapper">
                <p className="step-number">STEP 3</p>
                <p className="step-title">Upload Media</p>
              </div>
            </div>
          </div>
          <div onClick={() => {
            this.handleCurrentFormIsValid(4, this.state.step);
          }}>
            <div
              className={`count-wrapper ${this.state.step >= 4 ? "active" : ""}`}
            >
              <div className="count">04</div>
              <div className="title-wrapper">
                <p className="step-number">STEP 4 </p>
                <p className="step-title">Pricing</p>
              </div>
            </div>
          </div>
          <div onClick={() => {
            this.handleCurrentFormIsValid(5, this.state.step);
          }}>
            <div
              className={`count-wrapper ${this.state.step >= 5 ? "active" : ""}`}
            >
              <div className="count">05</div>
              <div className="title-wrapper">
                <p className="step-number">STEP 5</p>
                <p className="step-title">Checklist</p>
              </div>
            </div>
          </div>
          <div onClick={() => {
            this.handleCurrentFormIsValid(6, this.state.step);
          }}>
            <div
              className={`count-wrapper ${this.state.step >= 6 ? "active" : ""}`}
            >
              <div className="count">06</div>
              <div className="title-wrapper">
                <p className="step-number">STEP 6</p>
                <p className="step-title">Review &amp; Upload</p>
              </div>
            </div>
          </div>
        </div>
        <div className="step-conetnt-wrapper">
          {this.state.step === 1 && <StepIAddressDetail setStep={(step, data) => this.doStepSet(step, data)} fieldData={this.state.propertyAllDetailData} formRef={this.formRef} />}
          {this.state.step === 2 && <StepIIBasicDetails setStep={(step, data) => this.doStepSet(step, data)} fieldData={this.state.propertyAllDetailData} formRef={this.formRef} />}
          {this.state.step === 3 && <StepIIIUploadMedia setStep={(step, data) => this.doStepSet(step, data)} fieldData={this.state.propertyAllDetailData} formRef={this.formRef} />}
          {this.state.step === 4 && <StepIVPricing setStep={(step, data) => this.doStepSet(step, data)} fieldData={this.state.propertyAllDetailData} formRef={this.formRef} />}
          {this.state.step === 5 && <StepVChecklist setStep={(step, data) => this.doStepSet(step, data)} fieldData={this.state.propertyAllDetailData} formRef={this.formRef} />}
          {this.state.step === 6 && <StepVIReviewUpload setStep={(step, data) => this.doStepSet(step, data)} fieldData={this.state.propertyAllDetailData} formRef={this.formRef} />}
          {this.props.children}
        </div>
      </div>
    );

  }
}

// Customizable Area Start
export default withRouter(CreatePropertyParent);

// Customizable Area End
