Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start

// Error
exports.errorEmailNotValid = "Please Enter Valid Email";
exports.errorEmailRequired = "Please Enter Email";
exports.errorPasswordNotValid = "Please Enter Password";

//API
exports.signinApiEndPoint = "bx_block_login/login";
exports.forgotApiEndPoint = "bx_block_forgot_password/otps";
exports.otpsApiEndPoint = "bx_block_forgot_password/otp_confirmations";
exports.createPwdApiEndPoint = "bx_block_forgot_password/passwords";

//TEXT
exports.placeHolderEmail = "Enter your Email";
exports.placeHolderPassword = "Enter your Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";

exports.placeHolderEmail = "Enter your Email";
exports.verifyCodeHolder = "Enter Verification Code";
exports.placeHolderNewPwd = "New Password";
exports.placeHolderConfirmPwd = "Confirm New Password";

exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";

// Messages
exports.successLogin = "Login Successfully";
exports.verifyOtpIsRequired = "Please Enter Otp Code";
exports.passwordValidation =
  "Password Must be 8 Characters long,Contain Both UpperCase and LowerCase Characters,east one digit, and one special Character ";
exports.passwordsMustMatch = "Password must be Same";
exports.pleaseEnterAPassword = "Please Enter Password";
exports.pleaseConfirmYourPassword = "Please Enter Confirm Password";

// Customizable Area End
