export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const viewBuyerImage = require("../../../web/images/view-buyerimage.png");
export const imageMail = require("../../../web/images/image-mail.png");
export const imageAttachment = require("../../../web/images/image-attachment.png");
export const checked = require("../../../web/images/checked.svg");
export const imageInfo = require("../../../web/images/image-info.png");
export const checkListHome = require("../../../web/images/checklist-home.png");
export const fixImg = require("../../../web/images/image-fix.png");
export const workingImg = require("../../../web/images/image-working.png");
export const arrowDown = require("../../../web/images/arrow-down.svg");
export const imageScrewdrive = require("../../../web/images/image-screwdrive.png");
export const imagePriceTag = require("../../../web/images/image-pricetag.png");
export const attachmentImg = require("../../../web/images/attachment.svg");
export const sendImg = require("../../../web/images/send.svg");