import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import _ from 'lodash';


// Customizable Area Start
// Customizable Area End

export const configJSON = require(".././config.js");

export interface Props {
    // Customizable Area Start
    timetravelShow: boolean;
    handleTimetravel: () => void;
    google: any;
    checkListShow: boolean;
    handleCheckList: () => void;
    amanitiesList: any;
    sucessAgreementShow: boolean;
    handlesucessAgreementShow: () => void;
    propItemDetailShow: boolean;
    handlepropItemDetailShow: () => void;
    propertyDetail: any;
    onSortChange: () => void;
    documentUploadAlertShow: boolean;
    handleDocumentUploadAlertShow: () => void;
    filterData: any;
    homeTypeList: any;
    roomDetailList: any;
    filterFlag: boolean;
    handleFilterFlagShow: () => void;
    toggleFilterFlag: () => {};
    setFilterData: (value: string) => void;
    resetFilterData: () => void;
    showTourModalProp: boolean;
    onHideTourModal: () => {}
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    step: any;
    DoughnutValue: any;
    sortFlag: any;
    sortBy: {
        addressShow: boolean;
        statusShow: boolean;
        addressSortType: string;
        statusSortType: string;
    },
    filterSchema: any;
    // Customizable Area End
}
interface SS {
}

export default class PopupsController extends BlockComponent<Props, S, SS> {
    rows: any[];
    sortPrams: any;
    constructor(props: Props) {
        super(props);
        let filterSchema = {
            sq_ft_from: Yup.string().ensure().when('sq_ft_to', {
                is: "sq_ft_from" >= "sq_ft_to",
                then: Yup.string().required()
            })
        }
        this.state = {
            step: 1,
            sortFlag: false,
            DoughnutValue: {
                datasets: [
                    {
                        data: [50000, 100000],
                        backgroundColor: ["#22456e", '#e9eaee'],
                        cutout: 37,
                        spacing: 0,
                        borderWidth: 0,
                        responsive: true,
                        maintainAspectRatio: true,
                    }
                ]
            },
            sortBy: {
                addressShow: false,
                statusShow: false,
                addressSortType: "",
                statusSortType: "",
            },
            filterSchema: filterSchema
        }

        this.rows = [
            this.createData("03 July, 2020", 123245674, "For July Rent", "-$400", "debit"),
            this.createData(
                "03 July, 2020",
                123245674,
                "For July Rent",
                "+$400",
                "credit"
            ),
            this.createData("03 July, 2020", 123245674, "For July Rent", "-$400", "debit"),
            this.createData(
                "03 July, 2020",
                123245674,
                "For July Rent",
                "+$400",
                "credit"
            ),
            this.createData("03 July, 2020", 123245674, "For July Rent", "-$400", "debit"),
        ];

        let params: any = localStorage.getItem('sortParam');
        if (params) this.sortPrams = JSON.parse(params);
    }

    componentWillUnmount = async () => {
        // localStorage.removeItem('sortParam');
    }

    // Customizable Area Start
    handleStep = (type: string) => {
        if (type == "next") this.setState(prev => ({ step: prev.step + 1 }))
        else if (type == "prev") this.setState(prev => ({ step: prev.step - 1 }))
        else this.setState({ step: 1 })
    }

    createData = (date: any, id: any, note: any, amount: any, type: any) => {
        return { date, id, note, amount, type };
    }

    // Toogle Sort 
    doSortToggle = () => {
        this.setState({
            ...this.state,
            sortFlag: !this.state.sortFlag
        })
    }

    handleSortChild = (name: string) => {
        if (name == "address") {
            this.setState(prev => ({ sortBy: { ...prev.sortBy, addressShow: !prev.sortBy.addressShow } }));
        } else if (name == "status") {
            this.setState(prev => ({ sortBy: { ...prev.sortBy, statusShow: !prev.sortBy.statusShow } }));
        } else if (name == "clear") {
            this.doSortToggle();
            if (localStorage.getItem("sortParam") && localStorage.getItem("sortParam") != "") {
                this.props.onSortChange();
                localStorage.removeItem('sortParam');
                this.getSortParam();
            }
        }
    }
    handleSortSubChild = (type: string) => {
        this.props.onSortChange();
        this.doSortToggle();
        if (type == "desc" || type == "asc") {
            this.setSortParam('addressSortType', type);
        } else if (type == "active" || type == "inactive") {
            this.setSortParam('statusSortType', type);
        }
        this.getSortParam();
    }
    getSortParam = () => {
        let params: any = localStorage.getItem('sortParam');
        if (params) this.sortPrams = JSON.parse(params);
        else this.sortPrams = {}
    }
    setSortParam = (type: string, value: string) => {
        console.log("this. is working")
        let params: any = localStorage.getItem('sortParam');
        let pramsObj: any = {};
        if (params) {
            pramsObj = JSON.parse(params);
        }
        pramsObj[type] = value;
        localStorage.setItem('sortParam', JSON.stringify(pramsObj));
    }
    sendFilterData(value: any) {
        this.props.setFilterData(value);
    }
    closeButtonClickHandler = () => {
        this.props.handleFilterFlagShow();
    }
    // Customizable Area End
}
