// @ts-nocheck

import React from "react";
// Customizable Area Start
import SidebarBuyer from "../../../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../../../components/src/Sidebar";
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import DashboardController, { Props } from "../DashboardSellerController.web";
import Header from "./Header.web";

import AddressDetail from "./AddressDetail.web";
import BasicDetails from "./BasicDetails.web";
import UploadMedia from "./UploadMedia.web";
import Pricing from "./Pricing.web";
import Checklist from "./Checklist.web";
import ReviewUpload from "./ReviewUpload.web";

class StepperMain extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // componentDidMount(){}
  // Customizable Area End

  render() {
  return (
      <div className="flex">
        <div>
        {/* Slider  */}
         {
          localStorage.getItem("role") === "buyer"
          ? <SidebarBuyer menuCollapsed={true}/>
          : <SidebarSeller menuCollapsed={true}/>
         }
        </div>
        <div className="stepper-outer">
        <Header
        flag={this.state.HeaderDropdownFlag}
        toggle={this.doToggleHeaderDropdown}
        />
          <h2 className="step-header-title">Add Property</h2>
          <div className="stepper-wrapper">
            <div className="step-count">
              <div onClick={() => this.doStepSet(1)}>
                <div className="count-wrapper active">
                  <div className="count">01</div>
                  <div className="title-wrapper">
                    <p className="step-number">STEP 1</p>
                    <p className="step-title">Address Details</p>
                  </div>
                </div>
              </div>
              <div onClick={() => this.doStepSet(2)}>
                <div
                  className={`count-wrapper ${
                    this.props?.location.state !== undefined
                      ? this.props?.location.state.steps >= 2 && "active"
                      : ""
                  }`}
                >
                  <div className="count">02</div>
                  <div className="title-wrapper">
                    <p className="step-number">STEP 2</p>
                    <p className="step-title">Basic Details</p>
                  </div>
                </div>
              </div>
              <div onClick={() => this.doStepSet(3)}>
                <div
                  className={`count-wrapper ${
                    this.props?.location.state !== undefined
                      ? this.props?.location.state.steps >= 3 && "active"
                      : ""
                  }`}
                >
                  <div className="count">03</div>
                  <div className="title-wrapper">
                    <p className="step-number">STEP 3</p>
                    <p className="step-title">Upload Media</p>
                  </div>
                </div>
              </div>
              <div onClick={() => this.doStepSet(4)}>
                <div
                  className={`count-wrapper ${
                    this.props?.location.state !== undefined
                      ? this.props?.location.state.steps >= 4 && "active"
                      : ""
                  }`}
                >
                  <div className="count">04</div>
                  <div className="title-wrapper">
                    <p className="step-number">STEP 4 </p>
                    <p className="step-title">Pricing</p>
                  </div>
                </div>
              </div>
              <div onClick={() => this.doStepSet(5)}>
                <div
                  className={`count-wrapper ${
                    this.props?.location.state !== undefined
                      ? this.props?.location.state.steps >= 5 && "active"
                      : ""
                  }`}
                >
                  <div className="count">05</div>
                  <div className="title-wrapper">
                    <p className="step-number">STEP 5</p>
                    <p className="step-title">Checklist</p>
                  </div>
                </div>
              </div>
              <div onClick={() => this.doStepSet(6)}>
                <div
                  className={`count-wrapper ${
                    this.props?.location.state !== undefined
                      ? this.props?.location.state.steps>= 6 && "active"
                      : ""
                  }`}
                >
                  <div className="count">06</div>
                  <div className="title-wrapper">
                    <p className="step-number">STEP 6</p>
                    <p className="step-title">Review &amp; Upload</p>
                  </div>
                </div>
              </div>
          </div>
            <div className="step-conetnt-wrapper">
              {/* {this.state.step === 1 && <AddressDetail />}
              {this.state.step === 2 && <BasicDetails />}
              {this.state.step === 3 && <UploadMedia />}
              {this.state.step === 4 && <Pricing />}
              {this.state.step === 5 && <Checklist />}
              {this.state.step === 6 && <ReviewUpload />} */}
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Customizable Area Start
export default withRouter(StepperMain);

// Customizable Area End
