// @ts-nocheck
import React from "react";
import { Link, withRouter } from "react-router-dom";
// Customizable Area Start
import DashboardBuyerController, {
  Props,
} from "../DashboardBuyerController.web";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Slider from "@material-ui/core/Slider";
import * as Yup from "yup";

export interface Props { }
interface S {}
class PaymentBuyer extends DashboardBuyerController {
  constructor(props: Props) {
    super(props);
    this.price = 0;
    this.pricePerUnit = 0;
  }
  render() {
    this.price = +this.props.price;
    this.pricePerUnit =+(Math.round(this.price / 100 + "e+2")  + "e-2");
    return (
        <Formik
                  initialValues={{
                    paymentMethod: this.props.fieldData?.paymentMethod || "sparen",
                    downPayment: this.props.fieldData?.downPayment || this.pricePerUnit ,
                    downPayLimit:this.props.fieldData?.downPayment?
                     Math.round(100*this.props.fieldData?.downPayment/this.price):1,
                    downPayType: this.props.fieldData?.downPayType||"dollar",
                    termPeriod: this.props.fieldData?.termPeriod || "",
                    offerAmount:this.props.fieldData?.offerAmount||1,
                    offerAmountLimit:this.props.fieldData?.offerAmount?
                     Math.round(100*this.props.fieldData?.offerAmount/this.price):1,
                   
                  }}
                  validationSchema={Yup.object().shape(
                    this.state.buyerSchemaArray[this.props.currentStep]
                  )}
                  onSubmit={(values) => {  
                    this.props.nextStep();
                    this.props.paymentValues(values);
                  
                  }}
                  enableReinitialize
                >
                  {({ values, setFieldValue, errors}) => {
                    return(
                      <Form>
                        {/* Step3 */}
                          <div className="step-3">
                              <div className="step-datil">
                                <div className="question-block">
                                  How will you be paying?
                                </div>
                                <div className="ans-block">
                                  <button
                                    className={`select-ans ${values.paymentMethod ===
                                      "all_cash" && "active"}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setFieldValue(
                                        "paymentMethod",
                                        "all_cash"
                                      );
                                    }}
                                  >
                                    All Cash
                                  </button>
                                  <button
                                    className={`select-ans ${values.paymentMethod ===
                                      "sparen" && "active"}`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setFieldValue("paymentMethod", "sparen");
                                    }}
                                  >
                                    Sparen
                                  </button>
                                </div>
                                {values.paymentMethod === "sparen" && (
                                  <div className="content-wrapper">
                                    <div className="slider-wrapper">
                                      <div className="sub-title">
                                        <span>Downpayment</span>
                                        <div className="right-block">
                                          <span className="title">
                                            Equal to:
                                          </span>
                                          <span className="value">
                                            ${values.downPayment ? values.downPayment :  this.pricePerUnit}
                                          </span>
                                        </div>
                                      </div>
                                      {values.downPayType == "dollar" && (
                                        <Slider
                                          className="priceSlider"
                                          value={values.downPayLimit}
                                          // defaultValue={values.downPayLimit}
                                          min={1}
                                          step={1}
                                          // max={100}
                                          scale={(x) => (x * this.pricePerUnit)}
                                          aria-labelledby="discrete-slider-always"
                                          getAriaValueText={this.setLimitLabel}
                                          valueLabelFormat={this.setLimitLabel}
                                          onChange={(e, value) =>{
                                            this.setLimitHandler(
                                              e,
                                              value,
                                              setFieldValue,
                                              this.pricePerUnit,
                                              "downPayment"
                                            );
                                             setFieldValue("downPayLimit",(100*values.downPayment/ this.price))
                                            }
                                          }
                                          valueLabelDisplay="auto"

                                        />
                                      )}
                                      {values.downPayType == "percentage" && (
                                        <Slider
                                          // defaultValue={values.downPayLimit}
                                          value={values.downPayLimit}
                                          aria-labelledby="discrete-slider-custom"
                                          min={1}
                                          step={1}
                                          // max={100}
                                          onChange={(e, value) =>
                                            this.setPercentHandler(
                                              e,
                                              value,
                                              setFieldValue,
                                              this.pricePerUnit
                                            )
                                          }
                                          valueLabelDisplay="auto"

                                        />
                                      )}
                                      <div className="value-wrapper">
                                        <div className="left-block">
                                          {values.downPayType == "dollar" && "$" + values.downPayment}
                                          {values.downPayType == "percentage" && values.downPayLimit + "%"}
                                        </div>
                                        <div className="right-block">
                                          <button
                                          type="button"
                                            className={`btn ${values.downPayType === "dollar"
                                                ? "orange-fill-btn"
                                                : "gray-fill-btn"
                                              }`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setFieldValue(
                                                "downPayType",
                                                "dollar"
                                              );
                                            }}
                                          >
                                            Dollar
                                          </button>
                                          <button
                                          type="button"
                                            className={`btn ${values.downPayType ===
                                                "percentage"
                                                ? "orange-fill-btn"
                                                : "gray-fill-btn"
                                              }`}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setFieldValue(
                                                "downPayType",
                                                "percentage"
                                              );
                                            }}
                                          >
                                            Percentage
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="terms-wrapper">
                                      <div className="sub-title">
                                        <span>Term</span>
                                      </div>
                                      <div className="terms-btn-wrapper">
                                        <span
                                          className={`term-btn ${values.termPeriod ==
                                            10 && "active"}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setFieldValue("termPeriod", 10);
                                          }}
                                        >
                                          10 Years
                                        </span>
                                        <span
                                          className={`term-btn ${values.termPeriod ==
                                            15 && "active"}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setFieldValue("termPeriod", 15);
                                          }}
                                        >
                                          15 Years
                                        </span>
                                        <span
                                          className={`term-btn ${values.termPeriod ==
                                            20 && "active"}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setFieldValue("termPeriod", 20);
                                          }}
                                        >
                                          20 Years
                                        </span>
                                        
                                      </div>
                                      <div  className="tour-ans-block-error">
                                      <ErrorMessage
                                      className="error"
                                      component="div"
                                      name="termPeriod"
                                    />
                                    </div>
                                    </div>
                                     
                                    <div className="offer-block">
                                      <div className="sub-title">
                                        <span>Offer Amount</span>
                                        <div className="right-block">
                                          <span className="title">
                                            Monlthy Payment
                                          </span>
                                          <span className="value">${values.termPeriod ? this.roundData((this.price - values.downPayment) / values.termPeriod) : 0}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {values.paymentMethod === "all_cash" && (
                                  <div className="content-wrapper pay-cash">
                                    <div className="slider-wrapper">
                                      <div className="sub-title">
                                        <span>Offer Amount</span>
                                        <div className="right-block">
                                          <span className="title">
                                            Equal to:
                                          </span>
                                          <span className="value">$10,000</span>
                                        </div>
                                      </div>
                                      <Slider
                                          className="priceSlider"
                                          value={values.offerAmountLimit}
                                          // defaultValue={values.downPayLimit}
                                          min={1}
                                          step={1}
                                          max={100}
                                          scale={(x) => x * this.pricePerUnit}
                                          aria-labelledby="discrete-slider-always"
                                          getAriaValueText={this.setLimitLabel}
                                          valueLabelFormat={this.setLimitLabel}
                                          onChange={(e, value) =>{
                                            this.setLimitHandler(
                                              e,
                                              value,
                                              setFieldValue,
                                              this.pricePerUnit,
                                              "offerAmount"
                                            );
                                             setFieldValue("offerAmountLimit",  100*values.offerAmount/ this.price)
                                            }
                                          }
                                          valueLabelDisplay="auto"

                                        />
                                      <div className="value-wrapper">
                                        <div className="left-block">
                                          <div className="content">
                                            <span className="title">
                                              Est. back at close:
                                            </span>
                                            <span className="value">$2000</span>
                                          </div>
                                          <div className="content">
                                            <span className="title">
                                              List price:
                                            </span>
                                            <span className="value">$2000</span>
                                          </div>
                                        </div>
                                        <div className="right-block">
                                          <div className="content">
                                            <span className="value">
                                              $180,000
                                            </span>
                                          </div>{" "}
                                          <div className="content">
                                            <span className="title">
                                              Your offer is 23% below list
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div className="step-footer">
                                <button
                                  type="button"
                                  className="btn gray-fill-btn"
                                  onClick={this.props.previousStep}
                                >
                                  Back
                                </button>
                                <button
                                  className="rounded-fill-btn"
                                  type="submit"
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                        
                          {/* {JSON.stringify(errors, null, 2)}
                          {JSON.stringify(values, null, 2)}                                  */}
                    </Form>
                  )}}
                </Formik>
     );
  }
}

export default withRouter(PaymentBuyer);
