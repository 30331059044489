import { Route, Redirect } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";

function Wrapper({ element, history, match, routeMap, closeModal }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: { navigate, getParam, goBack },
    closeModal
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

const WebRoutesGenerator = ({ routeMap, islogged, routeMapLogin }) => {
  // console.log("islogged", islogged, routeMap, routeMapLogin)
  if (!routeMap && routeMapLogin) {
    routeMap = routeMapLogin;
    // islogged = true;
    // console.log("ha worked")
  }
  let timeUp = false;
  const expiry = localStorage.getItem('expiry');

  if (new Date().getTime() - new Date(expiry) >= 1000 * 60 * 60 * 24) {
    timeUp = true;
    islogged = false;
    localStorage.clear();
    console.log("new Date().getTime() - new Date(expiry)", new Date().getTime() - new Date(expiry), "this worked")
  }
  else {
    timeUp = false;
    islogged = true;
    console.log("new Date().getTime() - new Date(expiry)", new Date(), expiry, "this worked")
  }

  // console.log("routemap", routeMap, islogged)

  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={props => (
            <Wrapper element={<Component />} {...props} routeMap={routeMap} />
          )}
        />
      );
    } else {
      return (
        <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={props => routeMapLogin ?
            (<Wrapper element={<Component />} {...props} routeMap={routeMap} />)
            :
            (
              islogged && !timeUp ?
                (<Wrapper element={<Component />} {...props} routeMap={routeMap} />)
                : (<Redirect to={{ pathname: '/signin', state: { from: props.location } }} />)
            )
          }
        />
      );
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;
