//@ts-nocheck
import React from "react";
// Customizable Area Start
import SidebarBuyer from "../../../components/src/Sidebar-buyer";
import SidebarSeller from "../../../components/src/Sidebar";
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import ChatPageController, { Props } from "../src/ChatPageController.web";
import Header from "../../dashboard/src/Seller/AddPropertyStepper/Header.web";
import { viewBuyerImage, imageMail, imageAttachment } from "./assets";
import AppLoader from "../../../components/src/AppLoader.web";
import { Formik, Form, Field } from "formik";
import { isEmpty } from "lodash";
import moment from "moment";

class ChatPage extends ChatPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // console.log("chats--State", this.state.message);
    return (
      <>
        <div className="flex">
          <div>
            {/* Slider  */}
            {localStorage.getItem("role") === "buyer" ? (
              <SidebarBuyer menuCollapsed={true} />
            ) : (
              <SidebarSeller menuCollapsed={true} />
            )}
          </div>
          <div className="stepper-outer">
            <Header
              flag={this.state.HeaderDropdownFlag}
              toggle={this.doToggleHeaderDropdown}
            />
            <div className="new-msg-block">
              <div className="msg-notification">
                <button type="button" className="btn orange-fill-btn">
                  Messages
                  <span>{this.state.chats?.length}</span>
                </button>
              </div>
              <div className="new-msg">
                <button type="button" className="rounded-fill-btn">
                  New Message
                </button>
              </div>
            </div>
            <div className="chat-block-wrapper">
              <div className="chat-list-wrapper">
                <div className="search-wrapper">
                  <Formik
                  initialValues={{}}
                  // validationSchema={Yup.object().shape(this.state.emailSchema)}
                  onSubmit={(values) => console.log(values)}
                  enableReinitialize
                  >
                    {({ values, setFieldValue, errors }) => (
                    <Form>
                      <Field
                        type="text"
                        name="Keywords"
                        placeholder="MLS, Yard, etc…"
                        className="custom-input secondary-input"
                      />
                      </Form>
                    )}
                  </Formik>
                </div>
                {!isEmpty(this.state.chats) && this.state.chats.map(conv => <>
                  {/* {console.log("conv?.attributes?.communicator?",conv?.attributes?.communicator?.data?.attributes)} */}
                  <div className={`chat-block ${this.state.coversationId === conv.id ? "active-chat" : ""}`} key={conv.id} onClick={() => this.doConversationId(conv.id)}>
                    <span className="image-block">
                      <img src={viewBuyerImage} alt="" />
                    </span>
                    <div className="right-block">
                      <div className="top-block">
                        <span className="name">{`${conv?.attributes?.communicator?.data?.attributes?.first_name ? conv?.attributes?.communicator?.data?.attributes?.first_name : "John"} ${conv?.attributes?.communicator?.data?.attributes?.last_name ? conv?.attributes?.communicator?.data?.attributes?.last_name : "Dow"}`}</span>
                        <span className="mail-icon">
                          <img src={conv?.attributes?.communicator?.data?.attributes?.profile_picture ? conv?.attributes?.communicator?.data?.attributes?.profile_picture : imageMail} alt="" />
                        </span>
                        <span className="msg-time">{moment(conv?.attributes?.last_message?.attributes?.created_at).format("h:mm A")}</span>
                      </div>
                      <div className="msg-content">
                        <p>
                          {conv?.attributes?.last_message?.attributes?.body ? conv?.attributes?.last_message?.attributes?.body : "No Message"}
                        </p>
                      </div>
                    </div>
                  </div>
                </>)}
                {/* <div className="chat-block active-chat">
                  <span className="image-block">
                    <img src={viewBuyerImage} alt="" />
                  </span>
                  <div className="right-block">
                    <div className="top-block">
                      <span className="name">John Dow </span>
                      <span className="msg-time">9:40 AM</span>
                    </div>
                    <div className="msg-content">
                      <p>
                        Lorem ipsum dolor sit amonsectetur orem ipum dolor sit
                        amet, consectetur
                      </p>
                    </div>
                  </div>
                </div>
                <div className="chat-block">
                  <span className="image-block">
                    <img src={viewBuyerImage} alt="" />
                  </span>
                  <div className="right-block">
                    <div className="top-block">
                      <span className="name">John Dow </span>
                      <span className="msg-time">9:40 AM</span>
                    </div>
                    <div className="msg-content">
                      <p>
                        Lorem ipsum dolor sit amonsectetur orem ipum dolor sit
                        amet, consectetur
                      </p>
                    </div>
                  </div>
                </div>
                <div className="chat-block">
                  <span className="image-block">
                    <img src={viewBuyerImage} alt="" />
                  </span>
                  <div className="right-block">
                    <div className="top-block">
                      <span className="name">John Dow </span>
                      <span className="mail-icon">
                        <img src={imageMail} alt="" />
                      </span>
                      <span className="msg-time">9:40 AM</span>
                    </div>
                    <div className="msg-content">
                      <p>
                        Lorem ipsum dolor sit amonsectetur orem ipum dolor sit
                        amet, consectetur
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="chat-content">
                <div className="content-header">
                  <span className="title">Seller Made Counter Offer</span>
                  <div className="btn-wrapper">
                    <button type="button" className="btn orange-fill-btn">
                      Resolved
                    </button>
                    <button type="button" className="gray-border-btn">
                      Delete
                    </button>
                  </div>
                </div>
                <div className="content-block">
                  <div className="payment-block">
                    <div className="head-block">
                      <div className="profile-block">
                        <img src={viewBuyerImage} alt="" />
                      </div>
                      <span className="profile-name">Stephen Cross</span>
                    </div>
                    <div className="content">
                      <div className="left-block">
                        <img src={imageAttachment} alt="" />
                      </div>
                      <div className="right-block">
                        <span className="heading">
                          Seller Made Counter Offer
                        </span>
                        <div className="value-wrapper">
                          <div className="title">Monthly payment : </div>
                          <div className="value">$4,000</div>
                        </div>
                        <div className="value-wrapper">
                          <div className="title">Monthly payment : </div>
                          <div className="value">$4,000</div>
                        </div>
                        <div className="btn-wrapper">
                          <button type="button" className="btn orange-fill-btn">
                            Accept
                          </button>
                          <button type="button" className="gray-border-btn">
                            Counter Offer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chat-message-wrapper">
                    <div className="send-message">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do
                      </p>
                    </div>
                    <div className="recive-message">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do
                      </p>
                    </div>
                    <div className="send-message img-block">
                      <img src={imageAttachment} alt="" />
                    </div>
                    <div className="send-message">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do
                      </p>
                    </div>
                    <div className="recive-message">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do
                      </p>
                    </div>
                    <div className="recive-message img-block">
                      <img src={imageAttachment} alt="" />
                    </div>
                  </div>
                  <div className="send-msg-block">
                    <Formik>
                      <Form>
                      <label htmlFor="attachmentId">
                        <img alt="Attachment" className="btn-image" src={imageAttachment} />
                      </label>
                      <input
                        type="file"
                        name="attachment"
                        id="attachmentId"
                        className="custom-input secondary-input uploadImage file1"
                        multiple
                      />
                        <Field
                          type="text"
                          name="Keywords"
                          placeholder="MLS, Yard, etc…"
                          className="custom-input secondary-input"
                        />
                         <button type="submit" className="rounded-fill-btn">
                    send
                  </button>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Loader  */}
        {this.state.loader && <AppLoader title="Loading" />}
      </>
    );
  }
}

// Customizable Area Start
//@ts-ignore
export default withRouter(ChatPage);

// Customizable Area End
